import { Link } from "react-router-dom";
import CardCarousel from "./CardCarousel";

const Carousel = () => {
  return (
    <div className="bg-[#000023] font-proxima">
      <div className="container mx-auto max-w-full h-full p-12 flex flex-col text-center items-center my-20">
        <div className="w-full flex flex-col text-center items-center  mb-10">
          <h2 className=" mb-7 w-[80%] lg:w-[50%]  text-3xl md:text-4xl lg:text-[40px] font-bold leading-10 font-proxima  ">
            Nos basamos en una
            <span className="text-[#E84A84]"> estrategia 360</span> para
            potencializar tu carrera
          </h2>
        </div>
        <div className="w-full  items-center justify-center mt-10 mb-16">
          <CardCarousel />
        </div>
        <div className="w-full mt-4">
          <Link
            to="/servicios"
            className=" btn--secondary gradient hover:scale-75 transition duration-75 w-28  mx-auto"
          >
            Ver más
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
