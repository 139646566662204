import FirstService from "./FirstService";
import SecondService from "./SecondService";
import ThirdService from "./ThirdService";

const Services = () => {
  return (
    <section className="min-h-screen mt-20 pb-12">
      <FirstService />
      <SecondService />
      <ThirdService />
    </section>
  );
};

export default Services;
