import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { crearLogro } from "../../../../actions/adminActions";
import { formatDDMMYYYY } from "../../../../utilidades/Format";
import Cargando from "../../../general/Cargando";

const ModalAcchievements = ({ setModalAcchievements }) => {
  const dispatch = useDispatch();
  const [achievements, setAchievements] = useState({
    nombre: "",
    descripcion: "",
    foto: "",
  });

  const [cargando, setCargando] = useState(null);
  const [message, setMessage] = useState("");

  const { nombre, descripcion, foto } = achievements;
  const { id } = useParams();
  const handleChange = (e) => {
    setAchievements({ ...achievements, [e.target.name]: e.target.value });
  };
  const handleFile = (e) => {
    setAchievements({ ...achievements, [e.target.name]: e.target.files[0] });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nombre) {
      return setMessage("El nombre del logro es obligatorio");
    }
    if (!descripcion) {
      return setMessage("La descripción del logro es obligatoria");
    }
    if (!foto) {
      return setMessage("La foto del logro es obligatoria");
    }
    setCargando(true);
    await crearLogro(dispatch, {
      ...achievements,
      fecha: new Date(),
      artista: id,
    });
    setModalAcchievements(false);
    setCargando(false);
  };

  return (
    <div className="bg-white p-4 rounded-lg w-full text-gray-700 font-medium text-sm lg:text-base md:w-[28rem] max-w-[30rem]">
      {cargando ? <Cargando /> : null}
      <section className="flex justify-between items-center">
        <h3 className="font-semibold text-base lg:text-lg">
          Agregar nuevo logro
        </h3>
        <span className="text-rosa-primariy font-medium text-xs lg:text-base">
          {formatDDMMYYYY(new Date())}
        </span>
      </section>
      <form
        className="flex flex-col space-y-3 my-3 md:flex-row md:space-y-2 md:w-full md:flex-wrap"
        onSubmit={handleSubmit}
      >
        <section className="space-y-3 md:space-y-0 md:flex md:w-full md:justify-between">
          <div className="flex flex-col">
            <label htmlFor="nombre">Logro</label>
            <input
              className="border-b border-gray-400 focus:outline-none focus:border-rosa-primariy p-1"
              type="text"
              id="nombre"
              name="nombre"
              placeholder="Introduce nuevo logro"
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="descripcion">Descripción del logro</label>
            <input
              className="border-b border-gray-400 focus:outline-none focus:border-rosa-primariy p-1"
              type="text"
              name="descripcion"
              placeholder="Descripción del logro"
              onChange={handleChange}
            />
          </div>
        </section>
        <div className="flex flex-col w-full border-3">
          <p htmlFor="">Imagen del logro</p>
          <label
            className="bg-rosa-primariy p-2 text-white text-center rounded-md mt-2 cursor-pointer md:w-56"
            htmlFor="inputImage"
          >
            Seleccionar archivo
          </label>
          <input
            className="hidden"
            id="inputImage"
            type="file"
            name="foto"
            onChange={(e) => handleFile(e)}
          />
          <span className=" font-light text-rosa-primariy w-full overflow-x-hidden whitespace-nowrap overflow-ellipsis text-xs mb-2">
            {foto?.name}
          </span>
        </div>
        <div className="flex justify-center items-center gap-4 md:w-full md:justify-end">
          <button
            className="border-2 border-rosa-primariy rounded py-1 px-2 text-rosa-primariy font-semibold w-[40%] md:w-28"
            onClick={() => setModalAcchievements(false)}
          >
            Cancelar
          </button>
          <button
            className="border-2 border-rosa-primariy bg-rosa-primariy rounded py-1 px-2 text-white font-semibold w-[40%] md:w-28"
            type="submit"
          >
            Guardar
          </button>
        </div>
        <p className="text-rosa-primariy">{message}</p>
      </form>
    </div>
  );
};

export default ModalAcchievements;
