import React, { useState } from "react";
import DialogoEditar from "../../../general/DialogoEditar";
import PropTypes from "prop-types";
import {
  formatoNumero,
  generarUID,
  obtenerNumeroFormato,
} from "../../../../utilidades/FuncionesAuxiliares";

const EditarArchivo = ({ handleGuardar, handleCancelar, archivo }) => {
  const [archivoActual, setArchivoActual] = useState(
    archivo ?? {
      nombre: "",
      descripcion: "",
      costo: 0,
      archivo: "",
      uid: generarUID(),
    }
  );

  const handleChange = (e) => {
    if (e.target.name === "costo") {
      const nuevoCosto = obtenerNumeroFormato(e.target.value) + 0;
      setArchivoActual({
        ...archivoActual,
        costo: nuevoCosto,
      });
    } else {
      setArchivoActual({
        ...archivoActual,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleFile = (e) => {
    setArchivoActual({
      ...archivoActual,
      [e.target.name]: e.target.files[0],
    });
  };
  return (
    <DialogoEditar
      handleCancelar={handleCancelar}
      handleGuardar={() => handleGuardar(archivoActual)}
      habilitado={
        !!archivoActual.nombre &
        !!archivoActual.descripcion &
        !!archivoActual.costo &
        !!archivoActual.archivo
      }
    >
      <div className="flex flex-col ml-1">
        <h3 className="font-bold text-xl text-black sm:mt-8 font-proxima">
          Nombre del archivo
        </h3>
        <input
          type="text"
          value={archivoActual.nombre}
          onChange={handleChange}
          name="nombre"
          placeholder="Nombre del archivo"
          className="w-[440px] border-b-[1px] border-[#BBB] placeholder-[#BBB] mt-4 outline-none text-black max-w-full"
        />
        <h3 className="font-bold text-xl text-black mt-8 font-proxima">
          Descripción
        </h3>
        <input
          type="text"
          value={archivoActual.descripcion}
          onChange={handleChange}
          name="descripcion"
          placeholder="Descripción"
          className="w-[440px] border-b-[1px] border-[#BBB] placeholder-[#BBB] mt-4 outline-none text-black max-w-full"
        />
        <h3 className="font-bold text-xl text-black mt-8 font-proxima">
          Costo
        </h3>
        <input
          type="text"
          value={formatoNumero(archivoActual.costo, true, true, 2, false)}
          onChange={handleChange}
          name="costo"
          placeholder="$ "
          className="w-[440px] border-b-[1px] border-[#BBB] placeholder-[#BBB] mt-4 outline-none text-black max-w-full"
        />
        <div className="sm:self-end flex flex-col-reverse sm:flex-row items-center mt-4">
          <span className="text-[#BEBEBE] mt-2 sm:mt-0 sm:mr-5 underline text-lg whitespace-nowrap w-max overflow-x-hidden max-w-[40%]">
            {archivoActual.archivo?.name ?? archivo?.nombre}
          </span>
          <label
            htmlFor="video-curso"
            className="bg-[#E84A84] text-white relative w-max px-[10px] py-2 rounded-md cursor-pointer text-[17px]"
          >
            <input
              id="video-curso"
              type="file"
              onChange={handleFile}
              name="archivo"
              className="absolute w-full h-full opacity-0 top-0 left-0 right-0 bottom-0 outline-none cursor-pointer z-[-1]"
            />
            Seleccionar archivo
          </label>
        </div>
      </div>
    </DialogoEditar>
  );
};

EditarArchivo.propTypes = {
  handleGuardar: PropTypes.func.isRequired,
  handleCancelar: PropTypes.func.isRequired,
  archivo: PropTypes.any,
};

export default EditarArchivo;
