import { HiOutlineTrash, HiOutlinePencil } from "react-icons/hi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { obtenerUrl } from "../../../../utilidades/FuncionesAuxiliares";

const TableAchievements = ({
  headers,
  rows,
  modalAcchievement,
  modalRemoveAcchievement,
  modalEditAcchievement,
}) => {
  return (
    <div className="text-right font-proxima h-full mt-4 xl:m-0 lg:w-full">
      <div className="relative h-auto overflow-x-auto mb-4 rounded-lg">
        {rows?.length !== 0 && rows !== undefined ? (
          <table className="w-full text-xs text-center text-gray-500 md:text-base">
            <thead className="text-xs text-black bg-gray-100 md:text-base">
              <tr>
                {headers?.map((header) => (
                  <th scope="col" className="px-6 py-3">
                    {header}
                  </th>
                ))}
                <th scope="col" className="px-2 py-3">
                  <span className="sr-only">Edit</span>
                </th>
                <th scope="col" className="px-2 py-3">
                  <span className="sr-only">Delete</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {rows?.map((row) => (
                <tr className="bg-white border-b" id={row.id}>
                  <th
                    scope="row"
                    className="px-6 py-4 font-semibold whitespace-nowrap"
                    id={row.id}
                  >
                    {new Date(row.fecha).toLocaleDateString()}
                  </th>
                  <td className="px-6 py-4" id={row.id}>
                    {row?.nombre}
                  </td>
                  <td className="px-6 py-4" id={row.id}>
                    {row?.foto ? (
                      <a
                        className="text-xs md:text-base font-medium underline text-rosa-primariy hover:text-pink-800 hover:font-semibold"
                        href={obtenerUrl(row.foto)}
                        target="noopener"
                        id={row.id}
                      >
                        Abrir
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    className="px-2 py-4 cursor-pointer hover:bg-gray-100 "
                    id={row.id}
                    onClick={() => modalEditAcchievement(row.id)}
                  >
                    <HiOutlinePencil color="#E84A84" size={20} />
                  </td>
                  <td
                    className="px-2 py-4 cursor-pointer hover:bg-gray-100 "
                    id={row.id}
                    onClick={() => modalRemoveAcchievement(row.id)}
                  >
                    <HiOutlineTrash color="#E84A84" size={20} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex items-center w-full justify-center text-white bg-white/10 rounded-lg p-2">
            <span className="font-bold text-base font-proxima text-center">
              No hay ningún logro asociado a este artista
            </span>
          </div>
        )}
        <p
          className="my-2 text-left decoration-slice underline cursor-pointer flex items-center gap-2 text-sm sm:text-base hover:text-[#E84A84]"
          onClick={() => modalAcchievement(true)}
        >
          Agregar nuevo logro
          <AiOutlinePlusCircle />
        </p>
      </div>
    </div>
  );
};

export default TableAchievements;
