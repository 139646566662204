import AudienceKnowUs from "./AudienceKnowUs";
import Hero from "./Hero";
import MusicCareer from "./MusicCareer";

const KnowUs = () => {
  return (
    <div className="mt-20">
      <Hero />
      <MusicCareer />
      <AudienceKnowUs />
    </div>
  );
};

export default KnowUs;
