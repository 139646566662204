import {
  EyeIcon,
  EyeOffIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  eliminarRedArtista,
  eliminarRegistroRedesSocialesArtista,
  getRedesSocialesArtista,
} from "../../../../actions/adminActions";
import { ModalContext } from "../../../../context/ModalContext";
import { selectRedesSocialesArtista } from "../../../../reducers/adminReducer";
import { COLORES } from "../../../../utilidades/Constantes";
import GraficaRedesSociales from "../../../general/GraficaRedesSociales";
import CrearEditarRedSocial from "./CrearEditarRedSocial";
import CrearEditarRegistro from "./CrearEditarRegistro";

const RedesSociales = () => {
  const { id } = useParams();
  const redesSociales = useSelector((state) =>
    selectRedesSocialesArtista(state, id)
  );
  const [redes, setRedes] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!redesSociales) {
      getRedesSocialesArtista(id, dispatch);
    } else {
      setRedes(redesSociales.redes);
    }
  }, [redesSociales]);

  let header = ["Fecha"];
  header = header.concat(redesSociales?.redes?.map((red) => red.nombre) || []);

  const { setModal } = useContext(ModalContext);

  const toggleVisible = (red, field) => {
    setRedes(
      redes.map((currentRed) => {
        if (currentRed.id === red.id) {
          return {
            ...currentRed,
            [field]: !currentRed[field],
          };
        } else {
          return currentRed;
        }
      })
    );
  };

  return (
    <div className="w-full">
      <span className="text-[24px] mb-5 block font-semibold">
        Seguidores redes sociales
      </span>
      {redesSociales?.registrosSeguidoresRedes.length > 0 &&
      redesSociales?.redes.length > 0 ? (
        <>
          <GraficaRedesSociales
            registrosSeguidoresRedes={redesSociales?.registrosSeguidoresRedes}
            redes={redesSociales?.redes}
          />
          <div className="flex flex-col mt-4">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden rounded-lg">
                  <table className="w-full text-left">
                    <thead className="border-b bg-gray-100">
                      <tr>
                        {header.map((opcion) => (
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 w-[25%]"
                          >
                            {opcion}
                          </th>
                        ))}
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 w-[25%]"
                        >
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {redesSociales?.registrosSeguidoresRedes?.map(
                        (registroSeguidoresRedes) => {
                          return (
                            <tr className="bg-white border-b">
                              {header.map((h) => {
                                let value;
                                if (h === "Fecha") {
                                  value = moment(
                                    registroSeguidoresRedes.fecha
                                  ).format("DD/MM/YYYY");
                                } else {
                                  const registroRed =
                                    registroSeguidoresRedes.registrosRedes.find(
                                      (registroRed) =>
                                        registroRed.red.nombre === h
                                    );

                                  value = registroRed?.numeroSeguidores || "-";
                                }
                                return (
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {value}
                                  </td>
                                );
                              })}
                              <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                <div className="flex">
                                  <PencilIcon
                                    className="w-[20px] cursor-pointer"
                                    color={COLORES.PRIMARIO}
                                    onClick={() =>
                                      setModal(
                                        <CrearEditarRegistro
                                          registro={registroSeguidoresRedes}
                                          redes={redesSociales?.redes}
                                          idArtista={id}
                                        />
                                      )
                                    }
                                  ></PencilIcon>
                                  <TrashIcon
                                    className="w-[20px] cursor-pointer ml-2"
                                    color={COLORES.PRIMARIO}
                                    onClick={async () => {
                                      await eliminarRegistroRedesSocialesArtista(
                                        registroSeguidoresRedes.id,
                                        id,
                                        dispatch
                                      );
                                      toast.success("Registro eliminado");
                                    }}
                                  ></TrashIcon>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <span className="mx-auto my-10 text-[20px] block text-center">
          Todavía no hay ningún registro
        </span>
      )}

      {redesSociales?.redes.length > 0 && (
        <div
          className="flex items-center cursor-pointer hover:text-rosa-primariy"
          onClick={() =>
            setModal(
              <CrearEditarRegistro
                redes={redesSociales?.redes}
                idArtista={id}
              />
            )
          }
        >
          <span className="underline">Agrega nuevo registro</span>
          <PlusCircleIcon className="w-[20px] ml-2" />
        </div>
      )}

      <hr className="text-white my-8" />
      <span className="text-[24px] mb-5 block font-semibold">
        Manejo de cuentas
      </span>
      {redesSociales?.redes.length > 0 ? (
        <div className="flex flex-col mt-4">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden rounded-lg">
                <table className="w-full text-left">
                  <thead className="border-b bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 w-[25%]"
                      >
                        Red social
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 w-[25%]"
                      >
                        Enlace
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 w-[25%]"
                      >
                        Usuario
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 w-[25%]"
                      >
                        Contraseña
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 w-[25%]"
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {redes?.map((red) => {
                      return (
                        <tr className="bg-white border-b">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {red.nombre}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {red.enlace}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            <div className="flex items-center w-full justify-between">
                              <span>
                                {red.userVisible
                                  ? red.usuario
                                  : "*".repeat(red.usuario.length)}
                              </span>
                              {red.userVisible ? (
                                <EyeOffIcon
                                  onClick={() =>
                                    toggleVisible(red, "userVisible")
                                  }
                                  color={COLORES.PRIMARIO}
                                  className="w-[20px] cursor-pointer"
                                />
                              ) : (
                                <EyeIcon
                                  onClick={() =>
                                    toggleVisible(red, "userVisible")
                                  }
                                  color={COLORES.PRIMARIO}
                                  className="w-[20px] cursor-pointer"
                                />
                              )}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            <div className="flex items-center w-full justify-between">
                              <span>
                                {red.passwordVisible
                                  ? red.contraseña
                                  : "*".repeat(red.contraseña.length)}
                              </span>
                              {red.passwordVisible ? (
                                <EyeOffIcon
                                  onClick={() =>
                                    toggleVisible(red, "passwordVisible")
                                  }
                                  color={COLORES.PRIMARIO}
                                  className="w-[20px] cursor-pointer"
                                />
                              ) : (
                                <EyeIcon
                                  onClick={() =>
                                    toggleVisible(red, "passwordVisible")
                                  }
                                  color={COLORES.PRIMARIO}
                                  className="w-[20px] cursor-pointer"
                                />
                              )}
                            </div>
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                            <div className="flex">
                              <PencilIcon
                                className="w-[20px] cursor-pointer"
                                color={COLORES.PRIMARIO}
                                onClick={() =>
                                  setModal(
                                    <CrearEditarRedSocial
                                      red={red}
                                      idArtista={id}
                                    />
                                  )
                                }
                              ></PencilIcon>
                              <TrashIcon
                                className="w-[20px] cursor-pointer ml-2"
                                color={COLORES.PRIMARIO}
                                onClick={async () => {
                                  await eliminarRedArtista(
                                    red.id,
                                    id,
                                    dispatch
                                  );
                                  toast.success("Red eliminada");
                                }}
                              ></TrashIcon>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <span className="mx-auto my-10 text-[20px] block text-center">
          Todavía no hay ninguna red asociada al artista
        </span>
      )}
      <div
        className="flex items-center cursor-pointer hover:text-rosa-primariy mb-5"
        onClick={() => setModal(<CrearEditarRedSocial idArtista={id} />)}
      >
        <span className="underline">Agregar una nueva red social</span>
        <PlusCircleIcon className="w-[20px] ml-2" />
      </div>
    </div>
  );
};

export default RedesSociales;
