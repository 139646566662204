import React, { Component } from "react";
// import PropTypes from 'prop-types';
import HeaderAdministrador from "./componentes/HeaderAdministrador/HeaderAdministrador";
import "./administrador.scss";
import { Redirect, Route, Switch } from "react-router-dom";
import PaginaPrincipalAdministrador from "./componentes/PaginaPrincipalAdministrador/PaginaPrincipalAdministrador";
// import AdministrarArtista from "./componentes/AdministrarArtista/AdministrarArtista";
import DialogoAdministrador from "./componentes/DialogoAdministrador/DialogoAdministrador";
import { connect } from "react-redux";
import * as actions from "./../../actions/adminActions";
import { bindActionCreators } from "redux";
import { getUser } from "../../reducers/sesionReducer";
import { ROL_ADMIN } from "../../utilidades/Constantes";
import ScaleLoader from "react-spinners/ScaleLoader";
import PaginaTemas from "./componentes/PaginaTemas/PaginaTemas";
import DetalleTema from "./componentes/DetalleTema/DetalleTema";
import DetalleCategoria from "./componentes/DetalleCategoria/DetalleCategoria";
import DetalleCurso from "./componentes/DetalleCurso/DetalleCurso";
import Distribuidoras from "./componentes/Distribuidoras/Distribuidoras";
import ProfileRoute from "./Profile/ProfileRoute";

class Administrador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogo_activo: false,
      contenido_dialogo: null,
      dialogo_grande: false,
    };
  }

  handleCerrarDialogo = () => this.setState({ dialogo_activo: false });

  handleAbrirDialogo = (contenido, grande = false) =>
    this.setState({
      dialogo_activo: true,
      contenido_dialogo: contenido,
      dialogo_grande: grande,
    });

  render() {
    return (
      <div className="fondo-administrador">
        <div className="max-w-[1500px] mx-auto flex flex-col items-center w-full">
          <HeaderAdministrador />
          {this.props.user && this.props.user.rol?.id === ROL_ADMIN ? (
            <Switch>
              <Route path="/administrador/artistas/:id">
                {/* <AdministrarArtista
                  handleAbrirDialogo={this.handleAbrirDialogo}
                  handleCerrarDialogo={this.handleCerrarDialogo}
                /> */}
                <ProfileRoute
                  handleAbrirDialogo={this.handleAbrirDialogo}
                  handleCerrarDialogo={this.handleCerrarDialogo}
                />
              </Route>
              <Route path="/administrador/artistas">
                <PaginaPrincipalAdministrador
                  artistas={this.props.artistas}
                  handleAbrirDialogo={this.handleAbrirDialogo}
                  handleCerrarDialogo={this.handleCerrarDialogo}
                />
              </Route>
              <Route path="/administrador/distribuidoras">
                <Distribuidoras />
              </Route>
              <Route path="/administrador/academia/:idTema/categoria/:idCategoria/curso/:idCurso">
                <DetalleCurso />
              </Route>
              <Route path="/administrador/academia/:idTema/categoria/:idCategoria">
                <DetalleCategoria />
              </Route>
              <Route path="/administrador/academia/:id">
                <DetalleTema />
              </Route>
              <Route path="/administrador/academia">
                <PaginaTemas />
              </Route>
              <Route path="/administrador">
                <Redirect to="/administrador/artistas" />
              </Route>
            </Switch>
          ) : (
            <div className="loading-admin">
              <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
            </div>
          )}
          {this.state.dialogo_activo && (
            <DialogoAdministrador
              handleCerrarDialogo={this.handleCerrarDialogo}
              grande={this.state.dialogo_grande}
            >
              {this.state.contenido_dialogo}
            </DialogoAdministrador>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // artistas: getArtistas(state),
  artistas: [],
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Administrador);
