import React, { useState } from "react";

import InputNumber from "../Inputs/InputNumber";
import InputText from "../Inputs/InputText";
import { UploadIcon } from "@heroicons/react/outline";
import InputRadio from "../Inputs/InputRadio";
import { useDispatch } from "react-redux";
import { TIPOS_DISTRIBUIDORAS } from "../../../../utilidades/Constantes";
import { BeatLoader } from "react-spinners";
import { editDitribuidoras, postDistribuidoras } from "../../../../actions/adminActions";

const ModalDistribuidora = ({ modal, setModal }) => {
  const [distribuidora, setDistribuidora] = useState(
    modal.distribuidora || {
      nombre: "",
      tipo: TIPOS_DISTRIBUIDORAS.PAGO_ANUAL,
      pagoSencillo: "",
      pagoAlbum: "",
      file: {},
    }
  );
  const [loading, setLoading] = useState(false);
  const inputFileRef = React.useRef();

  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (e.target.name === "tipo") {
      setDistribuidora({
        ...distribuidora,
        [e.target.name]: parseInt(e.target.value),
      });
    } else {
      setDistribuidora({ ...distribuidora, [e.target.name]: e.target.value });
    }
  };

  const handleFile = (e) => {
    setDistribuidora({ ...distribuidora, [e.target.name]: e.target.files[0] });
    console.log(distribuidora, "file");
  };

  console.log(distribuidora);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (distribuidora.tipo === TIPOS_DISTRIBUIDORAS.PAGO_ANUAL) {
      delete distribuidora.porcentaje;
    } else {
      delete distribuidora.pagoAlbum;
      delete distribuidora.pagoSencillo;
    }

    if (typeof distribuidora.foto === "string") {
      delete distribuidora.foto;
    }

    if (distribuidora.id) {
      setLoading(true);
      await editDitribuidoras(distribuidora.id, distribuidora, dispatch);
      setLoading(false);
    } else {
      setLoading(true);
      await postDistribuidoras(distribuidora, dispatch);
      setLoading(false);
    }

    setModal(!modal);
  };

  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 w-full h-full min-h-fit bg-black/40 z-10 py-[50px] grid place-items-center overflow-y-scroll">
      <div className="bg-white w-[50%] min-w-[224px] box-content rounded-lg p-8 z-20 max-w-[600px] m-auto">
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between flex-col space-y-8 w-full">
            {modal.add && (
              <h4 className="text-3xl text-blackPrimary font-bold">Crear</h4>
            )}
            {modal.edit && (
              <h4 className="text-3xl text-blackPrimary font-bold">Editar</h4>
            )}
            <InputText
              labelText="Nombre de la distribuidora"
              placeholderText="Ingresa nombre de la distribuidora"
              handleChange={handleChange}
              name="nombre"
              value={distribuidora.nombre}
            />
            <div className="flex flex-col md:flex-row w-full justify-start items-start">
              <p className="text-blackPrimary text-[18px] font-normal  w-full">
                Comisiones
              </p>
              <div className="flex flex-col w-full gap-2">
                <InputRadio
                  handleChange={handleChange}
                  nameText={"tipo"}
                  valueText={1}
                  labelText="Pago Anual"
                  checkedConditional={
                    distribuidora.tipo === TIPOS_DISTRIBUIDORAS.PAGO_ANUAL
                  }
                  // refRadio={inputButton}
                  classNameRadio={`w-5 h-5 ${
                    distribuidora.tipo === 0 ? "checked" : ""
                  }`}
                />
                <InputRadio
                  handleChange={handleChange}
                  nameText={"tipo"}
                  valueText={2}
                  labelText="Pago Porcentual"
                  checkedConditional={
                    distribuidora.tipo === TIPOS_DISTRIBUIDORAS.PORCENTAJE
                  }
                  // refRadio={inputButton}
                  classNameRadio={`w-5 h-5 ${
                    distribuidora.tipo === 0 ? "checked" : ""
                  }`}
                />
              </div>
            </div>

            {distribuidora.tipo === TIPOS_DISTRIBUIDORAS.PAGO_ANUAL && (
              <>
                <InputNumber
                  labelText="Pago anual sencillo"
                  placeholderNumber={0}
                  handleChange={handleChange}
                  name="pagoSencillo"
                  value={distribuidora.pagoSencillo}
                />
                <InputNumber
                  labelText="Pago anual albúm"
                  placeholderNumber={0}
                  handleChange={handleChange}
                  name="pagoAlbum"
                  value={distribuidora.pagoAlbum}
                />
              </>
            )}

            {distribuidora.tipo === TIPOS_DISTRIBUIDORAS.PORCENTAJE && (
              <InputNumber
                labelText="Comisión(%)"
                placeholderNumber={0}
                handleChange={handleChange}
                name="porcentaje"
                value={distribuidora.porcentaje}
              />
            )}

            <div className="relative">
              <button
                className="btn-file"
                type="button"
                onClick={() => {
                  inputFileRef.current.click();
                }}
              >
                <div className="flex items-center gap-2">
                  <span>
                    {modal.add ? "Sube una imagen" : "Cambiar imagen"}
                  </span>
                  <UploadIcon className="w-5 h-5 text-white" />
                </div>
              </button>
              <span className="text-gray-400 ml-3 ">
                {distribuidora.foto
                  ? distribuidora.foto.name || distribuidora.foto
                  : "Selecciona un archivo"}
              </span>
              <input
                className="absolute top-0 left-0 hidden"
                type="file"
                name="foto"
                id="selectFile"
                onChange={handleFile}
                ref={inputFileRef}
                accept="image/*"
                // value={distribuidora.foto}
              />
            </div>
          </div>
          <div className="w-full flex justify-center md:justify-end gap-5 mt-10">
            <button
              type="button"
              className="border-2 border-rosa-primariy py-[10px] px-[25px] rounded-md p-5 text-rosa-primariy"
              onClick={() => setModal(!modal)}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="bg-rosa-primariy rounded-lg py-[10px] px-[25px] flex items-center justify-center"
            >
              {loading ? (
                <div className="w-full h-full">
                  <BeatLoader height={35 * 2} width={4 * 2} color={"#fff"} />
                </div>
              ) : (
                "Guardar"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalDistribuidora;
