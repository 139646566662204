import { createSelector } from "reselect";
import {
  CONSULTAR_REPERTORIO,
  CONSULTAR_HISTORIAL,
  CONSULTAR_HISTORIAL_REGALIAS,
  CONSULTAR_HISTORIAL_REGALIAS_YOUTUBE,
  ELIMINAR_ULTIMA_REGALIA,
  TRAER_REGALIAS,
  TRAER_PAGOS,
  GET_ARTISTA,
  EDIT_SENCILLO,
  PATCH_ARTISTA,
  GET_ARTISTAS,
  CREATE_ARTISTA,
  DELETE_SENCILLO,
  TRAER_PLATAFORMAS,
  CAMBIAR_ESTADO_PLATAFORMA,
  TRAER_TAREAS_ARTISTA,
  TAREA_ACTUALIZADA,
  DELETE_ALBUM,
  GET_DISTRIBUIDORAS,
  GET_SINGLE_DISTRIBUIDORA,
  ADD_DISTRIBUIDORA,
  DELETE_DISTRIBUIDORA,
  EDIT_DISTRIBUIDORA,
  CAMBIAR_FOTO_ARTISTA,
  GET_REDES_ARTISTA,
} from "../actions/adminActions";
import {
  ALBUM,
  EDITAR_ALBUM,
  EDITAR_LOGRO,
  EDITAR_SENCILLO,
  ELIMINAR_LOGRO,
  SENCILLO,
  SUBIR_ALBUM,
  SUBIR_LOGRO,
  SUBIR_PAGO,
  SUBIR_SENCILLO,
  TRAER_LOGROS,
} from "./../utilidades/Constantes";

const INITIAL_STATE = {
  artistas: null,
  artistasCompletos: false,
  regaliasArtista: null,
  plataformas: null,
  distribuidoras: null
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ARTISTAS: {
      const artistas = {};
      action.payload.forEach((artista) => {
        artistas[artista.id] = artista;
      });
      return { ...state, artistas, artistasCompletos: true };
    }
    case GET_ARTISTA: {
      const artista = action.payload;
  
      return {
        ...state,
        artistas: {
          ...(state.artistas || []),
          [artista.id]: { ...((state.artistas && state.artistas[artista.id]) || {}), ...artista, infoCompleta: true },
        },
      };
    }

    case EDIT_SENCILLO: {
      const sencillo = action.payload;
      const artista = state.artistas[sencillo.artistaId];

      return {
        ...state,
        artistas: {
          ...state.artistas,
          [artista.id]: {
            ...artista,
            canciones: { ...artista.canciones, [sencillo.id]: sencillo },
            ...(state.artistas || {}),
            [artista.id]: {
              ...((state.artistas && state.artistas[artista.id]) || {}),
              ...artista,
              infoBasica: true,
            },
          },
        },
      };
    }
    case CREATE_ARTISTA: {
      const artista = action.payload;
      return {
        ...state,
        artistas: {
          ...state.artistas,
          [artista.id]: artista,
        },
      };
    }
    case CONSULTAR_REPERTORIO: {
      const nuevo_artista = action.payload;

      const albumes = [];
      const sencillos = [];

      nuevo_artista.repertorio.forEach((obra) => {
        if (obra.tipo === ALBUM) {
          albumes.push(obra);
        } else if (obra.tipo === SENCILLO) {
          sencillos.push(obra);
        }
      });

      const nuevos_artistas = state.artistas.map((artista) => {
        if (artista.uidDoc === action.payload.uidDoc) {
          return { ...artista, albumes, sencillos };
        } else {
          return artista;
        }
      });

      return { ...state, artistas: nuevos_artistas };
    }
    case CONSULTAR_HISTORIAL: {
      const nuevo_artista = action.payload;

      const nuevos_artistas = state.artistas.map((artista) => {
        if (artista.uidDoc === action.payload.uidDoc) {
          return { ...artista, historial: nuevo_artista.historial };
        } else {
          return artista;
        }
      });

      return { ...state, artistas: nuevos_artistas };
    }
    case CONSULTAR_HISTORIAL_REGALIAS: {
      const nuevo_artista = action.payload;

      const nuevos_artistas = state.artistas.map((artista) => {
        if (artista.uidDoc === action.payload.uidDoc) {
          return {
            ...artista,
            historialRegalias: nuevo_artista.historialRegalias,
          };
        } else {
          return artista;
        }
      });

      return { ...state, artistas: nuevos_artistas };
    }
    case CONSULTAR_HISTORIAL_REGALIAS_YOUTUBE: {
      const historialRegaliasYoutube = action.payload;

      const nuevos_artistas = state.artistas.map((artista) => {
        if (artista.uidDoc === action.opciones.idArtista) {
          return {
            ...artista,
            historialRegaliasYoutube,
          };
        } else {
          return artista;
        }
      });

      return { ...state, artistas: nuevos_artistas };
    }
    case ELIMINAR_ULTIMA_REGALIA: {
      const regalias = state.regaliasArtista || [];
      return {
        ...state,
        regaliasArtista: regalias.filter(
          (regalia) => regalia.fecha !== action.payload?.fecha
        ),
      };
    }

    case TRAER_PAGOS: {
      const payload = action.payload;
      return {
        ...state,
        artistas: {
          ...(state.artistas || {}),
          [payload.idArtista]: {
            ...((state.artistas && state.artistas[payload.idArtista]) || {}),
            pagos: payload.pagos,
          },
        },
      };
    }
    case TRAER_REGALIAS: {
      const regaliasArtista = action.payload;
      return { ...state, regaliasArtista };
    }
    case PATCH_ARTISTA: {
      const artistaActualizado = action.payload;
      return {
        ...state,
        artistas: {
          ...state.artistas,
          [artistaActualizado.id]: artistaActualizado,
        },
      };
    }
    case SUBIR_PAGO: {
      const pago = action.payload;
      let balance = state.artistas[pago.artista].balanceCuenta;
      return {
        ...state,
        artistas: {
          ...state.artistas,
          [pago.artista]: {
            ...state.artistas[pago.artista],
            balanceCuenta: balance - pago.valor,
          },
        },
      };
    }
    case SUBIR_LOGRO: {
      const artistaId = action.id;
      return {
        ...state,
        artistas: {
          [artistaId]: {
            ...state.artistas[artistaId],
            logros: [
              ...(state.artistas[artistaId].logros || []),
              action.payload,
            ],
          },
        },
      };
    }
    case TRAER_LOGROS: {
      const artistaId = action.id;
      return {
        ...state,
        artistas: {
          ...state.artistas,
          [artistaId]: {
            ...state.artistas?.[artistaId],
            logros: action.payload || [],
          },
        },
      };
    }
    case TRAER_PLATAFORMAS: {
      return { ...state, plataformas: action.payload };
    }
    case ELIMINAR_LOGRO: {
      const artistaId = action.id;
      return {
        ...state,
        artistas: {
          [artistaId]: {
            ...state.artistas[artistaId],
            logros: state.artistas[artistaId].logros.filter(
              (logro) => logro.fecha !== action.payload.fecha
            ),
          },
        },
      };
    }
    case EDITAR_LOGRO: {
      const artistaId = action.id;
      return {
        ...state,
        artistas: {
          [artistaId]: {
            ...state.artistas[artistaId],
            logros: state.artistas[artistaId].logros.map((logro) => {
              if (logro.fecha === action.payload.fecha) {
                return action.payload;
              } else {
                return logro;
              }
            }),
          },
        },
      };
    }
    case SUBIR_ALBUM: {
      const artistaId = action.payload?.artistaId;
      return {
        ...state,
        artistas: {
          [artistaId]: {
            ...state.artistas?.[artistaId],
            albumes: [...state.artistas?.[artistaId]?.albumes, action.payload],
          },
        },
      };
    }
    case EDITAR_ALBUM: {
      const artistaId = action.payload?.artistaId;
      return {
        ...state,
        artistas: {
          [artistaId]: {
            ...state.artistas?.[artistaId],
            albumes: state.artistas?.[artistaId]?.albumes?.map((album) => {
              if (album.id === action.payload?.id) {
                return action.payload;
              } else {
                return album;
              }
            }),
          },
        },
      };
    }
    case SUBIR_SENCILLO: {
      const artistaId = action.payload?.artistaId;
      return {
        ...state,
        artistas: {
          [artistaId]: {
            ...state.artistas?.[artistaId],
            canciones: [
              ...state.artistas?.[artistaId]?.canciones,
              action.payload,
            ],
          },
        },
      };
    }
    case EDITAR_SENCILLO: {
      const sencilloId = action.payload?.id;
      const artistaId = action.payload?.artistaId;
      return {
        ...state,
        artistas: {
          [artistaId]: {
            ...state.artistas?.[artistaId],
            canciones: state.artistas?.[artistaId]?.canciones?.map(
              (cancion) => {
                if (cancion.id === sencilloId) {
                  return action.payload;
                } else {
                  return cancion;
                }
              }
            ),
          },
        },
      };
    }
    case CAMBIAR_ESTADO_PLATAFORMA: {
      const { artista, data, plataformaIndex } = action.payload;
      const plataformas = [...state.artistas[artista].plataformas];
      plataformas[plataformaIndex] = {
        ...plataformas[plataformaIndex],
        activa: data.activa,
      };

      return {
        ...state,
        artistas: {
          ...state.artistas,
          [artista]: {
            ...state.artistas[artista],
            plataformas,
          },
        },
      };
    }
    case TRAER_TAREAS_ARTISTA: {
      const { idArtista, tareas } = action.payload;
      return {
        ...state,
        artistas: {
          ...(state.artistas || {}),
          [idArtista]: {
            ...((state.artistas && state.artistas[idArtista]) || {}),
            tareas
          }
        }
      }
    }
    case TAREA_ACTUALIZADA: {
      const { idArtista, idCategoria, tareaArtista } = action.payload;
      const tareas = [...state.artistas[idArtista].tareas];
      const categoria = tareas.find(categoria => categoria.id === idCategoria);
      const tareaActual = categoria.tareas.find(tareaActual => tareaActual.id === tareaArtista.tarea);
      tareaActual.tareasArtista = [tareaArtista];

      return {
        ...state,
        artistas: {
          ...state.artistas,
          [idArtista]: {
            ...state.artistas[idArtista],
            tareas
          }
        }
      }
    }
    case DELETE_ALBUM: {
      const {idArtista, album} = action.payload;
      return {
        ...state,
        artistas: {
          ...state.artistas,
          [idArtista]: {
            ...state.artistas[idArtista],
            albumes: state.artistas[idArtista].albumes.filter(currentAlbum => album.id !== currentAlbum.id)
          }
        }
      }
    }
    case DELETE_SENCILLO: {
      const {idArtista, sencillo} = action.payload;
      return {
        ...state,
        artistas: {
          ...state.artistas,
          [idArtista]: {
            ...state.artistas[idArtista],
            canciones: state.artistas[idArtista].canciones.filter(cancion => sencillo.id !== cancion.id)
          }
        }
      }
    }
    case GET_DISTRIBUIDORAS:
      return { ...state, distribuidoras: action.payload };
    case GET_SINGLE_DISTRIBUIDORA:
      return { ...state, distribuidora: action.payload };
    case ADD_DISTRIBUIDORA:
      const distribuidoras = [...(state.distribuidoras || []), action.payload];
      return { ...state, distribuidoras };
    case DELETE_DISTRIBUIDORA:
      const distribuidoras2 = state.distribuidoras.filter(
        (distribuidora) => distribuidora.id !== action.payload.id
      );
      return { ...state, distribuidoras: distribuidoras2 };
    case EDIT_DISTRIBUIDORA:
      const distribuidoras3 = state.distribuidoras.map((distribuidora) =>
        distribuidora.id === action.payload.id ? action.payload : distribuidora
      );
      return { ...state, distribuidoras: distribuidoras3 };
    case CAMBIAR_FOTO_ARTISTA: {
      const {idArtista, foto} = action.payload;
      return {
        ...state,
        artistas: {
          ...state.artistas,
          [idArtista]: {
            ...state.artistas[idArtista],
            foto
          }
        }
      }
    }
    case GET_REDES_ARTISTA: {
      const {redes, registrosSeguidoresRedes, idArtista} = action.payload;
      
      return {
        ...state,
        artistas: {
          ...(state.artistas || {}),
          [idArtista]: {
            ...((state.artistas && state.artistas[idArtista]) || {}),
            redesSociales: {
              redes,
              registrosSeguidoresRedes
            }
          }
        }
      }
    }
    default:
      return state;
  }
};

export const selectArtistas = createSelector(
  (state) => state.adminReducer,
  (adminReducer) =>
    adminReducer.artistas ? Object.values(adminReducer.artistas) : null
);

export const getArtista = createSelector(
  (state, id) => [state.adminReducer, id],
  ([adminReducer, id]) => adminReducer.artistas && adminReducer.artistas[id]
);

export const selectArtistasCompletos = createSelector(
  (state) => state.adminReducer,
  (adminReducer) => adminReducer.artistasCompletos
);

export const getPagosArtista = createSelector(
  (state, idArtista) => ({ adminReducer: state.adminReducer, idArtista }),
  ({ adminReducer, idArtista }) =>
    adminReducer.artistas && adminReducer.artistas[idArtista]?.pagos
);

export const getRegalias = createSelector(
  (state) => state.adminReducer,
  (adminReducer) => adminReducer.regaliasArtista
);

export const selectPlataformas = createSelector(
  (state) => state.adminReducer,
  (adminReducer) => adminReducer.plataformas
);

export const getLogros = createSelector(
  (state, idArtista) => ({ adminReducer: state.adminReducer, idArtista }),
  ({ adminReducer, idArtista }) =>
    adminReducer.artistas && adminReducer.artistas[idArtista]?.logros
);

export const selectTareasArtista = createSelector(
  (state, id) => [state.adminReducer, id],
  ([adminReducer, id]) => adminReducer.artistas && adminReducer.artistas[id]?.tareas
);

export const selectDistribuidoras = createSelector(
  (state) => state.adminReducer,
  (adminReducer) => adminReducer.distribuidoras
);

export const selectRedesSocialesArtista = createSelector(
  (state, idArtista) => ({ adminReducer: state.adminReducer, idArtista }),
  ({ adminReducer, idArtista }) =>
    adminReducer.artistas && adminReducer.artistas[idArtista]?.redesSociales
);