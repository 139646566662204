import { TIPOS_DISTRIBUIDORAS } from "../../../../utilidades/Constantes";
import BeforeNextButtons from "./BeforeNextButtons";

const Regalias = ({
  distribuidoras,
  plataformas,
  crearRegaliaData,
  setCrearRegaliaData,
  currentStep,
  setCurrentStep,
}) => {
  let validDistribuidoraInputs = true;

  distribuidoras.forEach((distribuidora) => {
    const valorHistoricoPrevio =
      distribuidora.regalia?.valorHistoricoGenerado ||
      (crearRegaliaData.regaliasDistribuidora &&
        crearRegaliaData.regaliasDistribuidora[distribuidora.id]
          ?.valorHistoricoPrevioReferencia) ||
      0;
    const valorHistoricoGenerado = crearRegaliaData.regaliasDistribuidora
      ? crearRegaliaData.regaliasDistribuidora[distribuidora.id]
          ?.valorHistoricoGenerado || 0
      : 0;
    const diferenciaHistoricos = valorHistoricoGenerado - valorHistoricoPrevio;
    const comision =
      distribuidora.tipo === TIPOS_DISTRIBUIDORAS.PAGO_ANUAL
        ? 0
        : (distribuidora.porcentaje / 100) * diferenciaHistoricos;
    const total = diferenciaHistoricos - comision;

    validDistribuidoraInputs = validDistribuidoraInputs && total >= 0;
  });

  let validPlataformasInputs = true;

  plataformas.forEach((plataforma) => {
    validPlataformasInputs =
      validPlataformasInputs &&
      crearRegaliaData.regaliasPlataforma &&
      crearRegaliaData.regaliasPlataforma[plataforma.id]?.valor >= 0;
  });

  const handleDistribuidoraChange = (e, distribuidora) => {
    let valorHistoricoPrevio;
    let valorHistoricoGenerado;

    if (e.target.name === "valorHistoricoPrevioReferencia") {
      valorHistoricoPrevio = parseFloat(e.target.value) || 0;
      valorHistoricoGenerado =
        (crearRegaliaData.regaliasDistribuidora &&
          crearRegaliaData.regaliasDistribuidora[distribuidora.id]
            ?.valorHistoricoGenerado) ||
        0;
    } else if (e.target.name === "valorHistoricoGenerado") {
      valorHistoricoPrevio =
        distribuidora.regalia?.valorHistoricoGenerado ||
        (crearRegaliaData.regaliasDistribuidora &&
          crearRegaliaData.regaliasDistribuidora[distribuidora.id]
            ?.valorHistoricoPrevioReferencia) ||
        0;
      valorHistoricoGenerado = parseFloat(e.target.value) || 0;
    }

    const diferenciaHistoricos = valorHistoricoGenerado - valorHistoricoPrevio;
    const comision =
      distribuidora.tipo === TIPOS_DISTRIBUIDORAS.PAGO_ANUAL
        ? 0
        : (distribuidora.porcentaje / 100) * diferenciaHistoricos;
    const total = diferenciaHistoricos - comision;

    const dataActualizada = {
      valorHistoricoGenerado,
      valorHistoricoPrevioReferencia: valorHistoricoPrevio,
      distribuidora: distribuidora.id,
      total,
    };

    setCrearRegaliaData({
      ...crearRegaliaData,
      regaliasDistribuidora: {
        ...crearRegaliaData.regaliasDistribuidora,
        [distribuidora.id]: dataActualizada,
      },
    });
  };

  const handlePlataformaChange = (e, plataforma) => {
    setCrearRegaliaData({
      ...crearRegaliaData,
      regaliasPlataforma: {
        ...crearRegaliaData.regaliasPlataforma,
        [plataforma.id]: {
          valor: e.target.value === "" ? 0 : parseFloat(e.target.value),
          comision:
            e.target.value === ""
              ? 0
              : (parseFloat(e.target.value) * (plataforma.porcentaje || 0)) /
                100,
          plataforma: plataforma.id,
        },
      },
    });
  };

  return (
    <>
      <h3 className="my-[20px]">Distribuidoras</h3>
      {/* Header */}
      <div className="grid grid-cols-5 rounded-2xl shadow-lg overflow-hidden">
        <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]">
          Distribuidoras
        </h3>
        <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]">
          Nuevo valor histórico
        </h3>
        <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]">
          Valor previo histórico
        </h3>
        <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]">
          Comisión distribuidora
        </h3>
        <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]">
          Total
        </h3>
        {distribuidoras.map((distribuidora) => {
          const valorHistoricoPrevio =
            distribuidora.regalia?.valorHistoricoGenerado ||
            (crearRegaliaData.regaliasDistribuidora &&
              crearRegaliaData.regaliasDistribuidora[distribuidora.id]
                ?.valorHistoricoPrevioReferencia) ||
            0;
          const valorHistoricoGenerado = crearRegaliaData.regaliasDistribuidora
            ? crearRegaliaData.regaliasDistribuidora[distribuidora.id]
                ?.valorHistoricoGenerado || 0
            : 0;
          const diferenciaHistoricos =
            valorHistoricoGenerado - valorHistoricoPrevio;
          const comision =
            distribuidora.tipo === TIPOS_DISTRIBUIDORAS.PAGO_ANUAL
              ? 0
              : (distribuidora.porcentaje / 100) * diferenciaHistoricos;
          const total = diferenciaHistoricos - comision;

          return (
            <>
              <h3 className="flex items-center justify-center text-center w-full text-[15px] py-[12px]">
                {distribuidora.nombre}
              </h3>
              <input
                type="number"
                placeholder="Valor"
                name="valorHistoricoGenerado"
                className="text-center"
                min={valorHistoricoPrevio}
                value={valorHistoricoGenerado}
                onChange={(e) => handleDistribuidoraChange(e, distribuidora)}
              />
              {!distribuidora.regalia?.valorHistoricoGenerado ? (
                <input
                  type="number"
                  placeholder="Valor"
                  name="valorHistoricoPrevioReferencia"
                  className="text-center"
                  min={0}
                  value={valorHistoricoPrevio}
                  onChange={(e) => handleDistribuidoraChange(e, distribuidora)}
                />
              ) : (
                <h3 className="flex items-center justify-center text-center w-full font-normal text-[15px] py-[12px]">
                  {valorHistoricoPrevio}
                </h3>
              )}
              <h3 className="flex items-center justify-center text-center w-full font-normal text-[15px] py-[12px]">
                {comision}
              </h3>
              <h3
                className={`flex items-center justify-center text-center w-full font-normal text-[15px] py-[12px]${
                  total < 0 ? " text-red-700" : ""
                }`}
              >
                {total}
              </h3>
            </>
          );
        })}
      </div>
      {!validDistribuidoraInputs && (
        <h3 className="text-red-700 mt-1">
          El nuevo valor historico debe ser superior al valor historico previo
          para cada distribuidora
        </h3>
      )}
      {plataformas.length > 0 && (
        <>
          <h3 className="my-[20px]">Otras plataformas</h3>
          <div className="grid grid-cols-4 rounded-2xl shadow-lg overflow-hidden">
            <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]">
              Plataforma
            </h3>
            <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]">
              Valor registrado
            </h3>
            <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]">
              Comisión
            </h3>
            <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]">
              Total
            </h3>

            {plataformas.map((plataforma) => {
              const valor =
                crearRegaliaData.regaliasPlataforma &&
                crearRegaliaData.regaliasPlataforma[plataforma.id]?.valor;
              const comision = (valor * plataforma.porcentaje) / 100;
              const total = valor - comision;

              return (
                <>
                  <h3 className="flex items-center justify-center text-center w-full text-[15px] py-[12px]">
                    {plataforma.nombre}
                  </h3>
                  <input
                    min={0}
                    value={valor}
                    onChange={(e) => handlePlataformaChange(e, plataforma)}
                    type="number"
                    placeholder="Valor"
                    className="text-center"
                  />
                  <h3 className="flex items-center justify-center text-center w-full text-[15px] py-[12px] font-normal">
                    {comision || "-"}
                  </h3>
                  <h3 className="flex items-center justify-center text-center w-full text-[15px] py-[12px] font-normal">
                    {total || "-"}
                  </h3>
                </>
              );
            })}
          </div>
          {!validPlataformasInputs && (
            <h3 className="text-red-700 mt-1">
              El valor generado por cada una de las plataformas debe ser igual o
              superior a 0
            </h3>
          )}
        </>
      )}
      <BeforeNextButtons
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        disableCondition={!validDistribuidoraInputs || !validPlataformasInputs}
      />
    </>
  );
};

export default Regalias;
