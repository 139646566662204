import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { BeatLoader } from "react-spinners";
import { removeDistribuidoras } from "../../../../actions/adminActions";

const DeleteModal = ({ modal, setModal }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleRemove = async () => {
    setLoading(true);
    await removeDistribuidoras(modal.distribuidora.id, dispatch);
    setLoading(false);
    setModal(!modal);
  };
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 w-full h-full min-h-fit bg-black/40 z-10 py-[50px] grid place-items-center overflow-y-scroll">
      <div className="bg-white w-[50%] min-w-[224px] box-content rounded-lg p-8 z-20 max-w-[390px] m-auto">
        <h3 className="text-2xl text-[#3E3E3E] text-center font-bold">
          ¿Deseas eliminar esta distribuidora? {modal.nombre}
        </h3>
        <div className="mt-5 gap-6 flex w-full items-center justify-center">
          <button
            onClick={() => setModal(!modal)}
            className="border-2 border-rosa-primariy py-[10px] px-[25px] rounded-md p-5 text-rosa-primariy  w-full"
          >
            Cancelar
          </button>
          <button
            onClick={handleRemove}
            className="bg-rosa-primariy rounded-lg py-[10px] px-[25px] flex items-center justify-center w-full"
          >
            {loading ? (
              <div className="w-full h-full">
                <BeatLoader height={35 * 2} width={4 * 2} color={"#fff"} />
              </div>
            ) : (
              "Eliminar"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
