import { useEffect } from "react";
import { HiOutlineChevronRight, HiOutlineChevronDown } from "react-icons/hi";
import { S3_BUCKET } from "../../../../utilidades/Constantes";

const Accordion = ({ acchievements, setAccordionActive, accordionActive }) => {
  const { id, nombre, descripcion, foto } = acchievements;
  const { [id]: openId } = accordionActive;
  const isActive = (id, e) => {
    if (id.toString() === e.target.id) {
      return setAccordionActive({ [e.target.id]: !openId });
    }
  };
  useEffect(() => {
    setAccordionActive({ ...accordionActive, accordionActive });
  }, []);

  return (
    <div
      className={`transition-all duration-500 ease-in-out ${
        openId ? "max-h-[200px]" : "max-h-0"
      } overflow-hidden border-b border-gray-400/50 px-2 py-6 last:border-none cursor-pointer flex flex-col justify-center`}
      id={id}
      onClick={(e) => isActive(id, e)}
    >
      <section
        className="flex items-center justify-between text-sm sm:text-base"
        id={id}
      >
        <h5
          className={`${
            openId ? "text-rosa-primariy" : "text-gray-700"
          } font-semibold`}
          id={id}
        >
          {nombre}
        </h5>
        {!openId ? (
          <HiOutlineChevronRight color="#E84A84" id={id} />
        ) : (
          <HiOutlineChevronDown color="#E84A84" id={id} />
        )}
      </section>
      {openId && (
        <section className="flex justify-between items-center">
          <p className="text-gray-400 text-xs w-[50%] sm:text-sm">
            {descripcion}
          </p>
          <img
            src={S3_BUCKET + foto}
            className="object-cover w-[40%] h-[8rem]"
          />
        </section>
      )}
    </div>
  );
};

export default Accordion;
