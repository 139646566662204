import React, { Component } from "react";
import PropTypes from "prop-types";
// import "./dialogo-terminos.scss";

class DialogoTerminos extends Component {
  state = {
    checked: false,
  };

  handleCheck = (e) => {
    this.setState({ checked: e.target.checked });
  };

  render() {
    return (
      <form
        className="w-9/12 flex flex-col font-proxima pt-16"
        onSubmit={(e) => this.props.onComplete(e, this.state.checked)}
      >
        {this.props.registro && (
          <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold">
            Confirma tus datos
          </h2>
        )}
        {this.props.registro && (
          <p className="text-base md:text-lg text-white mt-6">
            Nombre y Apellido
          </p>
        )}
        {this.props.registro && (
          <input
            value={this.props.name ?? ""}
            onChange={this.props.onChange ?? null}
            className="my-2 p-1 text-base text-white bg-transparent border-b border-white"
            name="name"
            type="text"
            required
          />
        )}
        <p className={this.props.title}>Términos y Condiciones</p>
        <textarea
          id="terminos-label"
          disabled
          className={this.props.textArea}
          rows={7}
          value={this.props.terminos}
        />
        <label className={this.props.labelTerms}>
          {" "}
          Acepto términos y condiciones
          <input
            name="terminos"
            onChange={
              this.props.registro ? this.props.onChange : this.handleCheck
            }
            className={this.props.checkTerms}
            type="checkbox"
          />
          <span className="label-check" />
        </label>
        <div
          className="flex mt-2"
          style={
            this.props.registro
              ? { justifyContent: "center" }
              : { justifyContent: "space-between" }
          }
        >
          {!this.props.registro && (
            <button
              className={this.props.volver}
              alt="atras"
              onClick={this.props.funcAtras ?? null}
            >
              Volver
            </button>
          )}
          {this.props.registro && (
            <button
              type="submit"
              className={
                this.props.registro ? "btn--secondary-sm" : "btn-terminos"
              }
            >
              Confirmar
            </button>
          )}
          {!this.props.registro && (
            <button type="submit" className={this.props.button}>
              Confirmar
            </button>
          )}
        </div>
      </form>
    );
  }
}

DialogoTerminos.propTypes = {
  onComplete: PropTypes.func.isRequired,
  registro: PropTypes.bool.isRequired,
  terminos: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  funcAtras: PropTypes.func,
  name: PropTypes.string,
};

export default DialogoTerminos;
