const Modal = ({ children }) => {
  return (
    <>
      <div className="bg-black/40 w-full h-full absolute z-50">
        <div className="w-full h-full mx-auto grid place-content-center">
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
