import NavMenu from "./NavMenu";
// import Dropdown from './Dropdown'

const Menu = () => {
  return (
    <nav className="bg-[#090930]">
      <div className="container mx-auto">
        <NavMenu />
      </div>
    </nav>
  );
};

export default Menu;
