import React from "react";
import PropTypes from "prop-types";

const DialogoEditar = ({
  children,
  handleCancelar,
  handleGuardar,
  habilitado,
}) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-20">
      <div
        className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-40 z-[-1]"
        onClick={() => handleCancelar()}
      />
      <div className="flex flex-col lg:flex-row py-6 pl-6 pr-6 lg:pr-14 bg-white rounded-[20px] max-w-[80%]">
        <div className="pb-10 lg:pb-20 lg:mr-28 pt-5">{children}</div>
        <div className="flex lg:flex-col justify-around lg:justify-between items-center lg:items-end">
          <span
            className={
              habilitado
                ? "underline text-[#E84A84] text-[17px] cursor-pointer"
                : "underline text-[#BEBEBE] text-[17px]"
            }
            onClick={async () => {
              if (habilitado) {
                await handleGuardar();
                handleCancelar();
              }
            }}
          >
            Guardar
          </span>
          <button
            className="text-sm py-1 px-8 font-semibold text-[#E84A84] border-[#E84A84] border-2 rounded-md outline-none"
            onClick={() => handleCancelar()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

DialogoEditar.propTypes = {
  children: PropTypes.any.isRequired,
  handleCancelar: PropTypes.func.isRequired,
  handleGuardar: PropTypes.func.isRequired,
  habilitado: PropTypes.any,
};

export default DialogoEditar;
