import { useEffect, useState } from "react";
import { crearPago } from "../../../../actions/adminActions";
import { formatoNumero } from "../../../../utilidades/FuncionesAuxiliares";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getArtista } from "../../../../reducers/adminReducer";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";

const SubirPago = () => {
  const { params } = useRouteMatch();

  const balance = useSelector((state) => getArtista(state, params?.id));
  const [subirPago, setSubirPago] = useState({
    valor: "",
    fechaPago: "",
    error: "",
    soporte: {},
    cargando: false,
    balance: "",
  });
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setSubirPago({ ...subirPago, balance: balance?.balanceCuenta });
  }, [balance]);

  const dispatch = useDispatch();

  const { valor, fechaPago, soporte } = subirPago;

  const handleChange = (e) => {
    setSubirPago({ ...subirPago, [e.target.name]: e.target.value });
  };

  const handleFile = (e) => {
    setSubirPago({ ...subirPago, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseFloat(subirPago.valor) > parseFloat(subirPago.balance)) {
      return setSubirPago({
        ...subirPago,
        error:
          "Por favor ingrese un valor menor al que indica el balance del artista",
      });
    }
    if (parseFloat(subirPago.valor) <= 0.001) {
      return setSubirPago({
        ...subirPago,
        error: "Por favor ingrese un valor mayor a 0.01",
      });
    }

    setLoading(true)
    const res = await crearPago(dispatch, {
      fecha: fechaPago,
      valor: parseFloat(valor),
      soporte: soporte,
      artista: params.id,
    });
    setLoading(false)

    if(res.error) {
      toast.error("Hubo un error subiendo el pago, por favor vuelve a intentarlo");
    } else {
      setSubirPago({
        valor: "",
        fechaPago: "",
        error: "Pago realizado con éxito",
        archivo: "",
        cargando: false,
        balance: balance?.balanceCuenta,
      });
      toast.success("Pago realizado con éxito");
    }
  };

  return (
      loading ? 
        <div className="h-full flex items-center min-h-[300px]">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
        </div>:
        <form
          className="h-auto lg:h-[21rem] md:w-[541px] max-w-[541px] subir-pago-administrador p-5 bg-white rounded-lg text-black mt-4 font-proxima space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="flex items-center justify-between">
            <div className="font-medium text-lg">Subir pago</div>
            <button
              type="submit"
              className="guardar-subir-pago underline text-[#E84A84] text-base cursor-pointer font-medium"
            >
              Guardar
            </button>
          </div>
          <div className="flex flex-col">
            <div className="text-sm">
              <b className="font-semibold">Saldo actual: </b>
              <span className="text-[#E84A84] font-medium">
                {formatoNumero(subirPago.balance, true, true, 2, true)}
              </span>
            </div>
            <b className="text-base font-medium">Fecha</b>
            <input
              className="text-[#E84A84] border-b border-gray-400 focus:border-rosa-primariy focus:outline-none"
              type="date"
              max={new Date().toISOString().slice(0, 10)}
              name="fechaPago"
              required
              onChange={handleChange}
              value={fechaPago}
            />
          </div>
          <div className="item-subir-pago flex flex-col">
            <b className="text-base font-medium">Valor</b>
            <input className="border-b border-gray-400 focus:border-rosa-primariy transition-all outline-none" type="number" value={valor} min={0} step={0.01} onChange={e => {
              setSubirPago({ ...subirPago, valor: parseFloat(e.target.value) });
            }}/>
          </div>
          <div className="item-subir-pago">
            <b className="text-base font-medium">Soporte</b>
            <div className="flex flex-col sm:flex-row items-center my-2 sm:space-x-4">
              <label
                htmlFor="video-curso"
                className="bg-[#E84A84] text-white relative p-2 w-full sm:w-2/4 text-center rounded-md cursor-pointer text-base"
              >
                <input
                  id="video-curso"
                  name="soporte"
                  type="file"
                  onChange={handleFile}
                  className="absolute w-full h-full opacity-0 top-0 left-0 right-0 bottom-0 outline-none cursor-pointer z-[-1]"
                  required
                />
                Seleccionar archivo
              </label>
              <span className="text-[#BEBEBE] underline text-sm whitespace-nowrap w-max overflow-x-hidden max-w-[40%] overflow-ellipsis">
                {subirPago?.soporte?.name}
              </span>
            </div>
          </div>
          <div className="error-subir-pago">{subirPago.error}</div>
        </form>
    
  );
};

export default SubirPago;
