import { useContext } from "react";
import { ModalContext } from "../../../context/ModalContext";
import { formatter } from "../../../utilidades/Format";
import { obtenerUrl } from "../../../utilidades/FuncionesAuxiliares";
import CalculoRegalias from "../../general/CalculoRegalias/CalculoRegalias";

const Table = ({
  modalConfirm,
  modalRegalias,
  headers = [],
  rows = [],
  regalias = false,
}) => {
  const { setModal } = useContext(ModalContext);

  return (
    <div className="text-right font-proxima mt-4 xl:m-0 lg:w-full">
      <div className="relative h-auto overflow-x-auto mb-4 rounded-lg">
        <table className="w-full text-xs text-center text-gray-500 md:text-base">
          <thead className="text-xs text-black bg-gray-100 md:text-base">
            <tr>
              {headers?.map((header) => (
                <th scope="col" className="px-6 py-3">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows?.map((row) =>
              regalias ? (
                <tr
                  className="hover:bg-gray-100 cursor-pointer bg-white border-b dark:bg-gray-800"
                  onClick={(e) => modalRegalias(e, rows)}
                  id={row.id}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-semibold whitespace-nowrap"
                    id={row.id}
                  >
                    {new Date(row.fecha).toLocaleDateString()}
                  </th>
                  <td className="px-6 py-4" id={row.id}>
                    {formatter(row?.parteArtista)}
                  </td>
                  <td className="px-6 py-4" id={row.id}>
                    {formatter(row?.parteBlackLion)}
                  </td>
                  <td className="px-6 py-4" id={row.id}>
                    {row?.porcentajeArtista + "%"}
                  </td>
                  <td className="px-6 py-4" id={row.id}>
                    {row?.porcentajeBlackLion + "%"}
                  </td>
                  <td className="px-6 py-4" id={row.id}>
                    {row?.porcentajeIVA + "%"}
                  </td>
                  <td className="px-6 py-4" id={row.id}>
                    {formatter(row?.valorIVA)}
                  </td>
                  <td className="px-6 py-4" id={row.id}>
                    {row?.soporte ? (
                      <a
                        className="text-xs md:text-base font-medium underline text-rosa-primariy hover:text-pink-800 hover:font-semibold"
                        href={obtenerUrl(row.soporte)}
                        target="noopener"
                        id={row.id}
                      >
                        Abrir
                      </a>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                  <td className="px-6 py-4" id={row.id}>
                    <span
                      className="underline text-rosa-primariy hover:text-pink-800 hover:font-semibold"
                      onClick={(e) => {
                        e.stopPropagation();
                        setModal(
                          <CalculoRegalias
                            crearRegaliaData={row}
                            cerrarDialogo={() => setModal(null)}
                          />
                        );
                      }}
                    >
                      Resumen
                    </span>
                  </td>
                  {row?.total && (
                    <td className="px-6 py-4" id={row.id}>
                      {row?.total}
                    </td>
                  )}
                </tr>
              ) : (
                <tr className="hover:bg-gray-100 cursor-pointer bg-white border-b dark:bg-gray-800">
                  <th
                    scope="row"
                    className="px-6 py-4 font-semibold whitespace-nowrap"
                  >
                    {new Date(row.fecha).toLocaleDateString()}
                  </th>
                  <td className="px-6 py-4">{formatter(row?.valor)}</td>
                  <td className="px-6 py-4">
                    {row?.soporte ? (
                      <a
                        className="text-xs md:text-base font-medium underline text-rosa-primariy hover:text-pink-800 hover:font-semibold"
                        href={obtenerUrl(row.soporte)}
                        target="noopener"
                        id={row.id}
                      >
                        Abrir
                      </a>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {regalias && (
        <span
          className="cursor-pointer underline text-sm md:text-base hover:text-rosa-primariy"
          onClick={modalConfirm}
        >
          Eliminar último del historial
        </span>
      )}
    </div>
  );
};

export default Table;
