import { SaveIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { actualizarTareaArtista, getTareasArtista } from '../../../../actions/adminActions';
import { selectTareasArtista } from '../../../../reducers/adminReducer'
import { COLORES } from '../../../../utilidades/Constantes';

export const CompletarTareas = () => {
    const { id } = useParams();
    const tareasArtista = useSelector(state => selectTareasArtista(state, id));
    const [tareasArtistaModificaciones, setTareasArtistaModificaciones] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!tareasArtista && id) {
            getTareasArtista(dispatch, id);
        }
    }, [id])

    useEffect(() => {
        console.log("CAMBIO");
      if(tareasArtista)
        setTareasArtistaModificaciones(tareasArtista)
    }, [tareasArtista])
    
    const cambiarComentario = async (tareaId, comentario, idCategoria) => {
        const copyTareasArtistaModificaciones = [...tareasArtistaModificaciones];
        const categoria = copyTareasArtistaModificaciones.find(categoria => categoria.id === idCategoria);
        const tareaActual = categoria.tareas.find(tareaActual => tareaActual.id === tareaId);

        tareaActual.tareasArtista = [{
            ...(tareaActual.tareasArtista[0]),
            comentario,
            tareaActualizada: true
        }];

        setTareasArtistaModificaciones(
            copyTareasArtistaModificaciones
        )
    }

    const cambiarEstado = async (tareaId, realizada, idCategoria) => {
        const copyTareasArtistaModificaciones = [...tareasArtistaModificaciones];
        const categoria = copyTareasArtistaModificaciones.find(categoria => categoria.id === idCategoria);
        const tareaActual = categoria.tareas.find(tareaActual => tareaActual.id === tareaId);

        tareaActual.tareasArtista = [{
            ...(tareaActual.tareasArtista[0]),
            realizada,
            tareaActualizada: true
        }];

        setTareasArtistaModificaciones(
            copyTareasArtistaModificaciones
        )
    }

    const actualizarTarea = async (tareaId, realizada, comentario, idCategoria, idTareaArtista) => {
        await actualizarTareaArtista(dispatch, tareaId, id, realizada, comentario, idCategoria, idTareaArtista);
        toast.success("Estado de la tarea cambiado exitosamente");
    }

    return (
        <div className="w-full flex flex-col">
            {
                tareasArtistaModificaciones ?
                    tareasArtistaModificaciones.map(categoria => <>
                        <h2 className="font-bold text-[24px] leading-7">{categoria.nombre}</h2>
                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="overflow-hidden rounded-lg">
                                        <table className="w-full text-left">
                                            <thead className="border-b bg-gray-100">
                                            <tr>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 w-[25%]">
                                                    Tareas
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 w-[25%]">
                                                    Estado
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 w-[25%]">
                                                    Nota
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 w-[25%]">
                                                    Guardar
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    categoria.tareas.map(tarea => {
                                                        const completada = !!(tarea.tareasArtista && tarea.tareasArtista[0]?.realizada);
                                                        const comentario = tarea.tareasArtista && tarea.tareasArtista[0]?.comentario;
                                                        const tareaActualizada = tarea.tareasArtista && tarea.tareasArtista[0]?.tareaActualizada;

                                                        return <tr className="bg-white border-b">
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                {tarea.nombre}
                                                            </td>
                                                            <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                                                <select className='w-full' value={completada} onChange={(e) => cambiarEstado(tarea.id, JSON.parse(e.target.value), categoria.id)}>
                                                                    <option value={true}>Ok</option>
                                                                    <option value={false}>Pendiente</option>
                                                                </select>
                                                            </td>
                                                            <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                                                <div className="flex mt-2 mb-2 place-content-center">
                                                                    <input className="w-full" value={comentario} onChange={(e) => cambiarComentario(tarea.id, e.target.value, categoria.id)}></input>
                                                                </div>
                                                            </td>
                                                            <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                                                {
                                                                    tareaActualizada ?
                                                                        <SaveIcon className='w-[20px] cursor-pointer' color={COLORES.PRIMARIO} onClick={() => actualizarTarea(tarea.id, completada, comentario, categoria.id, tarea.tareasArtista[0]?.id)}></SaveIcon>:
                                                                        "-"
                                                                }
                                                            </td>
                                                        </tr >
                                                    })
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                    ):
                    <ScaleLoader color={COLORES.PRIMARIO}/>
            }
        </div> 
    )
}
