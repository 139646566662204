import {
  UserGroupIcon,
  FireIcon,
  ScaleIcon,
  ClipboardIcon,
  TagIcon,
  MusicNoteIcon,
  MicrophoneIcon,
  ChartBarIcon,
  TemplateIcon,
} from "@heroicons/react/outline";

export const data = [
  {
    id: 1,
    icon: <UserGroupIcon className="w-8 h-8 md:h-10 md:w-10  text-white" />,
    title: "Redes Sociales",
    description: "Aprende a usarlas en Pro de tu crecimiento musical ",
  },
  {
    id: 2,
    icon: <TagIcon className="w-8 h-8 md:h-10 md:w-10  text-white" />,
    title: "Campañas Digitales",
    description: "Que la gente se entere de tú música ",
  },
  {
    id: 3,
    icon: <ScaleIcon className="w-8 h-8 md:h-10 md:w-10  text-white" />,
    title: "Departamento Legal",
    description: "Protege tu carrera musical ",
  },
  {
    id: 4,

    icon: <FireIcon className="w-8 h-8 md:h-10 md:w-10  text-white" />,
    title: "Estrategias digitales",
    description: "Usar las herramientas digitales para cumplir tus objetivos ",
  },
  {
    id: 5,
    icon: <ClipboardIcon className="w-8 h-8 md:h-10 md:w-10  text-white" />,
    title: "Diseño Gráfico",
    description:
      "Proyecta comunicaciones visuales destinadas a transmitir mensajes ",
  },
  {
    id: 6,
    icon: <MusicNoteIcon className="w-8 h-8 md:h-10 md:w-10  text-white" />,
    title: "Sound Exchanged",
    description: "Recauda regalías de interpretación ",
  },
  {
    id: 7,
    icon: <ChartBarIcon className="w-8 h-8 md:h-10 md:w-10  text-white" />,
    title: "Estrategias SEO",
    description: "Optimización para motores de búsqueda",
  },
  {
    id: 8,
    icon: <TemplateIcon className="w-8 h-8 md:h-10 md:w-10  text-white" />,
    title: "Distribución Digital",
    description: "Tú música al alcance de todos ",
  },
  {
    id: 9,
    icon: <MicrophoneIcon className="w-8 h-8 md:h-10 md:w-10  text-white" />,
    title: "BMI",
    description: "Recauda regalías por tus composiciones ",
  },
];
