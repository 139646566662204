import React, { Component } from "react";
import { secondsToDateTimeYMD } from "./../../../../utilidades/Format";
import "./detalle-album.scss";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./../../../../actions/adminActions";
import Switch from "react-switch";
import { ScaleLoader } from "react-spinners";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import moment from "moment";
import ImgAlbum from "./../../../../assets/lista.svg";
import { obtenerUrl } from "../../../../utilidades/FuncionesAuxiliares";
import { toast } from "react-toastify";
import { selectDistribuidoras } from "../../../../reducers/adminReducer";

class DetalleAlbum extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nombre: "",
      upc: "",
      fechaLanzamiento: "",
      fechaLanzamientoDistribuidora: "",
      canciones: [],
      cancionPorAgregar: "",
      artistaCancionPorAgregar: "",
      photoInput: null,
      editando: false,
      online: false,
      cargando: false,
      distribuidora: null
    };
  }

  componentDidMount() {
    if (this.props.album) {
      let { nombre, upc, fechaLanzamiento, canciones, online, distribuidora } =
        this.props.album;
      this.setState({
        nombre,
        upc,
        fechaLanzamiento: moment(fechaLanzamiento).format("YYYY-MM-DD"),
        canciones,
        online,
        distribuidora: distribuidora?.id ?? null
      });
    }

    if(!this.props.distribuidoras) {
      this.props.getDistribuidoras();
    }
  }

  handleAgregarCancion = () => {
    if (
      this.state.cancionPorAgregar.replace(/\s/g, "") === "" ||
      this.state.artistaCancionPorAgregar.replace(/\s/g, "") === ""
    ) {
      this.setState({
        error: "Por favor indique el nombre de la canción y del artista",
      });
      return;
    }

    this.setState({
      canciones: this.state.canciones.concat({
        nombre: this.state.cancionPorAgregar,
        nombreArtista: this.state.artistaCancionPorAgregar,
      }),
      cancionPorAgregar: "",
      artistaCancionPorAgregar: "",
      error: "",
    });
  };

  handleDeleteCancion = (cancionPorBorrar) => {
    let nuevasCanciones = [];
    let cancionEliminada = false;

    this.state.canciones.forEach((cancion) => {
      if (
        cancion.nombre === cancionPorBorrar.nombre &&
        cancion.nombreArtista === cancionPorBorrar.nombreArtista &&
        !cancionEliminada
      )
        cancionEliminada = true;
      else nuevasCanciones.push(cancion);
    });

    this.setState({
      canciones: nuevasCanciones,
    });
  };

  handleChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleEditarClicked = () => this.setState({ editando: !this.state.editando });

  handleAtrasCliked = () => {
    this.volverEdicionEstadoOriginal();
    this.setState({ editando: !this.state.editando });
  };

  handleFile = (e) => {
    this.setState({
      photoInput: e.target.files[0],
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.canciones.length === 0) {
      this.setState({
        error: "Por favor agregue al menos una canción al álbum",
      });
      return;
    }

    this.setState({ cargando: true, error: "" });

    const dataAlbum = {
      nombre: this.state.nombre,
      nombreArtista: this.props.album?.nombreArtista,
      artistaId: this.props.album?.artistaId,
      online: this.state.online,
      upc: this.state.upc,
      fechaLanzamiento: new Date(this.state.fechaLanzamiento),
      canciones: this.state.canciones,
      distribuidora: this.state.distribuidora !== "-1" ? this.state.distribuidora : null
    };

    if(this.state.photoInput) {
      dataAlbum.portada = this.state.photoInput
    }

    await this.props.editarAlbum(dataAlbum,
      this.props.album?.id
    );
    toast.success("El álbum ha sido actualizado");
    this.setState({ cargando: false });
    this.props.handleCerrarDialogo();
  };

  volverEdicionEstadoOriginal = () => {
    let { nombre, upc, fechaLanzamiento, canciones, online } = this.props.album;
    this.setState({
      nombre,
      upc,
      fechaLanzamiento: secondsToDateTimeYMD(fechaLanzamiento.seconds),
      canciones,
      editando: false,
      online,
    });
  };

  handleEliminarClicked = async () => {
    this.setState({ cargando: true, error: "" });

    await this.props.eliminarAlbum(this.props.album.id, this.props.id);
    
    toast.success("El álbum ha sido eliminado");
    this.setState({ cargando: false });
    this.props.handleCerrarDialogo();

    this.props.handleCerrarDialogo();
  };

  render() {
    if (this.state.cargando)
      return (
        <div className="contenedor-loading-indicator">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
        </div>
      );

    if (!this.state.editando)
      return (
        <div className="detalle-album py-8 px-8 sm:px-12 max-w-full">
          <div className="text-[#E84A84] w-full flex justify-end">
            <ClearRoundedIcon fontSize="large" className="-mr-6 sm:mr-0" />
          </div>
          <div className="w-full flex flex-col items-center">
            <img
              src={
                this.props.album.portada
                  ? obtenerUrl(this.props.album.portada)
                  : ImgAlbum
              }
              className="w-[150px] h-[150px] sm:w-[200px] sm:h-[200px] object-cover rounded-2xl shadow-xl"
            />
          </div>
          <div className="flex flex-col items-center w-full justify-between my-5">
            <div className="font-bold text-3xl text-black font-proxima">
              {this.props.album.nombre}
            </div>
            <div
              className="editar-detalle-album underline text-[#E84A84] text-[17px] cursor-pointer"
              onClick={this.handleEditarClicked}
            >
              Editar
            </div>
            <div
              className="eliminar-detalle-album underline text-[#E84A84] text-[17px] cursor-pointer"
              onClick={this.handleEliminarClicked}
            >
              Eliminar
            </div>
          </div>
          <div className="item-detalle-album">
            <h3>Nombre</h3>
            <div>{this.props.album.nombre}</div>
          </div>
          <div className="item-detalle-album">
            <h3>Distribuidora</h3>
            <div>{this.props.album.distribuidora?.nombre || "Sin asignar"}</div>
          </div>
          {this.props.album.portada && (
            <div className="item-detalle-album flex flex-col">
              <h3>Portada</h3>
              <a
                href={obtenerUrl(this.props.album.portada)}
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-rosa-primariy"
              >
                Enlace portada
              </a>
            </div>
          )}
          <div className="item-detalle-album">
            <h3>UPC</h3>
            <div>{this.props.album.upc}</div>
          </div>
          <div className="item-detalle-album">
            <h3>Fecha de lanzamiento</h3>
            <div>
              {this.props.album.fechaLanzamiento
                ? moment(this.props.album.fechaLanzamiento)
                  .format("DD/MM/YYYY")
                : "No disponible"}
            </div>
          </div>
          <div className="item-detalle-album flex flex-col">
            <h3>Estado</h3>
            <span>{this.props.album.online ? "Online" : "Take down"}</span>
          </div>
          <div className="item-detalle-album">
            <h3>Canciones</h3>
            <ol className="lista-canciones-detalle-album">
              {this.props.album.canciones.map((cancion) => (
                <li className="cancion-detalle-album font-bold">
                  {`${cancion.nombre} - ${cancion.nombreArtista}`}
                </li>
              ))}
            </ol>
          </div>
        </div>
      );
    else
      return (
        <form
          className="detalle-album max-w-full py-8 px-8 sm:px-12"
          onSubmit={this.handleSubmit}
        >
          <div className="flex flex-col sm:flex-row items-center w-full justify-between mb-5">
            <div className="font-bold text-3xl text-black font-proxima">
              Álbum
            </div>
            <div className="flex">
              <div
                className="editar-detalle-album mr-4 underline text-[#E84A84] text-[17px] cursor-pointer"
                onClick={this.handleAtrasCliked}
              >
                Atras
              </div>
              <button
                type="submit"
                className="guardar-agregar-album eliminar-detalle-album underline text-[#E84A84] text-[17px] cursor-pointer"
              >
                Guardar
              </button>
            </div>
          </div>
          <div className="item-detalle-album">
            <span className="text-[22px] font-proxima">Nombre</span>
            <input
              className="input-agregar-album"
              type="text"
              name="nombre"
              value={this.state.nombre}
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="item-detalle-album flex flex-col">
            <span className="text-[22px] font-proxima mr-4 font-bold">
              Distribuidora
            </span>
            <select name="distribuidora" onChange={this.handleChange} value={this.state.distribuidora}>
                <option value={-1}>
                  Sin asignar
                </option>
                {this.props.distribuidoras.map(distribuidora => <option value={distribuidora.id}>
                    {distribuidora.nombre}
                  </option>
                )}
              <option>
              </option>
            </select>
          </div> 
          <div className="item-detalle-album">
            <div className="text-[22px] font-proxima">Portada</div>
            <div className="flex flex-col sm:flex-row sm:items-center mt-4 max-w-full">
              <label
                htmlFor="video-curso"
                className="bg-[#E84A84] text-white relative w-max px-[10px] py-2 rounded-md cursor-pointer text-[17px] float-left whitespace-nowrap"
              >
                <input
                  id="video-curso"
                  type="file"
                  accept="image/*"
                  name="portada"
                  onChange={this.handleFile}
                  className="absolute w-full h-full opacity-0 top-0 left-0 right-0 bottom-0 outline-none cursor-pointer z-[-1]"
                />
                Seleccionar archivo
              </label>
              <span className="text-[#BEBEBE] mt-2 sm:mt-0 sm:ml-5 underline text-lg whitespace-nowrap w-max overflow-x-hidden max-w-full">
                {this.state.photoInput?.name}
              </span>
            </div>
          </div>
          <div className="item-detalle-album">
            <span className="text-[22px] font-proxima">UPC</span>
            <input
              className="input-agregar-album"
              type="text"
              name="upc"
              value={this.state.upc}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="item-detalle-album">
            <span className="text-[22px] font-proxima">
              Fecha de lanzamiento
            </span>
            <input
              className="input-agregar-album"
              type="date"
              name="fechaLanzamiento"
              value={this.state.fechaLanzamiento}
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="mt-4 flex items-center">
            <span className="text-[22px] font-proxima mr-4 font-bold">
              Online
            </span>
            <Switch
              onColor="#e87096"
              width={40}
              height={20}
              uncheckedIcon={false}
              checkedIcon={false}
              activeBoxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
              onChange={() => this.setState({ online: !this.state.online })}
              checked={this.state.online}
            />
          </div>
          <div className="item-detalle-album" style={{ border: "none" }}>
            <span className="text-[22px] font-proxima">Canciones</span>
            <div id="input-cancion" className="input-agregar-album">
              <input
                className="agregar-cancion-texto"
                placeholder="Nombre"
                type="text"
                name="cancionPorAgregar"
                onChange={this.handleChange}
                value={this.state.cancionPorAgregar}
              />
              <input
                id="agregar-cancion-artista"
                className="agregar-cancion-texto"
                placeholder="Artista"
                type="text"
                name="artistaCancionPorAgregar"
                onChange={this.handleChange}
                value={this.state.artistaCancionPorAgregar}
              />
              <AddIcon
                onClick={this.handleAgregarCancion}
                className="agregar-cancion-boton"
              />
            </div>
          </div>
          <ol className="contenedor-canciones" start="1">
            {this.state.canciones.map((cancion) => {
              return (
                <li key={cancion.nombre} className="item-cancion mt-3 flex">
                  <div className="texto-artista-cancion">
                    {`${cancion.nombre} - ${cancion.nombreArtista}`}
                  </div>
                  <DeleteIcon
                    onClick={() => this.handleDeleteCancion(cancion)}
                    className="delete-icon"
                  />
                </li>
              );
            })}
          </ol>
          <div className="error-message">{this.state.error}</div>
        </form>
      );
  }
}

const mapStateToProps = (state) => ({
  distribuidoras: selectDistribuidoras(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetalleAlbum);
