import React from "react";
import Switch from "react-switch";
import { togglePlataformaArtista } from "../../../../actions/adminActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const EditarPerfil = ({ artista }) => {
  const dispatch = useDispatch();

  const togglePlataforma = async (plataforma, index) => {
    const res = await togglePlataformaArtista(
      dispatch,
      artista.id,
      plataforma.id,
      !plataforma.activa,
      index
    );

    if (res.isAxiosError) {
      toast.error(res.response.data.message);
    } else {
      toast.success(
        `${plataforma.nombre} ha sido ${
          plataforma.activa ? "desactivada" : "activada"
        }`
      );
    }
  };

  return (
    <div className="flex flex-col py-10 px-10 rounded-[20px] max-w-full w-full">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-5">
        <h2 className="font-bold text-2xl font-proxima">Tipo de perfil</h2>
      </div>
      <span className="italic text-sm w-full sm:w-[50%]">
        Activa o desactiva el tipo de perfil del artista
      </span>
      {artista &&
        artista.plataformas?.map((plataforma, index) => (
          <>
            <div className="flex flex-col font-bold text-xl mt-4 font-proxima">
              {plataforma.nombre}
            </div>
            <Switch
              className="mt-4"
              onColor="#e87096"
              width={60}
              height={30}
              uncheckedIcon={false}
              checkedIcon={false}
              activeBoxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
              checked={plataforma.activa}
              onChange={() => togglePlataforma(plataforma, index)}
            />
          </>
        ))}
    </div>
  );
};

export default EditarPerfil;
