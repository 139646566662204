import React from 'react'
import '../styles/inicio.scss'
import Header from './Inicio/Header'
import SectionCarousel from './Inicio/SectionCarousel'
import SectionKnowUs from './Inicio/SectionKnowUs'

const Inicio = () => {
  return (
    <div className="bg-[#090930] font-proxima pt-5">
      <Header />
      <SectionCarousel />
      <SectionKnowUs />
    </div>
  )
}

export default Inicio
