import React from "react";

const AbsoluteBackground = ({ modal }) => {
  return (
    <div className="fixed w-[100vw] h-[100vh] bg-black top-0 left-0 right-0 bottom-0 bg-opacity-20 z-50 flex items-center justify-center p-10">
      {modal}
    </div>
  );
};

export default AbsoluteBackground;
