import React from "react";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { getUid, getUser } from "../../../../reducers/sesionReducer";

function InfoCuenta({
  banco,
  tipo,
  numero,
  funcionCuenta,
  seleccionarCuenta,
  actualizar,
  conPago,
  cerrarDialogo,
  solicitarPago,
}) {
  const user = useSelector(getUser)
  const uid = useSelector(getUid);
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col justify-between text-rosa-darkBlue w-full px-6 py-2">
      {banco === null || tipo === null || numero === null ? (
        <div>
          <div className="w-full flex justify-end">
            <XIcon
              onClick={cerrarDialogo}
              className="w-8 h-8 text-rosa-primariy font-semibold cursor-pointer hover:scale-110 transition-transform duration-200"
            />
          </div>
          <span>Aún no tienes una cuenta agregada.</span>
        </div>
      ) : (
        <div>
          <div className="w-full flex justify-end">
            <XIcon
              onClick={cerrarDialogo}
              className="w-8 h-8 text-rosa-primariy font-semibold cursor-pointer hover:scale-110 transition-transform duration-200"
            />
          </div>
          <div className="flex flex-col justify-center items-center w-full">
            <div>
              <span className="font-semibold">{banco ?? ""}</span> /{" "}
              {tipo ?? ""}
            </div>
            <div>
              <span className="font-semibold">{numero ?? ""}</span>
            </div>
          </div>
        </div>
      )}
      {banco === null || tipo === null || numero === null ? (
        ""
      ) : (
        <div className="mt-4 w-full flex gap-4 justify-center items-center">
          <button
            className="btn--primary-sm hover:shadow-button transition ease-in-out"
            onClick={funcionCuenta}
          >
            Modificar datos
          </button>
          {actualizar && !conPago && (
            <button
              className="btn--primary-sm-outline text-rosa-primariy transition ease-in-out"
              onClick={seleccionarCuenta ?? null}
            >
              {"Confirmar cuenta"}
            </button>
          )}
          {conPago && (
            <button
              className="btn--primary-sm-outline text-rosa-primariy transition ease-in-out"
              onClick={() => {
                seleccionarCuenta();
                solicitarPago(uid, dispatch, user.correo, user.nombre);
              }}
            >
              {"Solicitar pago"}
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default InfoCuenta;
