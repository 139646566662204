import { UploadIcon } from "@heroicons/react/outline";
import React from "react";
import CalculoRegalias from "../../../general/CalculoRegalias/CalculoRegalias";
import BeforeNextButtons from "./BeforeNextButtons";

const ResumenRegalias = ({
  crearRegaliaData,
  setCrearRegaliaData,
  currentStep,
  setCurrentStep,
  handleCerrarDialogo,
}) => {
  const inputFileRef = React.useRef();

  const handleFile = (e) => {
    setCrearRegaliaData({
      ...crearRegaliaData,
      [e.target.name]: e.target.files[0],
    });
  };

  return (
    <>
      <CalculoRegalias crearRegaliaData={crearRegaliaData} />
      <div className="flex justify-between items-end max-w-[500px] mx-auto">
        <div className="flex flex-col mt-3 relative">
          <span className="font-bold text-rosa-primariy">
            Archivo de ventas:
          </span>
          <div className="flex items-center">
            <button
              className="bg-rosa-primariy text-white px-[20px] py-[6px] rounded-md text-[16px] flex justify-center items-center mt-2 min-w-[220px]"
              onClick={() => {
                inputFileRef.current.click();
              }}
            >
              <UploadIcon className="w-5 h-5 text-white mr-2" /> Seleccionar
              archivo
            </button>
            <span className="text-gray-400 ml-3 font-normal">
              {crearRegaliaData.soporte
                ? crearRegaliaData.soporte.name || crearRegaliaData.soporte
                : "Selecciona un archivo"}
            </span>
            <input
              className="absolute top-0 left-0 hidden"
              type="file"
              name="soporte"
              onChange={handleFile}
              ref={inputFileRef}
            />
          </div>
        </div>
      </div>
      <BeforeNextButtons
        crearRegaliaData={crearRegaliaData}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        handleCerrarDialogo={handleCerrarDialogo}
      />
    </>
  );
};

export default ResumenRegalias;
