import { combineReducers } from "redux";
import sesionReducer from "./sesionReducer";
import adminReducer from "./adminReducer";
import academiaReducer from "./academiaReducer";
import userReducer from "./userReducer";

export default combineReducers({
  sesionReducer,
  adminReducer,
  academiaReducer,
  userReducer,
});
