import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { traerPagos } from "../../../actions/adminActions";
import { getPagosArtista } from "../../../reducers/adminReducer";
import Table from "./Table";

const PaymentHistory = () => {
  const { params } = useRouteMatch();
  const pagos = useSelector((state) => getPagosArtista(state, params.id)) || [];
  const headers = ["Fecha", "Valor", "Soporte"];
  const dispatch = useDispatch();

  useEffect(() => {
    traerPagos(dispatch, params.id);
  }, []);

  return (
    <>
      {pagos.length !== 0 ? (
        <Table headers={headers} rows={pagos} />
      ) : (
        <div className="flex sm:flex-row items-center w-full justify-center text-white bg-white/10 rounded-lg">
          <div className="titulo-subir-pago font-bold text-2xl font-proxima ">
            No hay ningún pago asociado a este artista
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentHistory;
