import React from "react";
import PropTypes from "prop-types";
import { secondsToDateTimeForMoment } from "../../../../utilidades/Format";
import { formatoNumero } from "../../../../utilidades/FuncionesAuxiliares";
import { XIcon } from "@heroicons/react/solid";
import { S3_BUCKET } from "../../../../utilidades/Constantes";

HistorialPagos.propTypes = {
  historial: PropTypes.array.isRequired,
  cerrar: PropTypes.func.isRequired,
};

function HistorialPagos({ historial, cerrar }) {
  historial.sort((a, b) => {
    const dateA = new Date(secondsToDateTimeForMoment(a.fecha));
    const dateB = new Date(secondsToDateTimeForMoment(b.fecha));
    return dateA - dateB;
  });
  return (
    <div className="fixed inset-0 z-[80] flex flex-col items-center overflow-y-auto bg-pink fondo-tap-highlight">
      <div
        className="fixed inset-0 z-[90] bg-pink-500/20 flex flex-col items-center justify-center"
        onClick={cerrar}
      />
      <div className="relative flex flex-col items-center justify-center w-11/12 max-w-[600px] w-full bg-white bg-cover z-[100] rounded-md p-8 m-auto">
        <div className="w-full justify-end flex">
          <XIcon
            className="w-6 h-6 text-rosa-primariy cursor-pointer"
            onClick={cerrar}
            alt="cerrar"
          />
        </div>
        <h3 className="text-rosa-darkBlue text-3xl font-bold w-full mb-8">
          Historial de pagos
        </h3>
        <div className="w-full flex flex-col gap-5">
          <div className="grid grid-cols-3 place-items-center grid-rows-1 text-lg text-rosa-darkBlue ">
            <h4 className="text-lg font-semibold">Fecha</h4>
            <h4 className="text-lg font-semibold">Valor</h4>
            <h4 className="text-lg font-semibold">Comprobante</h4>
          </div>

          {historial?.map((pago) => {
            return (
              <div
                key={pago.uidDoc}
                className="grid grid-cols-3 place-items-center border-b border-gray-500 "
              >
                <div className="text-lg text-rosa-darkBlue label-date">
                  {new Date(pago.fecha).toLocaleDateString()}
                </div>
                <div className="text-lg text-rosa-primariy">
                  {pago.valor
                    ? formatoNumero(pago.valor, true, true, 2, false)
                    : "No disponible"}
                </div>
                {pago.soporte ? (
                  <a
                    className="text-lg underline text-rosa-primariy font-medium hover:text-pink-800 hover:font-semibold cursor-pointer"
                    rel="noopener noreferrer"
                    href={S3_BUCKET + pago.soporte}
                    target="_blank"
                  >
                    Soporte
                  </a>
                ) : (
                  <span className="text-lg text-rosa-primariy">-</span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HistorialPagos;
