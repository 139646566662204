import HeaderPerfil from "./components/HeaderPerfil";
import Album from "./components/Album";
import DialogoAlbum from "./components/DialogoAlbum";
import sinFoto from "../../../assets/perfilSinFoto.png";
import DialogoAgregarCuenta from "./components/DialogoAgregarCuenta.js";
import { UserIcon } from "@heroicons/react/outline";
import { formatoNumero, obtenerUrl } from "../../../utilidades/FuncionesAuxiliares";
import HistorialPagos from "./components/HistorialPagos";
import {
  getAlbumes,
  getSencillos,
  getUid,
  getUser,
} from "../../../reducers/sesionReducer";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { solicitarPagos, traerArtista } from "../../../actions/sesionActions";
import DialogoUnBotonPerfil from "./components/DialogoUnBotonPerfil";
import Cargando from "../../general/Cargando";
import Bank from "../../../svgComponents/Bank";
import Payment from "../../../svgComponents/Payment";
import Graphic from "../../../svgComponents/Graphic";
import Emoji from "../../../svgComponents/Emoji";
import { traerPagos } from "../../../actions/adminActions";
import { getPagosArtista } from "../../../reducers/adminReducer";
import Achievements from "./components/Achievements";
import { ModalContext } from "../../../context/ModalContext";
import ModalGraficaRedes from "./components/ModalGraficaRedes";

const Perfil = () => {
  const [dialogo, setDialogo] = useState({ dialogo: 0, cargando: false });
  const [album, setAlbum] = useState({ album: null });
  const user = useSelector(getUser) || [];
  const uid = useSelector(getUid) || "";
  const sencillos = useSelector(getSencillos) || [];
  const albumes = useSelector(getAlbumes);
  const pagos = useSelector((state) => getPagosArtista(state, user?.id)) || [];
  const dispatch = useDispatch();
  const { setModal } = useContext(ModalContext);

  useEffect(() => {
    traerArtista(uid, dispatch);
    traerPagos(dispatch, uid);
  }, [uid]);

  const manejarDialogo = (v) => {
    setDialogo({
      dialogo: v,
      cargando: false,
    });
  };

  const seleccionarAlbum = (al) => {
    setAlbum({
      album: al,
    });
  };

  const cerrarDialogo = () => {
    setAlbum({
      album: null,
    });
  };

  const verificarCampo = (user, campo) => {
    if (campo in user)
      if (user[campo] !== "") return user[campo];
      else return null;
    else return null;
  };

  const opcionesDialogo = (n) => {
    let titulo, descripcion, boton, funcion;
    if (n === 1) {
      titulo = "No hay archivo";
      descripcion =
        "Actualmente no hay ningún archivo de ventas para ver o descargar.";
      boton = "Listo";
      funcion = () => manejarDialogo(0);
    } else if (n === 3) {
      titulo = "Tú pago ha sido solicitado";
      descripcion =
        "El pago será procesado en el transcurso de 10 días hábiles. \n\n Enviaremos a tu correo una confirmación de la solicitud del pago.";
      boton = "Ok";
      funcion = () => manejarDialogo(0);
    } else if (n === 4) {
      titulo = "Sin Balance";
      descripcion =
        "Actualmente el balance es menor a $35 USD, intentalo cuando el balance sea mayor.";
      boton = "Ok";
      funcion = () => manejarDialogo(0);
    } else if (n === 5) {
      titulo = "Error";
      descripcion = "Hubo un error al solicitar el pago, inténtelo más tarde.";
      boton = "OK";
      funcion = () => manejarDialogo(0);
    } else if (n === 6) {
      titulo = "Pago ya solicitado";
      descripcion =
        "Usted ya ha solicitado el pago, recuserde que el pago es procesado durante los 10 días hábiles siguientes despees de ser solicitado el pago.";
      boton = "OK";
      funcion = () => manejarDialogo(0);
    } else if (n === 8) {
      titulo = "Cuenta actualizada";
      descripcion =
        "Tu cuenta bancaria fue actualzada exitosamente, recuserda que tus pagos serán enviados a esta nueva cuenta.";
      boton = "Ok";
      funcion = () => manejarDialogo(0);
    } else if (n === 9) {
      titulo = "Sin historial";
      descripcion =
        "Aún no te han realizado el primer pago, una vez lo recibas lo verás acá.";
      boton = "Ok";
      funcion = () => manejarDialogo(0);
    } else if (n === 0 || n === 2 || n === 7 || n === 10 || n === 12) return "";
    return mostrarDialogo(titulo, descripcion, boton, funcion);
  };

  const solicitarPago = (valor, solicitud) => {
    if (solicitud) {
      manejarDialogo(6);
      return;
    }
    if (valor < 35) {
      manejarDialogo(4);
    } else {
      manejarDialogo(2);
    }
  };

  const cerrarDialogoAgregarCuenta = () => {
    manejarDialogo(8);
  };

  const pagoSolicitado = () => {
    manejarDialogo(3);
  };

  const mostrarDialogo = (titulo, descripcion, textoBoton, funcion) => {
    return (
      <DialogoUnBotonPerfil
        titulo={titulo}
        descripcion={descripcion}
        textoBoton={textoBoton}
        funcion={funcion}
      />
    );
  };

  return (
    <div className="bg-gradient-to-tl from-[#090930] to-[#5D2D8C] w-full h-full font-proxima">
      <div className="mx-auto max-w-[1300px]">
        {dialogo.cargando ? <Cargando /> : null}
        {opcionesDialogo(dialogo.dialogo)}
        {dialogo.dialogo === 2 && (
          <div className="dialogo-perfil">
            <DialogoAgregarCuenta
              correo={verificarCampo(user, "correo")}
              nombre={verificarCampo(user, "nombre")}
              banco={verificarCampo(user, "banco")}
              tipo={verificarCampo(user, "tipoCuenta")}
              numero={verificarCampo(user, "numeroCuenta")}
              titular={verificarCampo(user, "titularCuenta")}
              identificacion={verificarCampo(user, "identificacion")}
              paisBanco={verificarCampo(user, "paisBanco")}
              cuentaAgregada={pagoSolicitado}
              solicitarPago={solicitarPagos}
              cerrarDialogo={() => manejarDialogo(0)}
              conTitulo={true}
              idArtista={uid}
            />
          </div>
        )}
        {dialogo.dialogo === 10 && (
          <HistorialPagos historial={pagos} cerrar={() => manejarDialogo(0)} />
        )}
        {dialogo.dialogo === 12 && (
          <Achievements cerrar={() => manejarDialogo(0)} />
        )}
        <HeaderPerfil />
        <div className="flex flex-col lg:flex-row mt-10 p-6 gap-10 lg:justify-between">
          <div className="rounded-xl bg-white/10 p-5 lg:w-1/3 lg:h-full lg:py-8">
            <div className="rounded-full relative w-32 h-32 md:w-44 md:h-44">
              <div className="absolute top-8 left-9 w-24 h-24 bg-[#FF6868] rounded-full filter  blur-3xl md:blur-[100px]"></div>
              <div className="absolute top-16 right-6 w-24 h-24 bg-[#2A2ACB] rounded-full filter  blur-2xl md:blur-[100px]"></div>
              <div className="absolute bottom-16  lg:left-64 left-24 w-24 h-24 bg-[#E84A84] rounded-full filter  blur-3xl md:blur-[100px]"></div>

              <div className="relative rounded-full w-full h-full p-1 bg-gradient-to-l from-rosa-morado to-rosa-primariy">
                <img
                  className="w-full h-full  z-50 rounded-full object-cover"
                  src={user.foto ? obtenerUrl(user.foto) : sinFoto}
                  alt="perfil"
                />
                <div className="w-8 h-8 md:w-12 md:h-12 bg-gradient-to-l from-[#f5576c] to-[#f093fb] rounded-full grid place-items-center absolute -right-1 bottom-0 z-[40]">
                  <UserIcon className="w-5 h-5 md:w-7 md:h-7" />
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col relative z-40 mt-4">
              <div className="flex flex-col">
                <span className="text-[26px] font-semibold">
                  {user.nombre ?? "Cargando..."}
                </span>
                <span className="text-white/90 text-base">{user.correo}</span>
              </div>
              <div className="bg-white/75 w-full h-px m-auto my-4"></div>
              <div className="flex flex-col items-start gap-4 pl-3">
                <button onClick={() =>
                  setModal(
                    <ModalGraficaRedes
                      idArtista={uid}
                    />
                  )
                }
                className="flex gap-2 items-center justify-center text-white text-base font-light lg:text-lg hover:text-rosa-primariy/90 hover:scale-105 hover:font-semibold transition-all duration-300 ease-in-out">
                  <span>
                    <Graphic color={"white"} />
                  </span>
                  Redes Sociales
                </button>
                <button
                  className="flex gap-2 items-center justify-center text-white text-base font-light lg:text-lg hover:text-rosa-primariy/90 hover:scale-105 hover:font-semibold transition-all duration-300 ease-in-out"
                  onClick={() => {
                    manejarDialogo(12);
                  }}
                >
                  <span>
                    <Emoji color={"white"} />
                  </span>
                  Logros
                </button>
                <button
                  onClick={
                    pagos.length === 0
                      ? () => manejarDialogo(9)
                      : () => manejarDialogo(10)
                  }
                  className="flex gap-2 items-center justify-center text-white text-base font-light lg:text-lg hover:text-rosa-primariy/90 hover:scale-105 hover:font-semibold transition-all duration-300 ease-in-out"
                >
                  <span>
                    <Payment color={"white"} />
                  </span>
                  Historial de pago
                </button>
                <button
                  onClick={() =>
                    dialogo.dialogo === 7
                      ? setDialogo({ dialogo: 0 })
                      : manejarDialogo(7)
                  }
                  className="flex gap-2 items-center justify-center text-white text-base font-light lg:text-lg hover:text-rosa-primariy/90 hover:scale-105 hover:font-semibold transition-all duration-300 ease-in-out"
                >
                  <span className="">
                    <Bank color={"white"} />
                  </span>
                  Información bancaria
                </button>
              </div>
              <div className="contenedor-sello"></div>
              {dialogo.dialogo === 7 && (
                <DialogoAgregarCuenta
                  banco={verificarCampo(user, "banco")}
                  nombre={verificarCampo(user, "nombre")}
                  tipo={verificarCampo(user, "tipoCuenta")}
                  numero={verificarCampo(user, "numeroCuenta")}
                  titular={verificarCampo(user, "titularCuenta")}
                  identificacion={verificarCampo(user, "identificacion")}
                  paisBanco={verificarCampo(user, "paisBanco")}
                  cuentaAgregada={cerrarDialogoAgregarCuenta}
                  conTitulo={false}
                  idArtista={uid}
                  correo={verificarCampo(user, "correo")}
                  cerrarDialogo={() => setDialogo({ dialogo: 0 })}
                />
              )}
            </div>
          </div>

          <div className="rounded-xl bg-white/10 p-5 lg:w-2/5 h-full">
            <div className="flex flex-col justify-center items-center space-y-5">
              <span className="text-xl font-semibold">Pagos</span>
              <div className=" flex items-center md:flex-row md:space-x-2 md:space-y-0 gap-2">
                <span className="text-base">Total pagado</span>
                <span className="text-base text-rosa-primariy font-bold">
                  {user.totalPagos === 0
                    ? "$0"
                    : formatoNumero(user.totalPagos, true, true, 2, false)}
                </span>
              </div>
            </div>

            <div className="bg-white w-full h-px m-auto mt-2"></div>

            <div className=" w-[90%] m-auto flex flex-col justify-center items-center mt-5">
              <div className="w-auto flex flex-col items-center mt-3">
                <span className="w-auto m-auto text-xl text-center leading-7 font-semibold mb-4">
                  Total generado
                </span>
                <div className="w-full justify-around bg-white/20 py-3 lg:py-3 px-8 gap-6 lg:px-10 rounded-md flex lg:flex-row items-center lg:space-y-0">
                  <span className="text-xl lg:mr-5 font-semibold">
                    {formatoNumero(
                          user.balanceCuenta + user.totalPagos ?? 0,
                          true,
                          true,
                          2,
                          false
                        )
                     }
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center w-full bg-white/20 rounded-lg mt-12 space-y-6 px-8 py-4">
                <span className="text-[20px] font-semibold">Nuevo Saldo</span>
                <div className="w-full flex flex-col gap-2 sm:flex-row justify-around lg:space-y-0 items-center">
                  <span className="text-base lg:text-lg text-center font-semibold">
                    {formatoNumero(user.balanceCuenta, true, true, 2, false)}
                  </span>
                  <button
                    onClick={() =>
                      solicitarPago(user.balanceCuenta, user.pagoSolicitado)
                    }
                    className="text-xs lg:text-sm p-2 btn--primary-sm hover:shadow-button transition-all duration-300 ease-in-out"
                  >
                    Solicitar pago
                  </button>
                </div>
                {user.pagoSolicitado ? (
                  <span className="text-base font-semibold text-pink-300 pb-3 text-center">
                    (El pago ha sido solicitado)
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center mt-16 pb-10">
          {albumes?.length > 0 && (
            <>
              <h2 className="text-2xl md:text-4xl font-semibold">Álbumes</h2>
              <div className="flex flex-wrap justify-center gap-5 w-4/5 mb-14">
                {albumes.map((a, i) => (
                  <Album
                    album={a}
                    key={`repertorio-${i}`}
                    seleccionar={seleccionarAlbum}
                  />
                ))}
              </div>
            </>
          )}
          {sencillos?.length > 0 && (
            <>
              <h2 className="text-2xl md:text-4xl font-semibold">Sencillos</h2>
              <div className="flex flex-wrap justify-center gap-5 w-4/5">
                {sencillos?.map((a, i) => (
                  <Album
                    album={a}
                    key={"repertorio" + { i }}
                    seleccionar={seleccionarAlbum}
                  />
                ))}
              </div>
            </>
          )}
          {album.album !== null ? (
            <DialogoAlbum
              album={album.album}
              cerrar={cerrarDialogo}
              nombre={user.nombre ?? ""}
            />
          ) : (
            ""
          )}
        </div>
        <div className="w-full relative flex flex-col justify-center itmes-center overflow-hidden"></div>
      </div>
    </div>
  );
};

export default Perfil;
