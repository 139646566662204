import React, { Component } from "react";
import "./detalle-sencillo.scss";
import { secondsToDateTimeYMD } from "./../../../../utilidades/Format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./../../../../actions/adminActions";
import Switch from "react-switch";
import { ScaleLoader } from "react-spinners";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import ImgSencillo from "./../../../../assets/sencillo-de-musica.svg";
import moment from "moment";
import { obtenerUrl } from "../../../../utilidades/FuncionesAuxiliares";
import { toast } from "react-toastify";
import { selectDistribuidoras } from "../../../../reducers/adminReducer";

class DetalleSencillo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nombre: "",
      upc: "",
      fechaLanzamiento: "",
      portada: "",
      editando: false,
      online: false,
      cargando: false,
      error: "",
      distribuidora: null
    };
  }

  componentDidMount() {
    if (this.props.sencillo) {
      let { nombre, upc, fechaLanzamiento, online, portada, distribuidora } =
        this.props.sencillo;
      this.setState({
        nombre,
        upc,
        portada,
        fechaLanzamiento: moment(fechaLanzamiento).format("YYYY-MM-DD"),
        online,
        distribuidora: distribuidora?.id ?? null
      });
    }

    if(!this.props.distribuidoras) {
      this.props.getDistribuidoras();
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleEditarClicked = () => this.setState({ editando: !this.state.editando });

  handleAtrasCliked = () => {
    this.volverEdicionEstadoOriginal();
    this.setState({ editando: !this.state.editando });
  };

  handleFile = (e) => {
    this.setState({
      portada: e.target.files[0],
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ cargando: true, error: "" });

    await this.props.editarSencillo(
      {
        nombre: this.state.nombre,
        upc: this.state.upc,
        fechaLanzamiento: new Date(this.state.fechaLanzamiento),
        online: !!this.state.online,
        portada: this.state.portada,
        distribuidora: this.state.distribuidora !== "-1" ? this.state.distribuidora : null
      },
      this.props.sencillo.id
    );

    toast.success("La canción ha sido actualizada");
    this.setState({ cargando: false });
    this.props.handleCerrarDialogo();
  };

  volverEdicionEstadoOriginal = () => {
    let { nombre, upc, fechaLanzamiento, online, portada } =
      this.props.sencillo;
    this.setState({
      nombre,
      upc,
      portada,
      fechaLanzamiento: secondsToDateTimeYMD(fechaLanzamiento),
      editando: false,
      online,
    });
  };

  handleEliminarClicked = async () => {
    this.setState({ cargando: true, error: "" });
    
    await this.props.eliminarSencillo(this.props.sencillo.id, this.props.id);
    toast.success("El sencillo ha sido eliminado");
    this.setState({ cargando: false });
    this.props.handleCerrarDialogo();
  };

  render() {
    if (this.state.cargando)
      return (
        <div className="contenedor-loading-indicator">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
        </div>
      );

    if (!this.state.editando)
      return (
        <div className="detalle-sencillo py-8 px-8 sm:px-12 max-w-full">
          <div className="text-[#E84A84] w-full flex justify-end">
            <ClearRoundedIcon fontSize="large" className="-mr-6 sm:mr-0" />
          </div>
          <div className="w-full flex flex-col items-center">
            <img
              src={
                this.props.sencillo.portada
                  ? obtenerUrl(this.props.sencillo.portada)
                  : ImgSencillo
              }
              className="w-[150px] h-[150px] sm:w-[200px] sm:h-[200px] object-cover rounded-2xl shadow-xl"
            />
          </div>
          <div className="flex flex-col items-center w-full justify-between my-5">
            <div className="font-bold text-3xl text-black font-proxima">
              {this.props.sencillo.nombre}
            </div>
            <div
              className=" underline text-[#E84A84] text-[17px] cursor-pointer"
              onClick={this.handleEditarClicked}
            >
              Editar
            </div>
            <div
              className="underline text-[#E84A84] text-[17px] cursor-pointer"
              onClick={this.handleEliminarClicked}
            >
              Eliminar
            </div>
          </div>
          <div className="item-detalle-sencillo">
            <h3>Nombre</h3>
            <div>{this.props.sencillo.nombre}</div>
          </div>
          <div className="item-detalle-sencillo">
            <h3>Distribuidora</h3>
            <div>{this.props.sencillo.distribuidora?.nombre || "Sin asignar"}</div>
          </div>
          {this.props.sencillo.portada && (
            <div className="item-detalle-sencillo flex flex-col">
              <h3>Portada </h3>
              <a
                href={obtenerUrl(this.props.sencillo.portada)}
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-rosa-primariy"
              >
                Enlace portada
              </a>
            </div>
          )}
          <div className="item-detalle-sencillo">
            <h3>UPC</h3>
            <div>{this.props.sencillo.upc}</div>
          </div>
          <div className="item-detalle-sencillo">
            <h3>Fecha de lanzamiento</h3>
            <div>
              {this.props.sencillo.fechaLanzamiento
                ? moment(this.props.sencillo.fechaLanzamiento)
                    .format("DD/MM/YYYY")
                : "No disponible"}
            </div>
          </div>
          <div className="item-detalle-sencillo  flex flex-col">
            <h3>Estado</h3>
            <span>{this.props.sencillo?.online ? "Online" : "Take down"}</span>
          </div>
        </div>
      );
    else
      return (
        <form
          className="detalle-sencillo py-8 px-8 sm:px-12 max-w-full"
          onSubmit={this.handleSubmit}
        >
          <div className="flex flex-col sm:flex-row items-center w-full justify-between mb-5">
            <div className="font-bold text-3xl text-black font-proxima">
              Sencillo
            </div>
            <div className="flex">
              <div
                className="editar-detalle-album mr-4 underline text-[#E84A84] text-[17px] cursor-pointer"
                onClick={this.handleAtrasCliked}
              >
                Atras
              </div>
              <button
                type="submit"
                className="guardar-agregar-album eliminar-detalle-album underline text-[#E84A84] text-[17px] cursor-pointer"
              >
                Guardar
              </button>
            </div>
          </div>
          <div className="item-detalle-sencillo">
            <span className="text-[22px] font-proxima">Nombre</span>
            <input
              className="input-agregar-sencillo"
              type="text"
              name="nombre"
              value={this.state.nombre}
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="item-detalle-sencillo flex flex-col">
            <span className="text-[22px] font-proxima mr-4 font-bold">
              Distribuidora
            </span>
            <select name="distribuidora" onChange={this.handleChange} value={this.state.distribuidora}>
                <option value={-1}>
                  Sin asignar
                </option>
                {this.props.distribuidoras.map(distribuidora => <option value={distribuidora.id}>
                    {distribuidora.nombre}
                  </option>
                )}
              <option>
              </option>
            </select>
          </div> 
          <div className="item-detalle-sencillo" style={{ border: "none" }}>
            <span className="text-[22px] font-proxima">Portada</span>
            <div className="flex flex-col sm:flex-row sm:items-center mt-4 max-w-full">
              <label
                htmlFor="video-curso"
                className="bg-[#E84A84] text-white relative w-max px-[10px] py-2 rounded-md cursor-pointer text-[17px] float-left whitespace-nowrap"
              >
                <input
                  id="video-curso"
                  type="file"
                  accept="image/*"
                  name="portada"
                  onChange={this.handleFile}
                  className="absolute w-full h-full opacity-0 top-0 left-0 right-0 bottom-0 outline-none cursor-pointer z-[-1]"
                />
                Seleccionar archivo
              </label>
              <span className="text-[#BEBEBE] mt-2 sm:mt-0 sm:ml-5 underline text-lg whitespace-nowrap w-max overflow-x-hidden max-w-full">
                {this.state.portada?.name}
              </span>
            </div>
          </div>
          <div className="item-detalle-sencillo">
            <span className="text-[22px] font-proxima">UPC</span>
            <input
              className="input-agregar-sencillo"
              type="text"
              name="upc"
              value={this.state.upc}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="item-detalle-sencillo">
            <span className="text-[22px] font-proxima">
              Fecha de lanzamiento
            </span>
            <input
              className="input-agregar-sencillo"
              type="date"
              name="fechaLanzamiento"
              value={this.state.fechaLanzamiento}
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="flex items-center mt-4">
            <span className="text-[22px] font-proxima mr-4 font-bold">
              Online
            </span>
            <Switch
              onColor="#e87096"
              width={40}
              height={20}
              uncheckedIcon={false}
              checkedIcon={false}
              activeBoxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
              onChange={() => this.setState({ online: !this.state.online })}
              checked={this.state.online}
            />
          </div>
          <div className="error-message">{this.state.error}</div>
        </form>
      );
  }
}

const mapStateToProps = (state) => ({
  distribuidoras: selectDistribuidoras(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetalleSencillo);
