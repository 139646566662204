import { useHistory } from "react-router-dom";
import logoFooter from "../../assets-2/Logo-footer.svg";
import facebookIcon from "../../assets-2/facebook-icon.svg";
import instagramIcon from "../../assets-2/Instagram-icon.svg";
import youtubeIcon from "../../assets-2/Youtube-icon.svg";

const Footer = () => {
  const history = useHistory();
  return (
    <div className="w-10/12 mx-auto flex flex-col items-center text-center space-y-12 pt-8 font-proxima">
      <div className=" flex flex-col space-y-8 items-center">
        <img src={logoFooter} alt="logo blacklion digital sas" />
        <div className="flex space-x-5">
          <a
            rel="noreferrer"
            href="https://www.facebook.com/blackliondigitalsas"
            target="_blank"
          >
            <img
              src={facebookIcon}
              className="hover:scale-110 transition duration-200"
              alt="facebook"
            />
          </a>
          <a
            rel="noreferrer"
            href="https://www.instagram.com/blacklion_digital_co"
            target="_blank"
          >
            <img
              src={instagramIcon}
              className="hover:scale-110 transition duration-200"
              alt="instagram"
            />
          </a>
          <a
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCDVIqm2gS4vdNPPkf5GQjVA"
            target="_blank"
          >
            <img
              src={youtubeIcon}
              className="hover:scale-110 transition duration-200"
              alt="youtube"
            />
          </a>
        </div>
      </div>
      <div className="flex flex-col text-sm">
        <span
          className="cursor-pointer"
          onClick={() => history.push("/terminos-condiciones")}
        >
          Términos y condiciones 🚀
        </span>
        <span>2020 BlackLion Digital SAS. Todos los derechos reservados</span>
      </div>
    </div>
  );
};

export default Footer;
