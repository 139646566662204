import React, { Component } from "react";
import "./header-administrador.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../actions/sesionActions";
import { Link, Redirect, withRouter } from "react-router-dom";
import { ROL_ADMIN, SIN_SESION } from "../../../../utilidades/Constantes";
import { getUid, getUser } from "../../../../reducers/sesionReducer";
import Logo from "./../../../../assets-2/logo-bl.svg";
import { UserIcon } from "@heroicons/react/outline";

class HeaderAdministrador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogoSesion: false,
    };
  }

  componentDidMount() {
    this.props.traerUidUsuario();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.uid === null && this.props.uid !== null) {
      this.props.traerUsuario(this.props.uid);
    }
  }

  render() {
    if (
      this.props.uid === SIN_SESION ||
      (this.props.user && this.props.user?.rol?.id !== ROL_ADMIN)
    ) {
      return <Redirect to="/" />;
    }
    return (
      <div className="bg-transparent flex flex-col md:flex-row justify-between items-center w-full py-6 md:px-24">
        <Link to="/administrador/academia">
          <img src={Logo} alt="Logo" className="w-[200px] sm:w-[167px]" />
        </Link>
        <div className="flex justify-between items-center  md:flex-row  w-[80%] md:w-min mt-5">
          <div className="w-full md:mx-10 flex  md:flex-row flex-col justify-center items-center gap-x-10 gap-y-3 my-6 md:my-0 lg:gap-16">
            <Link
              to="/administrador/academia"
              className={`${
                window.location.pathname.includes("academia")
                  ? "text-[#E84A84] font-semibold"
                  : "hover:text-[#E84A84]"
              } cursor-pointer font-proxima text-xl `}
            >
              Academia
            </Link>
            <Link
              to="/administrador/distribuidoras"
              className={`${
                window.location.pathname.includes("distribuidoras")
                  ? "text-[#E84A84] font-semibold"
                  : "hover:text-[#E84A84]"
              } cursor-pointer font-proxima text-xl  `}
            >
              Distribuidoras
            </Link>
            <Link
              to="/administrador/artistas"
              className={`${
                window.location.pathname.includes("artistas")
                  ? "text-[#E84A84] font-semibold"
                  : "hover:text-[#E84A84]"
              } cursor-pointer font-proxima text-xl  `}
            >
              Artistas
            </Link>

            <div className="relative">
              <div className="p-2 bg-white/40 rounded-full hover:scale-105 transition-all duration-300 ease-in-out">
                <UserIcon
                  style={this.state.dialogoSesion ? { color: "#fff" } : {}}
                  className="w-6 cursor-pointer "
                  onClick={() =>
                    this.setState({ dialogoSesion: !this.state.dialogoSesion })
                  }
                />
              </div>
              <div
                style={
                  this.state.dialogoSesion
                    ? { display: "block", boxShadow: "0px 6px 15px #54317E" }
                    : { display: "none", boxShadow: "0px 6px 15px #54317E" }
                }
                className="bg-white/50 px-2 py-1 rounded-sm cursor-pointer select-none w-max absolute mt-2 -right-8 md:right-0"
                onClick={() => this.props.limpiarUsuario()}
              >
                Cerrar Sesión
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  uid: getUid(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderAdministrador)
);
