import React from "react";

const InputRadio = ({
  create,
  handleChange,
  refRadio,
  checkedConditional,
  nameText,
  valueText,
  labelText,
  classNameRadio,
}) => {
  return (
    <label htmlFor="" className="flex w-auto gap-2 items-center">
      <input
        type="radio"
        value={valueText}
        className={classNameRadio}
        onChange={handleChange}
        checked={checkedConditional}
        required
        name={nameText}
        ref={refRadio}
      />
      <span className="text-[#3E3E3E] font-normal w-full text-[16px]">
        {labelText}
      </span>
    </label>
  );
};

export default InputRadio;
