import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getArtista } from "../../../../reducers/adminReducer";
import { editarInfoPerfil } from "../../../../actions/adminActions";
import { ScaleLoader } from "react-spinners";
import { toast } from "react-toastify";
import { COLORES } from "../../../../utilidades/Constantes";

const ProfileInfo = () => {
  const { id } = useParams();
  const artista = useSelector((state) => getArtista(state, id));
  const [artistaInfoEditable, setArtistaInfoEditable] = useState(artista);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (artista && !artista.infoBasica) {
      setArtistaInfoEditable(artista);
    }
  }, [artista]);

  const handleChange = (e) => {
    setArtistaInfoEditable({
      ...artistaInfoEditable,
      [e.target.name]: e.target.value,
    });
  };

  const guardarDatos = async () => {
    const {
      id,
      nombreLegal,
      pasaporte,
      titularCuenta,
      banco,
      numeroCuenta,
      paisBanco,
      identificacion,
      tipoCuenta,
      nombreRepresentante,
      cedulaRepresentante,
      direccionRepresentante,
      telefono,
    } = artistaInfoEditable;
    setLoading(true);
    await editarInfoPerfil(
      {
        id,
        nombreLegal,
        pasaporte,
        titularCuenta,
        banco,
        numeroCuenta,
        paisBanco,
        identificacion,
        tipoCuenta,
        nombreRepresentante,
        cedulaRepresentante,
        direccionRepresentante,
        telefono,
      },
      dispatch
    );
    setLoading(false);
    toast.success("Los datos del artista han sido actualizados");
  };

  return artistaInfoEditable ? (
    <div className="w-full flex flex-col">
      <h2 className="font-bold text-[24px] leading-7">Datos del artista</h2>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden rounded-lg">
              <table className="w-full text-left">
                <thead className="border-b bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4"
                    >
                      Datos
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4"
                    >
                      Información
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Nombre Legal
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="Nombre legal"
                          name="nombreLegal"
                          value={artistaInfoEditable.nombreLegal}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4  place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Pasaporte
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="Pasaporte"
                          name="pasaporte"
                          value={artistaInfoEditable.pasaporte}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4  place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Nombre del representante
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="Nombre del representante"
                          name="nombreRepresentante"
                          value={artistaInfoEditable.nombreRepresentante}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4  place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Cédula del representante
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="Cédula del representante"
                          name="cedulaRepresentante"
                          value={artistaInfoEditable.cedulaRepresentante}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4  place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Dirección del representante
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="Dirección del representante"
                          name="direccionRepresentante"
                          value={artistaInfoEditable.direccionRepresentante}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4  place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Teléfono
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="Teléfono"
                          name="telefono"
                          value={artistaInfoEditable.telefono}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4  place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <h2 className="font-bold text-[24px] leading-7">Cuenta bancaria</h2>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden rounded-lg">
              <table className="w-full text-left">
                <thead className="border-b bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4"
                    >
                      Datos
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4"
                    >
                      Información
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Titular de la cuenta
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="Titular de la cuenta"
                          name="titularCuenta"
                          value={artistaInfoEditable.titularCuenta}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4  place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Entidad Bancaria
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="Entidad bancaria"
                          name="banco"
                          value={artistaInfoEditable.banco}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4  place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Número de la cuenta
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="Número de la cuenta"
                          name="numeroCuenta"
                          value={artistaInfoEditable.numeroCuenta}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4 place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Número de identificación
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="Número de identificación"
                          name="identificacion"
                          value={artistaInfoEditable.identificacion}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4  place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      Tipo de cuenta
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="Tipo de cuenta"
                          name="tipoCuenta"
                          value={artistaInfoEditable.tipoCuenta}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4  place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      País de cuenta
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <div className="flex mt-2 mb-2 place-content-center">
                        <input
                          className="w-3/4 "
                          placeholder="País de cuenta"
                          name="paisBanco"
                          value={artistaInfoEditable.paisBanco}
                          onChange={handleChange}
                        ></input>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-1/4 h-4  place-content-end text-rosa-primariy"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="self-end">
          <ScaleLoader color={COLORES.PRIMARIO} />
        </div>
      ) : (
        <button
          onClick={guardarDatos}
          className="bg-rosa-primariy text-white font-bold my-5 py-2 px-4 rounded self-end"
        >
          Guardar
        </button>
      )}
    </div>
  ) : (
    <ScaleLoader />
  );
};

export default ProfileInfo;
