import React, { Component } from "react";
import PropTypes from "prop-types";
import { XIcon } from "@heroicons/react/outline";

class FormCuenta extends Component {
  constructor(props) {
    super(props);
    if (this.props.datos)
      this.state = {
        titular: this.props.datos.titular,
        identificacion: this.props.datos.identificacion,
        entidad: this.props.datos.entidad,
        numero: this.props.datos.numero,
        tipo: this.props.datos.tipo,
        pais: this.props.datos.pais,
      };
    else
      this.state = {
        titular: "",
        identificacion: "",
        entidad: "",
        numero: "",
        tipo: "",
        pais: "",
      };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleAddAccount = () => {
    let datosBancarios = this.state;
    this.props.funcDatos(datosBancarios);
  };

  render() {
    return (
      <div className="flex flex-col items-center w-full">
        <div className="w-[89%] mt-2 flex justify-end">
          <XIcon
            className="w-8 h-8 text-rosa-primariy cursor cursor-pointer"
            alt="cerrar"
            onClick={() => this.props.funcAtras()}
          />
        </div>
        <form
          className="mt-8 grid grid-flow-row  w-11/12 relative"
          onSubmit={this.handleAddAccount}
        >
          <div className="flex flex-col  mb-2">
            <p className="text-base font-semibold lg:text-xl mb-2 pl-4 text-rosa-darkBlue overflow-y-hidden">
              Titular de la cuenta
            </p>
            <input
              value={this.state.titular}
              onChange={this.handleChange}
              className="text-black mx-4 rounded-md p-1 border border-rosa-darkBlue overflow-y-hidden"
              name="titular"
              type="text"
              required
            />
          </div>
          <div className="flex flex-col mb-2">
            <p className="text-base font-semibold lg:text-xl mb-2 pl-4 text-rosa-darkBlue overflow-y-hidden">
              Número de identificación
            </p>
            <input
              value={this.state.identificacion}
              onChange={this.handleChange}
              className="text-black mx-4 rounded-md p-1 border border-rosa-darkBlue overflow-y-hidden number-input"
              name="identificacion"
              type="number"
              required
            />
          </div>
          <div className="flex flex-col mb-2">
            <p className="text-base font-semibold lg:text-xl mb-2 pl-4 text-rosa-darkBlue overflow-y-hidden">
              Entidad Bancaria
            </p>
            <input
              value={this.state.entidad}
              onChange={this.handleChange}
              className="text-black mx-4 rounded-md p-1 border border-rosa-darkBlue overflow-y-hidden"
              name="entidad"
              type="text"
              required
            />
          </div>
          <div className="flex flex-col mb-2">
            <p className="text-base font-semibold lg:text-xl mb-2 pl-4 text-rosa-darkBlue overflow-y-hidden">
              Número de cuenta
            </p>
            <input
              value={this.state.numero}
              onChange={this.handleChange}
              className="text-black mx-4 rounded-md p-1 border border-rosa-darkBlue overflow-y-hidden"
              name="numero"
              type="number"
              required
            />
          </div>
          <div className="flex flex-col mb-2">
            <p className="text-base font-semibold lg:text-xl mb-2 pl-4 text-rosa-darkBlue overflow-y-hidden">
              Tipo de cuenta
            </p>
            <select
              value={this.state.tipo}
              onChange={this.handleChange}
              className="text-black mx-4 rounded-md p-1 border border-rosa-darkBlue overflow-y-hidden select-input"
              name="tipo"
              required
            >
              <option value="Cuenta de Ahorros">Cuenta de Ahorros</option>
              <option value="Cuenta Corriente">Cuenta Corriente</option>
            </select>
          </div>
          <div className="flex flex-col mb-2">
            <p className="text-base font-semibold lg:text-xl mb-2 pl-4 text-rosa-darkBlue overflow-y-hidden">
              País de la entidad Bancaria
            </p>
            <input
              value={this.state.pais}
              onChange={this.handleChange}
              className="text-black mx-4 rounded-md p-1 border border-rosa-darkBlue overflow-y-hidden"
              name="pais"
              type="text"
              required
            />
          </div>

          <div className="mt-8 flex justify-center items-center">
            <button
              className="btn--primary hover:shadow-button transition"
              type="submit"
            >
              Agregar Cuenta
            </button>
          </div>
        </form>
      </div>
    );
  }
}

FormCuenta.propTypes = {
  funcDatos: PropTypes.func.isRequired,
  funcAtras: PropTypes.func.isRequired,
  datos: PropTypes.object,
};

export default FormCuenta;
