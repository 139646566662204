import React from "react";
import Chart from "react-google-charts";
import { COLORES } from "../../../utilidades/Constantes";

const GraficaRedesSociales = ({ registrosSeguidoresRedes, redes }) => {
  let header = ["Fecha"];
  header = header.concat(redes?.map((red) => red.nombre) || []);

  let rows =
    registrosSeguidoresRedes?.map((registro) => {
      const row = [new Date(registro.fecha)];
      redes?.forEach((red) => {
        const registroRed = registro.registrosRedes.find(
          (registroRed) => registroRed.red.nombre === red.nombre
        );
        row.push(registroRed?.numeroSeguidores || 0);
      });
      return row;
    }) || [];

  const data = [header, ...rows];
  return (
    <>
      <Chart
        chartType={"LineChart"}
        height={300}
        data={data}
        options={{
          colors: Object.values(COLORES),
          hAxis: {
            format: "dd/MM/yyyy",
            title: "Fecha",
          },
        }}
      />
    </>
  );
};

export default GraficaRedesSociales;
