import { getData } from "../proxy/RestCall";

export const EDITAR_INFO_BANCARIA = "EDITAR_INFO_BANCARIA"
export const TRAER_REDES_ARTISTA = "TRAER_REDES_ARTISTA"


export const traerRedesArtista = async (idArtista, dispatch) => {
  const res = await getData(`usuarios/${idArtista}/registrosSeguidores`);

  dispatch({
    type: TRAER_REDES_ARTISTA,
    payload: res.data
  })
}
