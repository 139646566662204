import React, { useEffect, useState } from "react";
import "./pagina-temas.sass";
import { connect } from "react-redux";
import * as actions from "./../../../../actions/academiaActions";
import {
  getTemas,
  getTemasCompletos,
} from "../../../../reducers/academiaReducer";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import IconoAgregar from "../../../general/IconoAgregar";
import IconoEditar from "../../../general/IconoEditar";
import ScaleLoader from "react-spinners/ScaleLoader";
import EditarTema from "../DetalleTema/EditarTema";
import { ERROR } from "../../../../utilidades/Constantes";

const PaginaTemas = ({ temas, temasCompletos, getDBTemasAcademia }) => {
  const history = useHistory();
  const [cargando, setCargando] = useState(false);
  const [dialogo, setDialogo] = useState({});

  useEffect(() => {
    if (!temas || !temasCompletos) getDBTemasAcademia();
  }, [temas, getDBTemasAcademia]);

  const handleCrearTema = async (tema) => {
    setCargando(true);
    const res = await actions.crearTema(tema);
    if (res.estado === ERROR) {
      setDialogo({
        titulo: res.estado,
        mensaje: res.mensaje,
        boton1: "Regresar",
        click1: () => setDialogo({}),
      });
    }
    setCargando(false);
  };

  return (
    <div className="w-[90%] mx-auto mt-10">
      {cargando && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-30">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
        </div>
      )}
      {dialogo === "crear-tema" && (
        <EditarTema
          handleGuardar={handleCrearTema}
          handleCancelar={() => setDialogo({})}
        />
      )}
      <h1 className="text-5xl font-bold font-proxima text-center sm:ml-20 sm:text-left">
        Temas
      </h1>
      <div className="flex items-center mt-10 justify-center sm:justify-start sm:mr-16 sm:ml-20">
        <span className="font-proxima font-semibold text-[28px] sm:text-[28px] mr-10">
          Crear Tema
        </span>
        <IconoAgregar onClick={() => setDialogo("crear-tema")} />
      </div>
      <div className="flex flex-wrap w-full justify-evenly my-4 sm:my-16">
        {temas?.map((tema) => (
          <div key={tema.nombre} className="w-64 my-10 mx-10 h-72">
            <img
              src={tema.imagen}
              alt={"Imagen de " + tema.nombre}
              className="w-[100px] h-[100px] object-cover mx-auto object-center"
            />
            <h3 className="w-full text-center font-extrabold mt-2 text-[21px] font-proxima">
              {tema.nombre}
            </h3>
            <p className="w-full text-center mt-6 leading-5 text-base font-proxima">
              {tema.descripcion}
            </p>
            <div className="flex w-1/2 mx-auto justify-around items-center mt-8">
              <IconoEditar
                onClick={() =>
                  history.push("/administrador/academia/" + tema.uidDoc)
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  temas: getTemas(state),
  temasCompletos: getTemasCompletos(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaginaTemas);
