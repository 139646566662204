import React from "react";
import PropTypes from "prop-types";

const IconoAgregar = ({ onClick }) => {
  return (
    <div
      className="bg-gradient-to-r from-[#F093FB] to-[#F5576C] rounded-full p-2 cursor-pointer animacion-iconos"
      onClick={() => onClick()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 4v16m8-8H4"
        />
      </svg>
    </div>
  );
};

IconoAgregar.propTypes = {
  onClick: PropTypes.func,
};

export default IconoAgregar;
