import React from "react";
import { formatFecha } from "../../../../utilidades/FuncionesAuxiliares";
import { seleccionarImagen } from "../../../../utilidades/SeleccionarImagen";

const Album = (props) => {
  const album = props.album;

  return (
    <section className="w-[300px] flex flex-col justify-center items-center space-y-8 mt-5">
      <div className="">
        <img
          src={seleccionarImagen(album?.portada, album)}
          alt="banner"
          className="rounded-md w-40 h-40 object-cover shadow-xl"
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <span>{album.nombre}</span>
        {formatFecha(album.fechaLanzamiento)}
        <button
          className="mt-5 btn--primary-sm px-3 py-2 rounded-md"
          onClick={() => props.seleccionar(album)}
        >
          Ver más
        </button>
      </div>
    </section>
  );
};

export default Album;
