import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { eliminarLogro } from "../../../../actions/adminActions";
import Cargando from "../../../general/Cargando";

const RemoveAcchievement = ({ closeModal, idAcchivement }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [cargando, setCargando] = useState(false);
  const removeAchievement = async (idAcchivement) => {
    setCargando(true);
    await eliminarLogro(dispatch, idAcchivement, id);
    closeModal(false);
    setCargando(false);
  };
  return (
    <div className="flex flex-col gap-4 bg-white p-4 rounded-lg w-full text-gray-700 font-medium text-sm lg:text-base md:w-[28rem] max-w-[30rem] xl:h-32 items-center justify-center">
      {cargando ? <Cargando /> : null}
      <p className="text-center font-semibold text-">
        ¿Desea eliminar este logro?
      </p>
      <div className="flex justify-center items-center gap-4 xl:gap-8">
        <button
          className="border-2 border-rosa-primariy rounded py-1 px-2 text-rosa-primariy font-semibold md:w-28"
          onClick={() => {
            closeModal(false);
          }}
        >
          Cancelar
        </button>
        <button
          className="border-2 border-rosa-primariy bg-rosa-primariy rounded py-1 px-2 text-white font-semibold md:w-28"
          type="submit"
          onClick={() => {
            removeAchievement(idAcchivement);
          }}
        >
          Confirmar
        </button>
      </div>
    </div>
  );
};

export default RemoveAcchievement;
