import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
// import { Button } from './Button'
import "./NavMenu.scss";
import logoWhite from "../../../assets-2/logo-bl.svg";
import { connect } from "react-redux";
import { getUid } from "../../../reducers/sesionReducer";
import { SIN_SESION } from "../../../utilidades/Constantes";

const Navbar = ({ uid }) => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobielMenu = () => setClick(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="navbar lg:fixed font-proxima">
        <div className="navbar-container container-menu">
          <div className="flex flex-col justify-center items-center">
            <Link to="/" className="navbar-logo" onClick={closeMobielMenu}>
              <img src={logoWhite} alt="" />
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? (
                <XIcon className="w-8 h-8 text-white" />
              ) : (
                <MenuIcon className="w-8 h-8 text-white" />
              )}
            </div>
          </div>
          <ul className={click ? "nav-menu active " : "nav-menu "}>
            <li className="nav-item">
              <Link
                to="/conocenos"
                className={
                  window.location.pathname.endsWith("conocenos")
                    ? "nav-links text-2xl md:text-base text-rosa-primariy font-semibold"
                    : "nav-links text-2xl md:text-base"
                }
                onClick={handleClick}
              >
                Conócenos
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/servicios"
                className={
                  window.location.pathname.endsWith("servicios")
                    ? "nav-links text-2xl md:text-base text-rosa-primariy font-semibold"
                    : "nav-links text-2xl md:text-base text-white"
                }
                onClick={handleClick}
              >
                Servicios
              </Link>
            </li>
            <li className="nav-item">
              {!uid || uid === SIN_SESION ? (
                <Link
                  to="/login"
                  className={
                    window.location.pathname.endsWith("login")
                      ? "nav-links text-2xl md:text-base text-rosa-primariy font-semibold"
                      : "nav-links text-2xl md:text-base text-white"
                  }
                  onClick={handleClick}
                >
                  Iniciar Sesión
                </Link>
              ) : (
                <Link
                  to="/artista"
                  className="nav-links text-2xl md:text-base hover:text-rosa-primariy"
                >
                  Perfil
                </Link>
              )}
            </li>
            <div className="hidden md:block lg:bg-white lg:w-px md:h-1/2 lg:mx-5"></div>
            <li className="ml-4 md:ml-0">
              <Link
                to="contacto"
                className=" btn--navbar gradient md:ml-4 cursor-pointer text-xl md:text-base font-normal"
                onClick={handleClick}
              >
                Contacto
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  uid: getUid(state),
});

export default connect(mapStateToProps, null)(Navbar);
