import phoneData from "../../assets-2/phone-data2.png";
// import { CheckCircleIcon } from "@heroicons/react/outline";
import checkIcon from "../../assets-2/checkIcon.svg";

const Hero = () => {
  return (
    <section className="container mx-auto  flex justify-around flex-col-reverse lg:flex-row font-proxima items-center gap-10 my-8 py-16">
      <div className="w-auto flex flex-col mt-8 lg:mt-0 justify-center items-center">
        <img
          src={phoneData}
          className="w-[70%] lg:w-auto"
          alt="iamgen estadistica"
        />
      </div>
      <div className="space-y-8 flex flex-col w-[75%]">
        <h3 className="text-3xl md:text-4xl lg:text-[40px] font-semibold text-center md:text-start mb-8">
          Campañas <span className="text-[#E84A84]">Digitales</span>{" "}
        </h3>
        <div className="flex items-center justify-center">
          <ul className="flex flex-col gap-y-7 mx-auto">
            <li className="flex  items-start gap-4">
              <div className="w-[15%] flex justify-center items-center mt-2">
                <img className="w-[30%]" src={checkIcon} alt="" />
              </div>
              <span className="w-full  text-sm md:text-lg ">
                Estudiamos tu público objetivo
              </span>
            </li>
            <li className=" flex items-start  gap-4">
              <div className="w-[15%] flex justify-center items-center mt-2">
                <img className="w-[30%]" src={checkIcon} alt="" />
              </div>
              <span className="w-full text-sm md:text-lg ">
                Ajustamos y segmentamos tu audiencia
              </span>
            </li>
            <li className=" flex items-start  gap-4">
              <div className="w-[15%] flex justify-center items-center mt-2">
                <img className="w-[30%]" src={checkIcon} alt="" />
              </div>
              <span className="w-full  text-sm md:text-lg">
                Desarrollamos el tipo de contenido, frecuencia e impacto
              </span>
            </li>
            <li className=" flex items-start  gap-4">
              <div className="w-[15%] flex justify-center items-center mt-2">
                <img className="w-[30%]" src={checkIcon} alt="" />
              </div>
              <span className="w-full text-sm md:text-lg">
                Organizamos tu plan de contenido
              </span>
            </li>
            <li className=" flex items-start  gap-4">
              <div className="w-[15%] flex justify-center items-center mt-2">
                <img className="w-[30%]" src={checkIcon} alt="" />
              </div>
              <span className="w-full text-sm md:text-lg">
                Optimizamos la calidad de tu audiencia
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Hero;
