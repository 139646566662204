import * as actions from "../../../../../actions/academiaActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTemas } from "../../../../../reducers/academiaReducer";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import HeroAcademia from "./HeroAcademia";
import Cargando from "../../../../general/Cargando";

const HomeAcademia = ({ temas, getDBTemasAcademia }) => {
  const history = useHistory();

  const getTema = (tema) => {
    history.push(`/academia/${tema.uidDoc}`);
  };

  useEffect(() => {
    getDBTemasAcademia();
  }, [getDBTemasAcademia]);

  return (
    <div className=" container w-full h-full">
      <HeroAcademia />
      <div className="w-full grid lg:grid-cols-3 flex-wrap items-center gap-12 pb-6">
        {!temas ? (
          <Cargando />
        ) : (
          temas.map((tema, index) => {
            return (
              <div
                className="flex flex-col w-[300px] p-4 items-center justify-center space-y-4 text-center  rounded-md  hover:bg-[#5A3D80]  transition-all duration-300 cursor-pointer mx-auto"
                onClick={() => getTema(tema)}
                key={index}
              >
                <div className="w-2/5">
                  <img className="w-full" src={tema.imagen} alt="" />
                </div>
                <h3 className="text-lg sm:text-xl font-semibold">
                  {tema.nombre}
                </h3>
                <span className="text-xs">{tema.descripcion}</span>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  temas: getTemas(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeAcademia);
