import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  crearRegistroRedesSocialesArtista,
  editarRegistroRedesSocialesArtista,
} from "../../../../actions/adminActions";
import { ModalContext } from "../../../../context/ModalContext";

const CrearEditarRegistro = ({ redes, idArtista, registro }) => {
  const { setModal } = useContext(ModalContext);
  const [registrosRedes, setRegistrosRedes] = useState({});
  const dispatch = useDispatch();

  const handleChange = (e, idRed) => {
    setRegistrosRedes({
      ...registrosRedes,
      [idRed]: {
        red: idRed,
        numeroSeguidores: parseFloat(e.target.value),
      },
    });
  };

  useEffect(() => {
    if (registro) {
      const objRegistros = {};
      registro.registrosRedes.forEach((registroRedes) => {
        objRegistros[registroRedes.red.id] = {
          numeroSeguidores: registroRedes.numeroSeguidores,
          red: registroRedes.red.id,
        };
      });
      setRegistrosRedes(objRegistros);
    }
  }, []);

  const guardarRegistro = async (e) => {
    e.preventDefault();
    if (!registro) {
      await crearRegistroRedesSocialesArtista(
        idArtista,
        Object.values(registrosRedes),
        dispatch
      );
      toast.success("Registro creado");
    } else {
      await editarRegistroRedesSocialesArtista(
        registro.id,
        idArtista,
        Object.values(registrosRedes),
        dispatch
      );
      toast.success("Registro actualizado");
    }
    setModal(null);
  };

  return (
    <form
      onSubmit={guardarRegistro}
      className="bg-white text-black p-5 rounded-xl flex flex-col w-[500px] max-w-[100%]"
    >
      <div className="flex justify-between w-full font-bold">
        <h2>Agregar nuevo registro</h2>
        <span className="text-rosa-primariy">
          {moment().format("DD/MM/YYYY")}
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
        {redes.map((red) => {
          return (
            <div className="flex flex-col">
              <h2 className="font-bold">{red.nombre}</h2>
              <input
                required
                value={registrosRedes[red.id]?.numeroSeguidores || 0}
                className="border-b-2 focus:border-rosa-primariy outline-none"
                placeholder="Ingresar registro"
                onChange={(e) => handleChange(e, red.id)}
                type="number"
                min={1}
              />
            </div>
          );
        })}
      </div>
      <div className="mt-5 flex justify-end">
        <button
          className="text-[16px] py-[7px] px-[20px] border-rosa-primariy border-2 rounded-lg text-rosa-primariy"
          onClick={() => setModal(null)}
          type="button"
        >
          Cancelar
        </button>
        <button
          className="text-[16px] py-[7px] px-[20px] bg-rosa-primariy rounded-lg text-white ml-4"
          type="submit"
        >
          Guardar
        </button>
      </div>
    </form>
  );
};

export default CrearEditarRegistro;
