import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../actions/sesionActions";
import {
  getUid,
  getUser,
  getRepertorio,
  getHistorial,
} from "../../../../reducers/sesionReducer";
import Cargando from "../../../general/Cargando";
import { SIN_SESION } from "../../../../utilidades/Constantes";
import { Redirect } from "react-router-dom";
import { UserIcon } from "@heroicons/react/solid";
import logoBlackLion from "../../../../assets-2/logo-bl.svg";
import { Link } from "react-router-dom";

class HeaderPerfil extends Component {
  state = {
    cargando: false,
    info: false,
    verCS: false,
    escuchadorSesion: false,
    logo: true,
  };

  componentDidMount() {
    this.setState({
      cargando: false,
    });

    this.props.traerUidUsuario();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.uid === null && this.props.uid !== null) {
      this.setState({
        escuchadorSesion: true,
      });
      if (this.props.user === null) this.props.traerUsuario(this.props.uid);
    }

    if (
      this.props.uid !== null &&
      !this.state.info &&
      !this.state.escuchadorSesion
    ) {
      this.setState({
        escuchadorSesion: true,
      });
      if (this.props.user === null) this.props.traerUsuario(this.props.uid);
    }

    if (
      this.props.user !== null &&
      this.props.repertorio !== null &&
      !this.state.info
    ) {
      this.setState({
        cargando: false,
        info: true,
      });
    }
  }

  render() {
    // let user = this.props.user ?? {};

    if (this.props.uid === SIN_SESION) {
      return <Redirect to="/" />;
    }

    return (
      <div className="container m-auto ">
        {this.state.cargando ? <Cargando /> : ""}
        <div className="w-full flex flex-col sm:flex-row gap-4 sm:gap-0 justify-between items-center flex-end py-5 px-10 space-y-8 sm:space-y-0">
          <div className="">
            <img
              src={logoBlackLion}
              className="w-full max-w-[200px]"
              alt="logo blacklion"
            />
          </div>
          <div className="flex items-center  md:w-52 gap-x-5 md:gap-x-6 flex-grow sm:justify-end">
            <Link
              className={
                window.location.pathname.includes("academia")
                  ? "text-rosa-primariy text-base md:text-xl font-semibold"
                  : "hover:text-rosa-primariy text-base md:text-xl"
              }
              to="/academia"
            >
              Academia
            </Link>
            <Link
              className={
                window.location.pathname.endsWith("artista")
                  ? "text-rosa-primariy text-base md:text-xl font-semibold"
                  : "hover:text-rosa-primariy text-base md:text-xl"
              }
              to="/artista"
            >
              Perfil
            </Link>
            <button
              className="relative"
              onClick={() =>
                this.setState({
                  verCS: !this.state.verCS,
                })
              }
            >
              <UserIcon className="w-6 h-6 md:w-10 md:h-10 text-rosa-primariy hover:scale-110 transition duration-300 ease-in-out relative" />
              {this.state.verCS ? (
                <div className="w-max flex justify-end top-[110%] items-center absolute right-0">
                  <button
                    className=" bg-white/50 px-3 py-2 rounded-sm"
                    style={{ boxShadow: "0px 6px 15px #54317E" }}
                    onClick={this.props.limpiarUsuario}
                  >
                    Cerrar Sesión
                  </button>
                </div>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>

        {this.state.verCS ? (
          <div
            className="fondo-cs"
            onClick={() => this.setState({ verCS: !this.state.verCS })}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  uid: getUid(state),
  repertorio: getRepertorio(state),
  historial: getHistorial(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPerfil);
