import React, { Component } from "react";
import "./dialogo-administrador.scss";

class DialogoAdministrador extends Component {
  dialogoRef = React.createRef();

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dialogoRef && !this.dialogoRef.current.contains(event.target)) {
      this.props.handleCerrarDialogo();
    }
  };

  render() {
    let { children } = this.props;

    const childrenWithProps = React.Children.map(children, (child) => {
      // checking isValidElement is the safe way and avoids a typescript error too
      if (React.isValidElement(child)) {
        return React.cloneElement(child);
      }
      return child;
    });

    return (
      <div className="fondo-dialogo-administrador text-black z-20">
        <div className="dialogo-administrador overflow-y-hidden" ref={this.dialogoRef}>
          {childrenWithProps}
        </div>
      </div>
    );
  }
}

export default DialogoAdministrador;
