import React, { Component } from "react";
import { connect } from "react-redux";
import { ScaleLoader } from "react-spinners";
import { bindActionCreators } from "redux";
import { ERROR } from "../../../../utilidades/Constantes";
import * as actions from "./../../../../actions/adminActions";
import "./cambiar-foto.sass";
class CambiarFoto extends Component {
  state = {
    cargando: false,
    error: null,
    photoInput: ""
  };

  handleFile = (e) => {
    this.setState({
      photoInput: e.target.files[0],
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      cargando: true,
      error: "",
    });

    let res = await this.props.cambiarFotoArtista({
      id: this.props.id,
      foto: this.state.photoInput,
    });

    this.setState({
      cargando: false,
    });

    if (res === ERROR)
      this.setState({
        error: "Hubo un error, por favor intentalo de nuevo",
      });
    else {
      this.props.handleCerrarDialogo();
    }
  };

  render() {
    if (this.state.cargando)
      return (
        <div className="contenedor-loading-indicator">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
        </div>
      );

    return (
      <form className="p-10 max-w-full w-[500px]" onSubmit={this.handleSubmit}>
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <div className="titulo-subir-pago font-bold text-2xl text-black font-proxima">
            Cambiar foto
          </div>
          <button
            type="submit"
            className="guardar-subir-pago underline text-[#E84A84] text-[17px] cursor-pointer"
          >
            Guardar
          </button>
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center mt-4 max-w-full">
          <label
            htmlFor="video-curso"
            className="bg-[#E84A84] text-white relative w-max px-[10px] py-2 rounded-md cursor-pointer text-[17px] float-left whitespace-nowrap"
          >
            <input
              id="video-curso"
              type="file"
              accept="image/*"
              name="portada"
              onChange={this.handleFile}
              className="absolute w-full h-full opacity-0 top-0 left-0 right-0 bottom-0 outline-none cursor-pointer z-[-1]"
              required
            />
            Seleccionar archivo
          </label>
          <span className="text-[#BEBEBE] mt-2 sm:mt-0 sm:ml-5 underline text-lg whitespace-nowrap w-max max-w-full overflow-x-hidden w-">
            {this.state.photoInput?.name}
          </span>
        </div>
        {this.state.error && (
          <div className="error-cambiar-foto">{this.state.error}</div>
        )}
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(null, mapDispatchToProps)(CambiarFoto);
