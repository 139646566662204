import {
  editarDocumento,
  consultarDocumentoConSubColeccion,
  eliminarDocumento,
  crearDocumentoAutoID,
  traerCopiaDocumento,
  consultarColeccion,
} from "../proxy/FirebaseDB";
import { registrarUsuario } from "../proxy/FirebaseAuth";
import {
  EDITAR_ALBUM,
  EDITAR_LOGRO,
  EDITAR_SENCILLO,
  ELIMINAR_LOGRO,
  ERROR,
  OK,
  SUBIR_ALBUM,
  SUBIR_LOGRO,
  SUBIR_PAGO,
  SUBIR_SENCILLO,
  TRAER_LOGROS,
} from "../utilidades/Constantes";
import { subirArchivo, eliminarArchivo } from "../proxy/FirebaseStorage";
import { llamarFunction } from "../proxy/CloudFunctions";
import { deleteData, getData, postData, patchData } from "../proxy/RestCall";
import moment from "moment";
import { Uploader } from "../proxy/Uploader";

export const GET_ARTISTAS = "GET_ARTISTAS";
export const GET_ARTISTA = "GET_ARTISTA";
export const EDIT_SENCILLO = "EDIT_SENCILLO";
export const DELETE_SENCILLO = "DELETE_SENCILLO";
export const DELETE_ALBUM = "DELETE_ALBUM";
export const CONSULTAR_REPERTORIO = "CONSULTAR_REPERTORIO";
export const CONSULTAR_HISTORIAL = "CONSULTAR_HISTORIAL";
export const CONSULTAR_HISTORIAL_REGALIAS = "CONSULTAR_HISTORIAL_REGALIAS";
export const CONSULTAR_HISTORIAL_REGALIAS_YOUTUBE =
  "CONSULTAR_HISTORIAL_REGALIAS_YOUTUBE";
export const ELIMINAR_ULTIMA_REGALIA = "ELIMINAR_ULTIMA_REGALIA";
export const TRAER_REGALIAS = "TRAER_REGALIAS";
export const TRAER_PAGOS = "TRAER_PAGOS";
export const PATCH_ARTISTA = "PATCH_ARTISTA";
export const CREATE_ARTISTA = "CREATE_ARTISTA";
export const TRAER_PLATAFORMAS = "TRAER_PLATAFORMAS";
export const CAMBIAR_ESTADO_PLATAFORMA = "CAMBIAR_ESTADO_PLATAFORMA";
export const TRAER_TAREAS_ARTISTA = "TRAER_TAREAS_ARTISTA";
export const TAREA_ACTUALIZADA = "TAREA_ACTUALIZADA";
export const CAMBIAR_FOTO_ARTISTA = "CAMBIAR_FOTO_ARTISTA";
export const GET_REDES_ARTISTA = "GET_REDES_ARTISTA";

export const getArtistas = async (dispatch) => {
  const res = await getData("usuarios/artistas");
  dispatch({
    type: GET_ARTISTAS,
    payload: res.data,
  });
};

export const getSingleArtista = (id) => {
  return async (dispatch) => {
    const res = await getData(`usuarios/${id}`);
    dispatch({
      type: GET_ARTISTA,
      payload: res.data,
    });
  };
};

export const editSencillo = (id, body) => {
  return async (dispatch) => {
    const formData = new FormData();
    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });
    const res = await patchData(`canciones/${id}`, formData);
    dispatch({
      type: EDIT_SENCILLO,
      payload: res.data,
    });
    return res;
  };
};

export const consultarReperorio = (payload) => {
  return (dispatch) => {
    consultarDocumentoConSubColeccion(
      `usuarios/${payload.id}`,
      "repertorio",
      dispatch,
      CONSULTAR_REPERTORIO
    );
  };
};

export const consultarHistorial = (payload) => {
  return (dispatch) => {
    consultarDocumentoConSubColeccion(
      `usuarios/${payload.id}`,
      "historial",
      dispatch,
      CONSULTAR_HISTORIAL
    );
  };
};

export const consultarHistorialRegalias = (payload) => {
  return (dispatch) => {
    consultarDocumentoConSubColeccion(
      `usuarios/${payload.id}`,
      "historialRegalias",
      dispatch,
      CONSULTAR_HISTORIAL_REGALIAS
    );
  };
};

export const consultarHistorialRegaliasYoutube = (payload) => {
  return (dispatch) => {
    consultarColeccion(
      `usuarios/${payload.id}/historialRegaliasYoutube`,
      dispatch,
      CONSULTAR_HISTORIAL_REGALIAS_YOUTUBE,
      {
        idArtista: payload.id,
      }
    );
  };
};

export const crearObra = (payload) => {
  return async (dispatch) => {
    const errorDefault = {
      tipo: ERROR,
      mensaje: "Hubo un error, intentalo de nuevo",
    };
    const obra = await crearDocumentoAutoID(
      `usuarios/${payload.id}/repertorio`,
      { ...payload.obra, fechaCreacion: new Date() }
    );
    if (obra === ERROR) {
      return errorDefault;
    }

    const rutaDocumento = `usuarios/${payload.id}/repertorio/${obra.id}`;
    const rutaArchivo = `usuarios/${payload.id}/repertorio/${obra.id}`;

    const fotoUrl = await subirArchivo(rutaArchivo, payload.foto);
    if (fotoUrl === ERROR) {
      return {
        ...errorDefault,
        mensaje: "No se pudo guardar la imagen del album, cambiala más tarde",
      };
    }

    const resEdicionDocumento = await editarDocumento(rutaDocumento, {
      ...payload.obra,
      urlFoto: fotoUrl,
    });
    if (resEdicionDocumento === ERROR) {
      await eliminarDocumento(rutaDocumento);
      await eliminarArchivo(rutaArchivo);
      return {
        ...errorDefault,
        mensaje: "No se pudo guardar la imagen del album, cambiala más tarde",
      };
    }

    return { tipo: OK };
  };
};

export const editarObra = (payload) => {
  return async (dispatch) => {
    const rutaArchivo = `usuarios/${payload.idArtista}/repertorio/${payload.idObra}`;
    const rutaDocumento = `usuarios/${payload.idArtista}/repertorio/${payload.idObra}`;
    const errorDefault = {
      tipo: ERROR,
      mensaje: "Hubo un problema, intentalo de nuevo más tarde",
    };
    if (payload.foto) {
      const fotoUrl = await subirArchivo(rutaArchivo, payload.foto);
      if (fotoUrl === ERROR) {
        return {
          tipo: ERROR,
          mensaje: "No se pudo subir la imagen, intentalo de nuevo más tarde",
        };
      }
      const edicionDocumento = await editarDocumento(rutaDocumento, {
        ...payload.obra,
        urlFoto: fotoUrl,
      });
      if (edicionDocumento === ERROR) {
        await eliminarArchivo(rutaArchivo);
        return errorDefault;
      }
      return { tipo: OK };
    } else {
      const edicionDocumento = await editarDocumento(
        rutaDocumento,
        payload.obra
      );
      if (edicionDocumento === ERROR) {
        return errorDefault;
      }
      return { tipo: OK };
    }
  };
};

export const subirPago = (payload) => {
  return async (dispatch) => {
    const d = new Date();

    const rutaArchivo = `usuarios/${payload.id
      }/historial/${d.toString()}.${payload.soporte.name.split(".").pop()}`;

    const errorPorDefecto = {
      tipo: ERROR,
      mensaje: "Hubo un error, intentalo de nuevo",
    };

    const pago = await crearDocumentoAutoID(
      `usuarios/${payload.id}/historial`,
      payload.pago
    );
    if (pago === ERROR) {
      return errorPorDefecto;
    }

    const rutaDocumento = `usuarios/${payload.id}/historial/${pago.id}`;

    const soporteUrl = await subirArchivo(rutaArchivo, payload.soporte);
    if (soporteUrl === ERROR) {
      await eliminarDocumento(rutaDocumento);
      return errorPorDefecto;
    }

    const resDocumendoEditado = await editarDocumento(rutaDocumento, {
      soportePago: soporteUrl,
    });
    if (resDocumendoEditado === ERROR) {
      await eliminarDocumento(rutaDocumento);
      await eliminarArchivo(rutaArchivo);
      return errorPorDefecto;
    }

    const copiaDocumentoArtista = await traerCopiaDocumento(
      `usuarios/${payload.id}`
    );
    if (copiaDocumentoArtista === ERROR) {
      await eliminarDocumento(rutaDocumento);
      await eliminarArchivo(rutaArchivo);
      return errorPorDefecto;
    }

    const nuevaCantidadPago =
      copiaDocumentoArtista.totalPagado + payload.pago.valor;
    const resDocumentoEditado2 = await editarDocumento(
      `usuarios/${payload.id}`,
      { totalPagado: nuevaCantidadPago, pagoSolicitado: false }
    );
    if (resDocumentoEditado2 === ERROR) {
      await eliminarDocumento(rutaDocumento);
      await eliminarArchivo(rutaArchivo);
      return errorPorDefecto;
    }

    const resCorreo = await llamarFunction("correoArtista", {
      tipo: 1,
      email: payload.correo,
      url: soporteUrl,
    });

    if (resCorreo === ERROR) {
      return {
        tipo: ERROR,
        mensaje:
          "Se guardo el pago, pero no se pudo enviar el correo al artista",
      };
    }

    return { tipo: OK };
  };
};

export const eliminarArtista = (payload) => {
  return async (dispatch) => {
    const resEdicionDocumento = await editarDocumento(
      `usuarios/${payload.uid}`,
      { activo: false }
    );
    if (resEdicionDocumento === ERROR) {
      return resEdicionDocumento;
    }
    const resLlamarFuncion = await llamarFunction("eliminarArtista", {
      uid: payload.uid,
    });
    if (resLlamarFuncion !== "exito") {
      await editarDocumento(`usuarios/${payload.uid}`, { activo: true });
      return ERROR;
    }

    return resLlamarFuncion;
  };
};

export const actualizarTipoPerfil = (
  idArtista,
  perfilYoutube,
  perfilPlataformasDigitales
) => {
  return async (dispatch) => {
    return await editarDocumento(`usuarios/${idArtista}`, {
      perfilYoutube: !!perfilYoutube,
      perfilPlataformasDigitales: !!perfilPlataformasDigitales,
    });
  };
};

export const eliminarUltimaRegalia = async (dispatch, idArtista) => {
  const res = await deleteData("regalias/artistas/" + idArtista, {});
  dispatch({ type: ELIMINAR_ULTIMA_REGALIA, payload: res });
};

export const traerPagos = async (dispatch, idArtista) => {
  const res = await getData(`usuarios/${idArtista}/pagos/`, {});
  dispatch({ type: TRAER_PAGOS, payload: { idArtista, pagos: res.data } });
};

export const traerRegalias = async (dispatch, idArtista) => {
  const regalias = await getData("regalias/artistas/" + idArtista, {});
  dispatch({ type: TRAER_REGALIAS, payload: regalias.data });
};

export const editarInfoPerfil = async (infoArtista, dispatch) => {
  const res = await patchData(`usuarios/${infoArtista.id}`, {}, infoArtista);
  dispatch({
    type: PATCH_ARTISTA,
    payload: res.data,
  });
};

export const crearPago = async (dispatch, dataPago) => {
  const formData = new FormData();
  Object.keys(dataPago).forEach((key) => {
    formData.append(key, dataPago[key]);
  });
  const res = await postData("usuarios/pagos", {}, formData);

  if (!res.error) {
    dispatch({ type: SUBIR_PAGO, payload: res.data });
  }
  return res;
};

export const crearLogro = async (dispatch, dataLogro) => {
  const formData = new FormData();
  Object.keys(dataLogro).forEach((key) => {
    formData.append(key, dataLogro[key]);
  });
  const res = await postData("usuarios/logros", {}, formData);
  dispatch({ type: SUBIR_LOGRO, payload: res.data, id: dataLogro.artista });
  return res;
};

export const traerLogros = async (dispatch, idArtista) => {
  const res = await getData(`usuarios/${idArtista}/logros/`, {});
  dispatch({ type: TRAER_LOGROS, payload: res.data, id: idArtista });
};

export const eliminarLogro = async (dispatch, idLogro, idArtista) => {
  const res = await deleteData(`usuarios/logros/${idLogro}`, {});
  dispatch({ type: ELIMINAR_LOGRO, payload: res, id: idArtista });
};

export const editarLogro = async (dispatch, idLogro, dataLogro, idArtista) => {
  const formData = new FormData();
  Object.keys(dataLogro).forEach((key) => {
    formData.append(key, dataLogro[key]);
  });
  const res = await patchData(`usuarios/logros/${idLogro}`, {}, formData);
  dispatch({ type: EDITAR_LOGRO, payload: res.data, id: idArtista });
};

export const subirAlbum = (dataAlbum) => {
  return async (dispatch) => {
    const formData = new FormData();
    Object.keys(dataAlbum).forEach((key) => {
      if (key === "canciones") {
        dataAlbum[key].forEach((cancion) => {
          formData.append(key, JSON.stringify(cancion));
        });
      } else {
        formData.append(key, dataAlbum[key]);
      }
    });
    const res = await postData("albumes", {}, formData);
    dispatch({ type: SUBIR_ALBUM, payload: res.data });
    return res;
  };
};

export const editarAlbum = (dataAlbum, idAlbum) => {
  return async (dispatch) => {
    const formData = new FormData();
    Object.keys(dataAlbum).forEach((key) => {
      if (key === "canciones") {
        dataAlbum[key].forEach((cancion) => {
          formData.append(key, JSON.stringify(cancion));
        });
      } else {
        formData.append(key, dataAlbum[key]);
      }
    });
    const res = await patchData(`albumes/${idAlbum}`, {}, formData);
    await getSingleArtista(res.data.artistaId)(dispatch);
    dispatch({ type: EDITAR_ALBUM, payload: res.data });
    return res;
  };
};

export const subirSencillo = (dataSencillo) => {
  return async (dispatch) => {
    const formData = new FormData();
    Object.keys(dataSencillo).forEach((key) => {
      formData.append(key, dataSencillo[key]);
    });
    const res = await postData("canciones", {}, formData);
    dispatch({ type: SUBIR_SENCILLO, payload: res.data });
    return res;
  };
};

export const editarSencillo = (dataSencillo, idSencillo) => {
  return async (dispatch) => {
    const formData = new FormData();
    Object.keys(dataSencillo).forEach((key) => {
      formData.append(key, dataSencillo[key]);
    });
    const res = await patchData("canciones/" + idSencillo, {}, formData);
    await getSingleArtista(res.data.artistaId)(dispatch);
    dispatch({ type: EDITAR_SENCILLO, payload: res.data });
    return res;
  };
};

export const registrarArtista = async (formulario, dispatch) => {
  let res = await registrarUsuario(formulario);

  if (res.resultado !== ERROR) {
    llamarFunction("correoArtista", {
      tipo: 2,
      email: formulario.correo,
      password: formulario.password,
    });

    const artista = await postData(
      "usuarios/",
      {},
      {
        id: res.user.uid,
        correo: formulario.correo,
      }
    );

    dispatch({
      type: CREATE_ARTISTA,
      payload: artista.data,
    });
  }

  return res;
};

export const agregarRegalias = async (dispatch, data, callback) => {
  const soporte = data.soporte;
  delete data.soporte;

  const res = await postData("regalias", {}, data);

  const finishAgregarRegalias = async () => {
    await getSingleArtista(data.artista)(dispatch);
    callback();
  }

  if (soporte) {
    const fileUploaderOptions = {
      fileName: `usuarios/${data.artista}/regalias/${res.data.id}/${soporte.name}`,
      // fileName: soporte.name,
      file: soporte,
    }

    const uploader = new Uploader(fileUploaderOptions)
    let percentage;
    uploader
      .onFinish(async () => {
        await patchData(`regalias/${res.data.id}/soporte`, {}, { fileName: soporte.name });
        finishAgregarRegalias()
      })
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage
          console.log(`${percentage}%`)
        }
      })
      .onError((error) => {
        console.error(error)
      })
    uploader.start();
  } else {
    finishAgregarRegalias()
  }

  return res;
};

export const getPlataformas = async (dispatch) => {
  const plataformas = await getData("plataformas", {});
  dispatch({ type: TRAER_PLATAFORMAS, payload: plataformas.data });
};

export const togglePlataformaArtista = async (
  dispatch,
  artista,
  plataforma,
  activa,
  plataformaIndex
) => {
  const res = await patchData(
    "plataformas/artistas",
    {},
    {
      artista,
      plataforma,
      activa,
    }
  );

  if (!res.isAxiosError) {
    dispatch({
      type: CAMBIAR_ESTADO_PLATAFORMA,
      payload: { artista, data: res.data, plataformaIndex },
    });
  }

  return res;
};

export const getTareasArtista = async (dispatch, idArtista) => {
  const res = await getData(`tareas/artistas/${idArtista}`, {});
  dispatch({
    type: TRAER_TAREAS_ARTISTA,
    payload: {
      idArtista,
      tareas: res.data
    }
  });
};

export const actualizarTareaArtista = async (dispatch, idTarea, idArtista, realizada, comentario, idCategoria, idTareaArtista) => {
  const body = {
    id: idTareaArtista,
    tarea: idTarea,
    artista: idArtista,
    realizada
  };

  if (comentario !== undefined)
    body.comentario = comentario;

  const res = await patchData(`tareas/artistas`, {}, body);

  dispatch({
    type: TAREA_ACTUALIZADA,
    payload: {
      idArtista,
      idCategoria,
      tareaArtista: res.data
    }
  });
}

export const eliminarAlbum = (idAlbum, idArtista) => {
  return async (dispatch) => {
    const res = await deleteData(`albumes/${idAlbum}`, {});
    dispatch({
      type: DELETE_ALBUM,
      payload: {
        idArtista,
        album: {
          ...res.data,
          id: idAlbum
        }
      }
    });
  }
};

export const eliminarSencillo = (idSencillo, idArtista) => {
  return async (dispatch) => {

    const res = await deleteData(`canciones/${idSencillo}`, {});
    dispatch({
      type: DELETE_SENCILLO,
      payload: {
        idArtista,
        sencillo: {
          ...res.data,
          id: idSencillo
        }
      },
    });
    return res;
  };
};

export const GET_DISTRIBUIDORAS = "GET_DISTRIBUIDORAS";
export const GET_SINGLE_DISTRIBUIDORA = "GET_SINGLE_DISTRIBUIDORA";
export const ADD_DISTRIBUIDORA = "ADD_DISTRIBUIDORA";
export const DELETE_DISTRIBUIDORA = "DELETE_DISTRIBUIDORA";
export const EDIT_DISTRIBUIDORA = "EDIT_DISTRIBUIDORA";

export const getDistribuidoras = () => {
  return async (dispatch) => {
    const res = await getData(`distribuidoras`, {});
    dispatch({
      type: GET_DISTRIBUIDORAS,
      payload: res.data,
    });
  }
};

export const getSingleDistribuidora = async (id, dispatch) => {
  const res = await getData(`distribuidoras/${id}`);
  dispatch({
    type: GET_SINGLE_DISTRIBUIDORA,
    payload: res.data,
  });
};

export const postDistribuidoras = async (body, dispatch) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  const res = await postData(`distribuidoras`, {}, formData);
  console.log(res.data, "postDistribuidoras");

  dispatch({
    type: ADD_DISTRIBUIDORA,
    payload: res.data,
  });
};

export const removeDistribuidoras = async (id, dispatch) => {
  const res = await deleteData(`distribuidoras/${id}`, {});
  dispatch({
    type: DELETE_DISTRIBUIDORA,
    payload: res.data,
  });
};

export const editDitribuidoras = async (id, body, dispatch) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  const res = await patchData(`distribuidoras/${id}`, {}, formData);
  dispatch({
    type: EDIT_DISTRIBUIDORA,
    payload: res.data,
  });
};


export const cambiarFotoArtista = (payload) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("foto", payload.foto);

    const res = await patchData(`usuarios/${payload.id}`, {}, body);
    dispatch({
      type: CAMBIAR_FOTO_ARTISTA,
      payload: {
        foto: res.data.foto,
        idArtista: payload.id
      }
    });
  };
};

export const getRedesSocialesArtista = async (idArtista, dispatch) => {
  const res = await getData(`usuarios/${idArtista}/registrosSeguidores`);

  dispatch({
    type: GET_REDES_ARTISTA,
    payload: {
      ...res.data,
      idArtista
    }
  })
}

export const crearRegistroRedesSocialesArtista = async (idArtista, registrosRedes, dispatch) => {
  const res = await postData("usuarios/registrosSeguidores", {}, {
    artista: idArtista,
    fecha: moment().format("YYYY-MM-DD"),
    registrosRedes
  })

  dispatch({
    type: GET_REDES_ARTISTA,
    payload: {
      ...res.data,
      idArtista
    }
  })
}

export const editarRegistroRedesSocialesArtista = async (registroId, idArtista, registrosRedes, dispatch) => {
  const res = await patchData(`usuarios/registrosSeguidores/${registroId}`, {}, {
    registrosRedes
  });

  dispatch({
    type: GET_REDES_ARTISTA,
    payload: {
      ...res.data,
      idArtista
    }
  })
}

export const eliminarRegistroRedesSocialesArtista = async (idRegistro, idArtista, dispatch) => {
  const res = await deleteData(`usuarios/registrosSeguidores/${idRegistro}`, {});
  dispatch({
    type: GET_REDES_ARTISTA,
    payload: {
      ...res,
      idArtista
    }
  })
}


export const crearRedArtista = async (idArtista, red, dispatch) => {
  const res = await postData("usuarios/redes", {}, {
    artista: idArtista,
    ...red
  });

  dispatch({
    type: GET_REDES_ARTISTA,
    payload: {
      ...res.data,
      idArtista
    }
  })
}

export const editarRedArtista = async (red, idArtista, dispatch) => {
  const res = await patchData(`usuarios/redes/${red.id}`, {}, red);

  dispatch({
    type: GET_REDES_ARTISTA,
    payload: {
      ...res.data,
      idArtista
    }
  })
}

export const eliminarRedArtista = async (idRed, idArtista, dispatch) => {
  const res = await deleteData(`usuarios/redes/${idRed}`, {});
  dispatch({
    type: GET_REDES_ARTISTA,
    payload: {
      ...res,
      idArtista
    }
  })
}