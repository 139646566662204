import React, { useState } from "react";
import "./agregar-regalias.sass";
import Regalias from "./Regalias";
import CobrosAdicionales from "./CobrosAdicionales"
import Porcentajes from "./Porcentajes"
import ResumenRegalias from "./ResumenRegalias";
import { QUIEN_ASUME_IVA } from "../../../../utilidades/Constantes";

const AGREGAR_REGALIAS_STEPS = {
  1: {
    component: Regalias,
    name: "Regalías"
  },
  2: {
    component: Porcentajes,
    name: "Porcentajes"
  },
  3: {
    component: CobrosAdicionales,
    name: "Cobros adicionales"
  },
  4: {
    component: ResumenRegalias,
    name: "Total"
  }
}

const AgregarRegalias = ({
  id,
  handleCerrarDialogo,
  distribuidoras,
  plataformas
}) => {

  const [crearRegaliaData, setCrearRegaliaData] = useState({
    artista: id,
    porcentajeArtista: 70,
    porcentajeBlackLion: 30,
    porcentajeIVA: 19,
    asumeIVA: QUIEN_ASUME_IVA.ARTISTA,
    cobrosAdicionales: []
  })
  const [currentStep, setCurrentStep] = useState(1)
  const CurrentStepComponent = AGREGAR_REGALIAS_STEPS[currentStep].component

  return (
    <div className="p-[25px] font-bold w-[750px] overflow-y-scroll custom-scroll">
      <h2 className="text-[18px] text-rosa-primariy mb-5">Agregar regalías</h2>
      <div className="flex justify-between relative">
        {
          Object.keys(AGREGAR_REGALIAS_STEPS).map(step => 
            <div onClick={() => step < currentStep && setCurrentStep(parseInt(step))} className={`flex flex-col items-center w-[100px] text-center z-10 select-none ${step < currentStep ? " cursor-pointer" : ""}`}>
              <div className={`flex items-center justify-center rounded-full h-[35px] w-[35px] ring-4 ${parseInt(step) <= currentStep ? "bg-rosa-primariy text-white ring-white" : "bg-white border-rosa-primariy text-rosa-primariy ring-4 ring-white border-2 "}`}>
                {step}
              </div>
              <h3 className="flex-grow flex items-center font-bold">
                {AGREGAR_REGALIAS_STEPS[step].name}
              </h3>
            </div>
          )
        }
        <div className="w-full h-[35px] absolute flex items-center px-[10%]">
          <div className="w-full h-[2px] bg-gray-400 z-0 top-[10px]"></div>
        </div>
      </div>

      {
        <CurrentStepComponent
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          distribuidoras={distribuidoras}
          plataformas={plataformas.filter(plataforma => plataforma.activa)}
          crearRegaliaData={crearRegaliaData}
          setCrearRegaliaData={setCrearRegaliaData}
          handleCerrarDialogo={handleCerrarDialogo}/>
      }
    </div>
  );
};

export default AgregarRegalias;
