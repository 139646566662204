import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  crearRedArtista,
  editarRedArtista,
} from "../../../../actions/adminActions";
import { ModalContext } from "../../../../context/ModalContext";

const CrearEditarRedSocial = ({ idArtista, red }) => {
  const { setModal } = useContext(ModalContext);
  const [redEditable, setRedEditable] = useState({});
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setRedEditable({
      ...redEditable,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (red) {
      setRedEditable(red);
    }
  }, []);

  const guardarRegistro = async (e) => {
    e.preventDefault();
    if (!red) {
      await crearRedArtista(idArtista, redEditable, dispatch);
      toast.success("Red creada");
    } else {
      await editarRedArtista(redEditable, idArtista, dispatch);
      toast.success("Red actualizada");
    }
    setModal(null);
  };

  return (
    <form
      onSubmit={guardarRegistro}
      className="bg-white text-black p-5 rounded-xl flex flex-col w-[500px] max-w-[100%]"
    >
      <div className="flex justify-between w-full font-bold">
        <h2>Agregar red social</h2>
        <span className="text-rosa-primariy">
          {moment().format("DD/MM/YYYY")}
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
        <div className="flex flex-col">
          <h2 className="font-bold">Red social</h2>
          <input
            required
            value={redEditable.nombre}
            className="border-b-2 focus:border-rosa-primariy outline-none"
            placeholder="Nombre"
            onChange={handleChange}
            type="text"
            name="nombre"
          />
        </div>
        <div className="flex flex-col">
          <h2 className="font-bold">Enlace</h2>
          <input
            required
            value={redEditable.enlace}
            className="border-b-2 focus:border-rosa-primariy outline-none"
            placeholder="Enlace"
            onChange={handleChange}
            type="text"
            name="enlace"
          />
        </div>
        <div className="flex flex-col">
          <h2 className="font-bold">Usuario</h2>
          <input
            required
            value={redEditable.usuario}
            className="border-b-2 focus:border-rosa-primariy outline-none"
            placeholder="Usuario"
            onChange={handleChange}
            type="text"
            name="usuario"
          />
        </div>
        <div className="flex flex-col">
          <h2 className="font-bold">Contraseña</h2>
          <input
            required
            value={redEditable.contraseña}
            className="border-b-2 focus:border-rosa-primariy outline-none"
            placeholder="Contraseña"
            onChange={handleChange}
            type="text"
            name="contraseña"
          />
        </div>
      </div>
      <div className="mt-5 flex justify-end">
        <button
          className="text-[16px] py-[7px] px-[20px] border-rosa-primariy border-2 rounded-lg text-rosa-primariy"
          onClick={() => setModal(null)}
          type="button"
        >
          Cancelar
        </button>
        <button
          className="text-[16px] py-[7px] px-[20px] bg-rosa-primariy rounded-lg text-white ml-4"
          type="submit"
        >
          Guardar
        </button>
      </div>
    </form>
  );
};

export default CrearEditarRedSocial;
