import React from "react";
import { terminosHtml } from "./TextoTerminosCondiciones";
import "./pagina-terminos.scss";

function PaginaTerminos() {
  return (
    <div className="mt-[100px] container m-auto">
      <div className="w-11/12 m-auto">
        <h1 className="text-3xl font-bold py-10">Terminos y Condiciones</h1>
        <p className="">{terminosHtml}</p>
      </div>
    </div>
  );
}

export default PaginaTerminos;
