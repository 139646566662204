import robot from "../../assets-2/Robot.png";
// import lup from '../../assets-2/lup-b.png'
import "./styles/MusicCareer.scss";

const MusicCareer = () => {
  return (
    <section className="bg-[#000023] mt-8 my-5 md:py-4 xl:py-6">
      <div className=" container mx-auto w-[90%] h-auto flex flex-col lg:flex-row-reverse justify-between lg:px-20 items-center  font-proxima mt-5 py-12">
        <div className="w-[80%] lg:w-1/2 flex flex-col items-center md:items-start gap-12 mb-10">
          <h3 className="max-w-[600px] text-center lg:text-left text-3xl md:text-4xl font-semibold leading-11 my-5">
            ¡Con BlackLion lleva tu carrera musical al
            <span className="text-[#E84A84]"> siguiente nivel!</span>
          </h3>
          <p className="text-center lg:text-left text-base md:text-xl font-normal ">
            Creciendo en la industria musical nos dimos cuenta de los vacíos,
            inconsistencias y faltantes legales sobre las regalías e ingresos,
            es por esto que creamos Black Lion, una empresa en pro del artista
            que busca asesorar, capacitar y cuidar con transparencia los
            intereses en el ámbito digital y en la carrera musical en general de
            los artistas.
          </p>
        </div>
        <img src={robot} className="ml-6 lg:ml-0 w-full max-w-[250px]" alt="" />
      </div>
    </section>
  );
};

export default MusicCareer;
