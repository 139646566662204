import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { QUIEN_ASUME_IVA } from "../../../utilidades/Constantes";
import { formatoNumero } from "../../../utilidades/FuncionesAuxiliares";

const CalculoRegalias = ({ crearRegaliaData, cerrarDialogo }) => {
  const valorDistribuidoras = Object.values(
    crearRegaliaData.regaliasDistribuidora ?? { distribuidoraX: { total: 0 } }
  ).reduce(
    (partialSum, a) => partialSum + (a.total !== undefined ? a.total : a.valorGeneradoPeriodo),
    0
  );

  const valorPlataformas = Object.values(
    crearRegaliaData.regaliasPlataforma ?? {}
  ).reduce((partialSum, a) => partialSum + a.valor - a.comision, 0);

  const valorTotal = valorDistribuidoras + valorPlataformas;
  const cobrosAdicionales = (crearRegaliaData.cobrosAdicionales ?? []).reduce(
    (partialSum, a) => partialSum + a.valor,
    0
  );

  let parteBlackLion =
    valorTotal * (crearRegaliaData.porcentajeBlackLion / 100);
  let parteArtista = valorTotal * (crearRegaliaData.porcentajeArtista / 100);
  const valorIVA = parteBlackLion * (crearRegaliaData.porcentajeIVA / 100);

  const nuevasRegaliasArtista =
    parteArtista -
    (crearRegaliaData.asumeIVA === QUIEN_ASUME_IVA.ARTISTA ? valorIVA : 0) -
    cobrosAdicionales;

  return (
    <div className="bg-white p-[25px] font-bold overflow-y-scroll custom-scroll text-black rounded-lg relative">
      {cerrarDialogo && (
        <div className="flex justify-end absolute inset-0 p-2">
          <XIcon
            onClick={cerrarDialogo}
            className="w-8 h-8 text-rosa-primariy transition duration-300 cursor-pointer hover:scale-110"
          />
        </div>
      )}
      <div className="w-[500px] mt-3 font-normal mx-auto text-center">
        <span className="font-bold text-rosa-primariy">
          Valor generado por artista en el mes:
        </span>
        <h3 className="font-semibold">
          {formatoNumero(valorTotal, true, false, 2)}
        </h3>
      </div>
      <h1 className="text-rosa-primariy font-bold text-center">Cálculo IVA:</h1>
      <div className="grid grid-cols-2 gap-4 mt-3 font-normal justify-items-center text-center max-w-[500px] mx-auto items-center">
        <span className="font-bold">Asume el IVA:</span>
        <h3 className="font-semibold">
          {crearRegaliaData.asumeIVA === QUIEN_ASUME_IVA.ARTISTA
            ? "Artista"
            : "BlackLion"}
        </h3>
        <span className="font-bold">
          Porcentaje de BlackLion del valor generado en el mes (
          {crearRegaliaData.porcentajeBlackLion}%):
        </span>
        <h3 className="font-semibold">
          {formatoNumero(parteBlackLion, true, false, 2)}
        </h3>
        <span className="font-bold bg-rosa-primariy w-full text-white">
          IVA ({crearRegaliaData.porcentajeIVA}%):
        </span>
        <h3 className="font-semibold bg-rosa-primariy w-full text-white">
          {formatoNumero(valorIVA, true, false, 2)}
        </h3>
      </div>
      <hr className="my-4" />
      <h1 className="text-rosa-primariy font-bold text-center">
        Cálculo ganancias BlackLion:
      </h1>
      <div className="grid grid-cols-2 gap-4 mt-3 font-normal justify-items-center text-center max-w-[500px] mx-auto items-center">
        {crearRegaliaData.asumeIVA === QUIEN_ASUME_IVA.BLACK_LION ? (
          <span className="font-bold">
            + Porcentaje de BlackLion del valor generado en el mes (
            {crearRegaliaData.porcentajeBlackLion}%):
          </span>
        ) : (
          <span className="font-bold bg-rosa-primariy w-full text-white">
            Ganancias BlackLion
          </span>
        )}
        <h3
          className={`font-semibold${crearRegaliaData.asumeIVA === QUIEN_ASUME_IVA.BLACK_LION
              ? ""
              : " bg-rosa-primariy w-full text-white"
            }`}
        >
          {formatoNumero(parteBlackLion, true, false, 2)}
        </h3>
        {crearRegaliaData.asumeIVA === QUIEN_ASUME_IVA.BLACK_LION && (
          <>
            <span className="font-bold">
              - IVA ({crearRegaliaData.porcentajeIVA}%):
            </span>
            <h3 className="font-semibold">
              {formatoNumero(valorIVA, true, false, 2)}
            </h3>
            <span className="font-bold bg-rosa-primariy w-full text-white">
              Ganancias BlackLion:
            </span>
            <h3 className="font-semibold bg-rosa-primariy w-full text-white">
              {formatoNumero(parteBlackLion - valorIVA, true, false, 2)}
            </h3>
          </>
        )}
      </div>
      <hr className="my-4" />
      <h1 className="text-rosa-primariy font-bold text-center">
        Cálculo ganancias Artista:
      </h1>
      <div className="grid grid-cols-2 gap-4 mt-3 font-normal justify-items-center text-center max-w-[500px] mx-auto items-center">
        <span className="font-bold">
          + Porcentaje del Artista del valor generado en el mes (
          {crearRegaliaData.porcentajeArtista}%):
        </span>
        <h3 className="font-semibold">
          {formatoNumero(parteArtista, true, false, 2)}
        </h3>
        <span className="font-bold">- Cobros adicionales:</span>
        <h3 className="font-semibold">
          {cobrosAdicionales
            ? formatoNumero(cobrosAdicionales, true, false, 2)
            : 0}
        </h3>

        {crearRegaliaData.asumeIVA === QUIEN_ASUME_IVA.ARTISTA && (
          <>
            <span className="font-bold">
              - IVA ({crearRegaliaData.porcentajeIVA}%):
            </span>
            <h3 className="font-semibold">
              {formatoNumero(valorIVA, true, false, 2)}
            </h3>
          </>
        )}
        <span className="font-bold bg-rosa-primariy w-full text-white">
          Nuevas regalías artista:
        </span>
        <h3 className="font-semibold bg-rosa-primariy w-full text-white">
          {formatoNumero(nuevasRegaliasArtista, true, false, 2)}
        </h3>
      </div>
    </div>
  );
};

export default CalculoRegalias;
