import { XIcon } from "@heroicons/react/solid";
import Accordion from "./Accordion";
import { useState } from "react";
import { useEffect } from "react";
import { traerLogros } from "../../../../actions/sesionActions";
import { getLogros, getUid } from "../../../../reducers/sesionReducer";
import { useSelector, useDispatch } from "react-redux";
import DialogoUnBotonPerfil from "./DialogoUnBotonPerfil";

const Achievements = ({ cerrar }) => {
  const [accordionActive, setAccordionActive] = useState({});
  const uid = useSelector(getUid);
  const logros = useSelector(getLogros);
  const dispatch = useDispatch();

  useEffect(() => {
    traerLogros(uid, dispatch);
  }, []);

  const noLogros = () => {
    return (
      <DialogoUnBotonPerfil
        titulo={"Sin logros"}
        descripcion={"Aún no has alcanzado logros con Black Lion"}
        textoBoton={"Ok"}
        funcion={cerrar}
      />
    );
  };

  return (
    <>
      {logros?.length === 0 ? (
        noLogros()
      ) : (
        <div className="fixed inset-0 z-[80] flex items-center bg-pink fondo-tap-highlight mx-2">
          <div
            className="fixed inset-0 z-[90] bg-pink-500/20 flex justify-center"
            onClick={cerrar}
          />
          <div className="overflow-y max-w-[600px] w-full  bg-white bg-cover z-[100] rounded-md p-4 m-auto ">
            <div className=" flex justify-between items-center mb-2">
              <h4 className="text-rosa-primariy font-semibold sm:text-lg">
                Logros con Black Lion
              </h4>
              <XIcon
                className="w-5 h-5 text-rosa-primariy cursor-pointer"
                onClick={cerrar}
                alt="cerrar"
              />
            </div>
            {logros?.map((logro) => (
              <Accordion
                acchievements={logro}
                setAccordionActive={setAccordionActive}
                accordionActive={accordionActive}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Achievements;
