import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ImgMusico from "./../../../../assets/usuario.svg";
import ImgAlbum from "./../../../../assets/lista.svg";
import ImgSencillo from "./../../../../assets/sencillo-de-musica.svg";
import "./administrar-artista.scss";
import DetalleAlbum from "../DetalleAlbum/DetalleAlbum";
import DetalleSencillo from "../DetalleSencillo/DetalleSencillo";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getArtista } from "../../../../reducers/adminReducer";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as actions from "../../../../actions/adminActions";
import AgregarRegalias from "../AgregarRegalias/AgregarRegalias";
import CambiarFoto from "../CambiarFoto/CambiarFoto";
import {
  formatoNumero,
  obtenerUrl,
} from "../../../../utilidades/FuncionesAuxiliares";

class AdministrarArtista extends Component {
  handleHistory = (e) => {
    this.props.history.push(e);
  };
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const totalRegalias =
      this.props.artista?.totalRegaliasDistribuidoras +
      this.props.artista?.totalRegaliasPlataformas -
      this.props.artista?.totalIVA -
      this.props.artista?.totalCobros;

    if (!this.props.artista)
      return (
        <div className="loading-indicator-administrar-artista">
          <ScaleLoader
            id="loading-indicator"
            height={35 * 2}
            width={4 * 2}
            color={"#E87096"}
          />
        </div>
      );

    return (
      <div className="mb-10 font-proxima w-full">
        <div className="flex flex-col items-center md:items-start md:flex-row md:justify-between w-full">
          <div className="foto-admin-artista space-y-2 justify-self-center flex-grow">
            {this.props.artista?.foto ? (
              <div
                className="borde-gradiente-musico-artista"
                onClick={() =>
                  this.props.handleAbrirDialogo(
                    <CambiarFoto
                      id={this.props.match.params.id}
                      handleCerrarDialogo={this.props.handleCerrarDialogo}
                    />
                  )
                }
              >
                <div
                  className="cont-img-musico-admin-artista"
                  style={{
                    backgroundImage: `url("${obtenerUrl(
                      this.props.artista?.foto
                    )}")`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </div>
            ) : (
              <div
                className="borde-gradiente-musico-artista"
                onClick={() =>
                  this.props.handleAbrirDialogo(
                    <CambiarFoto
                      id={this.props.match.params.id}
                      handleCerrarDialogo={this.props.handleCerrarDialogo}
                    />
                  )
                }
              >
                <div className="cont-img-musico-admin-artista">
                  <img
                    className="img-musico-admin-artista"
                    src={ImgMusico}
                    alt="Imagen del músico"
                  />
                </div>
              </div>
            )}
            <div className="text-3xl font-bold pt-7 text-center">
              {this.props.artista.nombre
                ? this.props.artista.nombre
                : this.props.artista.correo}
            </div>
            <div className="pt-2 justify-center flex flex-col items-center text-center">
              {this.props.artista.pagoSolicitado && (
                <span className="mt-2 text-md font-light cursor-pointer font-proxima hover:underline transition">
                  Solicitó un pago
                </span>
              )}
            </div>
          </div>

          <div className="max-w-md w-full flex flex-col">
            <div className="flex flex-col items-center justify-between  bg-white bg-opacity-20 rounded-lg p-8 mt-10 lg:mt-0 sm:max-w-lg">
              <div className="flex flex-col items-center w-full sm:items-start">
                <b className="text-3xl font-bold text-center">
                  Total de Regalías
                </b>
                <div className="datos-regalias-admin flex-col sm:flex-row items-center sm:items-start w-full border-b-[1px] py-6 text-xl max-w-full">
                  {totalRegalias
                    ? "$ " + formatoNumero(totalRegalias, true, false, 2)
                    : "$ 0"}
                  <b
                    className="editar-regalias underline text-[#FF6CA2] font-normal sm:mr-4 mt-2 sm:mt-0"
                    onClick={() =>
                      this.props.handleAbrirDialogo(
                        <AgregarRegalias
                          id={this.props.match.params.id}
                          distribuidoras={this.props.artista.distribuidoras}
                          plataformas={this.props.artista.plataformas}
                          perfilYoutube={this.props.artista.perfilYoutube}
                          perfilPlataformasDigitales={
                            this.props.artista.perfilPlataformasDigitales
                          }
                          handleCerrarDialogo={this.props.handleCerrarDialogo}
                          historicoGenerado={
                            this.props.artista.historicoGenerado
                          }
                        />
                      )
                    }
                  >
                    Agregar
                  </b>
                </div>
              </div>

              <div className="flex flex-col justify-between mb-4 w-full items-center mt-5 px-4 gap-3">
                <div className="flex gap-2 justify-between w-full min-h-[80px] flex-wrap">
                  <div className="flex flex-col items-center max-w-full min-w-[75px] flex-grow justify-between">
                    <h3 className="font-semibold text-xl leading-5 font-proxima text-center pb-2">
                      Regalías distribuidoras
                    </h3>
                    <span className="bg-white bg-opacity-40 py-2 w-full text-center rounded-md">
                      {this.props.artista?.totalRegaliasDistribuidoras
                        ? "$ " +
                        formatoNumero(
                          this.props.artista?.totalRegaliasDistribuidoras,
                          true,
                          false,
                          2
                        )
                        : "$ 0"}
                    </span>
                  </div>
                  {this.props.artista?.plataformas
                    ?.filter((plataforma) => plataforma.activa)
                    .map((plataforma) => (
                      <div className="flex flex-col items-center max-w-full flex-grow justify-between min-w-[75px]">
                        <h3 className="font-semibold text-xl font-proxima text-center pb-2 leading-5">
                          {plataforma.nombre}
                        </h3>
                        <span className="bg-white bg-opacity-40 py-2 w-full text-center rounded-md">
                          {plataforma.totalRegalias
                            ? "$ " +
                            formatoNumero(
                              plataforma.totalRegalias,
                              true,
                              false,
                              2
                            )
                            : "$ 0"}
                        </span>
                      </div>
                    ))}
                  <div className="flex flex-col items-center max-w-full min-w-[75px] flex-grow justify-between">
                    <h3 className="font-semibold text-xl font-proxima text-center pb-2">
                      IVA
                    </h3>
                    <span className="bg-white bg-opacity-40 py-2 w-full text-center rounded-md">
                      {this.props.artista?.totalIVA
                        ? "$ - " +
                        formatoNumero(
                          this.props.artista?.totalIVA,
                          true,
                          false,
                          2
                        )
                        : "$ 0"}
                    </span>
                  </div>
                  <div className="flex flex-col items-center max-w-full min-w-[75px] flex-grow justify-between">
                    <h3 className="font-semibold text-xl leading-5 font-proxima text-center pb-2">
                      Cobros adicionales
                    </h3>
                    <span className="bg-white bg-opacity-40 py-2 w-full text-center rounded-md">
                      {this.props.artista?.totalCobros
                        ? "$ - " +
                        formatoNumero(
                          this.props.artista?.totalCobros,
                          true,
                          false,
                          2
                        )
                        : "$ 0"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 w-full sm:w-3/4 flex flex-col">
              <h3 className="font-bold text-3xl">Total pagado al artista</h3>
              <span className="text-2xl py-5 border-b-[1px] w-full">
                {this.props.artista?.totalPagos
                  ? "$ " +
                  formatoNumero(
                    this.props.artista?.totalPagos,
                    true,
                    false,
                    2
                  )
                  : "$ 0"}
              </span>
              <h3 className="font-bold text-3xl mb-6 mt-6">Saldo</h3>
              <span className="text-2xl px-5 py-2 bg-white bg-opacity-40 rounded-md w-max">
                {this.props.artista?.balanceCuenta
                  ? "$ " +
                  formatoNumero(
                    this.props.artista.balanceCuenta,
                    true,
                    false,
                    2,
                    true
                  )
                  : "$ 0"}
              </span>
            </div>
          </div>
        </div>
        <div className="w-[80%] mx-auto">
          <h3 className="font-bold text-2xl text-center sm:text-left">
            Álbumes
          </h3>
          {this.props.artista.albumes ? (
            this.props.artista.albumes.length === 0 ? (
              <div className="sin-obras">No hay álbumes</div>
            ) : (
              <div className="contenedor-albums-sencillos-admin">
                {this.props.artista.albumes.map((album) => (
                  <div
                    key={album.nombre}
                    className="item-album-sencillo-administrador"
                    onClick={() =>
                      this.props.handleAbrirDialogo(
                        <DetalleAlbum
                          album={album}
                          handleCerrarDialogo={this.props.handleCerrarDialogo}
                          id={this.props.match.params.id}
                        />
                      )
                    }
                  >
                    {!album.portada ? (
                      <div className="cont-img-album-sencillo-admin rounded-2xl">
                        <img
                          className="img-album-sencillo-admin"
                          src={ImgAlbum}
                          alt={`Imagen del álbum ${album.nombre}`}
                        />
                      </div>
                    ) : (
                      <div className="w-[150px] h-[150px] bg-white shadow-2xl rounded-2xl">
                        <img
                          className="w-full h-full object-cover rounded-2xl"
                          src={obtenerUrl(album.portada)}
                          alt={`Imagen del álbum ${album.nombre}`}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )
          ) : (
            <div className="cargando-obras">
              <ScaleLoader
                id="loading-indicator"
                height={35 * 2}
                width={4 * 2}
                color={"#E87096"}
              />
            </div>
          )}
          <h3 className="font-bold text-2xl mt-10 text-center sm:text-left">
            Sencillos
          </h3>

          {this.props.artista.canciones ? (
            this.props.artista.canciones.filter((sencillo) => !sencillo.album)
              .length === 0 ? (
              <div className="sin-obras">No hay sencillos</div>
            ) : (
              <div className="contenedor-albums-sencillos-admin gap-1 w-full">
                {this.props.artista.canciones
                  .filter((sencillo) => !sencillo.album)
                  .map((sencillo) => (
                    <div
                      key={sencillo.nombre}
                      className="item-album-sencillo-administrador"
                      onClick={() =>
                        this.props.handleAbrirDialogo(
                          <DetalleSencillo
                            sencillo={sencillo}
                            handleCerrarDialogo={this.props.handleCerrarDialogo}
                            id={this.props.match.params.id}
                          />
                        )
                      }
                    >
                      {!sencillo.portada ? (
                        <div className="cont-img-album-sencillo-admin rounded-2xl">
                          <img
                            className="img-album-sencillo-admin"
                            src={ImgSencillo}
                            alt={`Imagen del álbum ${sencillo.nombre}`}
                          />
                        </div>
                      ) : (
                        <div className="w-[150px] h-[150px] bg-white shadow-2xl rounded-2xl">
                          <img
                            className="w-full h-full object-cover rounded-2xl"
                            src={obtenerUrl(sencillo.portada)}
                            alt={`Imagen del álbum ${sencillo.nombre}`}
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )
          ) : (
            <div className="cargando-obras">
              <ScaleLoader
                id="loading-indicator"
                height={35 * 2}
                width={4 * 2}
                color={"#E87096"}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  artista: getArtista(state, props.match.params.id),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdministrarArtista)
);
