import faces from "../../assets-2/faces.png";
import globe from "../../assets-2/globe.png";
import crown from "../../assets-2/crown.png";
import telescope from "../../assets-2/telescope-a.png";
import "./styles/MusicCareer.scss";

const AudienceKnowUs = () => {
  return (
    <section>
      <div className=" container mx-auto h-full flex justify-evenly items-center flex-wrap font-proxima mt-5 py-12 ">
        <div className=" w-11/12 flex flex-col text-center items-center md:text-left md:place-items-center space-y-4 mb-10">
          <h3 className="w-[90%] md:w-1/2 text-center mx-auto text-3xl md:text-4xl font-semibold leading-11 my-5">
            Incrementa el número de tu{" "}
            <span className="text-[#E84A84]"> audiencia</span>
          </h3>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-5 sm:gap-8 md:gap-14 md:mt-48 pb-2">
          <div
            className="w-[140px] h-[140px] md:w-[205px] md:h-[205px]
         card-audience flex flex-col justify-center items-center relative"
          >
            <img className="w-10/12 md:w-auto relative " src={faces} alt="" />
            <span className="text-center md:text-lg text-xs w-10/12 md:pb-16 pb-8">
              Crea una comunidad fiel a tu música
            </span>
          </div>

          <div
            className="w-[140px] h-[140px] md:w-[205px] md:h-[205px]
         card-audience flex flex-col justify-center items-center relative lg:bottom-20"
          >
            <img
              className="w-[60px] h-[60px]  md:w-[100px] md:h-[100px] relative md:bottom-6"
              src={telescope}
              alt=""
            />
            <span className="text-center md:text-lg text-xs w-10/12 md:pb-16 pb-8">
              Logra tus expectativas con tu audiencia
            </span>
          </div>

          <div
            className="w-[140px] h-[140px] md:w-[205px] md:h-[205px]
         card-audience flex flex-col justify-center items-center relative lg:bottom-32"
          >
            <img
              className="w-[60px] h-[60px]  md:w-[100px] md:h-[100px] relative md:bottom-6"
              src={globe}
              alt=""
            />
            <span className="text-center md:text-lg text-xs w-10/12 md:pb-16 pb-8">
              Aumentar el número de visualizaciones
            </span>
          </div>

          <div
            className="w-[140px] h-[140px] md:w-[205px] md:h-[205px]
         card-audience flex flex-col justify-center items-center relative lg:bottom-48"
          >
            <img
              className="w-[60px] h-[60px]  md:w-[100px] md:h-[100px] relative md:bottom-6"
              src={crown}
              alt=""
            />
            <span className="text-center md:text-lg text-xs w-10/12 md:pb-16 pb-8">
              Logra tus objetivos musicales con BlackLion
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AudienceKnowUs;
