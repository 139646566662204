import star from '../../assets-2/star 2.png'
import lock from '../../assets-2/lock 1.png'
import coin from '../../assets-2/coin.png'
import rocket from '../../assets-2/rocket b.png'

export const DataKnowUs = [
  {
    id: 1,
    title: '¡Certificamos tus perfiles musicales!',
    image: star,
  },
  {
    id: 2,
    title: '¡Blindamos y potencializamos tu carrera digital!',
    image: lock,
  },
  {
    id: 3,
    title: '¡Gana dinero con tu música!',
    image: coin,
  },
  {
    id: 4,
    title: '¡Incrementa tu audiencia!',
    image: rocket,
  },
]
