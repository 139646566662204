import React from "react";
import { XIcon } from "@heroicons/react/outline";
import { seleccionarImagen } from "../../../../utilidades/SeleccionarImagen";
import { formatFecha } from "../../../../utilidades/FuncionesAuxiliares";

const DialogoAlbum = (props) => {
  const album = props.album;
  const canciones = album.canciones;
  const nombre = props.nombre;
  let k = 0;

  return (
    <div className="fixed inset-0 w-screen h-screen flex justify-center items-center z-50">
      <div
        className="w-full h-full absolute inset-0 "
        onClick={props.cerrar}
        style={{ background: "rgba(232, 74, 132, 0.2)" }}
      />
      <div className="bg-white rounded-md text-rosa-darkBlue border-2 relative overflow-y-auto scrollbar-artista scrollbar-thumb pb-5 overflow-x-hidden">
        <div className="w-[65vw] py-4 max-h-[500px] ">
          <div
            className="text-right flex justify-end mr-4"
            onClick={props.cerrar}
          >
            <XIcon className="text-rosa-primariy w-8 h-8 cursor-pointer" />
          </div>
          <div className="flex flex-col lg:flex-row justify-center w-[85%] mx-auto relative">
            {/* Album foto */}
            <div className="flex flex-col justify-center items-center w-full space-y-3">
              <div className="w-[50%] text-center  text-lg font-bold ">
                <span className="text-center">{album.nombre}</span>
              </div>
              <img
                src={seleccionarImagen(album.portada, album)}
                className="w-[200px] h-[200px] object-cover shadow-sm mb-4"
                alt="album artista"
              />
            </div>
            {/* Datos album */}
            <div className="space-y-3 w-full flex flex-col mt-5">
              <div className="w-full m-4 flex flex-col mb-0">
                <span className="text-xs lg:text-sm">Nombre del artista</span>
                <div>
                  <span className="font-semibold text-sm lg:text-base">
                    {album.nombreArtista ?? nombre}
                  </span>
                </div>
              </div>
              <hr />
              <div className="w-full m-4 flex flex-col">
                <span className="text-xs lg:text-sm titulo-dato">
                  Estado del disco
                </span>
                <div>
                  <span className="font-semibold text-sm lg:text-base">
                    {album.online ? "Online" : "Take Down"}
                  </span>
                </div>
              </div>
              <hr />
              <div className="w-full text-base m-4 flex flex-col">
                <span className="">Fecha de Lanzamiento</span>
                <div>
                  <span className="font-semibold text-sm lg:text-base">
                    {formatFecha(album.fechaLanzamiento)}
                  </span>
                </div>
              </div>
              <hr />
              <div className="w-full text-base m-4 flex flex-col">
                <span className="titulo-dato">UPC</span>
                <div>
                  <span className="font-semibold text-sm lg:text-base">
                    {album.upc}
                  </span>
                </div>
              </div>
              <div className="dato-album"></div>
            </div>
          </div>
          <div className="w-[85%] mx-auto mt-10 pb-6">
            {album.canciones ? (
              <>
                <hr className="bg-rosa-primariy rounded-xl h-[2px] border-none" />
                <div className="mt-5 space-y-5">
                  {canciones.map((c) => {
                    k += 1;
                    return (
                      <div className="cancion" key={`cancion-${k}`}>
                        <div>
                          <nav className="text-sm font-semibold text-rosa-primariy">
                            {k}
                          </nav>
                          <span className="text-lg font-bold">{c.nombre}</span>
                        </div>
                        <span className="text-sm font-light">
                          {c.nombreArtista}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogoAlbum;
