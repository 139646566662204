import { TrashIcon, PlusCircleIcon } from "@heroicons/react/outline";
import BeforeNextButtons from "./BeforeNextButtons";

const CobrosAdicionales = ({
  crearRegaliaData,
  setCrearRegaliaData,
  currentStep,
  setCurrentStep,
}) => {
  let datosCompletos = true;
  crearRegaliaData.cobrosAdicionales.forEach((cobroAdicional) => {
    datosCompletos =
      datosCompletos && cobroAdicional.nombre !== "" && cobroAdicional.valor;
  });

  const agregarCobroAdicional = () => {
    setCrearRegaliaData({
      ...crearRegaliaData,
      cobrosAdicionales: [
        ...crearRegaliaData.cobrosAdicionales,
        {
          nombre: "",
          valor: null,
        },
      ],
    });
  };

  const eliminarCobroAdicional = (index) => {
    setCrearRegaliaData({
      ...crearRegaliaData,
      cobrosAdicionales: crearRegaliaData.cobrosAdicionales.filter(
        (cobroAdicional, i) => index !== i
      ),
    });
  };

  const handleChange = (e, index) => {
    const cobrosAdicionales = [...crearRegaliaData.cobrosAdicionales];
    if (e.target.name === "valor") {
      cobrosAdicionales[index] = {
        ...cobrosAdicionales[index],
        [e.target.name]:
          parseFloat(e.target.value) < 0
            ? parseFloat(e.target.value) * -1
            : parseFloat(e.target.value),
      };
    } else {
      cobrosAdicionales[index] = {
        ...cobrosAdicionales[index],
        [e.target.name]: e.target.value,
      };
    }

    setCrearRegaliaData({
      ...crearRegaliaData,
      cobrosAdicionales,
    });
  };

  return (
    <>
      {crearRegaliaData.cobrosAdicionales.length > 0 ? (
        <div className="grid grid-cols-3 rounded-2xl shadow-lg overflow-hidden mt-[20px]">
          <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]">
            Razón
          </h3>
          <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]">
            Valor
          </h3>
          <h3 className="flex items-center justify-center text-center w-full text-[15px] bg-[#F2F2F2] py-[12px]"></h3>
          {crearRegaliaData.cobrosAdicionales.map((cobroAdicional, index) => (
            <>
              <input
                onChange={(e) => handleChange(e, index)}
                name="nombre"
                type="text"
                placeholder="Razón"
                value={cobroAdicional.nombre}
                className="text-center py-[12px]"
              />
              <input
                onChange={(e) => handleChange(e, index)}
                name="valor"
                type="number"
                min={0}
                placeholder="Valor"
                value={cobroAdicional.valor}
                className="text-center py-[12px]"
              />
              <div
                onClick={() => eliminarCobroAdicional(index)}
                className="flex items-center justify-center text-rosa-primariy cursor-pointer hover:underline"
              >
                <TrashIcon className="w-[20px] mr-2" />
                Eliminar
              </div>
            </>
          ))}
        </div>
      ) : (
        <h3 className="text-center text-rosa-primariy">
          Actualmente no se ha agregado ningún cobro adicional
        </h3>
      )}
      <div
        onClick={agregarCobroAdicional}
        className="flex text-rosa-primariy items-center hover:underline cursor-pointer font-medium mt-4 mr-auto"
      >
        Agregar nuevo cobro
        <PlusCircleIcon className="w-[20px] ml-2" />
      </div>

      {!datosCompletos && (
        <h3 className="text-red-700 mt-1">
          Por favor llene cada uno de los campos de los cobros adicionales
        </h3>
      )}

      <BeforeNextButtons
        disableCondition={!datosCompletos}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
    </>
  );
};
export default CobrosAdicionales;
