import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { traerLogros } from "../../../../actions/adminActions";
import { getLogros } from "../../../../reducers/adminReducer";
import TableAchievements from "./TableAcchievements";

const headers = ["Fecha", "Logro", "Imagen"];

const Acchievements = ({
  modalAcchievement,
  modalRemoveAcchievement,
  modalEditAcchievement,
}) => {
  const { id } = useParams();
  const logros = useSelector((state) => getLogros(state, id));
  const dispatch = useDispatch();
  useEffect(() => {
    traerLogros(dispatch, id);
  }, []);
  return (
    <div className="flex flex-col space-y-8 mx-auto">
      <div className="flex flex-col justify-around my-2 lg:m-0 max-h-28 bg-white bg-opacity-20 rounded-lg p-4 font-proxima">
        <h1 className="font-semibold sm:text-lg xl:text-xl">Logros</h1>
        <p className="text-xs font-light sm:text-sm xl:text-base">
          Aquí podrás administrar los logros que lleva el artista y se verá
          reflejado en el perfil
        </p>
      </div>
      <TableAchievements
        headers={headers}
        rows={logros}
        modalAcchievement={modalAcchievement}
        modalRemoveAcchievement={modalRemoveAcchievement}
        modalEditAcchievement={modalEditAcchievement}
      />
    </div>
  );
};

export default Acchievements;
