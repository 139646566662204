import albumDefecto from "../assets/lista.svg";
import sencilloDefecto from "../assets/sencillo-de-musica.svg";
import { obtenerUrl } from "./FuncionesAuxiliares";

export const seleccionarImagen = (portada, album) => {
  if (album?.canciones?.length) {
    return portada ? obtenerUrl(portada) : albumDefecto;
  }
  return portada ? obtenerUrl(portada) : sencilloDefecto;
};
