import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getTemaActual } from "../../../../reducers/academiaReducer";
import { OK } from "../../../../utilidades/Constantes";
import IconoAgregar from "../../../general/IconoAgregar";
import IconoEditar from "../../../general/IconoEditar";
import IconoEliminar from "../../../general/IconoEliminar";
import * as actions from "./../../../../actions/academiaActions";
import "./detalle-curso.sass";
import EditarArchivo from "./EditarArchivo";
import EditarVideo from "./EditarVideo";
import DialogoUnBoton from "./../../../general/DialogoUnBoton";
import ScaleLoader from "react-spinners/ScaleLoader";

const DetalleCurso = ({
  tema,
  getDBCursosCategoria,
  getDBTemaConCategorias,
}) => {
  const history = useHistory();
  const { idTema, idCategoria, idCurso } = useParams();

  const [categoriaActual, setCategoriaActual] = useState({});
  const [cursoActual, setCursoActual] = useState({
    nombre: "",
    descripcion: "",
    descripcionLarga: "",
    nivel: 0,
    archivos: [],
    video: "",
    imagen: "",
  });
  const [cursoAntiguo, setCursoAntiguo] = useState({
    nombre: "",
    descripcion: "",
    descripcionLarga: "",
    nivel: 0,
    archivos: [],
    video: "",
    imagen: "",
  });
  const [dialogoActual, setDialogoActual] = useState("");
  const [cargando, setCargando] = useState(false);
  const [progresoVideo, setProgresoVideo] = useState(0);

  useEffect(() => {
    if (!tema) getDBTemaConCategorias(idTema);
    else if ((tema.existe !== undefined) & !tema.existe)
      history.push("/administrador/academia");
    else if (!tema.categorias) getDBTemaConCategorias(idTema);
    else {
      const categoriasFiltradas = tema.categorias.filter(
        (categoria) => categoria.uidDoc === idCategoria
      );
      if (categoriasFiltradas.length === 0)
        history.push(`/administrador/academia/${idTema}`);
      else if (!categoriasFiltradas[0].cursos)
        getDBCursosCategoria(idTema, idCategoria);
      else {
        setCategoriaActual(categoriasFiltradas[0]);
        if (idCurso !== "crear") {
          const cursosFiltrados = categoriasFiltradas[0].cursos.filter(
            (curso) => curso.uidDoc === idCurso
          );
          if (cursosFiltrados.length === 0)
            history.push(
              `/administrador/academia/${idTema}/categoria/${idCategoria}`
            );
          else {
            setCursoActual(cursosFiltrados[0]);
            setCursoAntiguo(cursosFiltrados[0]);
          }
        }
      }
    }
  }, [tema, getDBTemaConCategorias]);

  const handleChange = (e) => {
    setCursoActual({
      ...cursoActual,
      [e.target.name]: e.target.value,
    });
  };

  const eliminarArchivo = (pos) => {
    const archivosTemp = cursoActual.archivos;
    archivosTemp.splice(pos, 1);
    setCursoActual({
      ...cursoActual,
      archivos: archivosTemp,
    });
  };

  const mostrarDialogo = (dialogo, objeto, pos) => {
    switch (dialogo) {
      case "video":
        setDialogoActual(
          <EditarVideo
            handleGuardar={(video) =>
              setCursoActual({ ...cursoActual, video: video })
            }
            handleCancelar={() => setDialogoActual("")}
            video={objeto}
          />
        );
        break;
      case "editar-archivo":
        setDialogoActual(
          <EditarArchivo
            handleGuardar={(archivo) => {
              const archivosTemp = cursoActual.archivos;
              archivosTemp[pos] = archivo;
              setCursoActual({ ...cursoActual, archivos: archivosTemp });
            }}
            handleCancelar={() => setDialogoActual("")}
            archivo={objeto}
          />
        );
        break;
      case "agregar-archivo":
        setDialogoActual(
          <EditarArchivo
            handleGuardar={(archivo) => {
              const archivosTemp = cursoActual.archivos ?? [];
              archivosTemp.push(archivo);
              setCursoActual({ ...cursoActual, archivos: archivosTemp });
            }}
            handleCancelar={() => setDialogoActual("")}
          />
        );
        break;
      case "error":
        setDialogoActual(
          <DialogoUnBoton
            titulo="Error"
            descripcion={objeto}
            boton1="Regresar"
            click1={() => setDialogoActual("")}
            cerrar={() => setDialogoActual("")}
          />
        );
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCargando(true);
    const validator =
      !!cursoActual.nombre &
      !!cursoActual.descripcion &
      !!cursoActual.descripcionLarga &
      !!cursoActual.imagen &
      (cursoActual !== cursoAntiguo);

    if (validator) {
      const res = await actions.actualizarCurso(
        idTema,
        idCategoria,
        idCurso,
        cursoActual,
        cursoAntiguo,
        idCurso === "crear",
        setProgresoVideo
      );
      setProgresoVideo(100);
      if (res.estado === OK) {
        history.push(
          `/administrador/academia/${idTema}/categoria/${idCategoria}`
        );
      } else {
        mostrarDialogo("error", res.mensaje);
        setCargando(false);
      }
    }
  };

  return (
    <div className="container w-10/12 mx-auto mt-5 font-proxima">
      {cargando && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center z-30">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
          {cursoActual?.video?.name && (
            <span className="font-bold mt-5 p-2 bg-black bg-opacity-30 rounded-lg ">
              {Math.round(progresoVideo)}%
            </span>
          )}
        </div>
      )}
      {dialogoActual}
      {(cursoActual.nombre !== "" || idCurso === "crear") && (
        <>
          <div className="flex flex-wrap ml-3 items-center">
            <span
              className="font-normal text-xl cursor-pointer"
              onClick={() => history.push("/administrador/academia")}
            >
              Temas
            </span>
            <span className="font-normal mx-5 text-xl">{">"}</span>
            <span
              className="font-semibold text-xl cursor-pointer"
              onClick={() => history.push(`/administrador/academia/${idTema}`)}
            >
              {tema?.nombre}
            </span>
            <span className="font-normal mx-5 text-xl">{">"}</span>
            <span
              className="font-semibold text-xl cursor-pointer"
              onClick={() =>
                history.push(
                  `/administrador/academia/${idTema}/categoria/${idCategoria}`
                )
              }
            >
              {categoriaActual?.nombre}
            </span>
            <span className="font-normal mx-5 text-xl">{">"}</span>
            <span className="font-semibold text-xl text-[#E84A84]">
              {idCurso === "crear" ? "Crear curso" : cursoActual?.nombre}
            </span>
          </div>
          <form
            className="flex flex-wrap flex-col lg:flex-row mt-10 lg:mt-20 mb-20"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col w-full lg:w-1/2 lg:border-r-[1px] lg:pl-[8%] lg:pr-[8%] lg:pt-10 pb-10 lg:pb-20">
              <h3 className="text-[26px] font-proxima">Nombre del curso</h3>
              <input
                type="text"
                className="input-admin mt-8 h-10 placeholder-inferior pl-[7px]"
                placeholder="Descripción"
                name="nombre"
                value={cursoActual?.nombre}
                onChange={handleChange}
              />
              <h3 className="text-[26px] font-proxima mt-16">Imagen del curso</h3>
              <div className="flex flex-col mt-4">
                <label
                  htmlFor="video-curso"
                  className="bg-[#E84A84] text-white relative w-max px-[10px] py-2 rounded-md cursor-pointer text-[17px]"
                >
                  <input
                    id="video-curso"
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      setCursoActual({
                        ...cursoActual,
                        imagen: e.target.files[0],
                      })
                    }
                    name="archivo"
                    className="absolute w-full h-full opacity-0 top-0 left-0 right-0 bottom-0 outline-none cursor-pointer z-[-1]"
                  />
                  Seleccionar archivo
                </label>
                <span className="text-[#BEBEBE] mt-5 underline text-lg whitespace-nowrap w-max overflow-x-hidden max-w-full">
                  {cursoActual.imagen?.name ?? cursoActual.imagen}
                </span>
              </div>
              <h3 className="text-[26px] font-proxima mt-16">
                Descripción corta
              </h3>
              <textarea
                cols="30"
                rows="10"
                className="input-admin texto-inferior mt-8 h-20"
                placeholder="Nombre"
                name="descripcion"
                value={cursoActual.descripcion}
                onChange={handleChange}
              />
              <h3 className="text-[26px] font-proxima mt-16">
                Descripción larga
              </h3>
              <textarea
                cols="30"
                rows="10"
                className="input-admin texto-inferior mt-8 h-20"
                placeholder="Descripción larga"
                name="descripcionLarga"
                value={cursoActual.descripcionLarga}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col w-full lg:w-1/2 lg:border-l-[1px] lg:pl-[10%] mt-8 lg:mt-0 lg:pt-10 pb-20">
              <h3 className="text-[26px] font-proxima">Nivel</h3>
              <div className="mt-8 lg:ml-8">
                <div className="contenedor-radio-curso w-full lg:w-[80%] flex items-center cursor-pointer">
                  <span className="text-6xl mr-7 text-[#E84A84]">&bull;</span>
                  <label className="mr-10 lg:mr-[105px] pt-2 text-xl font-semibold">
                    Nivel básico
                  </label>
                  <input
                    type="radio"
                    checked={cursoActual.nivel === 0}
                    onChange={() =>
                      setCursoActual({ ...cursoActual, nivel: 0 })
                    }
                  />
                  <span className="checkmark"></span>
                </div>
                <div className="contenedor-radio-curso w-full lg:w-[80%] flex items-center cursor-pointer">
                  <span className="text-6xl mr-7 text-[#E84A84]">&bull;</span>
                  <label className="mr-10 lg:mr-[105px] pt-2 text-xl font-semibold">
                    Nivel intermedio
                  </label>
                  <input
                    type="radio"
                    checked={cursoActual.nivel === 1}
                    onChange={() =>
                      setCursoActual({ ...cursoActual, nivel: 1 })
                    }
                  />
                  <span className="checkmark"></span>
                </div>
                <div className="contenedor-radio-curso w-full lg:w-[80%] flex items-center cursor-pointer">
                  <span className="text-6xl mr-7 text-[#E84A84]">&bull;</span>
                  <label className="mr-10 lg:mr-[105px] pt-2 text-xl font-semibold">
                    Nivel avanzado
                  </label>
                  <input
                    type="radio"
                    checked={cursoActual.nivel === 2}
                    onChange={() =>
                      setCursoActual({ ...cursoActual, nivel: 2 })
                    }
                  />
                  <span className="checkmark"></span>
                </div>
              </div>
              <h3 className="text-[26px] font-proxima mt-14 lg:mt-20">Video</h3>
              <div className="input-admin w-full flex justify-between pb-2">
                <span className="my-auto overflow-x-hidden whitespace-nowrap mr-10">
                  {cursoActual?.video?.name ?? cursoActual?.video}
                </span>
                <IconoEditar
                  onClick={() => mostrarDialogo("video", cursoActual?.video)}
                />
              </div>
              <h3 className="text-[26px] font-proxima mt-20 lg:mt-28">
                Archivos
              </h3>
              {cursoActual?.archivos?.map((archivo, pos) => (
                <div
                  className="input-admin w-full flex justify-between pb-2 mt-4"
                  key={archivo.uid}
                >
                  <span className="my-auto">{archivo.nombre}</span>
                  <div className="flex items-center">
                    <IconoEditar
                      onClick={() =>
                        mostrarDialogo("editar-archivo", archivo, pos)
                      }
                    />
                    <IconoEliminar onClick={() => eliminarArchivo(pos)} />
                  </div>
                </div>
              ))}
              <div className="flex w-full justify-end items-center mt-10">
                <span className="text-xl lg:text-[26px] font-proxima mr-2 text-right">
                  Agregar más archivos
                </span>
                <IconoAgregar
                  onClick={() => mostrarDialogo("agregar-archivo")}
                />
              </div>
            </div>
            <div className="w-full flex justify-end mt-20">
              <button
                className="font-proxima outline-none rounded-md py-[5px] px-[15px] text-xl font-semibold mr-8 bg-transparent border-[1px]"
                onClick={() => {
                  history.push(
                    `/administrador/academia/${idTema}/categoria/${idCategoria}`
                  );
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className={
                  !cursoActual.nombre ||
                  !cursoActual.descripcion ||
                  !cursoActual.descripcionLarga ||
                  cursoActual === cursoAntiguo
                    ? "font-proxima outline-none rounded-md py-[5px] px-[15px] bg-[#BEBEBE] text-xl font-semibold cursor-default"
                    : "font-proxima outline-none rounded-md py-[5px] px-[15px] bg-[#E84A84] text-xl font-semibold"
                }
                disabled={
                  !cursoActual.nombre ||
                  !cursoActual.descripcion ||
                  !cursoActual.descripcionLarga ||
                  cursoActual === cursoAntiguo
                }
              >
                Guardar
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  tema: getTemaActual(state, props.match.params.idTema),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetalleCurso)
);
