import UserIcon from "./asset/user.svg";
import CheveronRight from "./asset/cheveron-right.svg";
import CheveronDown from "./asset/cheveron-down.svg";
import Collection from "./asset/collection.svg";

export const SidebarData = [
  {
    title: "Perfil",
    destiny: "/perfil",
    icon: UserIcon,
    iconClosed: CheveronDown,
    iconOpen: CheveronRight,
    subNav: [
      {
        title: "Perfil",
        destiny: "/perfil",
      },
      {
        title: "Información del Perfil",
        destiny: "/informacion-perfil",
      },
      {
        title: "Tipo de perfil",
        destiny: "/tipo-perfil",
      },
      {
        title: "Completar tareas",
        destiny: "/completar-tareas",
      },
      {
        title: "Logros",
        destiny: "/logros",
      },
      {
        title: "Redes Sociales",
        destiny: "/redes-sociales",
      },
      {
        title: "Subir pago",
        destiny: "/subir-pago",
      },
      {
        title: "Agregar albúm",
        destiny: "/agregar-album",
      },
      {
        title: "Agregar sencillo",
        destiny: "/agregar-sencillo",
      },
      // {
      //   title: "Eliminar artista",
      //   destiny: "/eliminar-artista",
      // },
    ],
  },
  {
    title: "Historial",
    destiny: "/team",
    icon: Collection,
    iconClosed: CheveronDown,
    iconOpen: CheveronRight,
    subNav: [
      {
        title: "Historial de pago",
        destiny: "/historial-pago",
      },
      {
        title: "Historial de regalías",
        destiny: "/historial-regalias",
      },
    ],
  },
];
