import React from "react";
import Sidebar from "./Sidebar";
import { SidebarData } from "./SidebarData";

const SidebarMobile = ({
  url,
  activeSubMenu,
  setActiveSubMenu,
  openMenu,
  setOpenMenu,
}) => {
  return (
    <div
      className={`${
        openMenu
          ? "-translate-x-full -left-20 transition-transform"
          : "translate-x-0 transform-gpu transition-transform"
      } absolute top-0 z-40 w-full h-full max-h-screen flex flex-col items-center  pt-24`}
    >
      <div className="flex mx-auto items-start justify-center relative w-full">
        <div className="flex rounded-md bg-white/20 flex-col h-auto w-[90%] ">
          {SidebarData.map((item, index) => (
            <Sidebar
              setOpenMenu={setOpenMenu}
              openMenu={openMenu}
              key={item.title}
              url={url}
              item={item}
              openSubMenu={index === activeSubMenu}
              index={index}
              setActiveSubMenu={setActiveSubMenu}
              activeSubMenu={activeSubMenu}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
