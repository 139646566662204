import React, { useEffect, useState } from "react";
import { PlusIcon, TrashIcon, PencilIcon } from "@heroicons/react/outline";
import ModalDistribuidora from "../Modals/ModalDistribuidora";
import DeleteModal from "../Modals/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import randomProfile from "../../../../assets-2/Academia/random-profile.png";
import { S3_BUCKET } from "../../../../utilidades/Constantes";
import { selectDistribuidoras } from "../../../../reducers/adminReducer";
import { getDistribuidoras } from "../../../../actions/adminActions";

const Distribuidoras = () => {
  const [modal, setModal] = useState({
    add: false,
    edit: false,
    delet: false,
    distribuidora: {},
  });

  const [type, setType] = useState();
  const distribuidoras = useSelector(selectDistribuidoras);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!distribuidoras) {
      getDistribuidoras()(dispatch);
    }
  }, []);

  return (
    <div className="w-ful xl:w-[80%] mt-16">
      {(modal.add || modal.edit) && (
        <ModalDistribuidora
          type={type}
          setType={setType}
          modal={modal}
          setModal={setModal}
          distribuidoras={distribuidoras}
        />
      )}
      {modal.delet && <DeleteModal modal={modal} setModal={setModal} />}
      <div className="flex items-center w-full space-x-5">
        <button
          onClick={() => setModal({ ...modal, add: true })}
          className="rounded-full bg-gradient-to-r from-[#F093FB] to-[#F5576C] w-[52px] h-[52px] flex justify-center items-center hover:scale-105 duration-300"
        >
          <PlusIcon className="h-[28px] w-[28px]" />
        </button>
        <h3>Agregar distribuidora</h3>
      </div>
      {!distribuidoras ? (
        <div className="w-full h-full flex justify-center items-center">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
        </div>
      ) : (
        <>
          <div className="w-full justify-center items-center my-10">
            <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4  justify-center items-center gap-20">
              {distribuidoras?.map((distribuidora) => (
                <li
                  className="list-none flex flex-col items-center space-y-3"
                  key={distribuidora.id}
                >
                  <button className="block bg-gradient-to-tr from-rosa-pinkCircle to-rosa-purpleCircle rounded-full p-1">
                    <img
                      className="h-40 w-40 rounded-full object-cover"
                      src={
                        distribuidora?.foto
                          ? S3_BUCKET +
                            distribuidora?.foto
                          : randomProfile
                      }
                    />
                  </button>
                  <p>{distribuidora.nombre}</p>
                  <div className="flex gap-4 justify-center items-center">
                    <div
                      className="rounded-full w-10 p-2 bg-gradient-to-r from-[#F093FB] to-[#F5576C] hover:scale-105 transition cursor-pointer"
                      onClick={() =>
                        setModal({ ...modal, edit: true, distribuidora })
                      }
                    >
                      <PencilIcon className="h-full w-full" />
                    </div>
                    <div
                      className="cursor-pointer hover:scale-105 transition"
                      onClick={() =>
                        setModal({ ...modal, delet: true, distribuidora })
                      }
                    >
                      <TrashIcon className="h-[28px] w-[28px]" />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Distribuidoras;
