const Graphic = (props) => (
  <svg
    width={20}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 1.367h8m0 0v8m0-8-8 8-4-4-6 6"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Graphic;
