import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getTemaActual } from "../../../../../reducers/academiaReducer";
import * as actions from "../../../../../actions/academiaActions";
import CarruselTema from "./CarruselTema";
import { ChevronRightIcon } from "@heroicons/react/solid";
import CardCursos from "./CardCursos";

const Tema = ({ tema, getDBTemaConCategorias, getDBCursosCategoria }) => {
  let { idTema } = useParams();

  const [indiceCategoriaActiva, setIndiceCategoriaActiva] = useState(0);

  useEffect(() => {
    if (!tema?.categorias) {
      getDBTemaConCategorias(idTema);
    } else if (!tema?.categorias[indiceCategoriaActiva]?.cursos) {
      getDBCursosCategoria(
        idTema,
        tema?.categorias[indiceCategoriaActiva].uidDoc
      );
    }
  }, [tema, indiceCategoriaActiva]);

  return (
    <div className="w-full h-full pb-10 overflow-hidden font-proxima">
      <div className=" w-10/12 flex flex-col space-y-10 pt-12 container mx-auto items-center">
        <div className="w-full flex gap-3 justify-center items-center md:justify-start">
          <Link
            to="/academia"
            className={`${
              window.location.pathname.endsWith("academia")
                ? "text-rosa-primariy"
                : "hover:text-rosa-primariy"
            }`}
          >
            Academia
          </Link>
          <div>
            <ChevronRightIcon className="w-5 h-5" />
          </div>
          <Link
            to="/"
            className={`${
              window.location.pathname.endsWith(`${idTema}`)
                ? "text-rosa-primariy font-semibold"
                : "hover:text-rosa-primariy"
            }`}
          >
            {tema?.nombre}
          </Link>
        </div>
        <div className="flex flex-col lg:flex-row items-center space-y-5 w-11/12 md:w-full max-h-96">
          <div className="space-y-5 w-full">
            <h2 className="text-3xl md:text-4xl lg:text-5xl font-semibold text-center md:text-left">
              {tema?.nombre}
            </h2>
            <p className="text-sm md:text-lg md:w-3/4 text-center md:text-left">
              {tema?.descripcion}
            </p>
          </div>
          <div className="w-full  flex justify-center items-center">
            <img
              className="w-full max-w-[150px] lg:max-w-[260px] "
              src={tema?.imagen}
              alt="imagen del tema"
            />
          </div>
        </div>
        <div className="w-full ">
          <CarruselTema
            indiceCategoriaActiva={indiceCategoriaActiva}
            setIndiceCategoriaActiva={setIndiceCategoriaActiva}
            categorias={tema?.categorias}
          />
          <div className="w-full py-8">
            <h3 className="w-full text-2xl lg:text-4xl font-semibold flex flex-col justify-center items-center">
              {tema?.categorias &&
                tema.categorias[indiceCategoriaActiva].nombre}
            </h3>
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center lg:justify-start lg:items-start">
          {tema?.categorias && (
            <CardCursos
              categoriaActiva={tema?.categorias[indiceCategoriaActiva]}
              idTema={idTema}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  tema: getTemaActual(state, props.match.params.idTema),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tema));
