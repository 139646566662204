import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { traerRegalias } from "../../../actions/adminActions";
import { getRegalias } from "../../../reducers/adminReducer";
import Table from "./Table";

const headers = [
  "Fecha",
  "Parte Artista",
  "Parte BlackLion",
  "Porcentaje Artista",
  "Porcentaje BlackLion",
  "Porcentaje IVA",
  "Valor IVA",
  "Soporte",
  "Resumen",
];

const RoyaltyHistory = ({ modalConfirm, modalRegalias, setRegalias }) => {
  const { params } = useRouteMatch();
  const regalias = useSelector(getRegalias) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    traerRegalias(dispatch, params.id);
  }, []);
  return (
    <>
      {regalias.length !== 0 ? (
        <Table
          modalConfirm={modalConfirm}
          modalRegalias={modalRegalias}
          setRegalias={setRegalias}
          regalias={true}
          headers={headers}
          rows={regalias}
        />
      ) : (
        <div className="flex sm:flex-row items-center w-full justify-center text-white bg-white/10 rounded-lg">
          <div className="titulo-subir-pago font-bold text-2xl font-proxima ">
            No hay ninguna regalía asociada a este artista
          </div>
        </div>
      )}
    </>
  );
};

export default RoyaltyHistory;
