import {
  consultarColeccion,
  consultarColeccionConSubcoleccion,
  consultarDocumentoConSubColeccion,
  crearDocumentoAutoID,
  editarDocumento,
  eliminarDocumento,
} from "../proxy/FirebaseDB";
import {
  eliminarArchivo,
  eliminarArchivosCarpeta,
  subirArchivo,
  subirArchivoProgreso,
} from "../proxy/FirebaseStorage";
import { ERROR, OK } from "../utilidades/Constantes";

export const GET_TEMAS_ACADEMIA = "GET_TEMAS_ACADEMIA";
export const GET_CATEGORIA_TEMA = "GET_CATEGORIA_TEMA";
export const GET_TEMA_ACADEMIA = "GET_TEMA_ACADEMIA";
export const GET_CURSOS_CATEGORIA = "GET_CURSOS_CATEGORIA";

export const getDBTemasAcademia = (payload) => {
  return (dispatch) => {
    consultarColeccion("temas", dispatch, GET_TEMAS_ACADEMIA);
  };
};

export const getDBCategoriaAcademia = (payload) => {
  return (dispatch) => {
    consultarColeccionConSubcoleccion(
      "temas",
      "categorias",
      dispatch,
      GET_CATEGORIA_TEMA
    );
  };
};

export const getDBTemaConCategorias = (idTema) => {
  return (dispatch) => {
    consultarDocumentoConSubColeccion(
      `temas/${idTema}`,
      "categorias",
      dispatch,
      GET_TEMA_ACADEMIA
    );
  };
};

export const getDBCursosCategoria = (idTema, idCategoria) => {
  return (dispatch) => {
    consultarColeccion(
      `temas/${idTema}/categorias/${idCategoria}/cursos`,
      dispatch,
      GET_CURSOS_CATEGORIA,
      {
        idTema,
        idCategoria,
      }
    );
  };
};

export const actualizarCurso = async (
  idTema,
  idCategoria,
  idCurso,
  curso,
  cursoAntiguo,
  crear,
  setProgresoVideo
) => {
  if (crear) {
    let cursoCrear = {
      ...curso,
    };
    cursoCrear.archivos = cursoCrear.archivos.map((archivo) => ({
      ...archivo,
      archivo: "",
    }));
    cursoCrear.video = "";
    cursoCrear.imagen = "";
    let res = await crearDocumentoAutoID(
      `temas/${idTema}/categorias/${idCategoria}/cursos/`,
      cursoCrear
    );
    idCurso = res.id;
    if (res === ERROR) {
      return {
        estado: ERROR,
        mensaje: "Hubo un error creando el curso. Intentelo de nuevo.",
      };
    }
  }

  let promises = [];
  curso.archivos.forEach((archivo) => {
    if (archivo.archivo.name)
      promises.push(
        subirArchivo(
          `temas/${idTema}/categorias/${idCategoria}/cursos/${idCurso}/${archivo.uid}`,
          archivo.archivo
        )
      );
  });

  if (curso.video?.name) {
    promises.push(
      subirArchivoProgreso(
        `temas/${idTema}/categorias/${idCategoria}/cursos/${idCurso}/video`,
        curso.video,
        setProgresoVideo
      )
    );
  }

  if (curso.imagen?.name) {
    promises.push(
      subirArchivo(
        `temas/${idTema}/categorias/${idCategoria}/cursos/${idCurso}/imagen`,
        curso.imagen
      )
    );
  }

  let res = await Promise.all(promises);
  const promisesErr = res.filter((promise) => promise === ERROR);
  if (promisesErr > 0) {
    return {
      estado: ERROR,
      mensaje: "Hubo un error subiendo los archivos, intentelo de nuevo.",
    };
  }
  let contador = 0;
  curso.archivos = curso.archivos.map((archivo) => {
    if (archivo.archivo.name) {
      contador += 1;
      return {
        ...archivo,
        archivo: res[contador - 1],
      };
    } else {
      return archivo;
    }
  });

  if (curso.video.name) {
    if (curso.imagen.name) {
      curso.imagen = res[res.length - 1];
      curso.video = res[res.length - 2];
    } else {
      curso.video = res[res.length - 1];
    }
  } else {
    if (curso.imagen.name) {
      curso.imagen = res[res.length - 1];
    }
  }

  res = await editarDocumento(
    `temas/${idTema}/categorias/${idCategoria}/cursos/${idCurso}`,
    curso
  );

  if (res === ERROR) {
    return {
      estado: ERROR,
      mensaje: "Hubo un error subiendo el curso. Intentelo de nuevo.",
    };
  }

  let archivosBorrados = cursoAntiguo.archivos.filter((archivo) => {
    let existe = true;
    curso.archivos.forEach((arc) => {
      if (arc.uid === archivo.uid) existe = false;
    });
    return existe;
  });
  promises = [];

  archivosBorrados.forEach((archivo) => {
    promises.push(
      eliminarArchivo(
        `temas/${idTema}/categorias/${idCategoria}/cursos/${idCurso}/${archivo.uid}`
      )
    );
  });

  res = await Promise.all(promises);

  return {
    estado: OK,
    mensaje: "El curso se guardo exitosamente.",
  };
};

export const eliminarCurso = async (idTema, idCategoria, idCurso) => {
  let res = await eliminarDocumento(
    `temas/${idTema}/categorias/${idCategoria}/cursos/${idCurso}`
  );
  if (res === ERROR)
    return {
      estado: ERROR,
      mensaje: "No se pudo borrar el curso. Intentelo de nuevo.",
    };
  res = await eliminarArchivosCarpeta(
    `temas/${idTema}/categorias/${idCategoria}/cursos/${idCurso}`
  );
  if (res === ERROR)
    return {
      estado: ERROR,
      mensaje: "No se pudo borrar el curso. Intentelo de nuevo.",
    };
  return {
    estado: OK,
    mensaje: "Se borró exitosamente el curso.",
  };
};

export const eliminarCategoria = async (idTema, idCategoria, cursos) => {
  if (cursos && cursos?.length > 0) {
    return {
      estado: ERROR,
      mensaje:
        "La categoria tiene cursos creados, borrelos primero e intentelo de nuevo.",
    };
  } else {
    const res = await eliminarDocumento(
      `temas/${idTema}/categorias/${idCategoria}`
    );
    if (res === ERROR)
      return {
        estado: ERROR,
        mensaje: "No se pudo eliminar la categoria. Intentelo de nuevo.",
      };
    return {
      estado: OK,
      mensaje: "Se elimino exitosamente la categoria.",
    };
  }
};

export const editarCategoria = async (idTema, idCategoria, doc) => {
  const res = await editarDocumento(
    `temas/${idTema}/categorias/${idCategoria}`,
    doc
  );
  if (res === ERROR)
    return {
      estado: ERROR,
      mensaje: "No se pudo editar la categoria. Intentelo de nuevo.",
    };
  return {
    estado: OK,
    mensaje: "Se edito exitosamente la categoria.",
  };
};

export const crearCategoria = async (idTema, doc) => {
  const res = crearDocumentoAutoID(`temas/${idTema}/categorias/`, doc);
  if (res === ERROR)
    return {
      estado: ERROR,
      mensaje: "No se pudo crear la categoria. Intentelo de nuevo.",
    };
  return {
    estado: OK,
    mensaje: "Se creo exitosamente la categoria.",
  };
};

export const eliminarTema = async (idTema, categorias) => {
  if (categorias && categorias.length > 0) {
    return {
      estado: ERROR,
      mensaje:
        "El tema tiene categorias creadas, borrelas primero e intentelo de nuevo.",
    };
  } else {
    let res = await eliminarDocumento(`temas/${idTema}`);
    if (res === ERROR)
      return {
        estado: ERROR,
        mensaje: "No se pudo eliminar el tema. Intentelo de nuevo.",
      };
    try {
      await eliminarArchivo(`temas/${idTema}/imagen`);
    } catch (error) {}

    return {
      estado: OK,
      mensaje: "Se elimino exitosamente el tema.",
    };
  }
};

export const editarTema = async (idTema, doc) => {
  let res = "";
  if (doc.imagen?.name) {
    res = await subirArchivo(`temas/${idTema}/imagen`, doc.imagen);
    if (res === ERROR) {
      return {
        estado: ERROR,
        mensaje: "No se pudo editar el tema. Intentelo de nuevo.",
      };
    }
    doc = { ...doc, imagen: res };
  }
  res = await editarDocumento(`temas/${idTema}`, doc);
  if (res === ERROR)
    return {
      estado: ERROR,
      mensaje: "No se pudo editar la categoria. Intentelo de nuevo.",
    };
  return {
    estado: OK,
    mensaje: "Se edito exitosamente la categoria.",
  };
};

export const crearTema = async (doc) => {
  let temaNuevo = {
    ...doc,
    imagen: "",
  };

  let res = await crearDocumentoAutoID(`temas/`, temaNuevo);
  if (res === ERROR) {
    return {
      estado: ERROR,
      mensaje: "Hubo un error creando el tema. Intentelo de nuevo.",
    };
  }

  let idTema = res.id;

  if (doc.imagen?.name) {
    res = await subirArchivo(`temas/${idTema}/imagen`, doc.imagen);
    if (res === ERROR) {
      return {
        estado: ERROR,
        mensaje:
          "Hubo un error subiendo la imagen del tema. Intentelo de nuevo editando el tema creado.",
      };
    }
    doc = { ...doc, imagen: res };
  }
  res = await editarDocumento(`temas/${idTema}`, doc);
  if (res === ERROR)
    return {
      estado: ERROR,
      mensaje: "No se pudo crear el tema. Intentelo de nuevo.",
    };
  return {
    estado: OK,
    mensaje: "Se creo exitosamente el tema.",
  };
};
