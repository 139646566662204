import React from "react";
import PropTypes from "prop-types";

const DialogoUnBotonPerfil = ({ titulo, descripcion, textoBoton, funcion }) => {
  // console.log({ titulo, descripcion, textoBoton, funcion });
  const ajustarTexto = (texto) => {
    return texto.split("\n").map((t, i) => {
      return (
        <span key={`tx-${i}`}>
          {t}
          <br />
        </span>
      );
    });
  };

  return (
    <div className="fixed w-screen h-screen inset-0 flex justify-center items-center bg-pink-500/40 z-[100]">
      <div
        className="bg-black/5 w-screen h-screen absolute left-0 top-0"
        onClick={funcion}
      />
      <div className="bg-white py-10 px-16 flex flex-col justify-between rounded-md text-rosa-darkBlue relative w-4/5 max-w-[600px]">
        <div className="text-3xl font-bold text-center">
          <h4>{titulo}</h4>
        </div>
        <div className="my-10 text-xl flex justify-center items-center">
          <span className="text-center">{ajustarTexto(descripcion)}</span>
        </div>
        <div className="flex flex-col">
          <button
            className="btn--primary mx-auto hover:shadow-button w-full"
            onClick={funcion}
          >
            {textoBoton}
          </button>
        </div>
      </div>
    </div>
  );
};

DialogoUnBotonPerfil.propTypes = {
  titulo: PropTypes.string.isRequired,
  descripcion: PropTypes.string.isRequired,
  textoBoton: PropTypes.string.isRequired,
  funcion: PropTypes.func.isRequired,
};

export default DialogoUnBotonPerfil;
