import { QUIEN_ASUME_IVA } from "../../../../utilidades/Constantes"
import BeforeNextButtons from "./BeforeNextButtons"

const Porcentajes = ({currentStep, setCurrentStep, crearRegaliaData, setCrearRegaliaData}) => {

    // let validPercentages = crearRegaliaData.porcentajeArtista + crearRegaliaData.porcentajeBlackLion === 100;

    const handleChange = (e) => {
        if(e.target.name === "porcentajeArtista" || e.target.name === "porcentajeBlackLion") {
            const nuevoPorcentaje = parseFloat(e.target.value) > 100 ? 100 : parseFloat(e.target.value);
            setCrearRegaliaData({
                ...crearRegaliaData,
                [e.target.name]: nuevoPorcentaje,
                [e.target.name === "porcentajeArtista" ? "porcentajeBlackLion" : "porcentajeArtista"]: 100 - nuevoPorcentaje
            });
        } else {
            setCrearRegaliaData({
                ...crearRegaliaData,
                [e.target.name]: parseFloat(e.target.value) > 100 ? 100 : parseFloat(e.target.value) 
            });
        }
    }

    return <>
        <div className="grid grid-cols-2 gap-4 w-[400px] mt-3 font-normal">
            <span>Porcentaje artista:</span>
            <input onChange={handleChange} type="number" min={0} max={100} name="porcentajeArtista" value={crearRegaliaData.porcentajeArtista} className="focus:border-rosa-primariy border-2 ml-5 rounded-md outline-none"/>
            <span>Porcentaje BlackLion:</span>
            <input onChange={handleChange} type="number" min={0} max={100} name="porcentajeBlackLion" value={crearRegaliaData.porcentajeBlackLion} className="focus:border-rosa-primariy border-2 ml-5 rounded-md outline-none"/>
            <span>Porcentaje IVA:</span>
            <input onChange={handleChange} type="number" min={0} max={100} name="porcentajeIVA" value={crearRegaliaData.porcentajeIVA} className="focus:border-rosa-primariy border-2 ml-5 rounded-md outline-none"/>
        </div>

        <div onChange={handleChange} className="flex flex-col justify-start items-start w-full mt-4">
            <h5 className="font-bold text-base">
                ¿Quién asume el IVA?
            </h5>
            <div className='flex items-center justify-start mt-2 font-normal'>
                <input defaultChecked type="radio" id="artista" name="asumeIVA" value={QUIEN_ASUME_IVA.ARTISTA} className='accent-pink-300 focus:accent-pink-500'/>
                &nbsp;
                <label className='ml-2' htmlFor="artista">Artista</label>
            </div>
            <div className='flex items-center justify-start font-normal'>
                <input type="radio" id="blacklion" name="asumeIVA" value={QUIEN_ASUME_IVA.BLACK_LION} className='accent-pink-300 focus:accent-pink-500'/>
                &nbsp;
                <label className='ml-2' htmlFor="blacklion">BlackLion</label>
            </div>
        </div>

        <BeforeNextButtons 
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}/>
    </>
}

export default Porcentajes;