import React from "react";
import PropTypes from "prop-types";

const DialogoUnBoton = ({
  titulo,
  descripcion,
  boton1,
  click1,
  cerrar,
  boton2,
  click2,
}) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-20">
      <div
        className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-40 z-[-1]"
        onClick={() => cerrar()}
      />
      <div className="flex flex-col py-10 px-8 bg-white rounded-[20px] text-black items-center max-w-[80%] sm:min-w-[595px]">
        <h2 className="text-xl sm:text-2xl text-center font-bold font-proxima max-w-full">
          {titulo}
        </h2>
        <p className="text-xl font-semibold font-proxima mt-2 max-w-full text-center">
          {descripcion}
        </p>
        <div className="w-[80%] max-w-[200px] sm:max-w-[400px] sm:w-[80%] flex justify-evenly flex-col-reverse sm:flex-row mt-8">
          <button
            className="text-sm py-1 px-8 mt-5 sm:mt-0 font-semibold text-white bg-[#E84A84] border-[#E84A84]  border-2 rounded-md outline-none"
            onClick={() => click1()}
          >
            {boton1}
          </button>
          {boton2 && click2 && (
            <button
              className="text-sm py-1 px-8 font-semibold text-[#E84A84] border-[#E84A84] border-2 rounded-md outline-none"
              onClick={() => click2()}
            >
              {boton2}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

DialogoUnBoton.propTypes = {
  titulo: PropTypes.string.isRequired,
  descripcion: PropTypes.string,
  boton1: PropTypes.string.isRequired,
  click1: PropTypes.func.isRequired,
  boton2: PropTypes.string,
  click2: PropTypes.func,
  cerrar: PropTypes.func.isRequired,
};

export default DialogoUnBoton;
