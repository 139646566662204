import React from "react";
import handAcademia from "../../../../../assets-2/mano-academia.png";

const HeroAcademia = () => {
  return (
    <section className="font-proxima flex flex-col md:flex-row lg:flex-row justify-evenly items-center flex-wrap mt-8">
      <div className="flex flex-col lg:flex-row justify-around items-center w-full space-y-10">
        <div className="max-w-sm flex flex-col text-center items-center md:text-left md:place-items-start space-y-5 gap-y-2 md:gap-y-3 md:space-y-8 mt-3">
          <h1 className="w-4/5 md:w-full text-3xl md:text-4xl lg:text-5xl font-semibold  leading-11 font-proxima">
            Academia <span className="text-rosa-primariy">BLACKLION</span>{" "}
          </h1>
          <p className="w-9/12 md:w-full  text-base md:text-lg">
            Un espacio en donde aprenderás a través del formato Masterclass para
            que te capacites y estés a la vanguardia de todo lo que rodea la
            nueva era digital de los artistas.
          </p>
        </div>
        <div className="max-w-sm mx-auto md:m-0 flex justify-center items mt-7">
          <img
            src={handAcademia}
            alt="ilustracion 3d"
            className="w-3/4 md:w-full mx-auto md:m-0"
          />
        </div>
      </div>
      <h3 className="w-full text-center text-3xl md:text-4xl lg:text-5xl font-semibold my-10">
        Aprendizaje <span className="text-rosa-primariy">Continuo</span>
      </h3>
    </section>
  );
};

export default HeroAcademia;
