import React, { useState } from "react";
// import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { ERROR, ERRORES_REGISTRO } from "../../../../utilidades/Constantes";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useDispatch } from "react-redux";
import { registrarArtista } from "../../../../actions/adminActions";

const AgregarArtista = ({handleCerrarDialogo}) => {
  const [state, setState] = useState({
    correo: "",
    password: "",
    confirmed_password: "",
    error: "",
    cargando: false,
  });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (state.password !== state.confirmed_password) {
      setState({
        ...state,
        error: "La contraseña y su confirmación no coinciden",
      });
      return;
    }

    setState({ ...state, cargando: true });
    let res = await registrarArtista(state, dispatch);

    if (res.resultado === ERROR) {
      setState({
        ...state,
        error: ERRORES_REGISTRO[res.code] ?? res.message,
        cargando: false,
      });
    } else {
      setState({
        ...state,
        error: "",
        cargando: false,
      });
      handleCerrarDialogo();
    }
  };

  if (state.cargando)
      return (
        <div className="dialogo-agregar-artista">
          <ScaleLoader
            id="loading-indicator"
            height={35 * 2}
            width={4 * 2}
            color={"#E87096"}
          />
        </div>
      );

      return (
        <form
          onSubmit={handleSubmit}
          className="flex flex-col py-10 px-10 bg-white rounded-[20px] max-w-full"
        >
          <div className="flex justify-between items-center mb-5">
            <h2 className="font-bold text-xl text-black font-proxima">
              Agregar Artista
            </h2>
            <button className="underline text-[#E84A84] text-[17px] cursor-pointer">
              Guardar
            </button>
          </div>
          <div className="flex flex-col font-bold text-xl text-black mt-8 font-proxima">
            Correo
          </div>
          <input
            name="correo"
            onChange={handleChange}
            type="email"
            className="w-[440px] border-b-[1px] border-[#BBB] placeholder-[#BBB] mt-4 outline-none text-black max-w-full"
            placeholder="Correo electrónico"
            required
          ></input>
          <div className="flex flex-col font-bold text-xl text-black mt-8 font-proxima max-w-full">
            Contraseña
          </div>
          <input
            name="password"
            onChange={handleChange}
            type="password"
            className="w-[440px] border-b-[1px] border-[#BBB] placeholder-[#BBB] mt-4 outline-none text-black max-w-full"
            placeholder="Contraseña"
            minLength="6"
            required
          ></input>
          <div className="flex flex-col font-bold text-xl text-black mt-8 font-proxima max-w-full">
            Confirmar contraseña
          </div>
          <input
            name="confirmed_password"
            onChange={handleChange}
            type="password"
            className="w-[440px] border-b-[1px] border-[#BBB] placeholder-[#BBB] mt-4 outline-none text-black max-w-full"
            placeholder="Confirmar contraseña"
            minLength="6"
            required
          ></input>
          <div className="error-formulario-registro">{state.error}</div>
        </form>
      );

}

export default withRouter(AgregarArtista);
