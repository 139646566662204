import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "./../../../../actions/academiaActions";
import { getTemaActual } from "../../../../reducers/academiaReducer";
import { bindActionCreators } from "redux";
import { useHistory, useParams, withRouter } from "react-router-dom";
import IconoAgregar from "../../../general/IconoAgregar";
import IconoEditar from "../../../general/IconoEditar";
import IconoEliminar from "../../../general/IconoEliminar";
import { useState } from "react";
import { ERROR } from "../../../../utilidades/Constantes";
import ScaleLoader from "react-spinners/ScaleLoader";
import DialogoEditar from "../../../general/DialogoEditar";
import DialogoUnBoton from "../../../general/DialogoUnBoton";
import EditarTema from "./EditarTema";

const DetalleTema = ({ tema, getDBTemaConCategorias }) => {
  const history = useHistory();
  const { id } = useParams();

  const [cargando, setCargando] = useState(false);
  const [nuevaCategoria, setNuevaCategoria] = useState("");
  const [dialogo, setDialogo] = useState({});

  useEffect(() => {
    if (!tema) getDBTemaConCategorias(id);
    else if ((tema.existe !== undefined) & !tema.existe)
      history.push("/administrador/academia");
    else if (!tema.categorias) getDBTemaConCategorias(id);
  }, [tema, getDBTemaConCategorias]);

  const handleEditarTema = async (nuevoTema) => {
    setCargando(true);
    const res = await actions.editarTema(id, nuevoTema);
    if (res.estado === ERROR) {
      setDialogo({
        titulo: res.estado,
        mensaje: res.mensaje,
        boton1: "Regresar",
        click1: () => setDialogo({}),
      });
    }
    setCargando(false);
  };

  const handleCrearCategoria = async () => {
    setCargando(true);
    const res = await actions.crearCategoria(id, { nombre: nuevaCategoria });
    if (res.estado === ERROR) {
      setDialogo({
        titulo: res.estado,
        mensaje: res.mensaje,
        boton1: "Regresar",
        click1: () => setDialogo({}),
      });
    }
    setCargando(false);
  };

  const handleEliminarTema = async () => {
    setCargando(true);
    const res = await actions.eliminarTema(id, tema.categorias);
    if (res.estado === ERROR) {
      setDialogo({
        titulo: res.estado,
        mensaje: res.mensaje,
        boton1: "Regresar",
        click1: () => setDialogo({}),
      });
      setCargando(false);
    } else history.push(`/administrador/academia`);
  };
  return (
    <div className="container w-10/12 mx-auto my-5 font-proxima">
      {cargando && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-30">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
        </div>
      )}
      {dialogo === "editar-tema" && (
        <EditarTema
          tema={tema}
          handleGuardar={handleEditarTema}
          handleCancelar={() => setDialogo({})}
        />
      )}
      {dialogo === "crear-categoria" && (
        <DialogoEditar
          habilitado={nuevaCategoria}
          handleGuardar={() => handleCrearCategoria()}
          handleCancelar={() => setDialogo({})}
        >
          <div className="flex flex-col ml-1">
            <h3 className="font-bold text-3xl text-black sm:mt-8 font-proxima">
              Nombre de la categoria
            </h3>
            <input
              type="text"
              value={nuevaCategoria}
              onChange={(e) => setNuevaCategoria(e.target.value)}
              placeholder="Nombre de la categoria"
              className="w-[440px] border-b-[1px] border-[#BBB] placeholder-[#BBB] mt-11 outline-none text-black max-w-full"
            />
          </div>
        </DialogoEditar>
      )}
      {dialogo.titulo && (
        <DialogoUnBoton
          titulo={dialogo.titulo}
          descripcion={dialogo.mensaje}
          boton1={dialogo.boton1}
          click1={() => dialogo.click1()}
          boton2={dialogo.boton2}
          click2={() => dialogo.click2()}
          cerrar={() => setDialogo({})}
        />
      )}
      {tema && (
        <>
          <div className="flex ml-3">
            <span
              className="font-normal text-xl cursor-pointer"
              onClick={() => history.push("/administrador/academia")}
            >
              Temas
            </span>
            <span className="font-normal mx-5 text-xl">{">"}</span>
            <span className="font-semibold text-xl text-[#E84A84]">
              {tema?.nombre}
            </span>
          </div>
          <div className="flex flex-col sm:flex-row mt-7">
            <div className="w-full sm:w-1/2">
              <div>
                <h1 className="text-[52px] text-[#E84A84] font-bold leading-[55px] w-8/12">
                  <span className="text-white">
                    {tema?.nombre?.split(" ")[0] + " "}
                  </span>
                  {tema?.nombre?.split(" ").slice(1)?.join(" ")}
                </h1>
              </div>
              <div className="flex items-center mt-5">
                <IconoEditar onClick={() => setDialogo("editar-tema")} />
                <IconoEliminar
                  onClick={() =>
                    setDialogo({
                      titulo: "¿Seguro que quiere eliminar este tema?",
                      boton1: "Eliminar",
                      click1: () => handleEliminarTema(),
                      boton2: "Cancelar",
                      click2: () => setDialogo({}),
                    })
                  }
                />
              </div>
              <p className="mt-8 text-xl">{tema?.descripcion}</p>
            </div>
            <img
              src={tema?.imagen}
              alt=""
              className="w-8/12 h-auto object-cover mt-10 sm:mt-0 sm:w-1/4 mx-auto"
            />
          </div>
          <h2 className="text-[52px] font-bold leading-[55px] mt-10">
            Categorias
          </h2>
          <div className="mt-10 sm:ml-5 flex items-center">
            <span className="text-[26px] mr-5">Crear una nueva categoria</span>
            <IconoAgregar onClick={() => setDialogo("crear-categoria")} />
          </div>
          <div className="mt-10 sm:ml-28 mb-10">
            {tema?.categorias?.map((categoria) => (
              <div
                className="w-[757px] max-w-full flex justify-between border-b-2 pb-6 mb-7"
                key={categoria.uidDoc}
              >
                <span className="text-[26px]">{categoria.nombre}</span>
                <div className="flex items-center">
                  <div className="flex items-center">
                    <IconoEditar
                      onClick={() =>
                        history.push(
                          "/administrador/academia/" +
                            id +
                            "/categoria/" +
                            categoria.uidDoc
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  tema: getTemaActual(state, props.match.params.id),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetalleTema)
);
