import {
  SESION,
  UID_USER,
  CLEAN_USER,
  HISTORIAL,
  SOLICITAR_PAGO,
  TRAER_LOGROS,
} from "../utilidades/Constantes";
import { createSelector } from "reselect";
import { EDITAR_INFO_BANCARIA } from "../actions/usersActions";
const INITIAL_STATE = {
  uidUser: null,
  user: null,
  repertorio: null,
  historial: null,
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UID_USER:
      return { ...state, uidUser: action.payload };
    case SESION:
      return {
        ...state,
        user: action.payload,
      };
    case CLEAN_USER:
      return {
        ...state,
        uidUser: null,
        user: {},
        repertorio: null,
        historial: null,
      };
    case HISTORIAL:
      return { ...state, historial: action.payload };
    case SOLICITAR_PAGO:
      return {
        ...state,
        user: { ...state.user, pagoSolicitado: action.payload },
      };
    case TRAER_LOGROS:
      return { ...state, user: { ...state.user, logros: action.payload } };
    case EDITAR_INFO_BANCARIA:
      return { ...state, user: { ...state.user, ...action.payload }}
    default:
      return { ...state };
  }
};

export const getUser = createSelector(
  (state) => state.sesionReducer,
  (sesionReducer) => sesionReducer.user
);
export const getUid = createSelector(
  (state) => state.sesionReducer,
  (sesionReducer) => sesionReducer.uidUser
);
export const getAlbumes = createSelector(
  (state) => state.sesionReducer,
  (sesionReducer) => sesionReducer.user?.albumes
);
export const getRepertorio = createSelector(
  (state) => state.sesionReducer,
  (sesionReducer) => sesionReducer.repertorio
);
export const getSencillos = createSelector(
  (state) => state.sesionReducer,
  (sesionReducer) => sesionReducer.user?.canciones
);
export const getHistorial = createSelector(
  (state) => state.sesionReducer,
  (sesionReducer) => sesionReducer.historial
);
export const getLogros = createSelector(
  (state) => state.sesionReducer,
  (sesionReducer) => sesionReducer.user?.logros
);
