import React from "react";

export const terminosHtml = (
  <div>
    <p>
      <strong className="text-rosa-primariy">BLACK LION DIGITAL S.A.S.</strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Bienvenido a <strong>BLACK LION DIGITAL S.A.S.</strong>, nos complace
      ofrecerle acceso a la plataforma, la cual prestar&aacute; los servicios
      que se describir&aacute;n en el presente documento, estando sujeto a los
      siguientes T&eacute;rminos y Condiciones. Se entiende que usted ha
      expresado su consentimiento, voluntario, excento de vicios, previo e
      informado, de los T&eacute;rminos y Condiciones al momento de registrarse,
      acceder, aceptar estos T&eacute;rminos y Condiciones&nbsp; y utilizar la
      plataforma.&nbsp; Si no est&aacute; de acuerdo con los T&eacute;rminos y
      Condiciones que aqu&iacute; se describen, le recomendamos no hacer click
      en acepto T&eacute;rminos y Condiciones y no hacer uso de la plataforma.
    </p>
    <p>&nbsp;</p>
    <p>
      Se recomienda leer todos los t&eacute;rminos que aqu&iacute; se incluyen,
      ya que{" "}
      <strong className="text-rosa-primariy">BLACK LION DIGITAL S.A.S.</strong>{" "}
      se exime de cualquier responsabilidad derivada del desconocimiento del
      contenido del presente documento.
    </p>
    <p>&nbsp;</p>
    <ol className="list-decimal">
      <li className="text-rosa-primariy">
        <strong className="text-rosa-primariy">
          GENERALIDADES Y DESCRIPCI&Oacute;N DE LOS SERVICIOS PROPORCIONADOS POR
          BLACK LION DIGITAL S.A.S A TRAV&Eacute;S DE LA PLATAFORMA
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      A continuaci&oacute;n, describiremos los servicios proporcionados por{" "}
      <strong className="text-rosa-primariy">BLACK LION DIGITAL S.A.S.</strong>{" "}
      a trav&eacute;s de la presente plataforma digital.
    </p>
    <p>&nbsp;</p>
    <ol>
      <li className="list-decimal">
        <strong className="text-rosa-primariy">
          BLACK LION DIGITAL S.A.S.
        </strong>{" "}
        es titular de todos los derechos patrimoniales sobre la plataforma{" "}
        <a href="http://www.blackliondigitalsas.com">blackliondigitalsas.com</a>{" "}
        y por ende, la pone a disposici&oacute;n los usuarios,
        otorg&aacute;ndoles a los titulares de los derechos patrimoniales de
        autor y/o conexos de las obras musicales un espacio en la plataforma
        mediante el cual podr&aacute; acceder a los soportes de
        recaudaci&oacute;n de regal&iacute;as por la explotaci&oacute;n de las
        obras en el entorno digital y la entrega del pago pactado en el contrato
        de mandato suscrito entre <strong>BLACK LION DIGITAL S.A.S. </strong>y
        el usuario de la plataforma.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol className="list-decimal" start="2">
      <li>
        Se entender&aacute;n por usuarios, aquellas personas naturales y/o
        jur&iacute;dicas que hayan suscrito el contrato de mandato
        otorg&aacute;ndole facultades extraordinarias a{" "}
        <strong>BLACK LION DIGITAL S.A.S. </strong>para:
      </li>
    </ol>
    <p>&nbsp;</p>
    <ul className="list-inside">
      <li>
        Distribuir, reproducir, comercializar y poner a disposici&oacute;n las
        obras musicales, audiovisuales, los fonogramas y/o videogramas de
        titularidad del usuario<strong>,</strong> en todas las plataformas
        digitales y/o p&aacute;ginas web, tales como Spotify, Youtube, Dezeer,
        Apple Music, y las dem&aacute;s que existan en el mundo, y en las que se
        est&aacute;n creando o las que se lleguen a crear.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul className="list-inside">
      <li>
        Acceder a las redes sociales del usuario con el fin de distribuir,
        reproducir, comercializar y poner a disposici&oacute;n las obras
        musicales, audiovisuales, los fonogramas y/o videogramas en los medios
        digitales, entorno digital o cualquier medio que pueda existir y que
        cumpla con las caracter&iacute;sticas de las anteriores.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>
        Ceder, licenciar y sublicenciar las obras musicales, audiovisuales, los
        fonogramas y/o videogramas de titularidad del usuario.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>
        Gestionar y recaudar las regal&iacute;as por la reproducci&oacute;n de
        las obras musicales, audiovisuales, los fonogramas y/o videogramas en
        los medios digitales, siempre que los derechos patrimoniales y conexos
        sean de titularidad del usuario.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>
        Presentar reclamaciones a las plataformas digitales, redes sociales y/o
        p&aacute;ginas web, cuando se vean vulnerados los derechos patrimoniales
        de autor y/o conexos del usuario, por la reproducci&oacute;n,
        distribuci&oacute;n, puesta a disposici&oacute;n y/o
        transformaci&oacute;n de las obras musicales, audiovisuales, los
        fonogramas y/o videogramas en los medios digitales, sin previa
        autorizaci&oacute;n del usuario.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>
        Negociar, transigir, conciliar, requerir y/o demandar a las personas
        naturales y/o jur&iacute;dicas que vulneren los derechos patrimoniales
        de autor y/o conexos del usuario, mediante la explotaci&oacute;n de las
        obras musicales, audiovisuales, los fonogramas y/o videogramas en los
        medios digitales.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>
        Contratar a los asesores legales que puedan representar al usuario en
        futuros pleitos judiciales y/o extrajudiciales, para buscar garantizar
        la protecci&oacute;n de los derechos patrimoniales autor y/o conexos que
        puedan llegar a ser vulnerados por terceros.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>
        Usar la(s) marca(s) del usuario en los medios digitales con el fin de
        comercializar las obras musicales, audiovisuales, los fonogramas y/o
        videogramas en los medios digitales.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ol start="3">
      <li>
        El usuario que haya suscrito el contrato mencionado anteriormente
        recibir&aacute; un correo electr&oacute;nico mediante el cual se le
        asignar&aacute; un usuario y una clave. Una vez recibida dicha
        informaci&oacute;n por parte del usuario, se transmitir&aacute; la
        responsabilidad de custodia de la informaci&oacute;n a este usuario y
        dejar&aacute; indemne a <strong>BLACK LION DIGITAL S.A.S. </strong>por
        la p&eacute;rdida o mal uso de esta.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="4">
      <li>
        El usuario podr&aacute; incluir la cuenta de ahorros o corriente de
        cualquier entidad bancaria, con el fin de que{" "}
        <strong>BLACK LION DIGITAL S.A.S. </strong>entregue las regal&iacute;as
        recuadadas de acuerdo con los porcentajes acordados en el contrato de
        mandato. <strong>BLACK LION DIGITAL S.A.S. </strong>se exime de
        cualquier responsabilidad si la cuenta bancaria ingresada es de un
        titular distinto al usuario que suscribi&oacute; el contrato de mandato.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      As&iacute; mismo, el usuario podr&aacute; cambiar la cuenta registrada
      cuantas veces sea necesario y recibir&aacute; una notificaci&oacute;n al
      correo electr&oacute;nico vinculado con la cuenta, conducta que exime de
      responsabilidad a <strong>BLACK LION DIGITAL S.A.S.</strong> de cualquier
      actuaci&oacute;n ilegal e ilegitima que puedan realizar terceros en
      perjuicio del usuario.
    </p>
    <p>&nbsp;</p>
    <ol start="5">
      <li>
        <strong>BLACK LION DIGITAL S.A.S. </strong>entregar&aacute; un informe
        trimestral mediante el cual se discriminar&aacute;n los valores
        recaudados, los medios digitales que reconocieron y pagaron las
        regal&iacute;as y los conceptos del dinero pagado por los medios
        digitales.
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Ante cualquier discreci&oacute;n que surta respecto a dicho informe, el
      usuario tendr&aacute; el derecho de solicitar la explicaci&oacute;n
      correspondiente a <strong>BLACK LION DIGITAL S.A.S., </strong>en los
      t&eacute;rminos contenidos en estos T&eacute;rminos y Condiciones.
    </p>
    <p>&nbsp;</p>
    <ol start="6">
      <li>
        Las regal&iacute;as solicitadas por el usuario se entregar&aacute;n en
        un t&eacute;rmino de cuarenta y cinco (45) d&iacute;as h&aacute;biles,
        contadas a partir de la presentaci&oacute;n de la solicitud o posterior
        al env&iacute;o de la respuesta a las reclamaciones que podr&iacute;an
        ser presentadas por el usuario.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>
          PERMISO PARA UTILIZAR LA PLATAFORMA; RESERVA DE DERECHOS.
        </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Al aceptar estos T&eacute;rminos y Condiciones, se le concede a usted un
      derecho limitado, no exclusivo, intransferible y no sub-licenciable, para
      entrar, acceder y utilizar la plataforma exclusivamente para solicitar la
      entrega de las regal&iacute;as recaudadas por{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong>
      <strong>, </strong>los soportes mediante los cuales se discriminan los
      valores y conceptos de las regal&iacute;as pagadas por los medios
      digitales y la vinculaci&oacute;n de la cuenta bancaria para realizarse la
      entrega de las regal&iacute;as recaudadas. &nbsp;Todos los derechos que no
      se le conceden expresamente a usted en estos T&eacute;rminos y
      Condiciones, est&aacute;n reservados para{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> &nbsp;y sus licenciadores,
      seg&uacute;n corresponda. Es por esto que usted acepta que este permiso es
      para su uso personal, raz&oacute;n por la cual se considerar&aacute;
      vulnerado el derecho cuando personas ajenas a usted hagan uso de las
      facultades que la plataforma les otorga.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      As&iacute; mismo, usted no tiene derecho para copiar o reproducir parcial
      o totalmente el contenido y funcionamiento de la plataforma, ya que se
      encuentran limitados por lo establecido en las leyes y tratados
      internacionales, ratificados por Colombia, correspondientes al derecho de
      autor y propiedad intelectual, como se desarrollar&aacute; m&aacute;s
      adelante.
    </p>
    <p>&nbsp;</p>
    <ul>
      <li>
        <strong className="text-rosa-primariy text-xl">RESTRICCIONES</strong>
      </li>
    </ul>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>Normas y pol&iacute;ticas de BLACK LION DIGITAL S.A.S.</strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      Como consecuencia del acceso y utilizaci&oacute;n de la plataforma, el
      usuario autoriza expresamente a <strong>BLACK LION DIGITAL S.A.S.</strong>{" "}
      de hacer uso de su informaci&oacute;n personal, tales como correo
      electr&oacute;nico, cuenta bancaria, n&uacute;mero de
      identificaci&oacute;n (c&eacute;dula de ciudadan&iacute;a,
      extranjer&iacute;a o n&uacute;mero de pasaporte), nombre, apellido y
      nombre art&iacute;stico, con la finalidad de tener un primer acercamiento
      entre el usuario y <strong>BLACK LION DIGITAL S.A.S.</strong>. Lo anterior
      con el prop&oacute;sito de que <strong>BLACK LION DIGITAL S.A.S.</strong>{" "}
      pueda garantizar la autenticidad de las personas que ya hab&iacute;an
      suscrito el contrato de mandato con la sociedad y as&iacute; mismo,
      garantizar la entrega de las regal&iacute;as de manera r&aacute;pida y
      eficiente. De esa manera, <strong>BLACK LION DIGITAL S.A.S.</strong> le
      estar&iacute;a dando cumplimiento a los establecido en el contrato
      suscrito entre el usuario y la sociedad.
    </p>
    <p>&nbsp;</p>
    <p>
      En el mismo sentido, el usuario autoriza a{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> a:
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        Actualizar, modificar e instalar el software, de manera
        autom&aacute;tica o requerida, entre otras razones para abordar
        cuestiones de seguridad, interoperabilidad y/o funcionamiento. Estas
        actualizaciones, modificaciones y operaciones similares pueden
        realizarse en forma peri&oacute;dica o cuando sea necesario, sin previo
        aviso.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol>
      <li>
        Crear un protocolo de notificaci&oacute;n al usuario para informar las
        siguientes circunstancias:
      </li>
    </ol>
    <p>&nbsp;</p>
    <ul>
      <li>
        La modificaci&oacute;n de la cuenta bancaria que ha sido vinculada en la
        plataforma.
      </li>
      <li>
        La confirmaci&oacute;n de la recepci&oacute;n de la solicitud de la
        entrega de las regal&iacute;as.
      </li>
      <li>
        La confirmaci&oacute;n de la consignaci&oacute;n de las regal&iacute;as
        recaudadas por <strong>BLACK LION DIGITAL S.A.S.</strong>
      </li>
      <li>
        La notificaci&oacute;n de la recepci&oacute;n de las reclamaciones a las
        que haya lugar.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ol>
      <li>
        Vender, sublicenciar y publicar los datos personales siempre que se
        encuentren en relaci&oacute;n directa y/o indirecta con los servicios
        proporcionados por <strong>BLACK LION DIGITAL S.A.S.</strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol>
      <li>
        Hacer uso de los datos personales para radicar, recibir, pagar,
        sustituir, desistir, renunciar, reasumir, transigir y todas aquellas que
        tiendan al buen cumplimiento de la prestaci&oacute;n del servicio de{" "}
        <strong>BLACK LION DIGITAL S.A.S.</strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <strong>BLACK LION DIGITAL S.A.S.</strong> deber&aacute; ce&ntilde;irse a
      lo establecido en la Ley 1581 de 2012 y el Decreto 1377 de 2013, para dar
      el adecuado tratamiento de los datos personales, tal y como se muestra a
      continuaci&oacute;n:
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>
          Obligaciones de los responsables del tratamiento de datos
        </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ul>
      <li>
        Garantizar al usuariio el uso efectivo, plena y en todo tiempo de los
        derechos de h&aacute;beas data;
      </li>
      <li>
        Impedir la adulteraci&oacute;nde la informaci&oacute;n, p&eacute;rdida,
        consulta, uso o acceso no autorizado o fraudulento;
      </li>
      <li>
        Respetar las condiciones de seguridad y privacidad de la
        informaci&oacute;n del usuario;
      </li>
      <li>
        Informar a la autoridad de protecci&oacute;n de datos, Superintendencia
        de Industria y Comercio (SIC), cuando se presenten violaciones a los
        c&oacute;digos de seguridad y existan riesgos en la
        administraci&oacute;n de la informaci&oacute;n de los usuarios.
      </li>
      <li>
        Cumplir las instrucciones y requerimientos que imparta la
        Superintendencia de Industria y Comercio.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>Obligaciones de los encargados del tratamiento de datos</strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <ul>
      <li>
        Garantizar al usuariio el uso efectivo, plena y en todo tiempo de los
        derechos de h&aacute;beas data;
      </li>
      <li>
        Impedir la adulteraci&oacute;nde la informaci&oacute;n, p&eacute;rdida,
        consulta, uso o acceso no autorizado o fraudulento;
      </li>
      <li>
        Abstenerse de circular informaci&oacute;n que est&eacute; siendo
        controvertida por el usuario y cuyo bloqueo haya sido ordenado por la
        Superintendencia de Industria y Comercio;
      </li>
      <li>
        Permitir el acceso a la informaci&oacute;n &uacute;nicamente a las
        personas que pueden tener acceso a ella;
      </li>
      <li>
        Informar a la Superintendencia de Industria y Comercio cuando se
        presenten violaciones a los c&oacute;digos de seguridad y existan
        riesgos en la administraci&oacute;n de la informaci&oacute;n de los
        Titulares;
      </li>
      <li>
        Cumplir las instrucciones y requerimientos que imparta la
        Superintendencia de Industria y Comercio.
      </li>
    </ul>
    <p>&nbsp;</p>
    <p>
      En ese sentido, <strong>BLACK LION DIGITAL S.A.S.</strong> se exime de
      cualquier responsabilidad derivada del incumplimiento de alguna de sus
      obligaciones tendientes a la protecci&oacute;n y tratamiento de datos,
      siempre que no exista plena prueba que demuestre el da&ntilde;o ocasionado
      al cliente o que este &uacute;ltimo haya ocasionado su propio perjuicio de
      manera dolosa o culposa.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>BLACK LION DIGITAL S.A.S.</strong>
      <strong>, </strong>tambi&eacute;n deber&aacute; cumplir con las anteriores
      obligaciones cuando terceros que no tengan calidad de usuarios, soliciten
      informaci&oacute;n acerca de los servicios prestados por{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> o presenten alguna
      reclamaci&oacute;n por la presunta vulneraci&oacute;n de los derechos
      morales y/o patrimoniales de autor y/o conexos.
    </p>
    <p>&nbsp;</p>
    <ol start="2">
      <li>
        <strong>Seguridad de la informaci&oacute;n</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>BLACK LION DIGITAL S.A.S.</strong> se asegurar&aacute; de actuar
      diligentemente para evitar e impedir hurto, fraude, filtraciones, uso
      inadecuado de la información. Para lo anterior, los trabajadores,
      representante legal principal y/o suplente, miembros de la Junta
      Directiva, proveedores o cualquier otra persona que tenga un
      v&iacute;nculo contractual con <strong>BLACK LION DIGITAL S.A.S.</strong>
      <strong>, </strong>deber&aacute;n cumplir con las políticas, normas,
      procedimientos y buenas prácticas de seguridad de la información
      establecidas por <strong>BLACK LION DIGITAL S.A.S.</strong>
    </p>
    <p>&nbsp;</p>
    <p>
      Adicionalmente, se garantizar&aacute; un respaldo de la informaci&oacute;n
      en tiempo real, la cual estar&aacute; disponible para los usuarios cuando
      estos lo requieran.
    </p>
    <p>&nbsp;</p>
    <ol start="3">
      <li>
        <strong>Requisito de edad.</strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      De acuerdo con la Ley 1581 de 2012 en el 2.2.8.4.2.4., se ha establecido
      la prohibici&oacute;n del tratamiento de los datos personales de menores
      de 18 a&ntilde;os o los menores de edad de acuerdo con la
      jurisdicci&oacute;n en la que se encuentre el usuario, sin previa
      autorizaci&oacute;n de su padre, madre o cualquier persona que tenga la
      custodia del menor. En ese sentido, se recomienda que, si usted es menor
      de 18 o menor de edad, y pretende acceder a la plataforma, su padre, madre
      o tutor legal deben aceptar estos T&eacute;rminos y Condiciones. De igual
      forma, se recomienda que los menores de 14 a&ntilde;os, por ser incapaces
      absolutos en nuestra legislaci&oacute;n, o el equivalente en la
      jurisdicci&oacute;n en la que se encuentre el usuario, no proporcionen
      informaci&oacute;n alguna a <strong>BLACK LION DIGITAL S.A.S.</strong>, ya
      sea mediante el uso de la plataforma o a trav&eacute;s de cualquier otra
      actividad con ocasi&oacute;n del desarrollo de la plataforma.
    </p>
    <p>&nbsp;</p>
    <ol start="4">
      <li>
        <strong>Conducta prohibida.</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Se encuentra completamente prohibido para los proveedores, administradores
      y usuarios realizar las siguientes actividades:
    </p>
    <ol>
      <li>
        Alojar en su propio sitio imágenes, textos, documentos o archivos
        audiovisuales que impliquen directa o indirectamente actividades
        sexuales con menores de edad.
      </li>
      <li>
        Alojar en su propio sitio material pornográfico, en especial en modo de
        imágenes o videos, cuando existan indicios de que las personas
        fotografiadas o filmadas son menores de edad.
      </li>
      <li>
        Alojar en su propio sitio vínculos o links, sobre sitios telemáticos que
        contengan o distribuyan material pornográfico relativo a menores de
        edad.
      </li>
      <li>
        Alojar datos personales de los menores de catorce (14) a&ntilde;os o los
        menores de edad de acuerdo con la jurisdicci&oacute;n en la que se
        encuentre el usuario.
      </li>
      <li>
        Alojar los datos personales de los menores de dieciocho (18) a&ntilde;os
        o los menores de edad de acuerdo con la jurisdicci&oacute;n en la que se
        encuentre el usuario, sin previa autorizaci&oacute;n de su pap&aacute;,
        mam&aacute; o quien tenga la custodia del menor.
      </li>
    </ol>
    <p>
      As&iacute; mismo, se encuentra prohibido transmitir, divulgar, transformar
      o poner a disposici&oacute;n p&uacute;blica cualquier contenido o material
      que viole o infrinja derechos de autor, marcas registradas, patentes,
      secretos comerciales y otros derechos de propiedad intelectual, derechos
      de privacidad o publicidad, reglamentos o estatutos de comunicaciones, o
      cualesquiera otras leyes, incluyendo, sin limitaci&oacute;n, las leyes
      sobre difamaci&oacute;n, acoso, obscenidad y pornograf&iacute;a.
      Tambi&eacute;n se prohibe la divulgaci&oacute;n de&nbsp; campa&ntilde;as
      pol&iacute;ticas o solicitudes de venta o marketing o que contengan virus
      inform&aacute;ticos u otro c&oacute;digo de computadora destinado a
      interferir con la funcionalidad de los sistemas de computadoras. Tampoco
      se podr&aacute; interrumpir ni intentar interrumpir la operaci&oacute;n de
      la plataforma de ninguna manera o hacer uso de una direcci&oacute;n de
      correo electr&oacute;nico falso con la finalidad de enga&ntilde;ar a otros
      miembros en cuanto a su identidad o al origen de un mensaje o contenido.
    </p>
    <p>&nbsp;</p>
    <p>
      Es por lo anterior que el uso de la plataforma por parte de los usuarios,
      debe ser adecuada y respetuosa. Cualquier violaci&oacute;n a lo
      aqu&iacute; dispuesto, estar&aacute; sujeta a la revisi&oacute;n de{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> para&nbsp; adoptar las acciones
      legales correspondientes, sin perjuicio de terminar el contrato de mandato
      suscrito entre las partes.
    </p>
    <p>&nbsp;</p>
    <ol start="5">
      <li>
        <strong>
          Restricci&oacute;n sobre el uso relacionado con la plataforma y su
          contenido
        </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      La plataforma incluye componentes de seguridad por lo que se aplican
      normas y pol&iacute;ticas especiales, que determinan que usted no
      deber&aacute; intentar eludir, aplicar ingenier&iacute;a inversa,
      descifrar, descompilar, desmontar o de alg&uacute;n modo modificar,
      alterar o interferir con ning&uacute;n aspecto de la plataforma. En el
      mismo sentido, usted no podr&aacute; distribuir, intercambiar, modificar,
      vender o revender, o transmitir a cualquier otra persona cualquier parte
      de la plataforma, incluyendo, sin limitaci&oacute;n, cualquier texto,
      imagen, audio o video para cualquier prop&oacute;sito empresarial,
      comercial o p&uacute;blico. Usted conviene en no copiar, vender,
      distribuir o de alg&uacute;n modo transferir el contenido de&nbsp;la
      plataforma{" "}
      <a href="http://www.blackliondigitalsas.com">
        www.blackliondigitalsas.com
      </a>
      , &nbsp;salvo que exista una licencia que as&iacute; se lo permita. A
      continuaci&oacute;n se decribir&aacute;n las restricci&oacute;n
      correspondientes a la plataforma y al contenido, de manera separada:
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>
          Restricci&oacute;n sobre el uso relacionado con la Cuenta de&nbsp;
        </strong>
        <strong>
          <a href="http://www.blackliondigitalsas.com">
            blackliondigitalsas.com
          </a>
        </strong>
      </li>
    </ol>
    <p>
      <strong>
        <u>&nbsp;</u>
      </strong>
    </p>
    <p>
      La cuenta{" "}
      <a href="http://www.blackliondigitalsas.com">
        www.blackliondigitalsas.com
      </a>
      &nbsp;solo puede ser utilizada por usted y no por ninguna otra persona. Es
      por lo anterior que el usuario exime de responsabilidad alguna a{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> cuando se haya permitido el uso
      de la cuenta por parte de personas distintas al usuario. As&iacute; mismo,
      el usuario adquiere la obligaci&oacute;n de mantener la confidencialidad y
      seguridad de la informaci&oacute;n a la que tiene acceso con
      ocasi&oacute;n al uso de la plataforma que regula estos T&eacute;rminos y
      Condiciones.
    </p>
    <p>&nbsp;</p>
    <p>
      Ser&aacute; obligaci&oacute;n del usuario notificar inmediatamente a{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> cualquier uso no autorizado de
      su contrase&ntilde;a y/o usuario de&nbsp;
      <a href="http://www.blackliondigitalsas.com">
        www.blackliondigitalsas.com
      </a>
      , envi&aacute;ndonos un correo electr&oacute;nico a&nbsp;
      <a href="mailto:contacto@blackliondigitalsas.com">
        contacto@blackliondigitalsas.com
      </a>
      , ya que de lo contrario, <strong>BLACK LION DIGITAL S.A.S.</strong>{" "}
      llevar&aacute; a cabo el siguiente procedimiento con el fin de sancionar
      las extralimitaciones en las que incurrio el usuario:
    </p>
    <p>&nbsp;</p>
    <ul>
      <li>
        Enviar comunicaci&oacute;n escrita al usuario con la finalidad de
        notificar su extralimitaci&oacute;n en el uso de la plataforma.
      </li>
      <li>
        Suspender el ingreso a la plataforma si el usuario sigue incurriendo en
        la misma conducta.
      </li>
      <li>Desvincular al usuario de la plataforma</li>
      <li>
        Terminar el contrato de mandato por la gravedad en las extralimitaciones
        en ls que incurri&oacute; el usuario.
      </li>
    </ul>
    <p>&nbsp;</p>
    <p>
      No habr&aacute; un orden espec&iacute;fico para aplicar el anterior
      procedimiento interno, puesto que esto depender&aacute; de la gravedad de
      las conductas ejecutadas por el usuario.
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>
          Restricci&oacute;n sobre el uso relacionado con el Contenido de&nbsp;
        </strong>
        <strong>
          <a href="http://www.blackliondigitalsas.com">
            blackliondigitalsas.com
          </a>
        </strong>
        <strong>.</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>El usuario no podr&aacute; ejercer los siguientes derechos:</p>
    <p>&nbsp;</p>
    <ul>
      <li>
        Derechos de reproducci&oacute;n o uso promocional en el contenido
        de&nbsp;
        <a href="http://www.blackliondigitalsas.com">blackliondigitalsas.com</a>
      </li>
      <li>
        Derechos para celebrar acuerdos con cualquier sistema de difusi&oacute;n
        que genere ingresos (terrestre, satelital, cable y/u otros canales de
        distribuci&oacute;n),
      </li>
      <li>
        Derechos de distribuir o redistribuir el contenido de&nbsp;
        <a href="http://www.blackliondigitalsas.com">blackliondigitalsas.com</a>
        en aplicaciones de streaming (v&iacute;a Internet, intranets y/u otras
        redes),
      </li>
      <li>
        Derechos de distribuir a otras personas el contenido de&nbsp;
        <a href="http://www.blackliondigitalsas.com">
          blackliondigitalsas.com
        </a>{" "}
        otros sistemas de distribuci&oacute;n de contenido (aplicaciones de
        audio de pago o audio bajo demanda, etc.) o en medios f&iacute;sicos
        (discos compactos, discos digitales vers&aacute;tiles, chips
        semiconductores, discos duros, tarjetas de memoria y similares)
      </li>
      <li>
        Derechos de uso comercial, venta, reventa, reproducci&oacute;n,
        distribuci&oacute;n o promocional del contenido de&nbsp;
        <a href="http://www.blackliondigitalsas.com">blackliondigitalsas.com</a>
        .
      </li>
    </ul>
    <p>&nbsp;</p>
    <p>
      Para no infringir las anteriores restricciones, vale la pena tener en
      cuenta que cualquier soluci&oacute;n de gesti&oacute;n de derechos
      digitales que se proporciona con el contenido de&nbsp;
      <a href="http://www.blackliondigitalsas.com">
        www.blackliondigitalsas.com
      </a>
      &nbsp;es una parte inseparable del mismo, y no podr&aacute; eludirse,
      salvo en la medida permitida por la ley aplicable. Si usted est&aacute; en
      posesi&oacute;n, control y/o uso del contenido de&nbsp;
      <a href="http://www.blackliondigitalsas.com">
        www.blackliondigitalsas.com
      </a>
      , es su responsabilidad no perder, destruir ni da&ntilde;ar dicho
      contenido, comercializar, reproducir o cualquier otra actuaci&oacute;n que
      se asemeje, sin previa autorizaci&oacute;n de{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="6">
      <li>
        <strong>
          Presentaci&oacute;n de la informaci&oacute;n al consumidor
        </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Se deja constancia que <strong>BLACK LION DIGITAL S.A.S.</strong>{" "}
      suministr&oacute; a los usuarios la informaci&oacute;n en castellano,
      clara, veraz, suficiente, oportuna, verificable, comprensible, precisa e
      id&oacute;nea sobre los servicios prestados. As&iacute; mismo,{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> suministr&oacute; la siguiente
      informaci&oacute;n mediante estos t&eacute;rminos y condiciones:
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>Las instrucciones para el correcto uso de la plataforma</li>
      <li>
        Las especificaciones de los servicios prestados a trav&eacute;s de la
        plataforma
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>ENTREGA Y SOLICITUD DE LAS REGAL&Iacute;AS RECAUDADAS</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol>
      <li>
        <strong>Recaudo y entrega de regal&iacute;as</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>BLACK LION DIGITAL S.A.S. </strong>liquidar&aacute;
      trimestralmente las regal&iacute;as recaudadas y presentar&aacute; al
      usuario un informe mediante el cual se discriminan los ingresos, su
      concepto y la plataforma que realiz&oacute; el pago. Posteriormente, el
      usuario podr&aacute; presentar la solicitud de entrega de sus
      regal&iacute;as, las cuales ser&aacute;n consignadas por{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong>, dentro de los cuarenta y cinco
      (45) h&aacute;biles siguientes a la solicitud o del d&iacute;a siguiente a
      la respuesta de la reclamaci&oacute;n presentada por el usuario. El
      anterior dinero se consignar&aacute; en la cuenta de ahorros/corriente que
      fue registrada en la cuenta personal que el usuario tiene en la plataforma{" "}
      <a href="http://www.blackliondigitalsas.com">
        www.blackliondigitalsas.com
      </a>
      .
    </p>
    <p>&nbsp;</p>
    <ol start="2">
      <li>
        <strong>
          Procedimiento para la solicitud de las regal&iacute;as recaudadas por
          BLACK LION DIGITAL S.A.S.
        </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      El usuario deber&aacute; seguir las siguientes intrucciones para solicitar
      la entrega de las regal&iacute;as recuadas por{" "}
      <strong>BLACK LION DIGITAL S.A.S.:</strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol>
      <li>
        Entrar a la p&aacute;gina{" "}
        <a href="http://www.blackliondigitalsas.com">blackliondigitalsas.com</a>{" "}
        y hacer click en el v&iacute;nculo con forma de mu&ntilde;eco con
        audifonos, el cual lo rediccionar&aacute; a la p&aacute;gina en
        d&oacute;nde debe poner el usuario y contrase&ntilde;a que fue enviado
        por <strong>BLACK LION DIGITAL S.A.S. </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol>
      <li>
        Una vez haya ingresado a su cuenta y siempre que tenga saldo en el
        &iacute;tem denominado como &ldquo;Disponible para pago&rdquo;,
        podr&aacute; solicitar el pago de las regal&iacute;as recaudadas por la
        totalidad del valor descrito en este &iacute;tem.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol>
      <li>
        Una vez solicitado el pago, usted recibir&aacute; un correo de
        notificaci&oacute;n mediante la cual se confirmar&aacute; la
        recepci&oacute;n de la solicitud. A partir de dicha notificaci&oacute;n,{" "}
        <strong>BLACK LION DIGITAL S.A.S. </strong>tendr&aacute; un
        t&eacute;rmino de cuarenta y cinco (45) d&iacute;as h&aacute;biles para
        realizar la consignaci&oacute;n en la cuenta de ahorros y/o corriente
        que se haya registrado en la cuenta.
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      En el evento de no recibir ning&uacute;n correo de notificaci&oacute;n, el
      usuario deber&aacute; enviar correo electr&oacute;nico a{" "}
      <a href="mailto:contacto@blackliondigitalsas.com">
        contacto@blackliondigitalsas.com
      </a>{" "}
      inform&aacute;ndole a <strong>BLACK LION DIGITAL S.A.S. </strong>sobre
      esta circunstancia.
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        Si de lo contrario el usuario no se encuentra de acuerdo con el valor
        incluido en el &iacute;tem &ldquo;Disponible para pago&rdquo;,
        podr&aacute; enviar su comunicaci&oacute;n solicitando los soportes que
        especifican el valor registrado, al siguiente correo{" "}
        <a href="mailto:contacto@blackliondigitalsas.com">
          contacto@blackliondigitalsas.com
        </a>
        . <strong>BLACK LION DIGITAL S.A.S. </strong>deber&aacute; dar respuesta
        dentro de los tres (3) d&iacute;as h&aacute;biles siguientes a la
        notificaci&oacute;n de la solicitud de los reportes. Una vez enviados
        los soportes al usuario, este tendr&aacute; un t&eacute;rmino de tres
        (3) d&iacute;as h&aacute;biles para realizar la reclamaci&oacute;n
        formal la cual deber&aacute; ser respondida por{" "}
        <strong>BLACK LION DIGITAL S.A.S. </strong>en los tres (3) d&iacute;as
        siguientes a su notificaci&oacute;n.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      Una vez surtidos los anteriores t&eacute;rminos,{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> contar&aacute; con cuarenta y
      cinco (45) d&iacute;as para realizar el pago correspondiente al valor
      incluido en el &iacute;tem &ldquo;Disponible para pago&rdquo;.
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        El procedimiento tendr&aacute; que ser interpretado de conformidad con
        lo establecido en la Cl&aacute;usula Segunda del contrato de mandato
        suscrito entre las partes.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="3">
      <li>
        <strong>Pago de IVA (19%)</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      El usuario deber&aacute; pagar el IVA, es decir el diecinueve por ciento
      (19%) sobre el total de las regal&iacute;as recaudas por{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong>
    </p>
    <p>&nbsp;</p>
    <ol start="4">
      <li>
        <strong>Desactivaci&oacute;n de la cuenta del usuario </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>BLACK LION DIGITAL S.A.S.</strong> se reserva el derecho a
      desactivar la cuenta del usuario, siempre que se den las siguientes
      circunstancias, sin limitarse a las mismas:
    </p>
    <p>&nbsp;</p>
    <ul>
      <li>
        Por la terminaci&oacute;n del contrato de mandato suscrito entre las
        partes.
      </li>
      <li>
        Por el incumplimiento grave de alguna de las obligaciones incluidas en
        el presente documento.
      </li>
      <li>
        Por el uso, sin autorizaci&oacute;n, de alg&uacute;n signo distintivo de
        titularidad de <strong>BLACK LION DIGITAL S.A.S.</strong>
      </li>
      <li>
        Por la infracci&oacute;n de los derechos de autor y conexos de
        <strong> BLACK LION DIGITAL S.A.S.</strong>
      </li>
      <li>
        Por hacer uso de la plataforma para ejecutar actos ilegales a la luz del
        ordenamiento jur&iacute;dico colombiano.
      </li>
    </ul>
    <p>&nbsp;</p>
    <p>
      <strong>BLACK LION DIGITAL S.A.S.</strong> proceder&aacute; a notificar al
      usuario mediante correo electr&oacute;nico, las razones por las cuales se
      decidi&oacute; desactivar la cuenta del usuario.
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>REGISTRO DE CUENTA BANCARIA EN LA PLATAFORMA </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>BLACK LION DIGITAL S.A.S.</strong> registrar&aacute; la cuenta de
      ahorros y/o corriente que fue incluida por el usuario en el contrato de
      mandato suscrito entre las partes. Si por alguna raz&oacute;n{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> no registra ninguna cuenta
      bancaria, el usuario deber&aacute; registrar en primera medida la cuenta
      de ahorros y/o corriente descrita en el contrato de mandato, con el
      prop&oacute;sito de que <strong>BLACK LION DIGITAL S.A.S.</strong> realice
      la entrega de las regal&iacute;as que se encuentren descritas en la
      plataforma como &ldquo;Disponible para pago&rdquo;.
    </p>
    <p>&nbsp;</p>
    <p>
      De igual forma, se faculta al usuario para actualizar la
      informaci&oacute;n de la cuenta de ahorros y/o corriente, quien
      recibir&aacute; una notificaci&oacute;n de la modificaci&oacute;n, por
      parte de <strong>BLACK LION DIGITAL S.A.S.</strong>
      <strong>, </strong>siempre que este acepte los t&eacute;rminos y
      condiciones que se desplegar&aacute;n tan pronto el usuario desee incluir
      una nueva cuenta para la entrega de regal&iacute;as.
    </p>
    <p>&nbsp;</p>
    <p>
      Se recomienda que el usuario vincule su cuenta de ahorros y/o corriente y
      no las de titularidad de terceros, pues de hacerlo,{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> se exime de responsabilidad de
      que el tercero garantice y realice la entrega de las regal&iacute;as a las
      que tiene derecho el usuario.
    </p>
    <p>&nbsp;</p>
    <p>
      As&iacute; mismo, es preferible que el usuario abra una cuenta bancaria en
      Colombia o en payoneer, plataforma que permite realizar pagos
      transfronterizos, de lo contrario, el usuario deber&aacute; buscar el
      intermediario bancario m&aacute;s beneficioso para realizar dicha
      operaci&oacute;n, teniendo en cuenta el domicilio de{" "}
      <strong>BLACK LION DIGITAL S.A.S</strong>.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol>
      <li>
        <strong>RECLAMACIONES POR TERCEROS</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      El procedimiento para las reclamaciones por parte de terceros que carecen
      de v&iacute;nculo contractual con{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong>
      <strong>, </strong>es el siguiente:
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol>
      <li>
        En la plataforma{" "}
        <a href="http://www.blackliondigitalsas.com">blackliondigitalsas.com</a>
        , en la parte inferior derecha, se encontrar&aacute; un v&iacute;nculo
        denominado &ldquo;contacto&rdquo;.
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="2">
      <li>
        Una vez le hayan dado click a el v&iacute;nculo descrito anteriormente,
        deber&aacute; diligenciar los datos que se despliegan en este.
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Vale la pena recalcar que los datos suministrados por los terceros
      tambi&eacute;n tendr&aacute;n el mismo tratamiento de datos dispuesto en
      el punto <strong>III.</strong> <strong>RESTRICCIONES</strong>
      <strong>, 1. </strong>
      <strong>Normas y pol&iacute;ticas de BLACK LION DIGITAL S.A.S.</strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="3">
      <li>
        <strong>BLACK LION DIGITAL S.A.S. </strong>dar&aacute; una respuesta
        dentro de los quince (15) d&iacute;as siguientes a la
        notificaci&oacute;n de la solicitud.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="4">
      <li>
        Una vez <strong>BLACK LION DIGITAL S.A.S. </strong>de respuesta a la
        reclamaci&oacute;n, el tercero tendr&aacute; un t&eacute;rmino de quince
        (15) d&iacute;as h&aacute;biles para la presentaci&oacute;n de nuevas
        reclamaciones o para complementar las que fueron presentadas
        inicialmente.
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="5">
      <li>
        Nuevamente,<strong> BLACK LION DIGITAL S.A.S. </strong>tendr&aacute; un
        t&eacute;rmino de quince (15) d&iacute;as para responder. El anterior
        procedimiento se repetir&aacute; hasta que se llegue a un acuerdo o se
        solucion&eacute; las reclamaciones presentadas por el tercero.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ul>
      <li>
        <strong>ASPECTOS GENERALES</strong>
      </li>
    </ul>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>Como usuario de la plataforma, tenga en cuenta lo siguiente:</p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>Privacidad y competencia leal</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Al registrarse en la plataforma, usted otorga su consentimiento para la
      recopilaci&oacute;n y procesamiento de toda la informaci&oacute;n personal
      y la de sus obras art&iacute;sticas. Es por lo anterior que
      manejar&aacute; esta informaci&oacute;n de acuerdo con lo establecido en
      la norma de habeas data de la legislaci&oacute;n colombiana, la cual ha
      sido expresamente incorporada en este documento.
    </p>
    <p>&nbsp;</p>
    <p>
      De igual forma, <strong>BLACK LION DIGITAL S.A.S.</strong> actuar&aacute;
      bajo los lineamientos impuestos por el ordenamiento jur&iacute;dico
      colombiano, para que cada uno de los usuarios compitan lealmente,
      raz&oacute;n por la cual se encuentra completamente prohibido incurrir en
      cualquier acto desleal por parte de{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> o los usuarios. Se entiende
      como acto desleal alguno de los siguientes:
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>Actos de desviaci&oacute;n de la clientela</li>
      <li>Actos de desorganizaci&oacute;n</li>
      <li>Actos de confusi&oacute;n</li>
      <li>Actos de enga&ntilde;o</li>
      <li>Actos de descr&eacute;dito</li>
      <li>Actos de comparaci&oacute;n</li>
      <li>Actos de imitaci&oacute;n</li>
      <li>Explotaci&oacute;n de la reputaci&oacute;n ajena</li>
      <li>Violaci&oacute;n de secretos</li>
      <li>Inducci&oacute;n a la ruptura contractual</li>
      <li>Violaci&oacute;n de normas</li>
      <li>Pactos desleales de exclusividad</li>
    </ol>
    <p>&nbsp;</p>
    <p>
      Al aceptar estos T&eacute;rminos y Condiciones, usted acepta los
      t&eacute;rminos de privacidad y competencia desleal.
    </p>
    <p>&nbsp;</p>
    <ol start="2">
      <li>
        <strong>Exactitud de la informaci&oacute;n</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Usted declara y garantiza que toda la informaci&oacute;n que proporciona a{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> es correcta, completa y
      vigente, y se compromete a actualizar dicha informaci&oacute;n cuando sea
      necesario. De igual forma, <strong>BLACK LION DIGITAL S.A.S.</strong> se
      obliga a cumplir con los dispuesto en la legislaci&oacute;n colombiana
      respecto del Habeas Data, con la finalidad de proteger la
      informaci&oacute;n proporcionada por usted, as&iacute; como se espera que
      el usuario se rija por las mismas obligaciones al momento de utilizar y
      tratar la informaci&oacute;n de <strong>BLACK LION DIGITAL S.A.S.</strong>
    </p>
    <p>&nbsp;</p>
    <ol start="3">
      <li>
        <strong>
          Certificaci&oacute;n de residencia y capacidad para celebrar contratos
        </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Al abrir una cuenta en la plataforma de&nbsp;
      <a href="http://www.blackliondigitalsas.com">
        www.blackliondigitalsas.com
      </a>
      , usted declara y garantiza que reside en el territorio que ha indicado en
      su en la plataforma y en el contrato de mandato suscrito entre las partes.
      As&iacute; mismo, declara que tiene capacidad para celebrar contratos de
      conformidad con las leyes de su jurisdicci&oacute;n.
    </p>
    <p>&nbsp;</p>
    <ol start="4">
      <li>
        <strong>Naturaleza del Contenido</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Usted entiende que al utilizar la plataforma y tener acceso al contenido
      de&nbsp;
      <a href="http://www.blackliondigitalsas.com">
        www.blackliondigitalsas.com
      </a>
      , no podr&aacute; compartir el contenido que se encuentra limitado en el
      punto <strong>I</strong>
      <strong>
        <em>V. RESTRICCIONES 3. Conducta Prohibida</em>
      </strong>
      , buscando la protecci&oacute;n de los menores de edad y del material que
      vulnera los derechos de terceros.
    </p>
    <p>&nbsp;</p>
    <p>
      De igual forma, vale la pena tener en cuenta que{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> subir&aacute;
      fotograf&iacute;as, m&uacute;sica, t&iacute;tulos y marcas de las obras de
      titularidad del usuario las cuales se encuentran protegidas por la
      Decisi&oacute;n 486 de la Comisi&oacute;n de la Comunidad Andina y la Ley
      23 de 1983. Es por lo anterior que{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> se exime de responsabilidad
      cuando los verdaderos titulares de los derechos patrimoniales de autor y
      conexos presente cualquier reclamaci&oacute;n a{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> por la infracci&oacute;n de
      dichos derechos. Como consecuencia, la responsabilidad recaer&aacute;
      directa y expresamente en los usuarios, quienes declararon en el contrato
      de mandato y en este documento, al aceptar los T&eacute;rminos y
      Condiciones, ser los titulares leg&iacute;timos de los derechos
      patrimoniales de autor y conexos.
    </p>
    <p>&nbsp;</p>
    <ol start="5">
      <li>
        <strong>Riesgo de uso</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>BLACK LION DIGITAL S.A.S.</strong> no asumir&aacute;
      responsabilidad alguna por ning&uacute;n da&ntilde;o o virus en su
      computadora, celular u otra propiedad como consecuencia del acceso, uso,
      descarga o navegaci&oacute;n por la plataforma{" "}
      <a href="http://www.blackliondigitalsas.com">
        www.blackliondigitalsas.com
      </a>
      .
    </p>
    <p>&nbsp;</p>
    <ol start="6">
      <li>
        <strong>El derecho de BLACK LION DIGITAL S.A.S.</strong>{" "}
        <strong>para cambiar estos T&eacute;rminos y Condiciones</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>BLACK LION DIGITAL S.A.S.</strong> se reserva el derecho de
      cambiar, modificar, a&ntilde;adir o eliminar, en cualquier momento, partes
      de estos T&eacute;rminos y Condiciones, sin previo aviso, entrando en
      vigor inmediatamente y entendiendose aceptados con el solo uso de la
      plataforma. Si llegara a existir un cambio sustancial, haremos todos los
      esfuerzos comercialmente razonables, para notific&aacute;rselo y obtener
      su consentimiento antes de implementarlos. Le recomendamos que consulte
      estos T&eacute;rminos y Condiciones en forma peri&oacute;dica para ver si
      se han registrado cambios.
    </p>
    <p>&nbsp;</p>
    <ol start="7">
      <li>
        <strong>El derecho de BLACK LION DIGITAL S.A.S.</strong>{" "}
        <strong>a efectuar cambios a la plataforma</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <strong>BLACK LION DIGITAL S.A.S.</strong> puede agregar, cambiar,
      terminar, remover o suspender cualquier material incorporado a la
      plataforma, incluyendo caracter&iacute;sticas, precios y especificaciones
      de las obras musicales descritos o rese&ntilde;ados en el presente
      documento, de forma temporal o permanente, en cualquier momento, sin
      previo aviso y eximiendose de responsabilidad. Adem&aacute;s,{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> del contenido de&nbsp;la
      plataforma&nbsp;puede, de forma ocasional, sacar y/o excluir el
      contenido&nbsp;de la plataforma sin previo aviso.
    </p>
    <p>&nbsp;</p>
    <ul>
      <li>
        <strong>PROPIEDAD INTELECTUAL</strong>
      </li>
    </ul>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol>
      <li>
        <strong>Propiedad Industrial </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      La plataforma contiene y/o incorpora secretos comerciales, marcas,
      inventos patentados, dise&ntilde;os industriales, modelos de utilidad,
      lemas comerciales, nombres comerciales, ense&ntilde;as comerciales, entre
      otros derechos cuyo titular es <strong>BLACK LION DIGITAL S.A.S.</strong>{" "}
      y/o los usuarios, derechos protegidos por la propiedad industrial en la
      Decisi&oacute;n 486 de la Comisi&oacute;n de la Comunidad Andina.
    </p>
    <p>&nbsp;</p>
    <p>
      Es por lo anterior que usted no puede divulgar, replicar, modificar,
      copiar, distribuir, reproducir, reconfigurar, descomponer, o transformar
      total o parcialmente esta plataforma ya que su contenido es de propiedad
      exclusiva de <strong>BLACK LION DIGITAL S.A.S.</strong>, los usuarios y/o
      quienes sean titulares de las respectivas licencias.
    </p>
    <p>&nbsp;</p>
    <p>
      Los usuarios ser&aacute;n responsables por la vulneraci&oacute;n de los
      derechos que aqu&iacute; fueron descritos y tendr&aacute;n que responder
      de acuerdo con lo establecido en la Decisi&oacute;n 486 de la
      Comisi&oacute;n de la Comunidad Andina.
    </p>
    <p>&nbsp;</p>
    <ol start="2">
      <li>
        <strong>Derechos de autor</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      El contenido de&nbsp;<a href="http://Talleres.Motapp.com.co">la</a>{" "}
      plataforma y otras obras que se encuentran en la plataforma que forman
      parte del mismo, est&aacute;n protegidos por el derecho de autor en&nbsp;
      la Ley 23 de 1982, la Ley 1915 de 2018, el Convenio de Berna y todo otro
      tratado internacional que haya sido ratificado por Colombia. Teniendo en
      cuenta que <strong>BLACK LION DIGITAL S.A.S.</strong> y/o los usuarios son
      titulares de los derechos patrimoniales de autor y/o conexos de las obras
      contenidas en la plataforma, usted no puede realizar copias no
      autorizadas, reproducir, transformar, divulgar, vender, reproducir,
      traducir, etc., la plataforma o el contenido de la misma so pena de
      incurrir en la vulneraci&oacute;n de los derechos que recaen en{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong>, los usuarios y/o los
      verdaderos titulares de los derechos patrimoniales de autor y conexos
      sobre las obras en cuesti&oacute;n.
    </p>
    <p>&nbsp;</p>
    <p>
      Adicionalmente, usted podr&aacute; incurrir en el tipo penal de
      violaci&oacute;n a los derechos morales de autor (art&iacute;culo 270 del
      C&oacute;digo Penal Colombiano) y la violaci&oacute;n de los mecanismos de
      protecci&oacute;n de los derechos patrimoniales de autor y otras
      deformaciones (Art&iacute;culo 272 del C&oacute;digo Penal Colombiano)
    </p>
    <p>&nbsp;</p>
    <p>
      Por lo anterior, usted acepta cumplir con todas las leyes aplicables para
      la protecci&oacute;n de derechos de autor en relaci&oacute;n con el uso de
      la plataforma y su contenido.
    </p>
    <p>
      En el momento de incurrir en alguna de las anteriores actividades,{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong>, los usuarios y/o el titular
      legitimo de los derechos, tomar&aacute; las acciones correspondientes para
      que usted pueda resacir los da&ntilde;os ocasionados.
    </p>
    <ol start="3">
      <li>
        <strong>Marcas comerciales</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      No puede utilizar ninguna de las marcas comerciales que se exponen en la
      plataforma, salvo lo especificado en el presente documento y de
      conformidad con la Decisi&oacute;n 486 de la Comisi&oacute;n de la
      Comunidad Andina en su T&iacute;tulo VI. Es por lo anterior que no se
      permite que usted:
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        Aplique o coloque la marca o un signo distintivo idéntico o semejante
        sobre productos para los cuales se ha registrado la marca; sobre
        productos vinculados a los servicios para los cuales ésta se ha
        registrado; o sobre los envases, envolturas, embalajes o
        acondicionamientos de tales productos;
      </li>
      <li>
        Suprima o modifique la marca con fines comerciales, después de que se
        hubiese aplicado o colocado sobre los productos para los cuales se ha
        registrado la marca; sobre los productos vinculados a los servicios para
        los cuales ésta se ha registrado; o sobre los envases, envolturas,
        embalajes o acondicionamientos de tales productos;
      </li>
      <li>
        Fabrique etiquetas, envases, envolturas, embalajes u otros materiales
        que reproduzcan o contengan la marca, así como comercializar o detentar
        tales materiales;
      </li>
      <li>
        Use en el comercio un signo idéntico o similar a la marca respecto de
        cualesquiera productos o servicios, cuando tal uso pudiese causar
        confusión o un riesgo de asociación con el titular del registro.
        Tratándose del uso de un signo idéntico para productos o servicios
        idénticos se presumirá que existe riesgo de confusión;
      </li>
      <li>
        Use en el comercio un signo idéntico o similar a una marca notoriamente
        conocida respecto de cualesquiera productos o servicios, cuando ello
        pudiese causar al titular del registro un daño económico o comercial
        injusto por razón de una dilución de la fuerza distintiva o del valor
        comercial o publicitario de la marca, o por razón de un aprovechamiento
        injusto del prestigio de la marca o de su titular;
      </li>
      <li>
        Use públicamente un signo idéntico o similar a una marca notoriamente
        conocida, aun para fines no comerciales, cuando ello pudiese causar una
        dilución de la fuerza distintiva o del valor comercial o publicitario de
        la marca, o un aprovechamiento injusto de su prestigio.
      </li>
    </ol>
    <p>
      En el momento de incurrir en alguna de las anteriores actividades,{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> y/o el titular de los derechos,
      tomar&aacute;n las acciones correspondientes para que usted pueda resacir
      los da&ntilde;os ocasionados.
    </p>
    <ol start="4">
      <li>
        <strong>Derechos de BLACK LION DIGITAL S.A.S.</strong>{" "}
        <strong>respecto a los materiales que usted publica</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Al enviar mensajes, opiniones, ingresar datos o participar en cualquier
      otra forma de comunicaci&oacute;n a trav&eacute;s de la plataforma, usted
      declara que tiene todos los derechos para publicar las obras y recibir las
      regal&iacute;as correspondientes a los derechos patrimoniales de autor y
      conexos, sin que estos vulneren los derechos de terceros, y que autoriza a{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> a ejecutar y celebrar los acto
      descritos en el contrato de mandato y este documento.
    </p>
    <p>&nbsp;</p>
    <p>
      Si el uso de tales contenidos por parte de{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> explota cualquier derecho de
      propiedad que usted pueda tener sobre dicho material, usted acepta que{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> tiene un derecho irrestricto,
      con el reconocimiento de un porcentaje sobre las regal&iacute;as recaudas
      y no exclusivo, sin limitaci&oacute;n de tiempo y lugar.
    </p>
    <p>&nbsp;</p>
    <p>
      Si con la publicaci&oacute;n y promoci&oacute;n de los mensajes,
      opiniones, im&aacute;genes, marcas, datos, etc., se vulnera alg&uacute;n
      derecho a un tercero, usted ser&aacute; responsable por los da&ntilde;os
      ocasionados, ya que nunca advirti&oacute; no ser el titular del derecho
      que se llegar&eacute; a discutir.
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>T&Eacute;RMINOS EST&Aacute;NDAR</strong>
      </li>
      <li>
        <strong>Recursos</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Usted acuerda que cualquier uso no autorizado de la plataforma, de su
      contenido o de cualquier tecnolog&iacute;a contenida en el mismo, que
      ocasione alg&uacute;n perjuicio irreparable a{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> y/o a un tercero, deber&aacute;
      compensar los da&ntilde;os y perjuicios, sin perjuicio{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> tendr&aacute; la facultad de
      ejercer la acci&oacute;n correspondiente en contra de usted, buscando la
      reparaci&oacute;n integral de los da&ntilde;os ocasionados.
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol start="2">
      <li>
        <strong>Indemnizaci&oacute;n</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Ante cualquier incumplimiento de alguna de las obligaciones que este
      documento contiene, usted deber&aacute; indemnizar integralmente a{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong> y eximir de toda
      responsabilidad a la misma respecto a toda reclamaci&oacute;n,
      p&eacute;rdida, da&ntilde;o y perjuicio, responsabilidades, deficiencias,
      grav&aacute;menes, multas, costas, gastos de honorarios de abogados y
      entre otros gastos que surjan en la relaci&oacute;n directa o
      indirectamente del uso de la plataforma. Como consecuencia de lo anterior,
      el usuario deber&aacute; responder por los da&ntilde;os pecunarios y/o
      morales de los que se vea <strong>BLACK LION DIGITAL S.A.S.</strong> o sus
      trabajadores y dem&aacute;s sanciones que sean impuestas judicialmente.
    </p>
    <p>&nbsp;</p>
    <ol start="3">
      <li>
        <strong>Terminaci&oacute;n</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      El plazo de estos T&eacute;rminos y Condiciones se mantendr&aacute;
      vigente hasta que se de alguna de las causales de terminaci&oacute;n del
      contrato de mandato y/o el incumplimiento de alguna de las obligaciones
      contenidas en el presente documento.
    </p>
    <p>&nbsp;</p>
    <p>
      Una vez terminada esta relaci&oacute;n jur&iacute;dica, usted debe dejar
      de utilizar por completo la plataforma y eximir de cualquier
      responsabilidad a <strong>BLACK LION DIGITAL S.A.S.</strong>
      <strong>, </strong>sin perjuicio de las responsabilidades que se
      incluyeron en el contrato de mandato suscrito entre las partes.
    </p>
    <p>&nbsp;</p>
    <ol start="4">
      <li>
        <strong>Notas legales; limitaci&oacute;n de responsabilidad.</strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>BLACK LION DIGITAL S.A.S. </strong>no garantiza que el servicio
        sea compatible con cualquier computadora personal, reproductor de CD o
        DVD, grabador de CD o DVD, o dispositivos port&aacute;tiles registrados,
        raz&oacute;n por la cual usted es el &uacute;nico responsable de
        asegurarse de que sus sistemas funcionen correctamente con el servicio.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol>
      <li>
        En ning&uacute;n caso, <strong>BLACK LION DIGITAL S.A.S. </strong>
        ser&aacute; responsable por da&ntilde;os y perjuicios que surjan de y/o
        en relaci&oacute;n con la plataforma y estos t&eacute;rminos y
        condiciones, entre otros:
      </li>
    </ol>
    <p>&nbsp;</p>
    <ul>
      <li>Da&ntilde;os y perjuicios derivados de la p&eacute;rdida de datos</li>
      <li>
        Lucro cesante y da&ntilde;o emergente por p&eacute;rdida del uso de la
        plataforma
      </li>
      <li>
        Da&ntilde;os y perjuicios derivados del uso que usted haga del servicio
        y que infrinja estos T&eacute;rminos y Condiciones. Es por esto que bajo
        ninguna circunstancia <strong>BLACK LION DIGITAL S.A.S. </strong>o sus
        afiliadas ser&aacute;n responsables por el uso no autorizado de
        cualquier contenido o cualquier uso del servicio para desarrollar,
        distribuir o utilizar cualquier material que sea difamatorio,
        calumnioso, injurioso u obsceno, que constituya una invasi&oacute;n de
        cualquier derecho a la privacidad o una infracci&oacute;n de cualquier
        derecho a la publicidad, que viole o infrinja los derechos de cualquier
        tercero o que vulnere cualquier ley o regulaci&oacute;n extranjera,
        federal, estatal o local.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ol start="5">
      <li>
        <strong>
          Elecci&oacute;n de derecho y jurisdicci&oacute;n territorial.
        </strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      Estos T&eacute;rminos y Condiciones se regir&aacute;n de conformidad con
      las leyes colombianas y de la siguiente:
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>CL&Aacute;USULA COMPROMISORIA</strong>
    </p>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Toda controversia o diferencia relativa a estos T&eacute;rminos y
      Condiciones y las que surgieran en temas relacionados directa e
      indirectamente con derechos de autor y/o conexos que surgieran entre las
      partes, se resolver&aacute;n por un Tribunal de Arbitramento que
      funcionar&aacute; en el Centro de Conciliaci&oacute;n y Arbitraje
      &ldquo;Fernando Hinestrosa&rdquo; de la Direcci&oacute;n Nacional de
      Derechos de Autor y toda diferencia que surja en relaci&oacute;n con
      cualquier otra situaci&oacute;n derivada del presente contrato, que no
      tenga que ver con temas de derechos de autor y/o conexos, se
      resolver&aacute;n por el Tribunal de Arbitramento del Centro de
      Conciliaci&oacute;n y Arbitraje de la C&aacute;mara de Comercio de
      Bogot&aacute;.
    </p>
    <p>&nbsp;</p>
    <p>
      Cualquiera de los Tribunales se regir&aacute; por el procedimiento
      dispuesto en la Ley 1563 del 2012 y a las dem&aacute;s disposiciones
      legales que los reglamenten, adicionen o modifiquen y de acuerdo con las
      siguientes reglas:&nbsp;
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        El Tribunal estar&aacute; integrado por un (1) &aacute;rbitro, si es por
        m&iacute;nima o menor cuant&iacute;a, que ser&aacute; nombrado por las
        partes en conflicto de com&uacute;n acuerdo y si no llegar&eacute; a
        haber acuerdo, se escoger&aacute; por sorteo de las listas de
        &aacute;rbitros del Centro de Conciliaci&oacute;n y Arbitraje competente
        para el asunto.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="2">
      <li>
        El Tribunal estar&aacute; integrado por tres (3) &aacute;rbitros si es
        por mayor cuant&iacute;a, los cuales ser&aacute;n nombrados por las
        partes en conflicto de com&uacute;n acuerdo y si no llegar&eacute; a
        haber acuerdo, se escoger&aacute;n por sorteo de las listas de
        &aacute;rbitros del Centro de Conciliaci&oacute;n y Arbitraje competente
        para el asunto.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="3">
      <li>El Tribunal decidir&aacute; en derecho.&nbsp;</li>
    </ol>
    <p>&nbsp;</p>
    <p>
      En el evento de que exista alg&uacute;n elemento extranjero en los
      conflictos estipulados en la presente cl&aacute;usula, en virtud de esta
      misma cl&aacute;usula, se podr&aacute; acudir a arbitraje internacional,
      seg&uacute;n lo dispuesto en el art&iacute;culo 62 de la Ley 1563 de 2012.
    </p>
    <p>&nbsp;</p>
    <p>
      Si el arbitraje es internacional, el Tribunal Arbitral funcionar&aacute;
      en el Centro de Conciliaci&oacute;n y Arbitraje de la C&aacute;mara de
      Comercio de Bogot&aacute; se regir&aacute; por las siguientes reglas:
    </p>
    <p>&nbsp;</p>
    <p>
      El Tribunal se sujetar&aacute; al Reglamento de Arbitraje Comercial
      Internacional del Centro de Arbitraje y Conciliaci&oacute;n de la
      C&aacute;mara de Comercio de Bogot&aacute;.
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        El Tribunal estar&aacute; integrado por un (1) &aacute;rbitro, si es por
        m&iacute;nima o menor cuant&iacute;a, que ser&aacute; nombrado por las
        partes en conflicto de com&uacute;n acuerdo y si no llegar&eacute; a
        haber acuerdo, se escoger&aacute; por sorteo de las listas de
        &aacute;rbitros del Centro de Conciliaci&oacute;n y Arbitraje competente
        para el asunto.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="2">
      <li>
        El Tribunal estar&aacute; integrado por tres (3) &aacute;rbitros si es
        por mayor cuant&iacute;a, los cuales ser&aacute;n nombrados por las
        partes en conflicto de com&uacute;n acuerdo y si no llegar&eacute; a
        haber acuerdo, se escoger&aacute;n por sorteo de las listas de
        &aacute;rbitros del Centro de Conciliaci&oacute;n y Arbitraje competente
        para el asunto.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="3">
      <li>El Tribunal decidir&aacute; en derecho.</li>
    </ol>
    <p>&nbsp;</p>
    <ol start="4">
      <li>El Tribunal conocer&aacute; en espa&ntilde;ol (Castellano).</li>
    </ol>
    <p>&nbsp;</p>
    <ol start="5">
      <li>
        Las leyes de fondo aplicables ser&aacute;n las de la Rep&uacute;blica de
        Colombia.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="6">
      <li>
        <strong>Convenio total y divisibilidad</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Estos T&eacute;rminos y Condiciones constituye el acuerdo total entre
      usted y <strong>BLACK LION DIGITAL S.A.S.</strong> y se debe interpretar
      de manera conjunta con el contrato de mandato suscrito entre las partes,
      reemplazando cualquier otro documento, convenio y entendimiento previo y/o
      contempor&aacute;neo, &nbsp;ya sea escritos u orales, que haya existido
      entre las partes. Salvo lo expresamente establecido en el presente
      documento, estos T&eacute;rminos y Condiciones no podr&aacute;n ser
      enmendados, modificados o complementados por las partes, excepto mediante
      instrumento por escrito firmado por un directivo autorizado de{" "}
      <strong>BLACK LION DIGITAL S.A.S. </strong>De manera complementaria, se
      entiende que no se ha renunciado a ninguna disposici&oacute;n de este
      documento, y si as&iacute; lo quisiera{" "}
      <strong>BLACK LION DIGITAL S.A.S. </strong>deber&iacute;a realizarlo de
      manera escrita, firmada por un directivo autorizado y notificando a los
      usuarios.
    </p>
    <p>&nbsp;</p>
    <p>
      De igual forma, si el Tribunal de Arbitramento de la C&aacute;mara de
      Comercio determinara que una parte de estos T&eacute;rminos y Condiciones
      es inv&aacute;lida o inexigible, el resto de estos t&eacute;rminos
      tendr&aacute;n validez jur&iacute;dica y deber&aacute;n ser interpretados
      de modo que refleje razonablemente la intenci&oacute;n de las partes.
    </p>
    <p>&nbsp;</p>
    <ol start="7">
      <li>
        <strong>Cesi&oacute;n de los T&eacute;rminos y Condiciones</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      En cuanto a la cesi&oacute;n de las obligaciones, estos T&eacute;rminos y
      Condciones podr&aacute;n ser cedidos por{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong>, siempre y cuando se hayan
      notificado al usuario. Este derecho que no puede ser ejercido por el
      usuario, bien sea total o parcialmente, sin el consentimiento previo por
      escrito de un directivo autorizado de{" "}
      <strong>BLACK LION DIGITAL S.A.S.</strong>
    </p>
    <p>&nbsp;</p>
    <ol start="8">
      <li>
        <strong>
          Propietario posterior del Servicio; Fusi&oacute;n/adquisici&oacute;n
          de BLACK LION DIGITAL S.A.S.
        </strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Al utilizar la plataforma, usted acuerda que{" "}
      <strong>BLACK LION DIGITAL S.A.S. </strong>o una de sus
      compa&ntilde;&iacute;as podr&aacute;n entrar en proceso de fusi&oacute;n y
      adquisici&oacute;n o vender la plataforma, ceder sus derechos y
      obligaciones en relaci&oacute;n con la plataforma. En caso de realizarse
      dicha fusi&oacute;n, adquisici&oacute;n, venta o cesi&oacute;n, la
      plataforma seguir&aacute; funcionando y estos T&eacute;rminos y
      Condiciones seguir&aacute;n vigentes hasta que el nuevo propietario
      considere lo contrario.
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>DEFINICIONES</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <ol>
      <li>
        "Usuarios" los artistas es decir, compostiores e interpr&eacute;tes
        (musicos, cantantes, etc) de las obras musicales, que hayan suscrito el
        contrato de mandato con <strong>BLACK LION DIGITAL S.A.S.</strong>
      </li>
      <li>
        "Contrato de mandato" significa el contrato suscrito entre las partes
        mediante el cual se le otorgan facultades excepcionales a{" "}
        <strong>BLACK LION DIGITAL S.A.S. </strong>para actuar en nombre y
        representaci&oacute;n del usuario.
      </li>
      <li>
        "Contenido de&nbsp;
        <a href="http://www.blackliondigitalsas.com">
          blackliondigitalsas.com
        </a>{" "}
        " significa la m&uacute;sica, im&aacute;genes, texto y otros materiales
        que usted puede obtener o a los cuales puede acceder en forma digital
        desde la plataforma, cuyo contenido es propiedad de{" "}
        <strong>BLACK LION DIGITAL S.A.S.</strong> o de terceros licenciadores.
      </li>
      <li>
        "Informaci&oacute;n de Registro" significa la informaci&oacute;n que
        usted proporciona a <strong>BLACK LION DIGITAL S.A.S.</strong> por medio
        de la plataforma{" "}
        <a href="http://www.blackliondigitalsas.com">blackliondigitalsas.com</a>
        , al registrarse y durante el proceso de instalaci&oacute;n que lleva a
        cabo para utilizarla, incluyendo un nombre &uacute;nico de miembro
        (usuario), una contrase&ntilde;a y su direcci&oacute;n v&aacute;lida de
        correo electr&oacute;nico.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong>PREGUNTAS</strong>
      </li>
    </ol>
    <p>
      <strong>&nbsp;</strong>
    </p>
    <p>
      Si tiene alguna pregunta o comentario sobre estos T&eacute;rminos y
      Condiciones, no dude en contactarnos mediante el siguiente correo{" "}
      <a href="mailto:contacto@blackliondigitalsas.com">
        contacto@blackliondigitalsas.com
      </a>
      .
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>BLACK LION DIGITAL S.A.S.</strong>
    </p>
    <p>Calle 145 No. 12 86 Of 101</p>
    <p>Bogot&aacute;, Colombia</p>
    <p>&nbsp;</p>
    <p>
      Se reserva cualquier derecho que no est&eacute; expresamente otorgado en
      el presente documento.
    </p>
  </div>
);

export const terminos =
  "TÉRMINOS Y CONDICIONES\n" +
  "BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "Bienvenido a BLACK LION DIGITAL S.A.S., nos complace ofrecerle acceso a la plataforma, la cual prestará los servicios que se describirán en el presente documento, estando sujeto a los siguientes Términos y Condiciones. Se entiende que usted ha expresado su consentimiento, voluntario, excento de vicios, previo e informado, de los Términos y Condiciones al momento de registrarse, acceder, aceptar estos Términos y Condiciones  y utilizar la plataforma.  Si no está de acuerdo con los Términos y Condiciones que aquí se describen, le recomendamos no hacer click en acepto Términos y Condiciones y no hacer uso de la plataforma.\n" +
  "\n" +
  "Se recomienda leer todos los términos que aquí se incluyen, ya que BLACK LION DIGITAL S.A.S. se exime de cualquier responsabilidad derivada del desconocimiento del contenido del presente documento. \n" +
  "\n" +
  "I.\tGENERALIDADES Y DESCRIPCIÓN DE LOS SERVICIOS PROPORCIONADOS POR BLACK LION DIGITAL S.A.S A TRAVÉS DE LA PLATAFORMA\n" +
  "\n" +
  "A continuación, describiremos los servicios proporcionados por BLACK LION DIGITAL S.A.S. a través de la presente plataforma digital. \n" +
  "\n" +
  "1.\tBLACK LION DIGITAL S.A.S. es titular de todos los derechos patrimoniales sobre la plataforma www.blackliondigitalsas.com y por ende, la pone a disposición los usuarios, otorgándoles a los titulares de los derechos patrimoniales de autor y/o conexos de las obras musicales un espacio en la plataforma mediante el cual podrá acceder a los soportes de recaudación de regalías por la explotación de las obras en el entorno digital y la entrega del pago pactado en el contrato de mandato suscrito entre BLACK LION DIGITAL S.A.S. y el usuario de la plataforma. \n" +
  "\n" +
  "2.\tSe entenderán por usuarios, aquellas personas naturales y/o jurídicas que hayan suscrito el contrato de mandato otorgándole facultades extraordinarias a BLACK LION DIGITAL S.A.S. para: \n" +
  "\n" +
  "•\tDistribuir, reproducir, comercializar y poner a disposición las obras musicales, audiovisuales, los fonogramas y/o videogramas de titularidad del usuario, en todas las plataformas digitales y/o páginas web, tales como Spotify, Youtube, Dezeer, Apple Music, y las demás que existan en el mundo, y en las que se están creando o las que se lleguen a crear. \n" +
  "\n" +
  "•\tAcceder a las redes sociales del usuario con el fin de distribuir, reproducir, comercializar y poner a disposición las obras musicales, audiovisuales, los fonogramas y/o videogramas en los medios digitales, entorno digital o cualquier medio que pueda existir y que cumpla con las características de las anteriores.\n" +
  "\n" +
  "•\tCeder, licenciar y sublicenciar las obras musicales, audiovisuales, los fonogramas y/o videogramas de titularidad del usuario. \n" +
  "\n" +
  "•\tGestionar y recaudar las regalías por la reproducción de las obras musicales, audiovisuales, los fonogramas y/o videogramas en los medios digitales, siempre que los derechos patrimoniales y conexos sean de titularidad del usuario.\n" +
  "\n" +
  "•\tPresentar reclamaciones a las plataformas digitales, redes sociales y/o páginas web, cuando se vean vulnerados los derechos patrimoniales de autor y/o conexos del usuario, por la reproducción, distribución, puesta a disposición y/o transformación de las obras musicales, audiovisuales, los fonogramas y/o videogramas en los medios digitales, sin previa autorización del usuario. \n" +
  "\n" +
  "•\tNegociar, transigir, conciliar, requerir y/o demandar a las personas naturales y/o jurídicas que vulneren los derechos patrimoniales de autor y/o conexos del usuario, mediante la explotación de las obras musicales, audiovisuales, los fonogramas y/o videogramas en los medios digitales.\n" +
  "\n" +
  "•\tContratar a los asesores legales que puedan representar al usuario en futuros pleitos judiciales y/o extrajudiciales, para buscar garantizar la protección de los derechos patrimoniales autor y/o conexos que puedan llegar a ser vulnerados por terceros. \n" +
  "\n" +
  "•\tUsar la(s) marca(s) del usuario en los medios digitales con el fin de comercializar las obras musicales, audiovisuales, los fonogramas y/o videogramas en los medios digitales.\n" +
  "\n" +
  "3.\tEl usuario que haya suscrito el contrato mencionado anteriormente recibirá un correo electrónico mediante el cual se le asignará un usuario y una clave. Una vez recibida dicha información por parte del usuario, se transmitirá la responsabilidad de custodia de la información a este usuario y dejará indemne a BLACK LION DIGITAL S.A.S. por la pérdida o mal uso de esta. \n" +
  "\n" +
  "4.\tEl usuario podrá incluir la cuenta de ahorros o corriente de cualquier entidad bancaria, con el fin de que BLACK LION DIGITAL S.A.S. entregue las regalías recuadadas de acuerdo con los porcentajes acordados en el contrato de mandato. BLACK LION DIGITAL S.A.S. se exime de cualquier responsabilidad si la cuenta bancaria ingresada es de un titular distinto al usuario que suscribió el contrato de mandato. \n" +
  "\n" +
  "Así mismo, el usuario podrá cambiar la cuenta registrada cuantas veces sea necesario y recibirá una notificación al correo electrónico vinculado con la cuenta, conducta que exime de responsabilidad a BLACK LION DIGITAL S.A.S. de cualquier actuación ilegal e ilegitima que puedan realizar terceros en perjuicio del usuario. \n" +
  "\n" +
  "5.\tBLACK LION DIGITAL S.A.S. entregará un informe trimestral mediante el cual se discriminarán los valores recaudados, los medios digitales que reconocieron y pagaron las regalías y los conceptos del dinero pagado por los medios digitales.  \n" +
  "\n" +
  "Ante cualquier discreción que surta respecto a dicho informe, el usuario tendrá el derecho de solicitar la explicación correspondiente a BLACK LION DIGITAL S.A.S., en los términos contenidos en estos Términos y Condiciones. \n" +
  "\n" +
  "6.\tLas regalías solicitadas por el usuario se entregarán en un término de cuarenta y cinco (45) días hábiles, contadas a partir de la presentación de la solicitud o posterior al envío de la respuesta a las reclamaciones que podrían ser presentadas por el usuario. \n" +
  "\n" +
  "II.\tPERMISO PARA UTILIZAR LA PLATAFORMA; RESERVA DE DERECHOS.\n" +
  "\n" +
  "Al aceptar estos Términos y Condiciones, se le concede a usted un derecho limitado, no exclusivo, intransferible y no sub-licenciable, para entrar, acceder y utilizar la plataforma exclusivamente para solicitar la entrega de las regalías recaudadas por BLACK LION DIGITAL S.A.S., los soportes mediante los cuales se discriminan los valores y conceptos de las regalías pagadas por los medios digitales y la vinculación de la cuenta bancaria para realizarse la entrega de las regalías recaudadas.  Todos los derechos que no se le conceden expresamente a usted en estos Términos y Condiciones, están reservados para BLACK LION DIGITAL S.A.S.  y sus licenciadores, según corresponda. Es por esto que usted acepta que este permiso es para su uso personal, razón por la cual se considerará vulnerado el derecho cuando personas ajenas a usted hagan uso de las facultades que la plataforma les otorga.  \n" +
  "\n" +
  "Así mismo, usted no tiene derecho para copiar o reproducir parcial o totalmente el contenido y funcionamiento de la plataforma, ya que se encuentran limitados por lo establecido en las leyes y tratados internacionales, ratificados por Colombia, correspondientes al derecho de autor y propiedad intelectual, como se desarrollará más adelante. \n" +
  "\n" +
  "III.\tRESTRICCIONES\n" +
  "\n" +
  "1.\tNormas y políticas de BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "Como consecuencia del acceso y utilización de la plataforma, el usuario autoriza expresamente a BLACK LION DIGITAL S.A.S. de hacer uso de su información personal, tales como correo electrónico, cuenta bancaria, número de identificación (cédula de ciudadanía, extranjería o número de pasaporte), nombre, apellido y nombre artístico, con la finalidad de tener un primer acercamiento entre el usuario y BLACK LION DIGITAL S.A.S.. Lo anterior con el propósito de que BLACK LION DIGITAL S.A.S. pueda garantizar la autenticidad de las personas que ya habían suscrito el contrato de mandato con la sociedad y así mismo, garantizar la entrega de las regalías de manera rápida y eficiente. De esa manera, BLACK LION DIGITAL S.A.S. le estaría dando cumplimiento a los establecido en el contrato suscrito entre el usuario y la sociedad.\n" +
  "\n" +
  "En el mismo sentido, el usuario autoriza a BLACK LION DIGITAL S.A.S. a:\n" +
  "\n" +
  "a)\tActualizar, modificar e instalar el software, de manera automática o requerida, entre otras razones para abordar cuestiones de seguridad, interoperabilidad y/o funcionamiento. Estas actualizaciones, modificaciones y operaciones similares pueden realizarse en forma periódica o cuando sea necesario, sin previo aviso. \n" +
  "\n" +
  "b)\tCrear un protocolo de notificación al usuario para informar las siguientes circunstancias:\n" +
  "\n" +
  "•\tLa modificación de la cuenta bancaria que ha sido vinculada en la plataforma. \n" +
  "•\tLa confirmación de la recepción de la solicitud de la entrega de las regalías. \n" +
  "•\tLa confirmación de la consignación de las regalías recaudadas por BLACK LION DIGITAL S.A.S.\n" +
  "•\tLa notificación de la recepción de las reclamaciones a las que haya lugar.\n" +
  "\n" +
  "c)\tVender, sublicenciar y publicar los datos personales siempre que se encuentren en relación directa y/o indirecta con los servicios proporcionados por BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "d)\tHacer uso de los datos personales para radicar, recibir, pagar, sustituir, desistir, renunciar, reasumir, transigir y todas aquellas que tiendan al buen cumplimiento de la prestación del servicio de BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "BLACK LION DIGITAL S.A.S. deberá ceñirse a lo establecido en la Ley 1581 de 2012 y el Decreto 1377 de 2013, para dar el adecuado tratamiento de los datos personales, tal y como se muestra a continuación: \n" +
  "\n" +
  "a)\tObligaciones de los responsables del tratamiento de datos\n" +
  "\n" +
  "•\tGarantizar al usuariio el uso efectivo, plena y en todo tiempo de los derechos de hábeas data;\n" +
  "•\tImpedir la adulteraciónde la información, pérdida, consulta, uso o acceso no autorizado o fraudulento;\n" +
  "•\tRespetar las condiciones de seguridad y privacidad de la información del usuario;\n" +
  "•\tInformar a la autoridad de protección de datos, Superintendencia de Industria y Comercio (SIC), cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los usuarios.\n" +
  "•\tCumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.\n" +
  "\n" +
  "b)\tObligaciones de los encargados del tratamiento de datos\n" +
  "\n" +
  "•\tGarantizar al usuariio el uso efectivo, plena y en todo tiempo de los derechos de hábeas data;\n" +
  "•\tImpedir la adulteraciónde la información, pérdida, consulta, uso o acceso no autorizado o fraudulento;\n" +
  "•\tAbstenerse de circular información que esté siendo controvertida por el usuario y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio;\n" +
  "•\tPermitir el acceso a la información únicamente a las personas que pueden tener acceso a ella;\n" +
  "•\tInformar a la Superintendencia de Industria y Comercio cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares;\n" +
  "•\tCumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.\n" +
  "\n" +
  "En ese sentido, BLACK LION DIGITAL S.A.S. se exime de cualquier responsabilidad derivada del incumplimiento de alguna de sus obligaciones tendientes a la protección y tratamiento de datos, siempre que no exista plena prueba que demuestre el daño ocasionado al cliente o que este último haya ocasionado su propio perjuicio de manera dolosa o culposa. \n" +
  "\n" +
  "BLACK LION DIGITAL S.A.S., también deberá cumplir con las anteriores obligaciones cuando terceros que no tengan calidad de usuarios, soliciten información acerca de los servicios prestados por BLACK LION DIGITAL S.A.S. o presenten alguna reclamación por la presunta vulneración de los derechos morales y/o patrimoniales de autor y/o conexos.\n" +
  "\n" +
  "2.\tSeguridad de la información\n" +
  "\n" +
  "BLACK LION DIGITAL S.A.S. se asegurará de actuar diligentemente para evitar e impedir hurto, fraude, filtraciones, uso inadecuado de la información. Para lo anterior, los trabajadores, representante legal principal y/o suplente, miembros de la Junta Directiva, proveedores o cualquier otra persona que tenga un vínculo contractual con BLACK LION DIGITAL S.A.S., deberán cumplir con las políticas, normas, procedimientos y buenas prácticas de seguridad de la información establecidas por BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "Adicionalmente, se garantizará un respaldo de la información en tiempo real, la cual estará disponible para los usuarios cuando estos lo requieran. \n" +
  "\n" +
  "3.\tRequisito de edad.\n" +
  "\n" +
  "De acuerdo con la Ley 1581 de 2012 en el 2.2.8.4.2.4., se ha establecido la prohibición del tratamiento de los datos personales de menores de 18 años o los menores de edad de acuerdo con la jurisdicción en la que se encuentre el usuario, sin previa autorización de su padre, madre o cualquier persona que tenga la custodia del menor. En ese sentido, se recomienda que, si usted es menor de 18 o menor de edad, y pretende acceder a la plataforma, su padre, madre o tutor legal deben aceptar estos Términos y Condiciones. De igual forma, se recomienda que los menores de 14 años, por ser incapaces absolutos en nuestra legislación, o el equivalente en la jurisdicción en la que se encuentre el usuario, no proporcionen información alguna a BLACK LION DIGITAL S.A.S., ya sea mediante el uso de la plataforma o a través de cualquier otra actividad con ocasión del desarrollo de la plataforma. \n" +
  "\n" +
  "4.\tConducta prohibida.\n" +
  "\n" +
  "Se encuentra completamente prohibido para los proveedores, administradores y usuarios realizar las siguientes actividades:\n" +
  "a)\tAlojar en su propio sitio imágenes, textos, documentos o archivos audiovisuales que impliquen directa o indirectamente actividades sexuales con menores de edad. \n" +
  "b)\tAlojar en su propio sitio material pornográfico, en especial en modo de imágenes o videos, cuando existan indicios de que las personas fotografiadas o filmadas son menores de edad. \n" +
  "c)\tAlojar en su propio sitio vínculos o links, sobre sitios telemáticos que contengan o distribuyan material pornográfico relativo a menores de edad. \n" +
  "d)\tAlojar datos personales de los menores de catorce (14) años o los menores de edad de acuerdo con la jurisdicción en la que se encuentre el usuario.\n" +
  "e)\tAlojar los datos personales de los menores de dieciocho (18) años o los menores de edad de acuerdo con la jurisdicción en la que se encuentre el usuario, sin previa autorización de su papá, mamá o quien tenga la custodia del menor. \n" +
  "Así mismo, se encuentra prohibido transmitir, divulgar, transformar o poner a disposición pública cualquier contenido o material que viole o infrinja derechos de autor, marcas registradas, patentes, secretos comerciales y otros derechos de propiedad intelectual, derechos de privacidad o publicidad, reglamentos o estatutos de comunicaciones, o cualesquiera otras leyes, incluyendo, sin limitación, las leyes sobre difamación, acoso, obscenidad y pornografía. También se prohibe la divulgación de  campañas políticas o solicitudes de venta o marketing o que contengan virus informáticos u otro código de computadora destinado a interferir con la funcionalidad de los sistemas de computadoras. Tampoco se podrá interrumpir ni intentar interrumpir la operación de la plataforma de ninguna manera o hacer uso de una dirección de correo electrónico falso con la finalidad de engañar a otros miembros en cuanto a su identidad o al origen de un mensaje o contenido.\n" +
  "\n" +
  "Es por lo anterior que el uso de la plataforma por parte de los usuarios, debe ser adecuada y respetuosa. Cualquier violación a lo aquí dispuesto, estará sujeta a la revisión de BLACK LION DIGITAL S.A.S. para  adoptar las acciones legales correspondientes, sin perjuicio de terminar el contrato de mandato suscrito entre las partes. \n" +
  "\n" +
  "5.\tRestricción sobre el uso relacionado con la plataforma y su contenido\n" +
  "\n" +
  "La plataforma incluye componentes de seguridad por lo que se aplican normas y políticas especiales, que determinan que usted no deberá intentar eludir, aplicar ingeniería inversa, descifrar, descompilar, desmontar o de algún modo modificar, alterar o interferir con ningún aspecto de la plataforma. En el mismo sentido, usted no podrá distribuir, intercambiar, modificar, vender o revender, o transmitir a cualquier otra persona cualquier parte de la plataforma, incluyendo, sin limitación, cualquier texto, imagen, audio o video para cualquier propósito empresarial, comercial o público. Usted conviene en no copiar, vender, distribuir o de algún modo transferir el contenido de la plataforma www.blackliondigitalsas.com,  salvo que exista una licencia que así se lo permita. A continuación se decribirán las restricción correspondientes a la plataforma y al contenido, de manera separada:\n" +
  "\n" +
  "a)\tRestricción sobre el uso relacionado con la Cuenta de www.blackliondigitalsas.com\n" +
  "\n" +
  "La cuenta www.blackliondigitalsas.com solo puede ser utilizada por usted y no por ninguna otra persona. Es por lo anterior que el usuario exime de responsabilidad alguna a BLACK LION DIGITAL S.A.S. cuando se haya permitido el uso de la cuenta por parte de personas distintas al usuario. Así mismo, el usuario adquiere la obligación de mantener la confidencialidad y seguridad de la información a la que tiene acceso con ocasión al uso de la plataforma que regula estos Términos y Condiciones. \n" +
  "\n" +
  "Será obligación del usuario notificar inmediatamente a BLACK LION DIGITAL S.A.S. cualquier uso no autorizado de su contraseña y/o usuario de www.blackliondigitalsas.com, enviándonos un correo electrónico a contacto@blackliondigitalsas.com, ya que de lo contrario, BLACK LION DIGITAL S.A.S. llevará a cabo el siguiente procedimiento con el fin de sancionar las extralimitaciones en las que incurrio el usuario:\n" +
  "\n" +
  "•\tEnviar comunicación escrita al usuario con la finalidad de notificar su extralimitación en el uso de la plataforma.\n" +
  "•\tSuspender el ingreso a la plataforma si el usuario sigue incurriendo en la misma conducta. \n" +
  "•\tDesvincular al usuario de la plataforma \n" +
  "•\tTerminar el contrato de mandato por la gravedad en las extralimitaciones en ls que incurrió el usuario.\n" +
  "\n" +
  "No habrá un orden específico para aplicar el anterior procedimiento interno, puesto que esto dependerá de la gravedad de las conductas ejecutadas por el usuario. \n" +
  " \n" +
  "b)\tRestricción sobre el uso relacionado con el Contenido de www.blackliondigitalsas.com.\n" +
  "\n" +
  "El usuario no podrá ejercer los siguientes derechos:\n" +
  "\n" +
  "•\tDerechos de reproducción o uso promocional en el contenido de www.blackliondigitalsas.com\n" +
  "•\tDerechos para celebrar acuerdos con cualquier sistema de difusión que genere ingresos (terrestre, satelital, cable y/u otros canales de distribución), \n" +
  "•\tDerechos de distribuir o redistribuir el contenido de www.blackliondigitalsas.com en aplicaciones de streaming (vía Internet, intranets y/u otras redes), \n" +
  "•\tDerechos de distribuir a otras personas el contenido de www.blackliondigitalsas.com otros sistemas de distribución de contenido (aplicaciones de audio de pago o audio bajo demanda, etc.) o en medios físicos (discos compactos, discos digitales versátiles, chips semiconductores, discos duros, tarjetas de memoria y similares)\n" +
  "•\tDerechos de uso comercial, venta, reventa, reproducción, distribución o promocional del contenido de www.blackliondigitalsas.com. \n" +
  "\n" +
  "Para no infringir las anteriores restricciones, vale la pena tener en cuenta que cualquier solución de gestión de derechos digitales que se proporciona con el contenido de www.blackliondigitalsas.com es una parte inseparable del mismo, y no podrá eludirse, salvo en la medida permitida por la ley aplicable. Si usted está en posesión, control y/o uso del contenido de www.blackliondigitalsas.com, es su responsabilidad no perder, destruir ni dañar dicho contenido, comercializar, reproducir o cualquier otra actuación que se asemeje, sin previa autorización de BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "6.\tPresentación de la información al consumidor\n" +
  "\n" +
  "Se deja constancia que BLACK LION DIGITAL S.A.S. suministró a los usuarios la información en castellano, clara, veraz, suficiente, oportuna, verificable, comprensible, precisa e idónea sobre los servicios prestados. Así mismo, BLACK LION DIGITAL S.A.S. suministró la siguiente información mediante estos términos y condiciones:\n" +
  "\n" +
  "a)\tLas instrucciones para el correcto uso de la plataforma\n" +
  "b)\tLas especificaciones de los servicios prestados a través de la plataforma\n" +
  "\n" +
  "IV.\tENTREGA Y SOLICITUD DE LAS REGALÍAS RECAUDADAS\n" +
  "\n" +
  "1.\tRecaudo y entrega de regalías  \n" +
  "\n" +
  "BLACK LION DIGITAL S.A.S. liquidará trimestralmente las regalías recaudadas y presentará al usuario un informe mediante el cual se discriminan los ingresos, su concepto y la plataforma que realizó el pago. Posteriormente, el usuario podrá presentar la solicitud de entrega de sus regalías, las cuales serán consignadas por BLACK LION DIGITAL S.A.S., dentro de los cuarenta y cinco (45) hábiles siguientes a la solicitud o del día siguiente a la respuesta de la reclamación presentada por el usuario. El anterior dinero se consignará en la cuenta de ahorros/corriente que fue registrada en la cuenta personal que el usuario tiene en la plataforma www.blackliondigitalsas.com. \n" +
  "\n" +
  "2.\tProcedimiento para la solicitud de las regalías recaudadas por BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "El usuario deberá seguir las siguientes intrucciones para solicitar la entrega de las regalías recuadas por BLACK LION DIGITAL S.A.S.:\n" +
  "\n" +
  "a)\tEntrar a la página www.blackliondigitalsas.com y hacer click en el vínculo con forma de muñeco con audifonos, el cual lo rediccionará a la página en dónde debe poner el usuario y contraseña que fue enviado por BLACK LION DIGITAL S.A.S. \n" +
  "\n" +
  "b)\tUna vez haya ingresado a su cuenta y siempre que tenga saldo en el ítem denominado como “Disponible para pago”, podrá solicitar el pago de las regalías recaudadas por la totalidad del valor descrito en este ítem. \n" +
  "\n" +
  "c)\tUna vez solicitado el pago, usted recibirá un correo de notificación mediante la cual se confirmará la recepción de la solicitud. A partir de dicha notificación, BLACK LION DIGITAL S.A.S. tendrá un término de cuarenta y cinco (45) días hábiles para realizar la consignación en la cuenta de ahorros y/o corriente que se haya registrado en la cuenta. \n" +
  "\n" +
  "En el evento de no recibir ningún correo de notificación, el usuario deberá enviar correo electrónico a contacto@blackliondigitalsas.com informándole a BLACK LION DIGITAL S.A.S. sobre esta circunstancia. \n" +
  "\n" +
  "d)\tSi de lo contrario el usuario no se encuentra de acuerdo con el valor incluido en el ítem “Disponible para pago”, podrá enviar su comunicación solicitando los soportes que especifican el valor registrado, al siguiente correo contacto@blackliondigitalsas.com. BLACK LION DIGITAL S.A.S. deberá dar respuesta dentro de los tres (3) días hábiles siguientes a la notificación de la solicitud de los reportes. Una vez enviados los soportes al usuario, este tendrá un término de tres (3) días hábiles para realizar la reclamación formal la cual deberá ser respondida por BLACK LION DIGITAL S.A.S. en los tres (3) días siguientes a su notificación. \n" +
  "\n" +
  "Una vez surtidos los anteriores términos, BLACK LION DIGITAL S.A.S. contará con cuarenta y cinco (45) días para realizar el pago correspondiente al valor incluido en el ítem “Disponible para pago”.\n" +
  "\n" +
  "e)\tEl procedimiento tendrá que ser interpretado de conformidad con lo establecido en la Cláusula Segunda del contrato de mandato suscrito entre las partes. \n" +
  "\n" +
  "3.\tPago de IVA (19%)\n" +
  "\n" +
  "El usuario deberá pagar el IVA, es decir el diecinueve por ciento (19%) sobre el total de las regalías recaudas por BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "4.\tDesactivación de la cuenta del usuario \n" +
  "\n" +
  "BLACK LION DIGITAL S.A.S. se reserva el derecho a desactivar la cuenta del usuario, siempre que se den las siguientes circunstancias, sin limitarse a las mismas: \n" +
  "\n" +
  "•\tPor la terminación del contrato de mandato suscrito entre las partes.\n" +
  "•\tPor el incumplimiento grave de alguna de las obligaciones incluidas en el presente documento. \n" +
  "•\tPor el uso, sin autorización, de algún signo distintivo de titularidad de BLACK LION DIGITAL S.A.S.\n" +
  "•\tPor la infracción de los derechos de autor y conexos de BLACK LION DIGITAL S.A.S.\n" +
  "•\tPor hacer uso de la plataforma para ejecutar actos ilegales a la luz del ordenamiento jurídico colombiano. \n" +
  "\n" +
  "BLACK LION DIGITAL S.A.S. procederá a notificar al usuario mediante correo electrónico, las razones por las cuales se decidió desactivar la cuenta del usuario. \n" +
  "\n" +
  "V.\tREGISTRO DE CUENTA BANCARIA EN LA PLATAFORMA \n" +
  "\n" +
  "\n" +
  "BLACK LION DIGITAL S.A.S. registrará la cuenta de ahorros y/o corriente que fue incluida por el usuario en el contrato de mandato suscrito entre las partes. Si por alguna razón BLACK LION DIGITAL S.A.S. no registra ninguna cuenta bancaria, el usuario deberá registrar en primera medida la cuenta de ahorros y/o corriente descrita en el contrato de mandato, con el propósito de que BLACK LION DIGITAL S.A.S. realice la entrega de las regalías que se encuentren descritas en la plataforma como “Disponible para pago”. \n" +
  "\n" +
  "De igual forma, se faculta al usuario para actualizar la información de la cuenta de ahorros y/o corriente, quien recibirá una notificación de la modificación, por parte de BLACK LION DIGITAL S.A.S., siempre que este acepte los términos y condiciones que se desplegarán tan pronto el usuario desee incluir una nueva cuenta para la entrega de regalías. \n" +
  "\n" +
  "Se recomienda que el usuario vincule su cuenta de ahorros y/o corriente y no las de titularidad de terceros, pues de hacerlo, BLACK LION DIGITAL S.A.S. se exime de responsabilidad de que el tercero garantice y realice la entrega de las regalías a las que tiene derecho el usuario. \n" +
  "\n" +
  "Así mismo, es preferible que el usuario abra una cuenta bancaria en Colombia o en payoneer, plataforma que permite realizar pagos transfronterizos, de lo contrario, el usuario deberá buscar el intermediario bancario más beneficioso para realizar dicha operación, teniendo en cuenta el domicilio de BLACK LION DIGITAL S.A.S. \n" +
  "\n" +
  "\n" +
  "VI.\tRECLAMACIONES POR TERCEROS\n" +
  "\n" +
  "El procedimiento para las reclamaciones por parte de terceros que carecen de vínculo contractual con BLACK LION DIGITAL S.A.S., es el siguiente:\n" +
  "\n" +
  "1.\tEn la plataforma www.blackliondigitalsas.com, en la parte inferior derecha, se encontrará un vínculo denominado “contacto”. \n" +
  "\n" +
  "2.\tUna vez le hayan dado click a el vínculo descrito anteriormente, deberá diligenciar los datos que se despliegan en este. \n" +
  "\n" +
  "Vale la pena recalcar que los datos suministrados por los terceros también tendrán el mismo tratamiento de datos dispuesto en el punto III. RESTRICCIONES, 1. Normas y políticas de BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "3.\tBLACK LION DIGITAL S.A.S. dará una respuesta dentro de los quince (15) días siguientes a la notificación de la solicitud. \n" +
  "\n" +
  "4.\tUna vez BLACK LION DIGITAL S.A.S. de respuesta a la reclamación, el tercero tendrá un término de quince (15) días hábiles para la presentación de nuevas reclamaciones o para complementar las que fueron presentadas inicialmente. \n" +
  "\n" +
  "5.\tNuevamente, BLACK LION DIGITAL S.A.S. tendrá un término de  quince (15) días para responder. El anterior procedimiento se repetirá hasta que se llegue a un acuerdo o se solucioné las reclamaciones presentadas por el tercero. \n" +
  "\n" +
  "VII.\tASPECTOS GENERALES\n" +
  "\n" +
  "Como usuario de la plataforma, tenga en cuenta lo siguiente:\n" +
  "\n" +
  "1.\tPrivacidad y competencia leal\n" +
  "\n" +
  "Al registrarse en la plataforma, usted otorga su consentimiento para la recopilación y procesamiento de toda la información personal y la de sus obras artísticas. Es por lo anterior que manejará esta información de acuerdo con lo establecido en la norma de habeas data de la legislación colombiana, la cual ha sido expresamente incorporada en este documento. \n" +
  "\n" +
  "De igual forma, BLACK LION DIGITAL S.A.S. actuará bajo los lineamientos impuestos por el ordenamiento jurídico colombiano, para que cada uno de los usuarios compitan lealmente, razón por la cual se encuentra completamente prohibido incurrir en cualquier acto desleal por parte de BLACK LION DIGITAL S.A.S. o los usuarios. Se entiende como acto desleal alguno de los siguientes:\n" +
  "\n" +
  "a)\tActos de desviación de la clientela\n" +
  "b)\tActos de desorganización\n" +
  "c)\tActos de confusión\n" +
  "d)\tActos de engaño\n" +
  "e)\tActos de descrédito\n" +
  "f)\tActos de comparación \n" +
  "g)\tActos de imitación \n" +
  "h)\tExplotación de la reputación ajena\n" +
  "i)\tViolación de secretos\n" +
  "j)\tInducción a la ruptura contractual\n" +
  "k)\tViolación de normas \n" +
  "l)\tPactos desleales de exclusividad \n" +
  "\n" +
  "Al aceptar estos Términos y Condiciones, usted acepta los términos de privacidad y competencia desleal. \n" +
  "\n" +
  "2.\tExactitud de la información\n" +
  "\n" +
  "Usted declara y garantiza que toda la información que proporciona a BLACK LION DIGITAL S.A.S. es correcta, completa y vigente, y se compromete a actualizar dicha información cuando sea necesario. De igual forma, BLACK LION DIGITAL S.A.S. se obliga a cumplir con los dispuesto en la legislación colombiana respecto del Habeas Data, con la finalidad de proteger la información proporcionada por usted, así como se espera que el usuario se rija por las mismas obligaciones al momento de utilizar y tratar la información de BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "3.\tCertificación de residencia y capacidad para celebrar contratos\n" +
  "\n" +
  "Al abrir una cuenta en la plataforma de www.blackliondigitalsas.com, usted declara y garantiza que reside en el territorio que ha indicado en su en la plataforma y en el contrato de mandato suscrito entre las partes. Así mismo, declara que tiene capacidad para celebrar contratos de conformidad con las leyes de su jurisdicción.\n" +
  "\n" +
  "4.\tNaturaleza del Contenido\n" +
  "\n" +
  "Usted entiende que al utilizar la plataforma y tener acceso al contenido de www.blackliondigitalsas.com, no podrá compartir el contenido que se encuentra limitado en el punto IV. RESTRICCIONES 3. Conducta Prohibida, buscando la protección de los menores de edad y del material que vulnera los derechos de terceros.\n" +
  "\n" +
  "De igual forma, vale la pena tener en cuenta que BLACK LION DIGITAL S.A.S. subirá fotografías, música, títulos y marcas de las obras de titularidad del usuario las cuales se encuentran protegidas por la Decisión 486 de la Comisión de la Comunidad Andina y la Ley 23 de 1983. Es por lo anterior que BLACK LION DIGITAL S.A.S. se exime de responsabilidad cuando los verdaderos titulares de los derechos patrimoniales de autor y conexos presente cualquier reclamación a BLACK LION DIGITAL S.A.S. por la infracción de dichos derechos. Como consecuencia, la responsabilidad recaerá directa y expresamente en los usuarios, quienes declararon en el contrato de mandato y en este documento, al aceptar los Términos y Condiciones, ser los titulares legítimos de los derechos patrimoniales de autor y conexos.\n" +
  "\n" +
  "5.\t Riesgo de uso\n" +
  "\n" +
  "BLACK LION DIGITAL S.A.S. no asumirá responsabilidad alguna por ningún daño o virus en su computadora, celular u otra propiedad como consecuencia del acceso, uso, descarga o navegación por la plataforma www.blackliondigitalsas.com. \n" +
  "\n" +
  "6.\tEl derecho de BLACK LION DIGITAL S.A.S. para cambiar estos Términos y Condiciones\n" +
  "\n" +
  "BLACK LION DIGITAL S.A.S. se reserva el derecho de cambiar, modificar, añadir o eliminar, en cualquier momento, partes de estos Términos y Condiciones, sin previo aviso, entrando en vigor inmediatamente y entendiendose aceptados con el solo uso de la plataforma. Si llegara a existir un cambio sustancial, haremos todos los esfuerzos comercialmente razonables, para notificárselo y obtener su consentimiento antes de implementarlos. Le recomendamos que consulte estos Términos y Condiciones en forma periódica para ver si se han registrado cambios. \n" +
  "\n" +
  "7.\tEl derecho de BLACK LION DIGITAL S.A.S. a efectuar cambios a la plataforma\n" +
  "\n" +
  "BLACK LION DIGITAL S.A.S. puede agregar, cambiar, terminar, remover o suspender cualquier material incorporado a la plataforma, incluyendo características, precios y especificaciones de las obras musicales descritos o reseñados en el presente documento, de forma temporal o permanente, en cualquier momento, sin previo aviso y eximiendose de responsabilidad. Además, BLACK LION DIGITAL S.A.S. del contenido de la plataforma puede, de forma ocasional, sacar y/o excluir el contenido de la plataforma sin previo aviso.\n" +
  "\n" +
  "VIII.\tPROPIEDAD INTELECTUAL\n" +
  "\n" +
  "1.\tPropiedad Industrial \n" +
  "\n" +
  "La plataforma contiene y/o incorpora secretos comerciales, marcas, inventos patentados, diseños industriales, modelos de utilidad, lemas comerciales, nombres comerciales, enseñas comerciales, entre otros derechos cuyo titular es BLACK LION DIGITAL S.A.S. y/o los usuarios, derechos protegidos por la propiedad industrial en la Decisión 486 de la Comisión de la Comunidad Andina. \n" +
  "\n" +
  "Es por lo anterior que usted no puede divulgar, replicar, modificar, copiar, distribuir, reproducir, reconfigurar, descomponer, o transformar total o parcialmente esta plataforma ya que su contenido es de propiedad exclusiva de BLACK LION DIGITAL S.A.S., los usuarios y/o quienes sean titulares de las respectivas licencias.\n" +
  "\n" +
  "Los usuarios serán responsables por la vulneración de los derechos que aquí fueron descritos y tendrán que responder de acuerdo con lo establecido en la Decisión 486 de la Comisión de la Comunidad Andina. \n" +
  " \n" +
  "2.\tDerechos de autor\n" +
  "\n" +
  "El contenido de la plataforma y otras obras que se encuentran en la plataforma que forman parte del mismo, están protegidos por el derecho de autor en  la Ley 23 de 1982, la Ley 1915 de 2018, el Convenio de Berna y todo otro tratado internacional que haya sido ratificado por Colombia. Teniendo en cuenta que BLACK LION DIGITAL S.A.S. y/o los usuarios son titulares de los derechos patrimoniales de autor y/o conexos de las obras contenidas en la plataforma, usted no puede realizar copias no autorizadas, reproducir, transformar, divulgar, vender, reproducir, traducir, etc., la plataforma o el contenido de la misma so pena de incurrir en la vulneración de los derechos que recaen en BLACK LION DIGITAL S.A.S., los usuarios y/o los verdaderos titulares de los derechos patrimoniales de autor y conexos sobre las obras en cuestión. \n" +
  "\n" +
  "Adicionalmente, usted podrá incurrir en el tipo penal de violación a los derechos morales de autor (artículo 270 del Código Penal Colombiano) y la violación de los mecanismos de protección de los derechos patrimoniales de autor y otras deformaciones (Artículo 272 del Código Penal Colombiano)\n" +
  "\n" +
  "Por lo anterior, usted acepta cumplir con todas las leyes aplicables para la protección de derechos de autor en relación con el uso de la plataforma y su contenido. \n" +
  "En el momento de incurrir en alguna de las anteriores actividades, BLACK LION DIGITAL S.A.S., los usuarios y/o el titular legitimo de los derechos, tomará las acciones correspondientes para que usted pueda resacir los daños ocasionados.\n" +
  "3.\tMarcas comerciales\n" +
  "\n" +
  "No puede utilizar ninguna de las marcas comerciales que se exponen en la plataforma, salvo lo especificado en el presente documento y de conformidad con la Decisión 486 de la Comisión de la Comunidad Andina en su Título VI. Es por lo anterior que no se permite que usted:\n" +
  "\n" +
  "a)\tAplique o coloque la marca o un signo distintivo idéntico o semejante sobre productos para los cuales se ha registrado la marca; sobre productos vinculados a los servicios para los cuales ésta se ha registrado; o sobre los envases, envolturas, embalajes o acondicionamientos de tales productos; \n" +
  "b)\tSuprima o modifique la marca con fines comerciales, después de que se hubiese aplicado o colocado sobre los productos para los cuales se ha registrado la marca; sobre los productos vinculados a los servicios para los cuales ésta se ha registrado; o sobre los envases, envolturas, embalajes o acondicionamientos de tales productos; \n" +
  "c)\tFabrique etiquetas, envases, envolturas, embalajes u otros materiales que reproduzcan o contengan la marca, así como comercializar o detentar tales materiales; \n" +
  "d)\tUse en el comercio un signo idéntico o similar a la marca respecto de cualesquiera productos o servicios, cuando tal uso pudiese causar confusión o un riesgo de asociación con el titular del registro. Tratándose del uso de un signo idéntico para productos o servicios idénticos se presumirá que existe riesgo de confusión; \n" +
  "e)\tUse en el comercio un signo idéntico o similar a una marca notoriamente conocida respecto de cualesquiera productos o servicios, cuando ello pudiese causar al titular del registro un daño económico o comercial injusto por razón de una dilución de la fuerza distintiva o del valor comercial o publicitario de la marca, o por razón de un aprovechamiento injusto del prestigio de la marca o de su titular; \n" +
  "f)\tUse públicamente un signo idéntico o similar a una marca notoriamente conocida, aun para fines no comerciales, cuando ello pudiese causar una dilución de la fuerza distintiva o del valor comercial o publicitario de la marca, o un aprovechamiento injusto de su prestigio. \n" +
  "En el momento de incurrir en alguna de las anteriores actividades, BLACK LION DIGITAL S.A.S. y/o el titular de los derechos, tomarán las acciones correspondientes para que usted pueda resacir los daños ocasionados. \n" +
  "4.\tDerechos de BLACK LION DIGITAL S.A.S. respecto a los materiales que usted publica\n" +
  "\n" +
  "Al enviar mensajes, opiniones, ingresar datos o participar en cualquier otra forma de comunicación a través de la plataforma, usted declara que tiene todos los derechos para publicar las obras y recibir las regalías correspondientes a los derechos patrimoniales de autor y conexos, sin que estos vulneren los derechos de terceros, y que autoriza a BLACK LION DIGITAL S.A.S. a ejecutar y celebrar los acto descritos en el contrato de mandato y este documento. \n" +
  "\n" +
  "Si el uso de tales contenidos por parte de BLACK LION DIGITAL S.A.S. explota cualquier derecho de propiedad que usted pueda tener sobre dicho material, usted acepta que BLACK LION DIGITAL S.A.S. tiene un derecho irrestricto, con el reconocimiento de un porcentaje sobre las regalías recaudas y no exclusivo, sin limitación de tiempo y lugar. \n" +
  "\n" +
  "Si con la publicación y promoción de los mensajes, opiniones, imágenes, marcas, datos, etc., se vulnera algún derecho a un tercero, usted será responsable por los daños ocasionados, ya que nunca advirtió no ser el titular del derecho que se llegaré a discutir. \n" +
  "\n" +
  "IX.\tTÉRMINOS ESTÁNDAR\n" +
  "1.\tRecursos\n" +
  "\n" +
  "Usted acuerda que cualquier uso no autorizado de la plataforma, de su contenido o de cualquier tecnología contenida en el mismo, que ocasione algún perjuicio irreparable a BLACK LION DIGITAL S.A.S. y/o a un tercero, deberá compensar los daños y perjuicios, sin perjuicio BLACK LION DIGITAL S.A.S. tendrá la facultad de ejercer la acción correspondiente en contra de usted, buscando la reparación integral de los daños ocasionados.\n" +
  "\n" +
  "2.\tIndemnización\n" +
  "\n" +
  "Ante cualquier incumplimiento de alguna de las obligaciones que este documento contiene, usted deberá indemnizar integralmente a BLACK LION DIGITAL S.A.S. y eximir de toda responsabilidad a la misma respecto a toda reclamación, pérdida, daño y perjuicio, responsabilidades, deficiencias, gravámenes, multas, costas, gastos de honorarios de abogados y entre otros gastos que surjan en la relación directa o indirectamente del uso de la plataforma. Como consecuencia de lo anterior, el usuario deberá responder por los daños pecunarios y/o morales de los que se vea BLACK LION DIGITAL S.A.S. o sus trabajadores y demás sanciones que sean impuestas judicialmente. \n" +
  "\n" +
  "3.\tTerminación\n" +
  "\n" +
  "El plazo de estos Términos y Condiciones se mantendrá vigente hasta que se de alguna de las causales de terminación del contrato de mandato y/o el incumplimiento de alguna de las obligaciones contenidas en el presente documento. \n" +
  "\n" +
  "Una vez terminada esta relación jurídica, usted debe dejar de utilizar por completo la plataforma y eximir de cualquier responsabilidad a BLACK LION DIGITAL S.A.S., sin perjuicio de las responsabilidades que se incluyeron en el contrato de mandato suscrito entre las partes.\n" +
  "\n" +
  "4.\tNotas legales; limitación de responsabilidad.\n" +
  "\n" +
  "\n" +
  "a)\tBLACK LION DIGITAL S.A.S. no garantiza que el servicio sea compatible con cualquier computadora personal, reproductor de CD o DVD, grabador de CD o DVD, o dispositivos portátiles registrados, razón por la cual usted es el único responsable de asegurarse de que sus sistemas funcionen correctamente con el servicio.\n" +
  "\n" +
  "b)\tEn ningún caso, BLACK LION DIGITAL S.A.S. será responsable por daños y perjuicios que surjan de y/o en relación con la plataforma y estos términos y condiciones, entre otros: \n" +
  "\n" +
  "•\tDaños y perjuicios derivados de la pérdida de datos\n" +
  "•\tLucro cesante y daño emergente por pérdida del uso de la plataforma \n" +
  "•\tDaños y perjuicios derivados del uso que usted haga del servicio y que infrinja estos Términos y Condiciones. Es por esto que bajo ninguna circunstancia BLACK LION DIGITAL S.A.S. o sus afiliadas serán responsables por el uso no autorizado de cualquier contenido o cualquier uso del servicio para desarrollar, distribuir o utilizar cualquier material que sea difamatorio, calumnioso, injurioso u obsceno, que constituya una invasión de cualquier derecho a la privacidad o una infracción de cualquier derecho a la publicidad, que viole o infrinja los derechos de cualquier tercero o que vulnere cualquier ley o regulación extranjera, federal, estatal o local.\n" +
  "\n" +
  "5.\tElección de derecho y jurisdicción territorial.\n" +
  "\n" +
  "Estos Términos y Condiciones se regirán de conformidad con las leyes colombianas y de la siguiente: \n" +
  "\n" +
  "CLÁUSULA COMPROMISORIA\n" +
  "\n" +
  "Toda controversia o diferencia relativa a estos Términos y Condiciones y las que surgieran en temas relacionados directa e indirectamente con derechos de autor y/o conexos que surgieran entre las partes, se resolverán por un Tribunal de Arbitramento que funcionará en el Centro de Conciliación y Arbitraje “Fernando Hinestrosa” de la Dirección Nacional de Derechos de Autor y toda diferencia que surja en relación con cualquier otra situación derivada del presente contrato, que no tenga que ver con temas de derechos de autor y/o conexos, se resolverán por el Tribunal de Arbitramento del Centro de Conciliación y Arbitraje de la Cámara de Comercio de Bogotá. \n" +
  "\n" +
  "Cualquiera de los Tribunales se regirá por el procedimiento dispuesto en la Ley 1563 del 2012 y a las demás disposiciones legales que los reglamenten, adicionen o modifiquen y de acuerdo con las siguientes reglas: \n" +
  "\n" +
  "1.\tEl Tribunal estará integrado por un (1) árbitro, si es por mínima o menor cuantía, que será nombrado por las partes en conflicto de común acuerdo y si no llegaré a haber acuerdo, se escogerá por sorteo de las listas de árbitros del Centro de Conciliación y Arbitraje competente para el asunto. \n" +
  "\n" +
  "2.\tEl Tribunal estará integrado por tres (3) árbitros si es por mayor cuantía, los cuales serán nombrados por las partes en conflicto de común acuerdo y si no llegaré a haber acuerdo, se escogerán por sorteo de las listas de árbitros del Centro de Conciliación y Arbitraje competente para el asunto.\n" +
  "\n" +
  "3.\tEl Tribunal decidirá en derecho. \n" +
  "\n" +
  "En el evento de que exista algún elemento extranjero en los conflictos estipulados en la presente cláusula, en virtud de esta misma cláusula, se podrá acudir a arbitraje internacional, según lo dispuesto en el artículo 62 de la Ley 1563 de 2012.\n" +
  "\n" +
  "Si el arbitraje es internacional, el Tribunal Arbitral funcionará en el Centro de Conciliación y Arbitraje de la Cámara de Comercio de Bogotá se regirá por las siguientes reglas:\n" +
  "\n" +
  "El Tribunal se sujetará al Reglamento de Arbitraje Comercial Internacional del Centro de Arbitraje y Conciliación de la Cámara de Comercio de Bogotá.\n" +
  "\n" +
  "1.\tEl Tribunal estará integrado por un (1) árbitro, si es por mínima o menor cuantía, que será nombrado por las partes en conflicto de común acuerdo y si no llegaré a haber acuerdo, se escogerá por sorteo de las listas de árbitros del Centro de Conciliación y Arbitraje competente para el asunto. \n" +
  "\n" +
  "2.\tEl Tribunal estará integrado por tres (3) árbitros si es por mayor cuantía, los cuales serán nombrados por las partes en conflicto de común acuerdo y si no llegaré a haber acuerdo, se escogerán por sorteo de las listas de árbitros del Centro de Conciliación y Arbitraje competente para el asunto.\n" +
  "\n" +
  "3.\tEl Tribunal decidirá en derecho.\n" +
  "\n" +
  "4.\tEl Tribunal conocerá en español (Castellano).\n" +
  "\n" +
  "5.\tLas leyes de fondo aplicables serán las de la República de Colombia.\n" +
  "\n" +
  "6.\tConvenio total y divisibilidad\n" +
  "\n" +
  "Estos Términos y Condiciones constituye el acuerdo total entre usted y BLACK LION DIGITAL S.A.S. y se debe interpretar de manera conjunta con el contrato de mandato suscrito entre las partes, reemplazando cualquier otro documento, convenio y entendimiento previo y/o contemporáneo,  ya sea escritos u orales, que haya existido entre las partes. Salvo lo expresamente establecido en el presente documento, estos Términos y Condiciones no podrán ser enmendados, modificados o complementados por las partes, excepto mediante instrumento por escrito firmado por un directivo autorizado de BLACK LION DIGITAL S.A.S. De manera complementaria, se entiende que no se ha renunciado a ninguna disposición de este documento, y si así lo quisiera BLACK LION DIGITAL S.A.S. debería realizarlo de manera escrita, firmada por un directivo autorizado y notificando a los usuarios.\n" +
  "\n" +
  "De igual forma, si el Tribunal de Arbitramento de la Cámara de Comercio determinara que una parte de estos Términos y Condiciones es inválida o inexigible, el resto de estos términos tendrán validez jurídica y deberán ser interpretados de modo que refleje razonablemente la intención de las partes. \n" +
  "\n" +
  "7.\tCesión de los Términos y Condiciones\n" +
  "\n" +
  "En cuanto a la cesión de las obligaciones, estos Términos y Condciones podrán ser cedidos por BLACK LION DIGITAL S.A.S., siempre y cuando se hayan notificado al usuario. Este derecho que no puede ser ejercido por el usuario, bien sea total o parcialmente, sin el consentimiento previo por escrito de un directivo autorizado de BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "8.\tPropietario posterior del Servicio; Fusión/adquisición de BLACK LION DIGITAL S.A.S.\n" +
  "\n" +
  "Al utilizar la plataforma, usted acuerda que BLACK LION DIGITAL S.A.S. o una de sus compañías podrán entrar en proceso de fusión y adquisición o vender la plataforma, ceder sus derechos y obligaciones en relación con la plataforma. En caso de realizarse dicha fusión, adquisición, venta o cesión, la plataforma seguirá funcionando y estos Términos y Condiciones seguirán vigentes hasta que el nuevo propietario considere lo contrario. \n" +
  "\n" +
  "X.\tDEFINICIONES\n" +
  "\n" +
  '1.\t"Usuarios" los artistas es decir, compostiores e interprétes (musicos, cantantes, etc) de las obras musicales, que hayan suscrito el contrato de mandato con BLACK LION DIGITAL S.A.S.\n' +
  '2.\t"Contrato de mandato" significa el contrato suscrito entre las partes mediante el cual se le otorgan facultades excepcionales a BLACK LION DIGITAL S.A.S. para actuar en nombre y representación del usuario. \n' +
  '3.\t"Contenido de www.blackliondigitalsas.com " significa la música, imágenes, texto y otros materiales que usted puede obtener o a los cuales puede acceder en forma digital desde la plataforma, cuyo contenido es propiedad de BLACK LION DIGITAL S.A.S.  o de terceros licenciadores.\n' +
  '4.\t"Información de Registro" significa la información que usted proporciona a BLACK LION DIGITAL S.A.S.  por medio de la plataforma www.blackliondigitalsas.com, al registrarse y durante el proceso de instalación que lleva a cabo para utilizarla, incluyendo un nombre único de miembro (usuario), una contraseña y su dirección válida de correo electrónico. \n' +
  "\n" +
  "XI.\tPREGUNTAS\n" +
  "\n" +
  "Si tiene alguna pregunta o comentario sobre estos Términos y Condiciones, no dude en contactarnos mediante el siguiente correo contacto@blackliondigitalsas.com. \n" +
  " \n" +
  "BLACK LION DIGITAL S.A.S.\n" +
  "Calle 145 No. 12 86 Of 101 \n" +
  "Bogotá, Colombia\n" +
  "\n" +
  "Se reserva cualquier derecho que no esté expresamente otorgado en el presente documento.\n";

export const terminosBancarios =
  "TÉRMINOS Y CONDICIONES PARA EL REGISTRO DE CUENTA BANCARIA EN LA PLATAFORMA\n" +
  "\n" +
  "\n" +
  "BLACK LION DIGITAL S.A.S. registrará la cuenta de ahorros y/o corriente que fue incluida por el usuario en el contrato de mandato suscrito entre las partes. Si por alguna razón BLACK LION DIGITAL S.A.S. no registra ninguna cuenta bancaria, el usuario deberá registrar en primera medida la cuenta de ahorros y/o corriente descrita en el contrato de mandato, con el propósito de que BLACK LION DIGITAL S.A.S. realice la entrega de las regalías que se encuentren descritas en la plataforma como “Disponible para pago”. \n" +
  "\n" +
  "De igual forma, se faculta al usuario para actualizar la información de la cuenta de ahorros y/o corriente, quien recibirá una notificación de la modificación, por parte de BLACK LION DIGITAL S.A.S., siempre que este acepte los términos y condiciones que se desplegarán tan pronto el usuario desee incluir una nueva cuenta para la entrega de regalías. \n" +
  "\n" +
  "Se recomienda que el usuario vincule su cuenta de ahorros y/o corriente y no las de titularidad de terceros, pues de hacerlo, BLACK LION DIGITAL S.A.S. se exime de responsabilidad de que el tercero garantice y realice la entrega de las regalías a las que tiene derecho el usuario. \n" +
  "\n" +
  "Así mismo, es preferible que el usuario abra una cuenta bancaria en Colombia o en payoneer, plataforma que permite realizar pagos transfronterizos, de lo contrario, el usuario deberá buscar el intermediario bancario más beneficioso para realizar dicha operación, teniendo en cuenta el domicilio de BLACK LION DIGITAL S.A.S.\n";
