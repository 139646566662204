import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getTemaActual } from "../../../../reducers/academiaReducer";
import { ERROR } from "../../../../utilidades/Constantes";
import IconoAgregar from "../../../general/IconoAgregar";
import IconoEditar from "../../../general/IconoEditar";
import IconoEliminar from "../../../general/IconoEliminar";
import * as actions from "./../../../../actions/academiaActions";
import DialogoUnBoton from "./../../../general/DialogoUnBoton";
import DialogoEditar from "./../../../general/DialogoEditar";
import ScaleLoader from "react-spinners/ScaleLoader";

const DetalleCategoria = ({
  tema,
  getDBCursosCategoria,
  getDBTemaConCategorias,
}) => {
  const history = useHistory();
  const { idTema, idCategoria } = useParams();
  const [categoriaActual, setCategoriaActual] = useState();
  const [dialogo, setDialogo] = useState({});
  //Nombre para manejar la edición del dialogo editar.
  const [nombreCategoria, setNombreCategoria] = useState("");
  const [cargando, setCargando] = useState(false);

  useEffect(() => {
    if (!tema) getDBTemaConCategorias(idTema);
    else if ((tema.existe !== undefined) & !tema.existe)
      history.push("/administrador/academia");
    else if (!tema.categorias) getDBTemaConCategorias(idTema);
    else {
      const categoriasFiltradas = tema.categorias.filter(
        (categoria) => categoria.uidDoc === idCategoria
      );
      if (categoriasFiltradas.length === 0)
        history.push(`/administrador/academia/${idTema}`);
      else if (!categoriasFiltradas[0].cursos)
        getDBCursosCategoria(idTema, idCategoria);
      else {
        setCategoriaActual(categoriasFiltradas[0]);
        setNombreCategoria(categoriasFiltradas[0].nombre);
      }
    }
  }, [tema, getDBTemaConCategorias]);

  const handleChange = (e) => {
    setNombreCategoria(e.target.value);
  };

  const handleEliminar = async (idCurso) => {
    setCargando(true);
    const res = await actions.eliminarCurso(idTema, idCategoria, idCurso);
    if (res.estado === ERROR)
      setDialogo({
        titulo: res.estado,
        mensaje: res.mensaje,
        boton1: "Regresar",
        click1: () => setDialogo({}),
      });
    else setDialogo({});
    setCargando(false);
  };

  const handleEditarCategoria = async () => {
    setCargando(true);
    const res = await actions.editarCategoria(idTema, idCategoria, {
      nombre: nombreCategoria,
    });
    if (res.estado === ERROR) {
      setDialogo({
        titulo: res.estado,
        mensaje: res.mensaje,
        boton1: "Regresar",
        click1: () => setDialogo({}),
      });
    }
    setCargando(false);
  };

  const handleEliminarCategoria = async () => {
    setCargando(true);
    const res = await actions.eliminarCategoria(
      idTema,
      idCategoria,
      categoriaActual.cursos
    );
    if (res.estado === ERROR) {
      setDialogo({
        titulo: res.estado,
        mensaje: res.mensaje,
        boton1: "Regresar",
        click1: () => setDialogo({}),
      });
      setCargando(false);
    } else history.push(`/administrador/academia/${idTema}`);
  };
  return (
    <div className="container w-10/12 mx-auto mt-5 font-proxima">
      {cargando && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-30">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
        </div>
      )}
      {dialogo === "editar-categoria" && (
        <DialogoEditar
          habilitado={
            nombreCategoria && nombreCategoria !== categoriaActual?.nombre
          }
          handleGuardar={() => handleEditarCategoria()}
          handleCancelar={() => setDialogo("")}
        >
          <div className="flex flex-col ml-1">
            <h3 className="font-bold text-3xl text-black sm:mt-8 font-proxima">
              Nombre de la categoria
            </h3>
            <input
              type="text"
              value={nombreCategoria}
              onChange={handleChange}
              placeholder="Nombre de la categoria"
              className="w-[440px] border-b-[1px] border-[#BBB] placeholder-[#BBB] mt-11 outline-none text-black max-w-full"
            />
          </div>
        </DialogoEditar>
      )}
      {dialogo.titulo && (
        <DialogoUnBoton
          titulo={dialogo.titulo}
          descripcion={dialogo.mensaje}
          boton1={dialogo.boton1}
          click1={() => dialogo.click1()}
          boton2={dialogo.boton2}
          click2={() => dialogo.click2()}
          cerrar={() => setDialogo({})}
        />
      )}
      {categoriaActual && (
        <>
          <div className="flex flex-wrap ml-3 items-center">
            <span
              className="font-normal text-xl cursor-pointer"
              onClick={() => history.push("/administrador/academia")}
            >
              Temas
            </span>
            <span className="font-normal mx-5 text-xl">{">"}</span>
            <span
              className="font-semibold text-xl cursor-pointer"
              onClick={() => history.push(`/administrador/academia/${idTema}`)}
            >
              {tema?.nombre}
            </span>
            <span className="font-normal mx-5 text-xl">{">"}</span>
            <span className="font-semibold text-xl text-[#E84A84]">
              {categoriaActual?.nombre}
            </span>
          </div>
          <div className="flex flex-col sm:flex-row mt-7">
            <div className="w-full">
              <h1 className="text-[52px] font-bold leading-[55px] w-full">
                {categoriaActual?.nombre}
              </h1>
              <div className="flex items-center mt-5">
                <IconoEditar onClick={() => setDialogo("editar-categoria")} />
                <IconoEliminar
                  onClick={() =>
                    setDialogo({
                      titulo: "¿Seguro que quiere eliminar esta categoria?",
                      boton1: "Eliminar",
                      click1: () => handleEliminarCategoria(),
                      boton2: "Cancelar",
                      click2: () => setDialogo({}),
                    })
                  }
                />
              </div>
            </div>
          </div>
          <h2 className="text-[40px] font-bold leading-[55px] mt-10 sm:ml-5">
            Cursos
          </h2>
          <div className="mt-5 sm:ml-5 flex items-center">
            <span className="text-[26px] mr-5">Crear un nuevo curso</span>
            <IconoAgregar
              onClick={() =>
                history.push(
                  "/administrador/academia/" +
                    idTema +
                    "/categoria/" +
                    idCategoria +
                    "/curso/crear"
                )
              }
            />
          </div>
          <div className="mt-10 sm:ml-28 mb-10">
            {categoriaActual?.cursos?.map((curso) => (
              <div
                className="w-[757px] max-w-full flex justify-between border-b-2 pb-6 mb-7"
                key={curso.uidDoc}
              >
                <span className="text-[26px]">{curso.nombre}</span>
                <div className="flex items-center">
                  <IconoEditar
                    onClick={() =>
                      history.push(
                        "/administrador/academia/" +
                          idTema +
                          "/categoria/" +
                          idCategoria +
                          "/curso/" +
                          curso.uidDoc
                      )
                    }
                  />
                  <IconoEliminar
                    onClick={() =>
                      setDialogo({
                        titulo: "¿Seguro que quiere eliminar esta categoria?",
                        boton1: "Eliminar",
                        click1: () => handleEliminar(curso.uidDoc),
                        boton2: "Cancelar",
                        click2: () => setDialogo({}),
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  tema: getTemaActual(state, props.match.params.idTema),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetalleCategoria)
);
