import React, { useState } from "react";
import DialogoEditar from "../../../general/DialogoEditar";
import PropTypes from "prop-types";

const EditarTema = ({ handleGuardar, handleCancelar, tema }) => {
  const [temaActual, setTemaActual] = useState(
    tema ?? {
      nombre: "",
      descripcion: "",
      imagen: "",
    }
  );

  const handleChange = (e) => {
    setTemaActual({
      ...temaActual,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (e) => {
    setTemaActual({
      ...temaActual,
      [e.target.name]: e.target.files[0],
    });
  };

  return (
    <DialogoEditar
      handleCancelar={handleCancelar}
      handleGuardar={() => handleGuardar(temaActual)}
      habilitado={
        !!temaActual.nombre &
        !!temaActual.descripcion &
        !!temaActual.imagen &
        (temaActual !== tema)
      }
    >
      <div className="flex flex-col ml-1">
        <h3 className="font-bold text-xl text-black lg:mt-8 font-proxima">
          Nombre del tema
        </h3>
        <input
          type="text"
          value={temaActual.nombre}
          onChange={handleChange}
          name="nombre"
          placeholder="Nombre del archivo"
          className="w-[300px] border-b-[1px] border-[#BBB] placeholder-[#BBB] mt-4 outline-none text-black max-w-full"
        />
        <h3 className="font-bold text-xl text-black mt-8 font-proxima">
          Descripción del tema
        </h3>
        <input
          type="text"
          value={temaActual.descripcion}
          onChange={handleChange}
          name="descripcion"
          placeholder="Descripción"
          className="w-[300px] border-b-[1px] border-[#BBB] placeholder-[#BBB] mt-4 outline-none text-black max-w-full"
        />
        <h3 className="font-bold text-xl text-black mt-8 font-proxima">
          Imagen del tema
        </h3>
        <span
          className={
            temaActual?.imagen?.name || temaActual?.imagen
              ? "w-[300px] max-w-full border-b-[1px] border-[#BBB] mt-4 outline-none text-black h-6 whitespace-nowrap break-words overflow-hidden"
              : "w-[300px] max-w-full border-b-[1px] border-[#BBB] mt-4 outline-none text-[#BBB] h-6 whitespace-nowrap break-words overflow-hidden"
          }
        >
          {temaActual?.imagen?.name
            ? temaActual?.imagen?.name
            : temaActual?.imagen !== ""
            ? temaActual?.imagen
            : "Nombre del archivo"}
        </span>
        <label
          htmlFor="video-curso"
          className="bg-[#E84A84] text-white relative w-max px-[10px] py-2 rounded-md cursor-pointer text-[17px] self-end mt-5"
        >
          <input
            id="video-curso"
            type="file"
            name="imagen"
            className="absolute w-full h-full opacity-0 top-0 left-0 right-0 bottom-0 outline-none cursor-pointer z-[-1]"
            onChange={handleFile}
          />
          Seleccionar archivo
        </label>
      </div>
    </DialogoEditar>
  );
};

EditarTema.propTypes = {
  handleGuardar: PropTypes.func.isRequired,
  handleCancelar: PropTypes.func.isRequired,
  tema: PropTypes.any,
};

export default EditarTema;
