import firebase from "./Firebase";
import { ERROR, OK } from "../utilidades/Constantes";

const database = firebase.firestore();

export const consultarColeccion = async (
  ruta,
  dispatch,
  etiqueta,
  opciones
) => {
  const onCollectionUpdate = (querySnapshot) => {
    const prds = [];
    querySnapshot.forEach((doc) => {
      prds.push({ ...doc.data(), uidDoc: doc.id });
    });
    dispatch({
      type: etiqueta,
      payload: prds,
      opciones,
    });
  };
  const suscripcion = database
    .collection(ruta)
    .onSnapshot(onCollectionUpdate, (e) => {
      if (e.message === "Missing or insufficient permissions.") {
        suscripcion();
      }
    });
};

export const consultarColeccionConSubcoleccion = (
  ruta,
  subcoleccion,
  dispatch,
  etiqueta
) => {
  const onCollectionUpdate = (querySnapshot) => {
    const prds = [];
    const promises = [];
    querySnapshot.forEach((doc) => {
      promises.push(
        database
          .collection(ruta) //temas/dasdada/categorias
          .doc(doc.id)
          .collection(subcoleccion) //temas/dasdada/categorias/dasdadada/cursos
          .get()
          .then((res) => {
            const terapias = [];
            res.forEach((terapia) =>
              terapias.push({ ...terapia.data(), uidDoc: terapia.id })
            );
            prds.push({ ...doc.data(), uidDoc: doc.id, terapias });
          })
      );
    });

    Promise.all(promises).then(() => {
      dispatch({
        type: etiqueta,
        payload: prds,
      });
    });
  };
  const suscripcion = database
    .collection(ruta) //temas/dasdada/categorias
    .onSnapshot(onCollectionUpdate, (e) => {
      if (e.message === "Missing or insufficient permissions.") {
        suscripcion();
      }
    });
};

// Consulta un documento en especifico y retorna los documentos que se encuentran dentro de las subcolecciones
// que se reciben como parametro. No se actualiza automaticamente cuando cambia una subcoleccion
export const consultarDocumentoConSubColecciones = (
  ruta,
  subcolecciones,
  dispatch,
  etiqueta
) => {
  const onDocumentUpdate = (querySnapshot) => {
    if (!querySnapshot.exists) {
      return null;
    }

    let doc = { ...querySnapshot.data(), uidDoc: querySnapshot.id };
    const promises = [];

    subcolecciones.forEach((subcoleccion) => {
      promises.push(
        database
          .doc(ruta)
          .collection(subcoleccion)
          .get()
          .then((res) => {
            const subcoleccion_array = [];
            res.forEach((doc_sub) =>
              subcoleccion_array.push({ ...doc_sub.data(), uidDoc: doc_sub.id })
            );
            doc = { ...doc, [subcoleccion]: subcoleccion_array };
          })
      );
    });

    Promise.all(promises).then(() => {
      dispatch({
        type: etiqueta,
        payload: doc,
      });
    });
  };
  const suscripcion = database.doc(ruta).onSnapshot(onDocumentUpdate, (e) => {
    if (e.message === "Missing or insufficient permissions.") {
      suscripcion();
    }
  });
};

// Consulta un documento especifico y una de sus subcolecciones en especifico. Se actualiza automaticamente cuando
// hay un cambio en la base de datos
export const consultarDocumentoConSubColeccion = (
  ruta,
  subcoleccion,
  dispatch,
  etiqueta
) => {
  const onDocumentUpdate = (querySnapshot) => {
    // if (!querySnapshot.exists) {
    //   return null
    // }

    let doc = { ...querySnapshot.data(), uidDoc: querySnapshot.id };

    database
      .doc(ruta)
      .collection(subcoleccion)
      .onSnapshot((res) => {
        const subcoleccion_array = [];
        res.forEach((doc_sub) =>
          subcoleccion_array.push({ ...doc_sub.data(), uidDoc: doc_sub.id })
        );
        doc = {
          ...doc,
          [subcoleccion]: subcoleccion_array,
          existe: querySnapshot.exists,
        };
        dispatch({
          type: etiqueta,
          payload: doc,
        });
      });
  };

  const suscripcion = database.doc(ruta).onSnapshot(onDocumentUpdate, (e) => {
    if (e.message === "Missing or insufficient permissions.") {
      suscripcion();
    }
  });
};

export const consultarColeccionCampoEspecifico = async (
  ruta,
  campo,
  valor,
  dispatch,
  etiqueta
) => {
  const onCollectionUpdate = (querySnapshot) => {
    const prds = [];
    querySnapshot.forEach((doc) => {
      prds.push({ ...doc.data(), uidDoc: doc.id });
    });
    dispatch({
      type: etiqueta,
      payload: prds,
    });
  };
  const suscripcion = database
    .collection(ruta)
    .where(campo, "==", valor)
    .onSnapshot(onCollectionUpdate, (e) => {
      if (e.message === "Missing or insufficient permissions.") {
        suscripcion();
      }
    });
};

export const consultarDocumentosPorId = async (
  ruta,
  arregloIds,
  dispatch,
  etiqueta
) => {
  const onCollectionUpdate = (querySnapshot) => {
    const prds = [];
    querySnapshot.forEach((doc) => {
      prds.push({ ...doc.data(), uidDoc: doc.id });
    });
    dispatch({
      type: etiqueta,
      payload: prds,
    });
  };
  const suscripcion = database
    .collection(ruta)
    .where(firebase.firestore.FieldPath.documentId(), "in", arregloIds)
    .onSnapshot(onCollectionUpdate, (e) => {
      if (e.message === "Missing or insufficient permissions.") {
        suscripcion();
      }
    });
};

export const consultarDocumento = async (ruta, dispatch, etiqueta) => {
  const onCollectionUpdate = (querySnapshot) => {
    if (querySnapshot.exists === false) {
      return null;
    }
    dispatch({
      type: etiqueta,
      payload: { ...querySnapshot.data(), uidDocumento: querySnapshot.id },
    });
  };
  const suscripcion = database.doc(ruta).onSnapshot(onCollectionUpdate, (e) => {
    if (e.message === "Missing or insufficient permissions.") {
      suscripcion();
    }
  });
};

export const crearDocumento = async (ruta, doc) => {
  try {
    const documento = await database.doc(ruta).set({ ...doc });
    return documento;
  } catch (e) {
    return ERROR;
  }
};

export const eliminarDocumento = async (ruta) => {
  database
    .doc(ruta)
    .delete()
    .then(() => {
      return OK;
    })
    .catch((error) => {
      return ERROR;
    });
};

export const crearDocumentoAutoID = async (ruta, doc) => {
  try {
    const documento = database.collection(ruta).doc();
    await documento.set(doc);
    return documento;
  } catch (e) {
    return ERROR;
  }
};

export const consultarColeccionConOrden = async (ruta, campo, tipo) => {
  let coleccion = [];

  const onCollectionUpdate = (querySnapshot) => {
    const prds = [];
    querySnapshot.forEach((doc) => {
      prds.push({ ...doc.data(), uidDoc: doc.id });
    });
    coleccion = prds;
    return coleccion;
  };

  const suscripcion = database
    .collection(ruta)
    .orderBy(campo, tipo)
    .onSnapshot(onCollectionUpdate, (e) => {
      if (e.message === "Missing or insufficient permissions.") {
        suscripcion();
      }
    });
};

export const editarDocumento = (ruta, doc) => {
  return database
    .doc(ruta)
    .update(doc)
    .then((res) => {
      return OK;
    })
    .catch((err) => {
      return ERROR;
    });
};

export const traerCopiaDocumento = async (ruta) => {
  const docRef = database.doc(ruta);
  const doc = await docRef.get();
  if (!doc.exists) {
    return ERROR;
  } else {
    return doc.data();
  }
};
