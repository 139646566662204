import { XIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../../../../context/ModalContext";
import { useSelector } from "react-redux";
import { selectRedesSocialesArtista } from "./../../../../../reducers/userReducer";
import { traerRedesArtista } from "./../../../../../actions/usersActions";
import GraficaRedesSociales from "../../../../general/GraficaRedesSociales";
import { useDispatch } from "react-redux";
import { ScaleLoader } from "react-spinners";
import { COLORES } from "../../../../../utilidades/Constantes";

const ModalGraficaRedes = ({ idArtista }) => {
  const { setModal } = useContext(ModalContext);
  const redesSociales = useSelector(selectRedesSocialesArtista);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!redesSociales) {
      setLoading(true);
      traerRedesArtista(idArtista, dispatch);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div className="bg-white text-black p-5 rounded-xl flex flex-col w-[500px] max-w-[100%]">
      <div className="flex justify-between w-full items-center">
        <h2 className="text-rosa-primariy text-[20px] font-semibold">
          Seguidores redes sociales
        </h2>
        <XIcon
          onClick={() => setModal(null)}
          className="w-[25px] text-rosa-primariy cursor-pointer"
        />
      </div>
      {redesSociales?.registrosSeguidoresRedes.length > 0 &&
      redesSociales?.redes.length > 0 ? (
        <GraficaRedesSociales
          registrosSeguidoresRedes={redesSociales?.registrosSeguidoresRedes}
          redes={redesSociales?.redes}
        />
      ) : loading ? (
        <div className="w-full flex justify-center my-10">
          <ScaleLoader color={COLORES.PRIMARIO} />
        </div>
      ) : (
        <h1 className="text-[15px] font-semibold text-center my-10">
          Todavía no hay registros de tus redes sociales
        </h1>
      )}
    </div>
  );
};

export default ModalGraficaRedes;
