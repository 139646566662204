import { llamarFunction } from "../proxy/CloudFunctions";
import {
  cerrarSesion,
  iniciarSesion,
  recuperarContrasena,
  verificarSesion,
} from "../proxy/FirebaseAuth";
import { getData, patchData } from "../proxy/RestCall";
import {
  CLEAN_USER,
  ERROR,
  ERROR_PASSWORD_INCORRECTO,
  ERROR_USUARIO_DESCONOCIDO,
  SESION,
  UID_USER,
  ERROR_MUCHOS_INTENTOS,
  SOLICITAR_PAGO,
  TRAER_LOGROS,
  SOLICITUD_PAGO,
  OK,
} from "../utilidades/Constantes";
import { EDITAR_INFO_BANCARIA } from "./usersActions";

export const ingresar = (user, password) => async (dispatch) => {
  const uid = await iniciarSesion(user, password);
  if (
    uid !== ERROR &&
    uid !== ERROR_PASSWORD_INCORRECTO &&
    uid !== ERROR_USUARIO_DESCONOCIDO &&
    uid !== ERROR_MUCHOS_INTENTOS
  ) {
    await buscarUsuario(uid, dispatch); 
  }

  dispatch({
    type: UID_USER,
    payload: uid,
  });
};

export const traerUidUsuario = () => (dispatch) => {
  verificarSesion(dispatch, UID_USER);
};

export const traerUsuario = (uid) => (dispatch) => {
  buscarUsuario(uid, dispatch);
};

export const registroCompletoArtista = async (nombre, uid) => {
  return await patchData(
    `usuarios/${uid}`,
    {},
    {
      nombre: nombre,
      fechaRegistro: new Date(),
    }
  );
};

const buscarUsuario = async (uid, dispatch) => {
  const res = await getData(`usuarios/${uid}`);
  dispatch({
    type: SESION,
    payload: res.data,
  });
};

export const limpiarUsuario = () => (dispatch) => {
  // console.log('apagando suscripciones :D')
  // apagarListeners()
  // console.log('Suscripciones terminadas :D')
  cerrarSesion();
  dispatch({
    type: CLEAN_USER,
    payload: null,
  });
};

export const recuperarPassword = async (correo) => {
  return await recuperarContrasena(correo);
};

export const traerArtista = async (idArtista, dispatch) => {
  const res = await getData(`usuarios/${idArtista}`, {});
  dispatch({ type: SESION, payload: res.data });
  return res;
};

export const solicitarPagos = async (idArtista, dispatch, correo, nombreArtista) => {
  const res = await patchData(`usuarios/${idArtista}/solicitarPago`, {});
  const fecha = retornarFecha();
  const parametros = {
    tipo: SOLICITUD_PAGO,
    diaMes: fecha[0],
    anio: fecha[1],
    hora: fecha[2],
    email: correo,
    nombreArtista
  };
  enviarCorreo(parametros);

  dispatch({ type: SOLICITAR_PAGO, payload: true });
  return res;
};

export const traerLogros = async (idArtista, dispatch) => {
  const res = await getData(`usuarios/${idArtista}/logros`, {});
  dispatch({ type: TRAER_LOGROS, payload: res.data });
  return res;
};

export const editarInfoBancaria = async (idArtista, infoArtista, dispatch) => {
  delete infoArtista.pagoSolicitado
  const res = await patchData(
    `usuarios/${idArtista}`,
    {},
    infoArtista
  );
  dispatch({
    type: EDITAR_INFO_BANCARIA,
    payload: res.data,
  });
};

const retornarFecha = () => {
  const fecha = new Date();
  const dia = fecha.getDate();
  const mes = fecha.getMonth();
  const anio = fecha.getFullYear();
  const hora = fecha.getHours();
  const minutos = fecha.getMinutes();
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return [
    `${dia} de ${meses[mes]}`,
    anio,
    `${hora < 10 ? `0${hora}` : hora}:${minutos}`,
  ];
};

async function enviarCorreo(parametros) {
  const correoEnviado = await llamarFunction("correoArtista", parametros);
  if (correoEnviado === "exito") {
    return OK;
  } else {
    return ERROR;
  }
}
