import React from "react";
import { useHistory } from "react-router";

const CursosCategoria = ({ categoriaActiva, idTema }) => {
  const history = useHistory();

  return (
    <div
      className="w-[260px] h-[390px] rounded-[20px] font-proxima  overflow-hidden"
      style={{
        background: "rgba(255, 255, 255, 0.15)",
        boxShadow: "0px 4px 4px rgba(48, 25, 94, 0.25)",
      }}
    >
      {categoriaActiva?.cursos?.map((curso, index) => (
        <div key={index} className="w-full space-y-3">
          <div className="w-full h-full">
            <img
              className="max-h-32 w-full object-cover"
              src={curso.imagen}
              alt=""
            />
          </div>
          <div className="w-full p-5 space-y-5">
            <h3 className="text-base font-bold text-left">{curso.nombre}</h3>
            <p className="text-left text-sm font-normal h-20">
              {curso.descripcion}
            </p>
            <div className="w-full pt-4 flex flex-col justify-end">
              <button
                className="p-[10px] w-[126px] bg-[#756194] rounded-[5px] text-sm font-semibold hover:bg-white hover:text-[#351C60] hover:shadow-sm transition-all duration-300 ease-in-out"
                type="button"
                onClick={() =>
                  history.push(
                    "/academia/" +
                      idTema +
                      "/categorias/" +
                      categoriaActiva.uidDoc +
                      "/cursos/" +
                      curso.uidDoc
                  )
                }
              >
                Comenzar
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CursosCategoria;
