import React, { Component } from "react";
import "../../styles/base.css";
import "../../styles/contacto.css";
import { withRouter } from "react-router-dom";
import firebase from "../../proxy/Firebase";
import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/react/outline";

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: false,
      formularioEnviado: false,
      nombre: "",
      correo: "",
      celular: "",
      tipo: "",
      mensaje: "",
      telefono: "",
      dialogo: {
        abrir: false,
        titulo: "",
        texto: "",
        btn1: "",
        enviar: "",
      },
    };
    this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  goBack(history) {
    history.goBack();
  }

  findUserToken() {
    const name = "hubspotutk";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length + 1, c.length);
      }
    }
    return "";
  }

  abrirDialogoEnviado = () => {
    let enviar = firebase.functions().httpsCallable("enviarCorreoApp");
    enviar({ email: this.state.correo }).then((res) => {
      if (res.data === "exito") {
        this.setState({
          formularioEnviado: true,
          cargando: false,
          nombre: "",
          correo: "",
          celular: "",
          tipo: "",
          mensaje: "",
          telefono: "",
          dialogo: {
            abrir: true,
            titulo: "TU MENSAJE FUE ENVIADO CON ÉXITO",
            texto: "Pronto nos comunicaremos contigo.",
            btn1: "ENTENDIDO",
            enviar: "/contacto-enviado",
          },
        });
      }
      else
        this.setState({
          cargando: false,
          dialogo: {
            abrir: true,
            titulo: "PROBLEMAS ENVIANDO TU SOLICITUD",
            texto:
              "Hubo un problema al tratar de enviar tu solicitud, vuélvelo a intentar",
            btn1: "REINTENTAR",
            enviar: "/contacto",
          },
        });
    });
  };

  abrirDialogoProblemas = () => {
    this.setState({
      cargando: false,
      dialogo: {
        abrir: true,
        titulo: "PROBLEMAS ENVIANDO TU SOLICITUD",
        texto:
          "Hubo un problema al tratar de enviar tu solicitud, vuélvelo a intentar",
        btn1: "REINTENTAR",
        enviar: "/contacto",
      },
    });
  };

  cerrarDialogo = () => {
    this.setState({
      dialogo: {
        abrir: false,
      },
    });
  };

  enviarRegistro = async (e) => {
    e.preventDefault();
    this.setState({
      cargando: true,
    });
    const ip = await fetch("https://api.ipify.org/?format=json")
      .then((result) => result.json())
      .then((data) => data.ip);
    const userToken = this.findUserToken();
    // Create the new request
    let xhr = new XMLHttpRequest();
    let url =
      "https://api.hsforms.com/submissions/v3/integration/submit/7664318/eb0d0739-cb9b-463e-929e-4e617036fcc4";

    // Request JSON:
    let data = {
      submittedAt: new Date().toISOString(),
      fields: [
        {
          name: "firstname",
          value: this.state.nombre,
        },
        {
          name: "email",
          value: this.state.correo,
        },
        {
          name: "phone",
          value: this.state.telefono,
        },
        {
          name: "message",
          value: this.state.mensaje,
        }
      ],
      context: {
        hutk: userToken,
        ipAddress: ip,
        pageUri: "https://blackliondigitalsas.com/contacto",
        pageName: "Black Lion Web",
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text: "I agree to allow Example Company to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Example Company.",
            },
          ],
        },
      },
    };

    const abrirDialogBien = this.abrirDialogoEnviado;
    const abrirDialogMal = this.abrirDialogoProblemas;

    let final_data = JSON.stringify(data);

    xhr.open("POST", url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json");
    abrirDialogBien();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log();
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        abrirDialogMal();
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        abrirDialogMal();
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        abrirDialogMal();
      }
    };

    // Sends the request

    xhr.send(final_data);
  };

  render() {
    return (
      <section className="bg-[#000023] pt-16">
        <div className="container mx-auto flex gap-x-14">
          <div className=" overflow-x-hidden  h-full font-proxima w-full flex flex-col justify-center items-center lg:flex-row space-y-20 md:space-x-20 py-5 md:py-10">
            <div className="flex flex-col w-11/12 mx-16">
              <div className="flex flex-col justify-center gap-y-5 lg:items-start items-center ">
                <h3 className="text-4xl font-bold pb-1 border-b-[#E84A84] relative text-center">
                  ¡Trabajemos <span className="text-[#E84A84]">juntos!</span>
                </h3>

                <p className="w-4/5 mt-5 text-center lg:text-left text-sm md:text-base font-light mb-5">
                  Muchos artistas ya están incrementando su audiencia y asegurando su música con nosotros.
                </p>
              </div>
              <div className="mt-5">
                {
                  !this.state.cargando && !this.state.formularioEnviado ?
                    <form
                      action=""
                      className="flex flex-col space-y-6 w-[80%] mx-auto lg:w-full"
                      onSubmit={(e) => this.enviarRegistro(e)}
                    >
                      <div className="flex flex-col">
                        <label
                          htmlFor=""
                          className="text-sm md:text-[18px] mb-2 font-semibold"
                        >
                          Nombre
                        </label>
                        <input
                          name="nombre"
                          value={this.state.nombre}
                          onChange={(e) => this.handleChange(e)}
                          type="text"
                          placeholder="Escribe tu nombre"
                          className="text-black ring ring-gray-100-300 w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-pink-300 mt-2"
                          required
                        />
                      </div>
                      <div className="flex flex-col">
                        <label
                          htmlFor=""
                          className="text-sm md:text-[18px] mb-2 font-semibold"
                        >
                          Correo
                        </label>
                        <input
                          name="correo"
                          value={this.state.correo}
                          onChange={(e) => this.handleChange(e)}
                          type="email"
                          placeholder="Escribe tu correo"
                          className="text-black ring ring-gray-100-300 w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-pink-300 mt-2"
                          required
                        />
                      </div>
                      <div className="flex flex-col">
                        <label
                          htmlFor=""
                          className="text-sm md:text-[18px] mb-2 font-semibold"
                        >
                          Teléfono
                        </label>
                        <input
                          name="telefono"
                          min={1000000}
                          max={9999999999999}
                          value={this.state.telefono}
                          onChange={(e) => this.handleChange(e)}
                          type="number"
                          placeholder="Digita tu número"
                          className="text-black ring ring-gray-100-300 w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-pink-300 mt-2"
                          required
                        />
                      </div>
                      <div className="flex flex-col">
                        <label
                          htmlFor=""
                          className="text-sm md:text-[18px] mb-2 font-semibold"
                        >
                          Comentarios
                        </label>
                        <textarea
                          value={this.state.mensaje}
                          onChange={(e) => this.handleChange(e)}
                          name="mensaje"
                          id=""
                          cols="30"
                          rows="8"
                          className="text-black ring ring-gray-100-300 w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-pink-300 mt-2"
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        value="Enviar"
                        className="btn--secondary gradient cursor-pointer hover:shadow-rosa mt-8 w-full block rounded-md py-2 px-2"
                      >
                        Enviar
                      </button>
                    </form> :
                    this.state.cargando && !this.state.formularioEnviado ? 
                      <span className="text-xl lg:text-3xl text-[#E84A84] font-bold text-center lg:text-left block">
                        Enviando formulario...
                      </span> :
                      <span className="text-xl lg:text-3xl font-bold text-center lg:text-left block">
                        <span className="text-[#E84A84]">¡Gracias por escribirnos!</span> Te contactaremos pronto
                      </span>
                    
                }
              </div>
            </div>
            <div className=" w-[80%] mx-auto lg:m-0 lg:w-full h-72 md:h-[500px] mx-auto flex flex-col items-center relative lg:-mr-10">
              <div className="bg-[#E84A84] w-full md:-mr-11 h-[90%] md:max-h-[28rem] rounded-lg z-0 relative"></div>
              <div className="w-10/12 md:w-[95%]  md:h-[80%] h-[80%] bg-white rounded-md absolute flex flex-col items-center space-y-6 -top-6 md:space-y-10 md:top-6 md:-left-4 p-2 md:items-start md:p-9 ">
                <h4 className="text-[#090928] text-xl md:text-3xl font-bold pt-5">
                  Contáctanos
                </h4>
                <div className=" flex flex-col items-center md:items-start space-y-6 md:space-y-12">
                  <div className="text-[#090928] flex justify-center items-center gap-x-3  ">
                    <MailIcon className="w-5 h-5 lg:w-6 lg:h-6 text-[#090928]" />
                    <a href="mailto:contacto@blackliondigitalsas.com" className="text-xs sm:text-sm md:text-xl hover:underline">
                      contacto@blackliondigitalsas.com
                    </a>
                  </div>
                  <div className="text-[#090928] flex justify-center items-center gap-x-3 ">
                    <PhoneIcon className="w-5 h-5 lg:w-6 lg:h-6 text-[#090928]" />
                    <span className="text-xs sm:text-sm md:text-xl">
                      +57 311 513 4425
                    </span>
                  </div>
                  <div className="text-[#090928] flex justify-center items-center gap-x-3 ">
                    <LocationMarkerIcon className="w-5 h-5 lg:w-6 lg:h-6 text-[#090928]" />
                    <span className="text-xs sm:text-sm md:text-xl">
                      Calle 145 # 12-86
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Contacto);
