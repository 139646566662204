import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  getCurso,
  getTemaActual,
} from "../../../../../reducers/academiaReducer";
import * as actions from "../../../../../actions/academiaActions";
import { ChevronRightIcon } from "@heroicons/react/solid";
import {
  ChartSquareBarIcon,
  RefreshIcon,
  ClipboardIcon,
} from "@heroicons/react/outline";
import ReactPlayer from "react-player";
import { formatoNumero } from "../../../../../utilidades/FuncionesAuxiliares";
import { NIVELES_CURSO } from "../../../../../utilidades/Constantes";

const Curso = ({
  getDBCursosCategoria,
  getDBTemaConCategorias,
  curso,
  tema,
}) => {
  let { idCategoria, idTema, idCurso } = useParams();

  useEffect(() => {
    if (!curso) {
      getDBCursosCategoria(idTema, idCategoria);
    }
  }, [curso]);
  useEffect(() => {
    if (!tema?.categorias) {
      getDBTemaConCategorias(idTema);
    }
  }, []);

  return (
    <div className="w-full h-full container pb-10 overflow-hidden font-proxima">
      <div className="flex flex-col space-y-16 pt-7 container mx-auto items-center">
        <div className="w-11/12 flex gap-3 justify-center items-center md:justify-start">
          <Link className="text-xs lg:text-base" to="/academia">
            Academia
          </Link>
          <div>
            <ChevronRightIcon className="w-5 h-5" />
          </div>
          <Link
            to={"/academia/" + idTema}
            className="text-white text-xs lg:text-base"
          >
            {tema?.nombre}
          </Link>
          <div>
            <ChevronRightIcon className="w-5 h-5" />
          </div>
          <span
            className={`${
              window.location.pathname.endsWith(`${idCurso}`)
                ? "text-rosa-primariy font-semibold text-xs lg:text-base"
                : "hover:text-rosa-primariy text-xs lg:text-base"
            }`}
          >
            {curso?.nombre}
          </span>
        </div>
        <div className="w-11/12 flex flex-col lg:flex-row">
          <div className=" w-11/12  m-auto space-y-5">
            <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start space-y-10">
              <h2 className="text-3xl md:text-4xl lg:text-5xl text-center md:text-left font-semibold ">
                {curso?.nombre}
              </h2>
              <p className="w-10/12 leading-5 text-xs md:text-sm lg:text-base text-center lg:text-left text-overflow">
                {curso?.descripcionLarga}
              </p>
            </div>
            <div className="flex flex-col justify-center items-center lg:items-start space-y-3">
              <div className="flex text-[#FFD338] gap-2 items-center">
                {!NIVELES_CURSO ? (
                  ""
                ) : (
                  <ChartSquareBarIcon className="w-5 h-5 text-[#FFD338]" />
                )}
                <span>
                  {!NIVELES_CURSO
                    ? "Nivel: Básico"
                    : "Nivel:  " + "  " + NIVELES_CURSO[curso?.nivel]}
                </span>
              </div>
            </div>
          </div>
          <div className=" w-4/5 h-full m-auto pt-10 lg:pt-0">
            <div className="flex flex-col justify-center items-center relative pt-[56.25%]">
              x
              <ReactPlayer
                className="absolute top-0 left-0"
                url={curso?.video}
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <h3 className="w-full text-center text-xl sm:text-2xl lg:text-3xl font-semibold py-4">
            ¿Quieres mejorar tu experiencia?
          </h3>
          {!curso?.archivos ? (
            <div className="w-full my-20 flex flex-col justify-center items-center">
              <span className="w-[90%] text-center p-6 mt-6 rounded-md bg-white/10">
                En estos momentos no hay ningún archivo disponible
              </span>
            </div>
          ) : (
            curso?.archivos.map((archivo) => {
              return (
                <div className="bg-white/10 flex flex-col lg:flex-row w-[70%] h-full m-auto rounded-md py-5 px-7 my-8">
                  <div className="w-full lg:w-6/12 text-center lg:text-left lg:py-10 lg:pl-8 space-y-3 lg:space-y-5 px-5">
                    {archivo.nombre ? (
                      <div className="flex gap-5 m-auto">
                        <span
                          className="text-base lg:text-3xl underline
                         font-semibold text-rosa-primariy"
                        >
                          {archivo.nombre}
                        </span>
                      </div>
                    ) : (
                      <span>No hay archivos por el momento</span>
                    )}
                    <p className="font-light text-xs md:text-sm lg:text-base">
                      {archivo.descripcion}
                    </p>
                  </div>
                  <div className="w-10/12 bg-white h-px m-auto my-5 lg:w-px lg:min-h-[300px]"></div>
                  <div className="flex flex-col justify-center items-center">
                    <div className="w-10/12 space-y-3 lg:pt-7">
                      <div className="flex gap-5 m-auto">
                        <RefreshIcon className="w-5 h-5 font-extrabold" />
                        <span className="text-xs lg:text-sm">
                          Tiempo ilimitado para completar el curso
                        </span>
                      </div>
                      <div className="flex gap-5 m-auto">
                        <ClipboardIcon className="w-5 h-5" />
                        <span className="text-xs lg:text-sm">
                          Recursos digitales para uso digital
                        </span>
                      </div>
                    </div>
                    <div className=" flex justify-center items-center gap-3 mt-10 w-full">
                      <span>
                        {formatoNumero(
                          archivo?.costo,
                          true,
                          true,
                          2,
                          false
                        )}
                      </span>
                      {archivo?.archivo !== "" ? (
                        <a
                          href={archivo?.archivo ?? ""}
                          target="_blank"
                          className="text-sm btn--secondary-sm"
                          rel="noreferrer"
                        >
                          Comprar
                        </a>
                      ) : (
                        <span>No hay archivos disponibles</span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  curso: getCurso(
    state,
    props.match.params.idTema,
    props.match.params.idCategoria,
    props.match.params.idCurso
  ),
  tema: getTemaActual(state, props.match.params.idTema),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Curso));
