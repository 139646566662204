import React from "react";

const InputText = ({
  labelText,
  placeholderText,
  handleChange,
  name,
  value,
}) => {
  return (
    <div className="flex flex-col md:flex-row w-full items-end">
      <p className="text-blackPrimary text-[18px] font-normal  w-full">
        {labelText}
      </p>
      <input
        type="text"
        className="w-full active:bg-transparent focus:bg-transparent pt-3 border-b-2 border-[#BBBBBB] text-black pl-2 focus:outline-none focus:border-2 focus:border-pink-400 focus:no-underline focus:rounded-md"
        placeholder={placeholderText}
        onChange={handleChange}
        name={name}
        value={value}
        required
      />
    </div>
  );
};

export default InputText;
