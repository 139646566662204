// import { CheckCircleIcon } from "@heroicons/react/outline";s
import checkIcon from "../../assets-2/checkIcon.svg";
import phoneYoutube from "../../assets-2/phone-youtube.png";

const ThirdService = () => {
  return (
    <section className="container mx-auto flex justify-around flex-col-reverse lg:flex-row font-proxima items-center gap-10 mt-11 py-16">
      <div className="w-auto mt-8 lg:mt-0   flex flex-col justify-center items-center">
        <img
          src={phoneYoutube}
          className="w-[70%] lg:w-auto"
          alt="iamgen estadistica"
        />
      </div>
      <div className="space-y-8 flex flex-col items-center lg:items-start justify-start w-[75%]">
        <h3 className="text-3xl md:text-4xl lg:text-[40px] w-[60%] m-x-auto lg:m-x-0 lg:w-[90%] font-semibold text-center md:text-center mb-8">
          Canal de <span className="text-[#E84A84]">YouTube</span>{" "}
        </h3>
        <div className="w-full lg:w-[80%] flex items-center justify-center mx-auto">
          <ul className="flex flex-col gap-y-7 mx-auto">
            <li className="flex items-start gap-4">
              <div className="w-[15%] flex justify-center items-center mt-2">
                <img className="w-[30%]" src={checkIcon} alt="" />
              </div>
              <span className="w-full  text-sm md:text-lg">
                ¿Sabías que puedes identificar tus canciones han sido utilizadas
                por otros usuarios gracias al sistema de content ID de YouTube
              </span>
            </li>
            <li className=" flex items-start gap-4">
              <div className="w-[15%] flex justify-center items-center mt-0 lg:mt-2">
                <img className="w-[30%]" src={checkIcon} alt="" />
              </div>
              <span className="w-full text-sm md:text-lg">
                Al monetizar tu contenido en YouTube maximizas tus ingresos y
                proteges los derechos de tu música en otros canales
              </span>
            </li>
            <li className=" flex items-start gap-4">
              <div className="w-[15%] flex justify-center items-center mt-2">
                <img className="w-[30%]" src={checkIcon} alt="" />
              </div>
              <span className=" w-full text-sm md:text-lg">
                Optimización SEO para mejorar el posicionamiento orgánico de tu
                canal de YouTube
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ThirdService;
