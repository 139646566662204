import { Link } from "react-router-dom";

const Sidebar = ({
  item,
  url,
  openSubMenu,
  setActiveSubMenu,
  index,
  activeSubMenu,
}) => {
  return (
    <>
      {Array.isArray(item.subNav) ? (
        <div
          className={`${
            openSubMenu ? "bg-white/40" : ""
          } flex justify-between px-5 py-4 gap-x-3 hover:bg-white/40  hover:rounded-md cursor-pointer  transition w-full`}
          onClick={() => {
            if (activeSubMenu === index) setActiveSubMenu(null);
            else setActiveSubMenu(index);
          }}
        >
          <div className="flex items-center gap-x-3">
            <img src={item.icon} alt="" />
            <span>{item.title}</span>
          </div>

          <img
            src={
              item.subNav && openSubMenu
                ? item.iconClosed
                : item.subNav
                ? item.iconOpen
                : null
            }
            className="transition duration-500 ease-in-out"
            alt=""
          />
        </div>
      ) : (
        <Link
          className="flex justify-between px-5 py-4 gap-x-3 hover:bg-white/40  hover:rounded-md cursor-pointer  transition w-full"
          to={`${url}${item.destiny}`}
        >
          <div className="flex items-center gap-x-3">
            <img src={item.icon} alt="" />
            <span>{item.title}</span>
          </div>
        </Link>
      )}

      <div className="flex flex-col transition duration-500">
        {openSubMenu && Array.isArray(item.subNav)
          ? item.subNav.map((subItem) => (
              <Link
                to={`${url}${subItem.destiny}`}
                className={
                  window.location.pathname.endsWith(subItem.destiny)
                    ? "cursor-pointer pl-16 border-l-2 py-2 "
                    : "cursor-pointer pl-16 hover:border-l-2 py-2 "
                }
              >
                <span
                  className={
                    window.location.pathname.endsWith(subItem.destiny)
                      ? "font-regular"
                      : "font-regular"
                  }
                >
                  {subItem.title}
                </span>
              </Link>
            ))
          : null}
      </div>
    </>
  );
};

export default Sidebar;
