export const formatter = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 5,
  }).format(value);

export function secondsToDateTime(secs) {
  const t = new Date(1970, 0, 1);
  t.setSeconds(secs);

  let month = "" + (t.getMonth() + 1);
  let day = "" + t.getDate();
  const year = t.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

export function secondsToDateTimeForMoment(secs) {
  const t = new Date(1970, 0, 1);
  t.setSeconds(secs);

  let month = "" + (t.getMonth() + 1);
  let day = "" + t.getDate();
  const year = t.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function secondsToDateTimeYMD(secs) {
  const t = new Date(1970, 0, 1);
  t.setSeconds(secs);

  let month = "" + (t.getMonth() + 1);
  let day = "" + t.getDate();
  const year = t.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export const formatearFechaDetalleEvento = (fecha) => {
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abrirl",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const diaMes = fecha.getDate();
  const mes = fecha.getMonth();
  const anio = fecha.getFullYear();

  let hours = fecha.getHours();
  let minutes = fecha.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${meses[mes]} ${diaMes} de ${anio} ${hours}:${minutes} ${ampm}`;
};

export const formatMYYYY = (t) => {
  if (!t) return "Fecha de lanzamiento no disponible";
  const f = new Date(t);
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const mes = meses[f.getMonth() + 1];
  const anio = f.getFullYear();
  return `${mes}, ${anio}`;
};

export const formatDDMMYYYY = (t) => {
  const day = new Date(t).getDate();
  const month = new Date(t).getMonth() + 1;
  const year = new Date(t).getFullYear();
  return `${day}/${month}/${year}`;
};
