import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Blob from "../../assets-2/blob-rosa.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { data } from "./DataCarousel";

const CardCarousel = () => {
  const settings = {
    dots: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container mx-auto font-proxima">
      <div className="w-full m-auto font-proxima ">
        <div className=" flex flex-col justify-center mx-auto ">
          <Carousel {...settings}>
            {data.map((card) => {
              return (
                <div className="" key={card.id}>
                  <div
                    className="bg-[#12123E] w-44 md:w-[210px] xl:w-[250px] mx-auto w-200px h-[260px] md:h-72 rounded-lg flex flex-col"
                    style={{
                      boxShadow: "0px 2px 12px rgba(96, 96, 233, 0.25)",
                    }}
                  >
                    <div className="flex items-center justify-center relative">
                      <img className="w-4/6" src={Blob} alt="vector" />
                      <div className="absolute">{card.icon}</div>
                    </div>
                    <h4 className="text-sm font-bold md:text-base">
                      {card.title}
                    </h4>
                    <p className="text-xs md:text-sm font-light text-center flex justify-center content-center mx-6 mt-4 ">
                      {card.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

const Carousel = styled(Slider)`
  margin-top: 10px;

  & > button {
    opacity: 1;
    height: 100%;
    width: 5vw;
    z-index: 100;
    right: 11px;
  }

  ul li button {
    &:before {
      margin-top: 1.5rem;
      font-size: 10px;
      color: #e84a84;
    }
  }
  .slick-track > .slick-slide {
    margin: 0rem;
    width: 210px;
  }

  li.slick-active button:before {
    color: #e84a84;
  }

  .slick-list {
    /* overflow: initial; */
    gap: 2rem;
  }

  .slick-prev {
    left: -3rem;

    @media (max-width: 460px) {
      left: -2rem;
    }
  }

  .slick-next {
    right: -3rem;

    @media (max-width: 460px) {
      right: -2rem;
    }
  }
  .slick-track {
    width: 500px;
  }
`;

export default CardCarousel;
