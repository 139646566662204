import firebase, { config } from "./Firebase";
import firebaseSDK from "firebase";
import {
  ERROR,
  OK,
  ERROR_PASSWORD_INCORRECTO,
  ERROR_USUARIO_DESCONOCIDO,
  ERROR_CORREO_INVALIDO,
  SIN_SESION,
  ERROR_MUCHOS_INTENTOS,
} from "../utilidades/Constantes";

export const auth = firebase.auth();

const createAuth = firebaseSDK.initializeApp(config, "Secondary").auth();

export const registrarUsuario = async (data) => {
  try {
    return await createAuth.createUserWithEmailAndPassword(
      data.correo,
      data.password
    );
  } catch (error) {
    return {
      resultado: ERROR,
      code: error.code,
      message: error.message,
    };
  }
};

export const iniciarSesion = async (user, password) => {
  try {
    const usuario = await auth.signInWithEmailAndPassword(user, password);
    return usuario.user.uid;
  } catch (error) {
    console.log(error);
    if (error.code === ERROR_PASSWORD_INCORRECTO)
      return ERROR_PASSWORD_INCORRECTO;
    else if (error.code === ERROR_USUARIO_DESCONOCIDO)
      return ERROR_USUARIO_DESCONOCIDO;
    else if (error.code === ERROR_MUCHOS_INTENTOS) return ERROR_MUCHOS_INTENTOS;
    return ERROR;
  }
};

export const cerrarSesion = () => {
  auth.signOut();
};

export const recuperarContrasena = async (correo) => {
  try {
    await auth.sendPasswordResetEmail(correo);
    return OK;
  } catch (e) {
    console.log(e);
    if (e.code === ERROR_USUARIO_DESCONOCIDO) return ERROR_CORREO_INVALIDO;
    return ERROR;
  }
};

export const verificarSesion = (dispatch, etiqueta) => {
  auth.onAuthStateChanged((user) => {
    if (user === null) {
      dispatch({
        payload: SIN_SESION,
        type: etiqueta,
      });
    } else {
      dispatch({
        payload: user.uid,
        type: etiqueta,
      });
    }
  });
};
