import React from "react";
// import styled from "styled-components";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/CarruselTema.scss";

const CarruselTema = ({
  categorias,
  indiceCategoriaActiva,
  setIndiceCategoriaActiva,
}) => {
  return (
    <div className=" h-full w-full container mb-4">
      <div className="w-full flex items-center justify-start lg:justify-center mt-10 gap-3 overflow-x-auto relative container-scroll pb-4 ">
        {categorias ? (
          categorias?.map((card, index) => (
            <div
              key={card.uidDoc}
              onClick={() => {
                setIndiceCategoriaActiva(index);
              }}
            >
              <button
                className={
                  "cursor-pointer mx-6 flex justify-center min-w-[200px] h-[85px] sm:min-w-[225px] sm:h-[100px] bg-[#4A3072] items-center rounded-lg relative hover:bg-[#9B87B5] transition-all duration-300 ease-in-out" +
                  (indiceCategoriaActiva === index ? " bg-[#9B87B5]" : "")
                }
              >
                <span className="flex flex-col justify-center items-center">
                  {card.nombre}
                </span>
              </button>
            </div>
          ))
        ) : (
          <div>
            {" "}
            <span>No hay categorias para seleccionar en este momento</span>{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default CarruselTema;
