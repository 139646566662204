import { useState } from "react";
import { useDispatch } from "react-redux";
import { ScaleLoader } from "react-spinners";
import { agregarRegalias } from "../../../../actions/adminActions";

const BeforeNextButtons = ({ currentStep, setCurrentStep, disableCondition, crearRegaliaData, handleCerrarDialogo }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const createRegalias = async () => {
    setLoading(true)
    await agregarRegalias(dispatch, crearRegaliaData, () => {
      setLoading(false)
      handleCerrarDialogo()
    })
  }

  if (loading)
    return <div className="w-full flex justify-end mt-[20px]">
      <button
        disabled
        className={`text-white px-[25px] py-[7px] rounded-md text-[16px] ml-3 bg-rosa-primariy`}>
        <ScaleLoader
          height={28}
          color={"white"}
        />
      </button>
    </div>

  return <div className="w-full flex justify-end mt-[20px]">
    {
      currentStep > 1 &&
      <button disabled={disableCondition} onClick={() => setCurrentStep(currentStep - 1)} className="border-rosa-primariy border-2 text-rosa-primariy px-[25px] py-[7px] rounded-md text-[16px]">Anterior</button>
    }
    {
      currentStep <= 4 &&
      <button
        disabled={disableCondition}
        onClick={
          currentStep === 4 ?
            createRegalias :
            () => setCurrentStep(currentStep + 1)
        }
        className={`text-white px-[25px] py-[7px] rounded-md text-[16px] ml-3 ${disableCondition ? "bg-gray-400" : "bg-rosa-primariy"}`}>

        {
          currentStep === 4 ? `Agregar regalías` : `Siguiente`
        }
      </button>
    }
  </div>
}

export default BeforeNextButtons;