import React, { Component } from "react";
import "./agregar-album.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./../../../../actions/adminActions";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Switch from "react-switch";
import { ScaleLoader } from "react-spinners";
import { withRouter } from "react-router-dom";
import { getArtista } from "../../../../reducers/adminReducer";
import { toast } from "react-toastify";

class AgregarAlbum extends Component {
  state = {
    nombre: "",
    upc: "",
    fechaLanzamiento: "",
    canciones: [],
    cancionPorAgregar: "",
    artistaCancionPorAgregar: "",
    portada: "",
    online: false,
    cargando: false,
    error: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.canciones.length === 0) {
      this.setState({
        error: "Por favor agregue al menos una canción al álbum",
      });
      return;
    }

    this.setState({
      cargando: true,
      error: "",
    });

    await this.props.subirAlbum({
      nombre: this.state.nombre,
      nombreArtista: this.props.artista?.nombre,
      artistaId: this.props.artista?.id,
      online: this.state.online,
      upc: this.state.upc,
      fechaLanzamiento: new Date(this.state.fechaLanzamiento),
      portada: this.state.portada,
      canciones: this.state.canciones,
    });

    toast.success("Álbum agregado exitosamente");

    this.setState({
      nombre: "",
      upc: "",
      fechaLanzamiento: "",
      canciones: [],
      cancionPorAgregar: "",
      artistaCancionPorAgregar: "",
      portada: "",
      online: false,
      cargando: false,
      error: "",
    });
  };

  handleAgregarCancion = () => {
    if (
      this.state.cancionPorAgregar.replace(/\s/g, "") === "" ||
      this.state.artistaCancionPorAgregar.replace(/\s/g, "") === ""
    ) {
      this.setState({
        error: "Por favor indique el nombre de la canción y del artista",
      });
      return;
    }

    this.setState({
      canciones: this.state.canciones.concat({
        nombre: this.state.cancionPorAgregar,
        nombreArtista: this.state.artistaCancionPorAgregar,
      }),
      cancionPorAgregar: "",
      artistaCancionPorAgregar: "",
      error: "",
    });
  };

  handleFile = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  };

  handleDeleteCancion = (cancionPorBorrar) => {
    let nuevasCanciones = [];
    let cancionEliminada = false;

    this.state.canciones.forEach((cancion) => {
      if (
        cancion.nombre === cancionPorBorrar.nombre &&
        cancion.artista === cancionPorBorrar.artista &&
        !cancionEliminada
      )
        cancionEliminada = true;
      else nuevasCanciones.push(cancion);
    });

    this.setState({
      canciones: nuevasCanciones,
    });
  };

  render() {
    if (this.state.cargando)
      return (
        <div className="h-full w-full m-auto flex justify-center items-center">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
        </div>
      );

    return (
      <form
        className="p-8 w-full"
        onSubmit={this.handleSubmit}
      >
        <div className="flex flex-col sm:flex-row items-center w-full justify-between mb-5">
          <div className="titulo-agregar-album font-bold text-3xl text-white font-proxima">
            Agregar álbum
          </div>
          <button
            type="submit"
            className="bg-rosa-primariy rounded-lg py-2 px-4 text-[15px] cursor-pointer mt-4 md:mt-0"
          >
            Guardar
          </button>
        </div>
        <div className="flex flex-col">
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="item-agregar-album">
              <b>Nombre</b>
              <input
                className="input-agregar-album text-black"
                type="text"
                name="nombre"
                placeholder="Nombre"
                required
                value={this.state.nombre}
                onChange={this.handleChange}
              />
            </div>
            <div className="item-agregar-album">
              <b>Portada</b>
              <div className="flex flex-col sm:flex-row sm:items-center mt-4 max-w-full">
                <label
                  htmlFor="video-curso"
                  className="bg-[#E84A84] text-white relative w-max px-[10px] py-2 rounded-md cursor-pointer text-[17px] float-left whitespace-nowrap"
                >
                  <input
                    id="video-curso"
                    type="file"
                    accept="image/*"
                    required
                    name="portada"
                    onChange={this.handleFile}
                    className="absolute w-full h-full opacity-0 top-0 left-0 right-0 bottom-0 outline-none cursor-pointer z-[-1]"
                  />
                  Seleccionar archivo
                </label>
                <span className="text-[#BEBEBE] mt-2 sm:mt-0 sm:ml-5 underline text-lg whitespace-nowrap w-max overflow-x-hidden">
                  {this.state.portada?.name}
                </span>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="item-agregar-album">
              <b>UPC</b>
              <input
                className="input-agregar-album text-black"
                type="text"
                name="upc"
                placeholder="UPC"
                required
                value={this.state.upc}
                onChange={this.handleChange}
              />
            </div>
            <div className="item-agregar-album">
              <b>Fecha de lanzamiento</b>
              <input
                className="input-agregar-album text-black"
                type="date"
                name="fechaLanzamiento"
                value={this.state.fechaLanzamiento}
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="item-agregar-album flex flex-col">
              <span className="font-medium">Online</span>
              <Switch
                onColor="#e87096"
                width={60}
                height={30}
                uncheckedIcon={false}
                checkedIcon={false}
                activeBoxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                onChange={() => this.setState({ online: !this.state.online })}
                checked={this.state.online}
                className="mt-5"
              />
            </div>
            <div className="item-agregar-album">
              <b>Canciones</b>
              <div id="input-cancion" className="input-agregar-album text-black">
                <input
                  className="agregar-cancion-texto"
                  placeholder="Nombre"
                  type="text"
                  name="cancionPorAgregar"
                  onChange={this.handleChange}
                  value={this.state.cancionPorAgregar}
                />
                <input
                  id="agregar-cancion-artista"
                  className="agregar-cancion-texto"
                  placeholder="Artista"
                  type="text"
                  name="artistaCancionPorAgregar"
                  onChange={this.handleChange}
                  value={this.state.artistaCancionPorAgregar}
                />
                <AddIcon
                  onClick={this.handleAgregarCancion}
                  className="agregar-cancion-boton text-rosa-primariy"
                />
              </div>
              <ol className="contenedor-canciones" start="1">
                {this.state.canciones.map((cancion) => {
                  return (
                    <li key={cancion.nombre} className="item-cancion">
                      {`${cancion.nombre} - ${cancion.nombreArtista}`}
                      <DeleteIcon
                        onClick={() => this.handleDeleteCancion(cancion)}
                        className="delete-icon"
                      />
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
        <div className="error-message">{this.state.error}</div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => ({
  artista: getArtista(state, props.match.params.id),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgregarAlbum)
);
