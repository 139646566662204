// Constantes para diferenciar las repuestas de Firebase
export const OK = "OK";
export const ERROR = "ERROR";
export const NO_EXISTE = "NO_EXISTE";
export const ERROR_USUARIO_DESCONOCIDO = "auth/user-not-found";
export const ERROR_PASSWORD_INCORRECTO = "auth/wrong-password";
export const ERROR_MUCHOS_INTENTOS = "auth/too-many-requests";
export const ERROR_CORREO_INVALIDO = "auth/invalid-email";

// Posibles codigos para errores en el registro
export const ERRORES_REGISTRO = {
  "auth/weak-password": "La contraseña debe contener al menos 6 caracteres",
  "auth/email-already-in-use":
    "El correo ya esta siendo utilizado por otro usuario",
};

// Constantes para diferenciar los types en los reducers
export const SESION = "SESION";
export const UID_USER = "UID_USER";
export const CLEAN_USER = "CLEAN_USER";
export const SIN_SESION = "SIN_SESION";
export const CAMBIAR = "CAMBIAR";
export const REPERTORIO = "REPERTORIO";
export const HISTORIAL = "HISTORIAL";
export const ALBUMES = "ALBUMES";
export const SOLICITAR_PAGO = "SOLICITAR_PAGO";
export const SUBIR_PAGO = "SUBIR_PAGO";
export const TRAER_LOGROS = "TRAER_LOGROS";
export const SUBIR_LOGRO = "SUBIR_LOGRO";
export const EDITAR_INFO_BANCARIA = "EDITAR_INFO_BANCARIA";
export const ELIMINAR_LOGRO = "ELIMINAR_LOGRO";
export const EDITAR_LOGRO = "EDITAR_LOGRO";
export const SUBIR_ALBUM = "SUBIR_ALBUM";
export const EDITAR_ALBUM = "EDITAR_ALBUM";
export const SUBIR_SENCILLO = "SUBIR_SENCILLO";
export const EDITAR_SENCILLO = "EDITAR_SENCILLO";

// Constantes para identificar el rol del usuarios
export const ROL_ADMIN = 0;
export const ROL_ARTISTA = 1;

// Constante para identificar el tipo de obra en el repertorio
export const ALBUM = 0;
export const SENCILLO = 1;

// Constantes para identificar el tipo de correo que se debe enviar

export const SOLICITUD_PAGO = 0; /* recibe los parametros:
    tipo -> Indica el tipo de correo - 0
    diaMes -> Indica el dia y el mes en que se envió la solicitud - 20 de Julio
    anio -> Indica el año en que se envió la solicitud - 2020
    hora -> Indica la hora en que se envió la solicitud - 16:20
    email -> Indica el correo de la persona al que le debe llegar - "js.millan10@uniandes.edu.co"
*/
export const PAGO_REALIZADO = 1; /* recibe los parametros:
    tipo -> Indica el tipo de correo - 1
    url -> Indica la url del soporte de pago - "https://url.com"
    email -> Indica el correo de la persona al que le debe llegar - "js.millan10@uniandes.edu.co"
*/
export const BIENVENIDO = 2; /* recibe los parametros:
    tipo -> Indica el tipo de correo - 2
    nombre -> Indica el nombre del usuario registrado - "Juan José Ruiz Soto"
    email -> Indica el correo de la persona al que le debe llegar - "js.millan10@uniandes.edu.co"
    password -> Indica la contraseña del usuario registrado - "1234567890"
*/
export const CAMBIO_CUENTA_BANCO = 3; /* recibe los parametros:
    tipo -> Indica el tipo de correo - 3
    nombre -> Indica el nombre del usuario - "Juan José Ruiz Soto"
    email -> Indica el correo de la persona al que le debe llegar - "js.millan10@uniandes.edu.co"
    tipoCuenta -> Indica el tipo de cuenta de banco del usuario - "Ahorros" - "Corriente"
    numero -> Indica el numero de cuenta de banco del usuario - "1234567890"
    banco -> Indica el banco de la cuenta de banco del usuario - "Bancolombia"
*/

// Constantes nivel curso:

export const NIVELES_CURSO = ["Básico", "Intermedio", "Avanzado"];
export const TIPOS_REGALIAS = {
  PLATAFORMAS_DIGITALES: 0,
  YOUTUBE: 1,
};

export const QUIEN_ASUME_IVA = {
  BLACK_LION: 1,
  ARTISTA: 2,
};

export const TIPOS_DISTRIBUIDORAS = {
  PAGO_ANUAL: 1,
  PORCENTAJE: 2,
};

export const STATUS_OK = 200;
export const BAD_REQUEST = "BAD_REQUEST";

export const BACKEND_URL = process.env.BACKEND_URL;

export const S3_BUCKET = process.env.S3_BUCKET;

export const COLORES = {
  PRIMARIO: "#E87096",
  SECUNDARIO: "#55369A"
}