import { createSelector } from "reselect";
import {
  GET_CATEGORIA_TEMA,
  GET_CURSOS_CATEGORIA,
  GET_TEMAS_ACADEMIA,
  GET_TEMA_ACADEMIA,
} from "../actions/academiaActions";

// 1. Consultar documento tema con subcoleccion de categorias (consultarDocumentoConSubColeccion) 2. Consultar subcoleccion de cursos para la categoria activa (consultarColeccion) 1 + 1 + 1

const INITIAL_STATE = {
  // temas: [
  //   {
  //     nombre: '',
  //     imagen: '',
  //     categorias: [
  //       {
  //         nombre: '',
  //         cursos: [{}],
  //       },
  //       {
  //         nombre: '',
  //         cursos: [],
  //       },
  //     ],
  //   },
  //   {},
  // ],
  temas: null,
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TEMAS_ACADEMIA:
      if (state.temas) {
        const temasActualizados = action.payload.map((tema) => {
          const temaBuscado = state.temas.find(
            (temaActual) => temaActual.uidDoc === tema.uidDoc
          );
          if (temaBuscado) {
            return temaBuscado;
          } else {
            return tema;
          }
        });
        return { ...state, temas: temasActualizados, temasCompletos: true };
      } else {
        return { ...state, temas: action.payload, temasCompletos: true };
      }
    case GET_CATEGORIA_TEMA:
      return { ...state, temas: action.payload };
    case GET_TEMA_ACADEMIA:
      let temas = state.temas;
      let temaPorGuardar = action.payload;
      if (!temas) return { ...state, temas: [temaPorGuardar] };
      else {
        let temasActualizados = temas.map((tema) => {
          if (tema.uidDoc === temaPorGuardar.uidDoc) {
            return temaPorGuardar;
          } else {
            return tema;
          }
        });
        return { ...state, temas: temasActualizados };
      }

    case GET_CURSOS_CATEGORIA:
      if (state.temas) {
        let temasActualizados = state.temas?.map((tema) => {
          if (tema.uidDoc === action.opciones.idTema) {
            let categoriasActualizadas = tema.categorias?.map((categoria) => {
              if (categoria.uidDoc === action.opciones.idCategoria) {
                return { ...categoria, cursos: action.payload };
              } else {
                return categoria;
              }
            });
            return { ...tema, categorias: categoriasActualizadas };
          } else {
            return tema;
          }
        });

        return { ...state, temas: temasActualizados };
      } else {
        return {
          ...state,
          temas: [
            {
              uidDoc: action.opciones.idTema,
              categorias: [
                { uidDoc: action.opciones.idCategoria, cursos: action.payload },
              ],
            },
          ],
        };
      }

    default:
      return { ...state };
  }
};

export const getTemas = createSelector(
  (state) => state.academiaReducer,
  (academiaReducer) => academiaReducer.temas
);

export const getTemasCompletos = createSelector(
  (state) => state.academiaReducer,
  (academiaReducer) => academiaReducer.temasCompletos
);

export const getCategorias = createSelector(
  (state) => state.academiaReducer,
  (academiaReducer) => academiaReducer.categorias
);

export const getTemaActual = createSelector(
  (state, idTema) => [state.academiaReducer, idTema],
  ([academiaReducer, idTema]) =>
    academiaReducer.temas?.find((temas) => temas.uidDoc === idTema)
);

export const getTemaActualValidado = createSelector(
  (state, idTema) => [state.academiaReducer, idTema],
  ([academiaReducer, idTema]) => {
    if (!academiaReducer.temas) return null;
    const res = academiaReducer.temas?.find((temas) => temas.uidDoc === idTema);
    if (res) return res;
    else return "ERR";
  }
);

export const getCurso = createSelector(
  (state, idTema, idCategoria, idCurso) => [
    state.academiaReducer,
    idTema,
    idCategoria,
    idCurso,
  ],
  ([academiaReducer, idTema, idCategoria, idCurso]) => {
    if (!academiaReducer.temas) return null;
    const tema = academiaReducer.temas.find((tema) => tema.uidDoc === idTema);
    if (!tema.categorias) return null;
    const categoria = tema.categorias.find(
      (categoria) => categoria.uidDoc === idCategoria
    );
    if (!categoria.cursos) return null;
    const curso = categoria.cursos.find((curso) => curso.uidDoc === idCurso);

    return curso;
  }
);
