import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import ImgMusico from "./../../../../assets/usuario.svg";
import DialogoAgregarArtista from "../AgregarArtista/AgregarArtista";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./pagina-principal-administrador.scss";
import { getArtistas } from "../../../../actions/adminActions";
import {
  selectArtistas,
  selectArtistasCompletos,
} from "../../../../reducers/adminReducer";
import { useDispatch, useSelector } from "react-redux";
import { obtenerUrl } from "../../../../utilidades/FuncionesAuxiliares";

const PaginaPrincipalAdministrador = ({
  handleAbrirDialogo,
  handleCerrarDialogo,
}) => {
  const history = useHistory();
  const artistas = useSelector(selectArtistas);
  const artistaCompletos = useSelector(selectArtistasCompletos);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!artistaCompletos) {
      getArtistas(dispatch);
    }
  }, []);

  return (
    <div className="pagina-principal-administrador">
      {artistas && artistaCompletos ? (
        <div className="content-administrador">
          <div className="fila-agregar sm:ml-16">
            <AddIcon
              id="add-boton-admin"
              onClick={() =>
                handleAbrirDialogo(
                  <DialogoAgregarArtista
                    handleCerrarDialogo={handleCerrarDialogo}
                  />
                )
              }
            />
            <div className="font-proxima font-bold ml-5 text-xl">
              Agregar músico
            </div>
          </div>
          <div className="contenedor-musicos-admin">
            {artistas.map((artista) => (
              <div
                key={artista.nombre ? artista.nombre : artista.correo}
                className="item-musico-administrador"
                onClick={() =>
                  history.push(`/administrador/artistas/${artista.id}/perfil`)
                }
              >
                {artista.foto ? (
                  <div className="borde-gradiente-musico">
                    <div
                      className="cont-img-musico-admin"
                      style={{
                        backgroundImage: `url("${obtenerUrl(artista.foto)}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                  </div>
                ) : (
                  <div className="borde-gradiente-musico">
                    <div className="cont-img-musico-admin p-4">
                      <img
                        className="img-musico-admin"
                        src={ImgMusico}
                        alt="Imagen del músico"
                      />
                    </div>
                  </div>
                )}
                <div className="nombre-item-lista-artistas">
                  {artista.nombre ? artista.nombre : artista.correo}
                </div>
                {artista.pagoSolicitado && (
                  <div className="pago-solicitado">Solicitó un pago</div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="loading-indicator-pagina-administrador">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
        </div>
      )}
    </div>
  );
};

export default PaginaPrincipalAdministrador;
