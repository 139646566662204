import { Link } from "react-router-dom";
import { DataKnowUs } from "./DataKnowUs";

const Conocenos = () => {
  return (
    <div className="container mx-auto w-full h-full font-proxima py-10 ">
      <div className="">
        <div className="lg:max-w-[53rem] flex flex-col text-center mx-auto justify-between h-auto max-w-lg pb-10">
          <h2 className=" w-[80%] m-auto md:w-[90%] text-3xl md:text-4xl lg:text-[40px] font-bold leading-10 font-proxima ">
            Genera ingresos reales y confiables por hacer lo que más te
            apasiona, <span className="text-[#E84A84] font-bold">música</span>{" "}
          </h2>
        </div>
        <div className="flex justify-center mt-16 md:mt-28 flex-wrap md:space-x-12 mr-0 md:mr-16 lg:mr-5 gap-4 md:gap-2 pb-5">
          {DataKnowUs.map((data) => {
            return (
              <div
                key={data.id}
                className="w-32 h-32 md:w-64 md:h-64 flex justify-end  relative"
              >
                <div className="w-[100px] h-[100px] md:w-48 md:h-48 bg-gradient-to-tl from-[#B0B0F1] to-[#3030CD] rounded-2xl absolute z-20 flex flex-col justify-center items-center">
                  <div className="relative flex flex-col items-center justify-center">
                    <img
                      src={data.image}
                      className=" w-[50px] -mt-12 md:-mt-28 md:w-full "
                      alt=""
                    />
                  </div>
                  <h3 className="text-xs md:text-lg text-center relative mt-2.5 p-2">
                    {data.title}
                  </h3>
                </div>
                <div className="w-24 h-20 md:w-40 md:h-40  bg-gradient-to-tl from-[#B0B0F1] to-[#3030CD] opacity-60 rounded-2xl absolute z-10 left-4 top-[0.8rem] md:left-9"></div>
                <div className="w-[60px] h-16 md:w-24 md:h-24 opacity-30 rounded-2xl bg-gradient-to-tl from-[#B0B0F1] to-[#3030CD]  absolute z-0 left-1 top-6 md:top-10 md:left-5"></div>
              </div>
            );
          })}
        </div>
        <Link
          to="/conocenos"
          className="block btn--secondary gradient mt-6 px-2 mx-auto font-proxima  text-center w-32 md:w-40 "
        >
          Conócenos
        </Link>
      </div>
    </div>
  );
};

export default Conocenos;
