import { createSelector } from "reselect";
import { TRAER_REDES_ARTISTA } from "../actions/usersActions";

const INITIAL_STATE = {
  artista: null,
  albumes: [],
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRAER_REDES_ARTISTA:
      const {redes, registrosSeguidoresRedes} = action.payload;
      
      return {
        ...state,
        artista: {
          ...(state.artista || {}),
          redesSociales: {
            redes,
            registrosSeguidoresRedes
          }
        }
      }

    default:
      return { ...state };
  }
};

export const selectRedesSocialesArtista = createSelector(
  (state) => state.userReducer,
  (userReducer) => userReducer.artista?.redesSociales
);