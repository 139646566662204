import moment from "moment";
import 'moment/locale/es';
import { S3_BUCKET } from "./Constantes";

export const generarUID = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + s4() + s4() + s4();
};

export const formatoNumero = (
  input,
  ingles = true,
  moneda = false,
  decimales = 0,
  negativos = false
) => {
  if (!input) return "";

  if (ingles) input = String(input).replace(".", ",");

  let negativo = false;

  if (input === "-" && negativos) return input;
  else if (input.replace(/\D/g, "") === "") return "";
  else if (input.substring(0, 1) === "-") negativo = true;

  input = input.replace(/^-?[$]?00/, "0");

  if (input.indexOf(",") >= 0) {
    const decimal_pos = input.indexOf(",");

    let left_side = input.substring(0, decimal_pos);
    left_side = left_side
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    if (decimales <= 0) input = left_side;
    else {
      let right_side = input.substring(decimal_pos);

      right_side = right_side.replace(/\D/g, "");
      right_side = right_side.substring(0, decimales);

      input = left_side + "," + right_side;
    }
  } else {
    input = input.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  if (moneda && negativos && negativo) return "-$" + input;
  else if (moneda) return "$" + input;
  else if (negativos && negativo) return "-" + input;
  else return input;
};

export const obtenerNumeroFormato = (input) => {
  if (!input) return 0;

  let negativo = false;

  if (input.replace(/\D/g, "") === "") return 0;

  if (input.substring(0, 1) === "-") negativo = true;

  if (input.indexOf(",") >= 0) {
    const decimal_pos = input.indexOf(",");

    let left_side = input.substring(0, decimal_pos);
    let right_side = input.substring(decimal_pos);

    left_side = left_side.replace(/\D/g, "");
    right_side = right_side.replace(/\D/g, "");

    input = left_side + "." + right_side;
  } else input = input.replace(/\D/g, "");

  if (negativo) return parseFloat("-" + input);
  else return parseFloat(input);
};

export const obtenerUrl = (url) => {
  return url.includes("http") ? url : `${S3_BUCKET}${url}`;
};

export const formatFecha = (fecha) => {
  if(fecha) {
    const date = moment(fecha);
    return capitalize(date.locale('es').format("MMMM, YYYY")); 
  } else {
    return "Fecha no disponible"
  }
}

export const capitalize = (string) => {
  return string.substring(0, 1).toUpperCase() + string.substring(1, string.length);
}