import React, { Component } from "react";
import "./agregar-sencillo.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./../../../../actions/adminActions";
import Switch from "react-switch";
import { ScaleLoader } from "react-spinners";
import { withRouter } from "react-router-dom";
import { getArtista } from "../../../../reducers/adminReducer";
import { toast } from "react-toastify";

class AgregarSencillo extends Component {
  state = {
    nombre: "",
    upc: "",
    fechaLanzamiento: "",
    portada: "",
    online: false,
    cargando: false,
    error: "",
  };

  handleFile = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      cargando: true,
      error: "",
    });

    await this.props.subirSencillo({
      nombre: this.state.nombre,
      nombreArtista: this.props.artista?.nombre,
      artistaId: this.props.artista?.id,
      upc: this.state.upc ?? null,
      fechaLanzamiento: new Date(this.state.fechaLanzamiento),
      online: this.state.online,
      portada: this.state.portada,
    });

    this.setState({
      cargando: false,
    });

    toast.success("Álbum agregado exitosamente");
    this.setState({
      nombre: "",
      upc: "",
      fechaLanzamiento: "",
      portada: "",
      online: false,
      cargando: false,
      error: "",
    });
  };

  render() {
    if (this.state.cargando)
      return (
        <div className="h-full w-full m-auto flex justify-center items-center">
          <ScaleLoader height={35 * 2} width={4 * 2} color={"#E87096"} />
        </div>
      );

    return (
      <form
        className="agregar-sencillo-administrador p-8 max-w-full"
        onSubmit={this.handleSubmit}
      >
        <div className="flex flex-col sm:flex-row items-center w-full justify-between mb-5">
          <div className="titulo-agregar-sencillo font-bold text-3xl text-white font-proxima">
            Agregar sencillo
          </div>
          <button
            type="submit"
            className="bg-rosa-primariy rounded-lg py-2 px-4 text-[15px] cursor-pointer mt-4 md:mt-0"
          >
            Guardar
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:grid-rows-2 gap-x-8 md:grid-flow-col w-[700px] max-w-full">
          <div className="item-agregar-sencillo">
            <b>Nombre</b>
            <input
              className="input-agregar-sencillo text-black"
              type="text"
              name="nombre"
              placeholder="Nombre del artista"
              required
              value={this.state.nombre}
              onChange={this.handleChange}
            />
          </div>
          <div className="item-agregar-sencillo">
            <b>Portada</b>
            <div className="flex flex-col sm:flex-row sm:items-center mt-4 max-w-full">
              <label className="bg-[#E84A84] text-white relative w-max px-[10px] py-2 rounded-md cursor-pointer text-[17px] float-left whitespace-nowrap">
                <input
                  type="file"
                  accept="image/*"
                  required
                  name="portada"
                  onChange={this.handleFile}
                  className="absolute w-full h-full opacity-0 top-0 left-0 right-0 bottom-0 outline-none cursor-pointer z-[-1]"
                />
                Seleccionar archivo
              </label>
              <span className="text-[#BEBEBE] mt-2 sm:mt-0 sm:ml-5 underline text-lg whitespace-nowrap w-max overflow-x-hidden">
                {this.state.portada?.name}
              </span>
            </div>
          </div>
          <div className="item-agregar-sencillo">
            <b>Fecha de lanzamiento</b>
            <input
              className="input-agregar-sencillo text-black"
              type="date"
              name="fechaLanzamiento"
              value={this.state.fechaLanzamiento}
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="item-agregar-sencillo flex flex-col">
            <span className="font-medium">Online</span>
            <Switch
              onColor="#e87096"
              width={60}
              height={30}
              uncheckedIcon={false}
              checkedIcon={false}
              activeBoxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
              onChange={() => this.setState({ online: !this.state.online })}
              checked={this.state.online}
              className="mt-5"
            />
          </div>
        </div>
        <div className="error-message">{this.state.error}</div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => ({
  artista: getArtista(state, props.match.params.id),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgregarSencillo)
);
