import girl from "../../assets-2/girl2x.png";
import speaker from "../../assets-2/speaker.png";
import folder from "../../assets-2/folder.png";
import "./styles/MusicCareer.scss";

const Hero = () => {
  return (
    <div className=" container mx-auto flex justify-around items-center flex-wrap font-proxima w-screen space-y-8 lg:pt-14 pb-10 lg:pb-16">
      <div className="max-w-xl flex flex-col text-center items-center lg:text-left lg:place-items-start space-y-4">
        <h2 className="w-11/12 text-3xl md:text-[40px]  font-semibold leading-10 my-5">
          Te contamos nuestra
          <br /> <span className="text-[#E84A84]">historia</span>
        </h2>
        <p className="my-4 text-base md:text-xl font-normal w-2/3 md:w-11/12">
          Nuestro proposito es ser aliado de los artistas en esta nueva era
          digital, mediante asesorías para comprender, entender y aplicar las
          herramientas digitales y así generar gran impacto.
        </p>
      </div>
      <div className=" min-w-max flex justify-center relative">
        <img
          className="self-end pb-8 lg:pb-44 lg:pr-12 image-speaker w-[75px] lg:w-44"
          src={speaker}
          alt=""
        />
        <img className="image-girl" src={girl} alt="" />
        <img
          className="self-start image-folder lg:relative lg:top-48 lg:left-10"
          src={folder}
          alt=""
        />
      </div>
    </div>
  );
};

export default Hero;
