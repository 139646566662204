import { BACKEND_URL, BAD_REQUEST, STATUS_OK } from "../utilidades/Constantes";
import axios from "axios";
import { auth } from "./FirebaseAuth";

const queryString = require("query-string");


export const deleteData = async (url, params) => {
  const token = await auth.currentUser.getIdToken();

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  };

  let config = {
    method: "DELETE",
    url: `${BACKEND_URL}${url}${setParamsString(params)}`,
    headers,
  };
  try {
    let response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
};

const setParamsString = (params) => {
  let strParams = "";
  Object.keys(params).forEach((key, i) => {
    let value = params[key];
    if (typeof value === "object") {
      let options = "";
      value.forEach((opcion) => (options += opcion + ","));
      value = options;
    }
    if (i === 0) strParams += key + "=" + value;
    else strParams += "&" + key + "=" + value;
  });
  return strParams ? `?${params}` : "";
};

export const getData = async (url, params = {}) => {
  const token = await auth.currentUser.getIdToken();
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  };
  const queryParams = Object.keys(params).length
    ? `?${queryString.stringify(params)}`
    : "";
  const urlComplete = url + queryParams;
  const config = {
    method: "GET",
    url: `${BACKEND_URL}${urlComplete}`,
    headers,
  };
  try {
    const response = await axios(config);
    if (response && response.status && response.status === STATUS_OK) {
      const data = response.data;
      return { data: data };
    } else {
      return { error: BAD_REQUEST };
    }
  } catch (error) {
    return { error: BAD_REQUEST };
  }
};

export const postData = async (url, params = {}, data = {}) => {
  const token = await auth.currentUser.getIdToken();

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  };
  const queryParams = Object.keys(params).length
    ? `?${queryString.stringify(params)}`
    : "";
  const urlComplete = url + queryParams;
  const config = {
    method: "POST",
    url: `${BACKEND_URL}${urlComplete}`,
    headers,
    data,
  };
  try {
    const response = await axios(config);
    if (response && response.status) {
      const data = response.data;
      return { data: data };
    } else {
      return { error: BAD_REQUEST };
    }
  } catch (error) {
    return { error: BAD_REQUEST };
  }
};

export const patchData = async (url, params = {}, data = {}) => {
  const token = await auth.currentUser.getIdToken();

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
  };
  const queryParams = Object.keys(params).length
    ? `?${queryString.stringify(params)}`
    : "";
  const urlComplete = url + queryParams;
  const config = {
    method: "PATCH",
    url: `${BACKEND_URL}${urlComplete}`,
    headers,
    data,
  };
  try {
    const response = await axios(config);
    if (response && response.status && response.status === STATUS_OK) {
      const data = response.data;
      return { data: data };
    } else {
      return { error: BAD_REQUEST };
    }
  } catch (error) {
    return error;
  }
};
