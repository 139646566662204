import { Link } from "react-router-dom";
import logoFooter from "../../../../../assets-2/Logo-footer.svg";
import facebookIcon from "../../../../../assets-2/facebook-icon.svg";
import instagramIcon from "../../../../../assets-2/Instagram-icon.svg";
import youtubeIcon from "../../../../../assets-2/Youtube-icon.svg";

const Footer = () => {
  return (
    <div
      className="w-full mx-auto flex flex-col items-center text-center space-y-12 pt-8 font-proxima"
      style={{ backgroundColor: "rgba(31, 6, 63, 0.2) " }}
    >
      <div className=" flex flex-col space-y-8 items-center">
        <img src={logoFooter} alt="" />
        <div className="flex space-x-5">
          <Link to="/" target="_blank">
            <img src={facebookIcon} alt="" />
          </Link>
          <Link to="/" target="_blank">
            <img src={instagramIcon} alt="" />
          </Link>
          <Link to="/" target="_blank">
            <img src={youtubeIcon} alt="" />
          </Link>
        </div>
      </div>
      <div className="flex flex-col text-sm">
        <span>Términos y condiciones 🚀</span>
        <span>2020 BlackLion Digital SAS. Todos los derechos reservados</span>
      </div>
    </div>
  );
};

export default Footer;
