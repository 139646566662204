import { Link } from "react-router-dom";
import headerImage from "../../assets-2/socialM-2.png";

const Header = () => {
  return (
    <div className="w-full container m-auto sm:bg-hero-pattern lg:bg-xl-pattern  bg-small-pattern bg-no-repeat lg:bg-contain bg-contain font-proxima mt-20">
      <div className="w-10/12 m-auto flex flex-col lg:flex-row justify-between items-center flex-wrap">
        <div className="w-full lg:w-1/2 flex flex-col text-center items-center lg:text-left lg:place-items-start space-y-7 gap-y-2 lg:gap-y-3 lg:space-y-8 mt-3">
          <h1 className=" w-full md:w-[90%] text-3xl md:text-4xl lg:text-5xl font-bold leading-10 font-proxima">
            El lugar donde tu carrera musical fluye de forma segura
          </h1>
          <p className="w-9/12 md:w-[60%]  text-base md:text-lg">
            Incrementa tu audiencia y conecta con tu comunidad de fans.
          </p>
          <Link
            className=" btn--header gradient justify-items-center"
            to="/contacto"
          >
            Únete ahora
          </Link>
        </div>
        <div className="w-[75%] lg:w-[45%] my-9">
          <img
            className="w-full max-w-[400px] md:max-w-[700px] m-auto"
            src={headerImage}
            alt="chico"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
