import React, { useState } from "react";
import DialogoEditar from "../../../general/DialogoEditar";
import PropTypes from "prop-types";

const EditarVideo = ({ handleGuardar, handleCancelar, video }) => {
  const [videoActual, setVideoActual] = useState(video);

  const handleFile = (e) => {
    setVideoActual(e.target.files[0]);
  };

  return (
    <DialogoEditar
      handleCancelar={handleCancelar}
      handleGuardar={() => handleGuardar(videoActual)}
      habilitado={!!videoActual}
    >
      <h2 className="font-bold text-2xl text-black font-proxima">
        Editar video
      </h2>
      <div className="flex flex-col ml-1">
        <h3 className="font-bold text-xl text-black mt-8 font-proxima">
          Video
        </h3>
        <input
          type="text"
          placeholder="Nombre del archivo"
          value={videoActual?.name ?? videoActual}
          className="w-[440px] max-w-full border-b-[1px] border-[#BBB] placeholder-[#BBB] mt-2 outline-none text-black"
          onChange={(e) => setVideoActual(e.target.value)}
          disabled={videoActual.name}
        />
        <div className="sm:self-end flex flex-col-reverse sm:flex-row items-center mt-4">
          <button
            className="text-[17px] px-[10px] py-[6px] font-semibold text-[#E84A84] border-[#E84A84] border-2 rounded-md outline-none sm:mr-5 mt-5 sm:mt-0"
            onClick={() => setVideoActual("")}
          >
            Eliminar
          </button>
          <label
            htmlFor="video-curso"
            className="bg-[#E84A84] text-white relative w-max px-[10px] py-2 rounded-md cursor-pointer text-[17px]"
          >
            <input
              id="video-curso"
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              className="absolute w-full h-full opacity-0 top-0 left-0 right-0 bottom-0 outline-none cursor-pointer z-[-1]"
              onChange={handleFile}
            />
            Seleccionar archivo
          </label>
        </div>
      </div>
    </DialogoEditar>
  );
};

EditarVideo.propTypes = {
  handleGuardar: PropTypes.func.isRequired,
  handleCancelar: PropTypes.func.isRequired,
  video: PropTypes.any,
};

export default EditarVideo;
