import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import Sidebar from "./Sidebar";
import { SidebarData } from "./SidebarData";
import AdministrarArtista from "../componentes/AdministrarArtista/AdministrarArtista";
import ProfileInfo from "./components/ProfileInfo";
import ModalConfirm from "../Historical/ModalConfirm";
import ModalRegalias from "../Historical/ModalRegalias";
import SubirPago from "../componentes/SubirPago/SubirPago";
import { ArrowNarrowLeftIcon, MenuIcon } from "@heroicons/react/outline";
import SidebarMobile from "./SidebarMobile";
import RoyaltyHistory from "../Historical/RoyaltyHistory";
import PaymentHistory from "../Historical/PaymentHistory";
import { getSingleArtista, traerLogros } from "../../../actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import EditarPerfil from "../componentes/EditarPerfil/EditarPerfil";
import Acchievements from "./components/Acchievements";
import ModalAcchievements from "./components/ModalAcchievements";
import RemoveAcchievement from "./components/RemoveAcchievement";
import Modal from "../../../utilidades/Modal";
import EditAcchievement from "./components/EditAcchievement";
import AgregarAlbum from "../componentes/AgregarAlbum/AgregarAlbum";
import AgregarSencillo from "../componentes/AgregarSencillo/AgregarSencillo";
import { getArtista } from "../../../reducers/adminReducer";
import { CompletarTareas } from "../componentes/CompletarTareas";
import RedesSociales from "../componentes/RedesSociales";

const ProfileRoute = ({ handleAbrirDialogo, handleCerrarDialogo }) => {
  const { url, path, params } = useRouteMatch();
  const dispatch = useDispatch();
  const [activeSubMenu, setActiveSubMenu] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const [size, setSize] = useState(window.innerWidth);
  const artista = useSelector((state) => getArtista(state, params?.id));

  const isMobile = size < 1024;
  useEffect(() => {
    if (!artista || !artista.infoBasica) {
      getSingleArtista(params?.id)(dispatch);
    }
    traerLogros(dispatch, params?.id);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setSize(window.innerWidth);
    });
  }, [size]);

  const history = useHistory();
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalRegalias, setModalRegalias] = useState(false);
  const [modalAcchievements, setModalAcchievements] = useState(false);
  const [modalRemoveAcchievement, setModalRemoveAcchievement] = useState(false);
  const [modalEditAcchievement, setModalEditAcchievement] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [regalias, setRegalias] = useState([]);

  const openModalConfirm = (fecha) => {
    setModalConfirm(!modalConfirm);
  };

  const openModalRegalias = (e, regalias) => {
    setRowId(e.target.id);
    setRegalias(regalias || []);
    setModalRegalias(!modalRegalias);
  };

  const openModalRemoveAcchievement = (id) => {
    setRowId(id);
    setModalRemoveAcchievement(!modalRemoveAcchievement);
  };

  const openModalEditAcchievement = (id) => {
    setRowId(id);
    setModalEditAcchievement(!modalEditAcchievement);
  };

  return (
    <>
      <div className="w-10/12 m-auto mt-5 flex flex-col items-center">
        {isMobile && (
          <SidebarMobile
            setActiveSubMenu={setActiveSubMenu}
            activeSubMenu={activeSubMenu}
            url={url}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
          />
        )}
        <div className="flex justify-between items-center w-full">
          <div
            onClick={() => history.push("/administrador/artistas")}
            className="flex cursor-pointer items-center space-x-5 my-5"
          >
            <ArrowNarrowLeftIcon className="w-6 h-6" />
            <span className="text-xl font-medium">Volver</span>
          </div>
          {isMobile && (
            <div
              className="text-white p-4 bg-white/40 inline-block z-40 rounded-full cursor-pointer hover:scale-105 transition-all"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <MenuIcon className="text-white w-8 h-8" />
            </div>
          )}
        </div>
        <div className=" flex w-full justify-between gap-10">
          <div className="lg:flex gap-x-16 relative hidden h-full min-w-[350px]">
            <div className="flex rounded-md bg-white/20 flex-col w-full max-w-[350px] z-20 h-full">
              {SidebarData.map((item, index) => (
                <Sidebar
                  key={item.title}
                  url={url}
                  item={item}
                  openSubMenu={index === activeSubMenu}
                  index={index}
                  setActiveSubMenu={setActiveSubMenu}
                  activeSubMenu={activeSubMenu}
                />
              ))}
            </div>
          </div>
          <div
            className={`${!openMenu && isMobile ? "blur-3xl" : "blur-none"
              } w-full lg:flex lg:justify-between max-w-[900px] flex-grow overflow-x-scroll overflow-y-auto min-h-fit`}
          >
            <Switch>
              <Route path={`${path}/perfil`}>
                <AdministrarArtista
                  handleAbrirDialogo={handleAbrirDialogo}
                  handleCerrarDialogo={handleCerrarDialogo}
                />
              </Route>
              <Route path={`${path}/informacion-perfil`}>
                <ProfileInfo />
              </Route>
              <Route path={`${path}/completar-tareas`}>
                <CompletarTareas />
              </Route>
              <Route path={`${path}/redes-sociales`}>
                <RedesSociales />
              </Route>
              <Route path={`${path}/logros`}>
                <Acchievements
                  modalAcchievement={setModalAcchievements}
                  modalRemoveAcchievement={openModalRemoveAcchievement}
                  modalEditAcchievement={openModalEditAcchievement}
                />
              </Route>
              <Route path={`${path}/agregar-album`}>
                <AgregarAlbum />
              </Route>
              <Route path={`${path}/agregar-sencillo`}>
                <AgregarSencillo />
              </Route>
              <Route path={`${path}/historial-pago`}>
                <PaymentHistory />
              </Route>
              <Route path={`${path}/subir-pago`}>
                <div className="w-full flex justify-center">
                  <SubirPago />
                </div>
              </Route>
              <Route path={`${path}/tipo-perfil`}>
                <div className="w-full flex justify-center">
                  <EditarPerfil artista={artista} />
                </div>
              </Route>
              <Route path={`${path}/historial-regalias`}>
                <RoyaltyHistory
                  modalConfirm={openModalConfirm}
                  modalRegalias={openModalRegalias}
                />
              </Route>
            </Switch>
          </div>
        </div>
        {/* //{" "} */}
      </div>
      {modalConfirm && (
        <div className="w-full h-[200%] md:h-full absolute  flex justify-center items-center bg-black bg-opacity-40 z-50">
          <ModalConfirm modalConfirm={openModalConfirm} />
        </div>
      )}
      {modalRegalias && (
        <div className="w-full h-screen md:h-full fixed flex justify-center items-center bg-black bg-opacity-40 px-8 z-50 ">
          <ModalRegalias
            modalRegalias={openModalRegalias}
            rows={regalias}
            id={rowId}
          />
        </div>
      )}
      {modalAcchievements && (
        <Modal>
          <ModalAcchievements setModalAcchievements={setModalAcchievements} />
        </Modal>
      )}
      {modalRemoveAcchievement && (
        <Modal>
          <RemoveAcchievement
            closeModal={setModalRemoveAcchievement}
            idAcchivement={rowId}
          />
        </Modal>
      )}
      {modalEditAcchievement && (
        <Modal>
          <EditAcchievement
            closeModal={setModalEditAcchievement}
            idAcchivement={rowId}
          />
        </Modal>
      )}
    </>
  );
};

export default ProfileRoute;
