const Bank = (props) => (
  <svg
    width={20}
    height={19}
    fill={"none"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 4.367H3a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-9a2 2 0 0 0-2-2h-5m-4 0v-1a2 2 0 1 1 4 0v1m-4 0a2 2 0 1 0 4 0m-5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 0c1.306 0 2.417.835 2.83 2m-2.83-2a3.001 3.001 0 0 0-2.83 2m8.83-5h3m-3 4h2"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Bank;
