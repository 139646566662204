import { ERROR } from "../utilidades/Constantes";
import firebase from "./Firebase";

const functions = firebase.functions();
// functions.useEmulator("localhost", 5001);

export const llamarFunction = async (nombreFunction, datos) => {
  const callable = functions.httpsCallable(nombreFunction);
  try {
    const result = await callable(datos);
    if (result.data === "exito") {
      return result.data;
    }
  } catch (error) {
    return { ...error, resultado: ERROR };
  }
};
