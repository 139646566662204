import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions/sesionActions";
import Cargando from "../../general/Cargando";
import { Link, Redirect } from "react-router-dom";
import DialogoUnBotonPerfil from "../../sesionUsiaro/perfil/components/DialogoUnBotonPerfil";
import DialogoTerminos from "../TerminosCondiciones/DialogoTerminos";
import { terminos } from "../TerminosCondiciones/TextoTerminosCondiciones";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";

import { getUid, getUser } from "../../../reducers/sesionReducer";
import {
  ERROR,
  ERROR_PASSWORD_INCORRECTO,
  ERROR_USUARIO_DESCONOCIDO,
  OK,
  ERROR_CORREO_INVALIDO,
  SIN_SESION,
  ERROR_MUCHOS_INTENTOS,
  ROL_ADMIN,
  ROL_ARTISTA,
} from "../../../utilidades/Constantes";
import handPhone from "../../../assets-2/handPhone.png";

class DialogoIniciarSesion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      name: "",
      cambiarContra: false,
      cargando: false,
      terminos: false,
      dialogo: {
        abrir: false,
      },
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.uid === null && this.props.uid !== null) {
      this.visualizacionDialogo(this.props.uid);
    }
    if (prevProps.uid === SIN_SESION) this.props.limpiarUsuario();
  }

  visualizacionDialogo = (uidUser) => {
    if (uidUser === ERROR_PASSWORD_INCORRECTO) {
      this.setState({ password: "" });
      return this.abrirDialogo(1);
    } else if (uidUser === ERROR_USUARIO_DESCONOCIDO)
      return this.abrirDialogo(2);
    else if (uidUser === ERROR_MUCHOS_INTENTOS) return this.abrirDialogo(6);
    else if (uidUser === ERROR) return this.abrirDialogo(3);
    else
      this.setState({
        cargando: false,
      });
  };

  abrirDialogo = (tipo) => {
    let info = {};
    switch (tipo) {
      case 1:
        info = {
          abrir: true,
          titulo: "Contraseña Incorrecta",
          descripcion:
            "La contraseña que ingresaste no es correcta, inténtanlo de nuevo",
          boton: "Intentar de nuevo",
        };
        break;
      case 2:
        info = {
          abrir: true,
          titulo: "Correo Incorrecto",
          descripcion:
            "Parece que el correo que ingresaste no se encuentra registrado, inténtalo de nuevo",
          boton: "Intentar de nuevo",
        };
        break;
      case 3:
        info = {
          abrir: true,
          titulo: "Problemas",
          descripcion:
            "Parece que hubo un problema, por favor intenta de nuevo más tarde",
          boton: "Intentar de nuevo",
        };
        break;
      case 4:
        info = {
          abrir: true,
          titulo: "Correo Enviado",
          descripcion:
            "Se te envio un correo de recuperación al correo que ingresaste, revisa tu bandeja de entrada para recuperar tu contraseña",
          boton: "Listo",
        };
        break;
      case 5:
        info = {
          abrir: true,
          titulo: "Acepta Términos",
          descripcion:
            "Para poder continuar con tu registro como artista debes aceptar los términos y condiciones.",
          boton: "Listo",
        };
        break;
      case 6:
        info = {
          abrir: true,
          titulo: "Has realizado muchos intentos",
          descripcion: "Por favor espera un momento y vuelve a intentarlo",
          boton: "Intentar de nuevo",
        };
        break;
      default:
        break;
    }
    this.setState({
      cargando: false,
      dialogo: info,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      cargando: true,
    });
    this.props.ingresar(this.state.email, this.state.password);
  };

  handleSubmitContra = (e) => {
    e.preventDefault();
    this.setState({
      cargando: true,
    });
    this.recuperarPassword();
  };

  recuperarPassword = async () => {
    let respuesta = await actions.recuperarPassword(this.state.email);
    if (respuesta === OK) {
      this.abrirDialogo(4);
      this.setState({ cambiarContra: false });
    }
    if (respuesta === ERROR_CORREO_INVALIDO) this.abrirDialogo(2);
    if (respuesta === ERROR) this.abrirDialogo(3);
  };

  handleComplete = (e) => {
    e.preventDefault();
    if (this.state.terminos) {
      this.setState({
        cargando: true,
      });
      actions
        .registroCompletoArtista(this.state.name, this.props.uid)
        .then((res) => {
          if (res.error) this.abrirDialogo(3);
        });
    } else {
      this.abrirDialogo(5);
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  cerrarDialogo = () => {
    this.setState({
      dialogo: {
        abrir: false,
      },
    });
    this.props.limpiarUsuario();
  };

  render() {
    let { cerrar, user } = this.props;
    let completarReg = false;
    
    if (user !== null) {
      if (user?.rol?.id === ROL_ADMIN) return <Redirect to="/administrador" />;
      else if (user?.rol?.id === ROL_ARTISTA && user.nombre) {
        return <Redirect to="/artista" />;
      } else if(user?.id) completarReg = true;
    }
    return (
      <div className=" flex w-full h-screen flex-col lg:flex-row font-proxima ">
        {this.state.cargando && <Cargando />}
        {this.state.dialogo.abrir && (
          <DialogoUnBotonPerfil
            textoBoton={this.state.dialogo.boton}
            funcion={this.cerrarDialogo}
            titulo={this.state.dialogo.titulo}
            descripcion={this.state.dialogo.descripcion}
          />
        )}
        <div className="bg-[#E84A84] w-full lg:w-[75%] h-full flex flex-col items-center justify-between px-8">
          <div className="flex flex-col items-center space-y-6 mt-36 container m-auto ">
            <h2 className="text-2xl sm:text-3xl md:text-5xl font-semibold">
              Bienvenido
            </h2>
            <p className="w-[60%] text-sm sm:text-base md:text-lg text-center">
              Si aún no tienes una cuenta Black Lion con nosotros, contáctanos
              para brindarte la posibilidad de unirte a esta gran familia
            </p>
            <Link
              to="/contacto"
              className="border border-white rounded-lg px-9 py-3 hover:bg-white hover:text-[#E84A84] hover:font-semibold transition-all duration-300 ease-in-out"
            >
              Contacto
            </Link>
            <Link
              to="/"
              className="border-b-0 hover:border-b-2 transition-all duration-100 ease-linear"
            >
              Volver
            </Link>
          </div>
          <div className="w-full">
            <img
              className="w-1/2 md:w-2/5 m-auto"
              src={handPhone}
              alt="ilustracion de una mano 3d"
            />
          </div>
        </div>
        <div className="fondo-dialogo-rc" onClick={cerrar} />
        <div className="flex flex-col justify-center items-center md:w-full lg:w-[70%] 2xl:w-1/2 bg-[#090930]">
          {this.state.cambiarContra ? (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <h2 className="text-2xl sm:text-3xl md:text-5xl mt-36 font-semibold">
                ¿Olvidaste tu contraseña?
              </h2>
              <div className="flex flex-col my-auto w-3/5 space-y-7 mt-8">
                <form
                  className="flex flex-col space-y-6"
                  onSubmit={this.handleSubmitContra}
                >
                  <span className="text-white text-lg">
                    No te preocupes. Te enviaremos un correo para que puedas
                    acceder a tu cuenta.
                  </span>
                  <div className="flex flex-col space-y-5">
                    <label className="text-white">Correo electrónico</label>
                    <input
                      className="w-fultext-white font-normal bg-transparent rounded-md border-b-2 border-white outline-none p-2"
                      name="email"
                      onChange={this.handleChange}
                      type="email"
                      value={this.state.email}
                      required
                    />
                  </div>
                  <button type="submit" className="btn--secondary mt-5 w-2/4">
                    Enviar
                  </button>
                </form>
                <ArrowCircleLeftIcon
                  onClick={
                    this.state.cambiarContra
                      ? () => this.setState({ cambiarContra: false })
                      : completarReg
                      ? () => this.props.limpiarUsuario()
                      : cerrar
                  }
                  alt="Atras"
                  className="w-8 h-8 cursor-pointer hover:scale-110 transition-all duration-200 ease-in-out hover:text-pink-400"
                />
              </div>
            </div>
          ) : !completarReg ? (
            <div className="w-full flex flex-col items-center justify-center pb-20 md:pb-32">
              <h2 className="text-2xl sm:text-3xl md:text-5xl mt-36 font-semibold">
                Inicia Sesión
              </h2>
              <div className="flex flex-col my-auto w-3/5 space-y-7 mt-8">
                <form
                  className="flex flex-col space-y-5"
                  onSubmit={this.handleSubmit}
                >
                  <div className="flex flex-col space-y-5">
                    <label className="text-white text-base md:text-xl font-semibold">
                      Correo electrónico
                    </label>
                    <input
                      className="w-fultext-white font-normal bg-transparent rounded-md border-b-2 border-white outline-none p-2"
                      name="email"
                      style={{ backgroundColor: "transparent" }}
                      onChange={this.handleChange}
                      type="email"
                      value={this.state.email}
                      required
                    />
                  </div>
                  <div className="flex flex-col space-y-5">
                    <label className="text-white text-base md:text-xl font-semibold">
                      Contraseña
                    </label>
                    <input
                      className="text-white font-normal bg-transparent border-b-2 border-white outline-none p-2 rounded-md "
                      name="password"
                      onChange={this.handleChange}
                      required
                      value={this.state.password}
                      type="password"
                      minLength={6}
                    />
                  </div>
                  <div className="flex flex-col justify-center items-center lg:items-end">
                    <span
                      className="text-pink-500 mt-3 cursor-pointer hover:text-pink-200"
                      onClick={() => this.setState({ cambiarContra: true })}
                    >
                      ¿Olvidaste tu contraseña?
                    </span>
                    <button
                      type="submit"
                      className="btn--secondary gradient mt-5 w-2/4"
                    >
                      Ingresar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <DialogoTerminos
              name={this.state.name}
              terminos={terminos}
              onChange={this.handleChange}
              onComplete={this.handleComplete}
              registro={true}
              textArea={
                "w-full my-2 p-1 text-base text-white bg-white/30 border-b border-white"
              }
              title="text-base md:text-lg text-white mt-6"
              checkTerms="mr-4 w-5 h-5 bg-white"
              labelTerms="flex flex gap-2 justify-center"
            />
          )}
        </div>
      </div>
    );
  }
}

DialogoIniciarSesion.propTypes = {
  cerrar: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  uid: getUid(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogoIniciarSesion);
