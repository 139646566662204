import { Route, Switch } from "react-router-dom";
import Tema from "./components/tema/Tema";
import Curso from "./components/curso/Curso";
import FooterSesion from "./components/footer/FooterSesion";
import HomeAcademia from "./components/homeAcademia/HomeAcademia";
import HeaderPerfil from "../perfil/components/HeaderPerfil";

const Academia = () => {
  return (
    <div className="bg-gradient-to-tl from-[#090930] to-[#5D2D8C] w-full h-full">
      <HeaderPerfil />
      <Switch>
        <Route
          path="/academia/:idTema/categorias/:idCategoria/cursos/:idCurso"
          component={Curso}
        />
        <Route path="/academia/:idTema" component={Tema} />
        <Route path="/academia" component={HomeAcademia} />
      </Switch>
      <FooterSesion />
    </div>
  );
};

export default Academia;
