import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { eliminarUltimaRegalia } from "../../../actions/adminActions";
import { getRegalias } from "../../../reducers/adminReducer";

const ModalConfirm = ({ modalConfirm }) => {
  const { params } = useRouteMatch();
  const dispatch = useDispatch();
  const regalias = useSelector(getRegalias);
  const regaliasOrdenadas = regalias.sort();
  return (
    <div className=" bg-white rounded-xl py-7 px-7 gap-6 text-center flex flex-col justify-center items-center text-gray-700 sm:w-3/6 md:h-48 lg:gap-16 lg:h-60 mx-8 xl:w-5/12 border-2 max-w-[565px]">
      <p className="font-bold lg:text-xl">
        ¿Deseas eliminar el último historial de las regalías{" "}
        {new Date(regaliasOrdenadas[0].fecha).toLocaleString()}?
      </p>
      <div className="space-x-6 text-sm w-full flex justify-center">
        <button
          onClick={modalConfirm}
          className="border-2 border-rosa-primariy rounded py-1 px-4 text-rosa-primariy hover:bg-rosa-primariy hover:text-white hover:border-2 hover:border-rosa-primariy sm:w-30 md:w-36"
        >
          Cancelar
        </button>
        <button
          onClick={() => {
            eliminarUltimaRegalia(dispatch, params.id);
            modalConfirm();
          }}
          className="bg-rosa-primariy text-white border-2 border-rosa-primariy rounded py-1 px-4 hover:border-2 hover:border-rosa-primariy hover:bg-white hover:rounded hover:text-rosa-primariy sm:w-30 md:w-36"
        >
          Confirmar
        </button>
      </div>
    </div>
  );
};

export default ModalConfirm;
