import { IoIosClose } from "react-icons/io";
import TableModal from "./TableModal";

const headersDistribuidoras = [
  "Nombre",
  "Porcentaje",
  "Comisión",
  "Valor Generado Periodo",
  "Valor Histórico Generado",
];

const headersCostos = ["Nombre", "Valor"];

const headersPlataformas = ["Nombre", "Valor registrado", "Comisión", "Total"];

const ModalRegalias = ({ modalRegalias, rows, id }) => {
  return (
    <div className="relative bg-white px-5 py-8 rounded-lg w-full md:w-auto h-full space-y-3 max-w-[800px] max-h-[600px]">
      <div className="h-full overflow-y-scroll">
        <h1 className="text-gray-800 font-bold font-proxima">
          Regalías de las distribuidoras
        </h1>
        <IoIosClose
          className="cursor-pointer text-rosa-primariy absolute right-2 top-2"
          fontSize={30}
          onClick={modalRegalias}
        />
        <div className="shadow-md xl:mt-4 rounded-lg space-y-10">
          <TableModal
            headers={headersDistribuidoras}
            rows={rows}
            id={id}
            distribuidoras={true}
          />
        </div>
        <h1 className="text-gray-800 font-bold font-proxima mt-4">
          Cobros adicionales
        </h1>
        <div className="shadow-md xl:mt-4 rounded-lg border-2 space-y-10">
          <TableModal
            headers={headersCostos}
            rows={rows}
            id={id}
            costos={true}
          />
        </div>
        <h1 className="text-gray-800 font-bold font-proxima mt-4">
          Regalías de las plataformas
        </h1>
        <div className="shadow-md xl:mt-4 rounded-lg border-2 space-y-10">
          <TableModal headers={headersPlataformas} rows={rows} id={id} />
        </div>
      </div>
    </div>
  );
};

export default ModalRegalias;
