import firebase from "firebase";
require("dotenv").config();

export const config = {
  apiKey: "AIzaSyCqxCmuP1WT23BCdofFaMTmvEW0gCXoLIU",
  authDomain: "blacklion-bb341.firebaseapp.com",
  databaseURL: "https://blacklion-bb341.firebaseio.com",
  projectId: "blacklion-bb341",
  storageBucket: "blacklion-bb341.appspot.com",
  messagingSenderId: "49646434184",
  appId: "1:49646434184:web:8524770ff2419b66c1ac3d",
  measurementId: "G-T7KNWDRKF5",
};

firebase.initializeApp(config);
export default firebase;
