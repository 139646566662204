import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles/dialogo-agregar-cuenta.sass";
import FormCuenta from "./FormCuenta";
import DialogoTerminos from "../../../general/TerminosCondiciones/DialogoTerminos";
import InfoCuenta from "./InfoCuenta";
import { terminosBancarios } from "../../../general/TerminosCondiciones/TextoTerminosCondiciones";
import DialogoUnBotonPerfil from "./DialogoUnBotonPerfil";
import Cargando from "../../../general/Cargando";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch } from "react-redux";
import { editarInfoBancaria } from "../../../../actions/sesionActions";

const DialogoAgregarCuenta = ({idArtista, banco, tipo, numero, titular, identificacion, paisBanco, cuentaAgregada, conTitulo, nombre, solicitarPago, cerrarDialogo}) => {
  const [state, setState] = useState({
    cargando: false,
    dialogo: "",
    dialogoMensaje: {
      abrir: false,
      titulo: "",
      descripcion: "",
      boton: "",
    },
    terminos: false,
    infoBanco: {
      entidad: banco ?? "",
      tipo: tipo ?? "",
      numero: numero ?? "",
      titular: titular ?? "",
      identificacion: identificacion ?? "",
      pais: paisBanco ?? "",
    },
  });
  const dispatch = useDispatch();

  const traerInfoBanco = (datosForm) => {
    setState({
      ...state,
      infoBanco: datosForm,
      dialogo: "terminos",
    });
  }

  const devolverAtras = () => {
    switch (state.dialogo) {
      case "terminos":
        setState({ ...state, dialogo: "agregar" });
        break;
      case "agregar":
        setState({
          ...state,
          dialogo: "",
          infoBanco: {
            entidad: banco ?? "",
            tipo: tipo ?? "",
            numero: numero ?? "",
          },
        });
        break;

      default:
        break;
    }
  }

  const handleComplete = (e, check) => {
    e.preventDefault();
    if (check)
      setState({
        ...state,
        dialogo: "",
        terminos: true,
      });
    else
      setState({
        ...state,
        dialogoMensaje: {
          abrir: true,
          titulo: "Acepta Términos",
          descripcion:
            "Para poder continuar con el registro de tu cuenta bancaria como artista debes aceptar los términos y condiciones.",
          boton: "Intentar de nuevo",
        },
      });
  }

  const actualizarCuenta = () => {
    setState({
      ...state,
      dialogo: "agregar",
      terminos: false,
    });
  }

  const seleccionarCuenta = () => {
    cuentaAgregada();

    let infoBanco = state.infoBanco;
    let solicitud = {};
    if (state.terminos && conTitulo) {
      solicitud = {
        titularCuenta: infoBanco.titular,
        banco: infoBanco.entidad,
        numeroCuenta: infoBanco.numero,
        paisBanco: infoBanco.pais,
        identificacion: infoBanco.identificacion,
        tipoCuenta: infoBanco.tipo,
        pagoSolicitado: true,
        nombre: nombre,
      };
    } else if (conTitulo) {
      solicitud = {
        pagoSolicitado: true,
      };
    } else if (state.terminos) {
      solicitud = {
        titularCuenta: infoBanco.titular,
        banco: infoBanco.entidad,
        numeroCuenta: infoBanco.numero,
        paisBanco: infoBanco.pais,
        identificacion: infoBanco.identificacion,
        tipoCuenta: infoBanco.tipo,
        nombre: nombre,
      };
    }
    setState({ ...state, cargando: true });

    editarInfoBancaria(idArtista, solicitud, dispatch)
    // actions
    //   .actualizarCuenta(uid, solicitud, correo)
    //   .then((res) => {
    //     if (res === OK) {
    //       setState({ ...state, cargando: false });
    //       cuentaAgregada();
    //     } else
    //       setState({
    //         ...state,
    //         cargando: false,
    //         dialogoMensaje: {
    //           abrir: true,
    //           titulo: "Problemas Técnicos",
    //           descripcion:
    //             "Parece que estamos teniendo problemas técnicos, intenta de nuevo o comunicate con nosotros",
    //           boton: "Intentar de nuevo",
    //         },
    //       });
    //   });
  };

    let datosBanco = state.infoBanco;
    return (
      <div className="fixed w-screen h-screen inset-0 flex justify-center items-center bg-pink-500/40 z-[100]">
        <div
          className={` w-[80%] md:w-[60%] font-proxima absolute bg-white flex flex-col items-center z-[150] rounded-md py-4 ${
            conTitulo ? "w-[60%] h-auto pt-4 pb-12" : "w-[80%] "
          }`}
        >
          {conTitulo && (
            <h3 className="text-lg md:text-xl font-bold text-rosa-darkBlue mt-4 mb-8">
              Cuenta para pago
            </h3>
          )}
          {state.cargando && <Cargando />}
          {state.dialogoMensaje.abrir ? (
            <DialogoUnBotonPerfil
              textoBoton={state.dialogoMensaje.boton}
              titulo={state.dialogoMensaje.titulo}
              descripcion={state.dialogoMensaje.descripcion}
              funcion={() => setState({...state, dialogoMensaje: { abrir: false, titulo: "", descripcion: "", boton: "" }})}
            />
          ) : (
            ""
          )}
          <div className="flex justify-center items-center w-full">
            {state.dialogo === "terminos" ? (
              <DialogoTerminos
                funcAtras={devolverAtras}
                onComplete={handleComplete}
                registro={false}
                terminos={terminosBancarios}
                textArea="w-full my-2 p-1 text-base text-black scrollbar-artista scrollbar-thumb"
                title="text-base md:text-lg text-black mt-6"
                volver="text-rosa-primariy font-semibold hover:underline transition duration-300"
                button="btn--primary-sm "
                checkTerms="mr-4 w-5 h-5 focus:text-rosa-primariy"
                labelTerms="flex flex-row-reverse justify-end text-rosa-primariy gap-y-2"
              />
            ) : state.dialogo === "agregar" ? (
              <FormCuenta
                datos={datosBanco.numero ? datosBanco : null}
                funcDatos={traerInfoBanco}
                funcAtras={devolverAtras}
              />
            ) : datosBanco.numero ? (
              <InfoCuenta
                tipo={state.infoBanco.tipo}
                numero={state.infoBanco.numero}
                banco={state.infoBanco.entidad}
                actualizar={state.terminos}
                conPago={conTitulo}
                seleccionarCuenta={seleccionarCuenta}
                funcionCuenta={actualizarCuenta}
                cerrarDialogo={cerrarDialogo}
                solicitarPago={solicitarPago}
              />
            ) : (
              <div className="w-full flex flex-col px-10">
                <div className="flex justify-end absolute inset-0 p-2">
                  <XIcon
                    onClick={cerrarDialogo}
                    className="w-8 h-8 text-rosa-primariy transition duration-300 cursor-pointer hover:scale-110"
                  />
                </div>
                <span className="text-rosa-darkBlue flex flex-col justify-center items-center">
                  Sin cuenta creada
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  
}

DialogoAgregarCuenta.propTypes = {
  uid: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  correo: PropTypes.string.isRequired,
  conTitulo: PropTypes.bool.isRequired,
  banco: PropTypes.string,
  titular: PropTypes.string,
  tipo: PropTypes.string,
  numero: PropTypes.string,
  identificacion: PropTypes.string,
  paisBanco: PropTypes.string,
  cuentaAgregada: PropTypes.func,
};

export default DialogoAgregarCuenta;
