// import { CheckCircleIcon } from "@heroicons/react/outline";
import checkIcon from "../../assets-2/checkIcon.svg";
import phoneSpotify from "../../assets-2/phone-spotify.png";
import shazam from "../../assets-2/shazam.svg";
import itunes from "../../assets-2/itunes.svg";
import appleMusic from "../../assets-2/apple-music.svg";
import youtube from "../../assets-2/youtube-music.svg";
import claroMusic from "../../assets-2/claro-music.svg";
import deezer from "../../assets-2/deezer.svg";
import spotify from "../../assets-2/spotify.svg";
import amazonMusic from "../../assets-2/amazon-music.svg";

const SecondService = () => {
  return (
    <div className=" bg-[rgb(0,0,35)] ">
      <section className="container mx-auto flex justify-around flex-col-reverse lg:flex-row-reverse font-proxima gap-10 items-center py-16">
        <div className="w-auto flex flex-col justify-center items-center mt-8 lg:mt-0 ">
          <img
            src={phoneSpotify}
            className="w-[70%] lg:w-auto"
            alt="iamgen estadistica"
          />
        </div>
        <div className="space-y-8 flex flex-col w-[75%]">
          <h3 className="text-3xl md:text-4xl lg:text-[40px] font-semibold text-center md:text-center mb-8">
            Plataformas <span className="text-[#E84A84]">Digitales</span>{" "}
          </h3>
          <div className="flex flex-col items-center justify-center">
            <ul className="flex flex-col gap-y-7 mx-auto">
              <li className="flex items-start  gap-4">
                <div className="w-[15%] flex justify-center items-center mt-2">
                  <img className="w-[30%]" src={checkIcon} alt="" />
                </div>
                <span className=" w-full text-sm md:text-lg">
                  Distribuimos tu música en todas las plataformas digitales
                </span>
              </li>
              <li className=" flex items-start  gap-4">
                <div className="w-[15%] flex justify-center items-center mt-2">
                  <img className="w-[30%]" src={checkIcon} alt="" />
                </div>
                <span className=" w-full text-sm md:text-lg">
                  Acceso a estadísticas
                </span>
              </li>
              <li className=" flex items-start md:items-start gap-4">
                <div className="w-[15%] flex justify-center items-center mt-2">
                  <img className="w-[30%]" src={checkIcon} alt="" />
                </div>
                <span className=" w-full  text-sm md:text-lg">
                  Rastreamos y eliminamos tu contenido distribuido ilegalmente
                </span>
              </li>
            </ul>
            <div className="mt-5 w-[100%] md:w-full flex flex-wrap gap-10 pt-10 justify-center items-center">
              <img src={shazam} className="w-[10%] md:w-10" alt="shazam-icon" />
              <img src={itunes} className="w-[10%] md:w-10" alt="itunes-icon" />
              <img
                src={appleMusic}
                className="w-[10%] md:w-10"
                alt="apple-icon"
              />
              <img
                src={youtube}
                className="w-[10%] md:w-10"
                alt="youtube-icon"
              />
              <img
                src={claroMusic}
                className="w-[10%] md:w-10"
                alt="claromusic-icon"
              />
              <img src={deezer} className="w-[10%] md:w-10" alt="deezer-icon" />
              <img
                src={spotify}
                className="w-[10%] md:w-10"
                alt="spotify-icon"
              />
              <img
                src={amazonMusic}
                className="w-[10%] md:w-10"
                alt="amazon-icon"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SecondService;
