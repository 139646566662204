const TableModal = ({
  headers = [],
  rows = [],
  id,
  distribuidoras,
  costos,
}) => {
  const encontrarRegalia = (id) => {
    return rows.filter((row) => row.id === parseInt(id));
  };
  return (
    <div className="text-right font-proxima h-full mt-4 xl:m-0 lg:w-full border-2">
      <div className="relative h-auto overflow-x-auto mb-4 rounded-lg">
        <table className="w-full text-xs text-center text-gray-500 md:text-base">
          <thead className="text-xs text-black bg-gray-100 md:text-base">
            <tr>
              {headers.map((header) => (
                <th scope="col" className="px-6 py-3">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {distribuidoras
              ? encontrarRegalia(id)?.map((row) =>
                  row?.regaliasDistribuidora?.map((distribuidora) => (
                    <tr className="hover:bg-gray-100 cursor-pointer bg-white border-b dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-6 py-4 font-semibold whitespace-nowrap"
                      >
                        {distribuidora?.distribuidora?.nombre ||
                          "No disponible"}
                      </th>

                      <td className="px-6 py-4">
                        {distribuidora?.distribuidora?.porcentaje
                          ? distribuidora?.distribuidora?.porcentaje + "%"
                          : "No disponible"}
                      </td>
                      <td className="px-6 py-4">
                        {distribuidora?.comision || "No disponible"}
                      </td>
                      <td className="px-6 py-4">
                        {distribuidora?.valorGeneradoPeriodo === null
                          ? "No disponible"
                          : distribuidora?.valorGeneradoPeriodo}
                      </td>
                      <td className="px-6 py-4">
                        {distribuidora?.valorHistoricoGenerado ||
                          "No disponible"}
                      </td>
                    </tr>
                  ))
                )
              : costos
              ? encontrarRegalia(id)?.map((row) =>
                  row?.cobrosAdicionales?.map((cobros) => (
                    <tr className="hover:bg-gray-100 cursor-pointer bg-white border-b dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-6 py-4 font-semibold whitespace-nowrap"
                      >
                        {cobros?.nombre ?? "No disponible"}
                      </th>
                      <td className="px-6 py-4">
                        {cobros?.valor ?? "No disponible"}
                      </td>
                    </tr>
                  ))
                )
              : encontrarRegalia(id)?.map((row) =>
                  row?.regaliasPlataforma?.map((plataforma) => (
                    <tr className="hover:bg-gray-100 cursor-pointer bg-white border-b dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-6 py-4 font-semibold whitespace-nowrap"
                      >
                        {plataforma?.plataforma?.nombre || "No disponible"}
                      </th>
                      <td className="px-6 py-4">
                        {plataforma?.valor || "No disponible"}
                      </td>
                      <td className="px-6 py-4">{plataforma?.comision || 0}</td>
                      <td className="px-6 py-4">
                        {plataforma?.valorGenerado || "No disponible"}
                      </td>
                    </tr>
                  ))
                )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableModal;
