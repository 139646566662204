import React, { useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Inicio from "./pages/Inicio.js";
import Contacto from "./pages/contacto/Contacto";
import Perfil from "./pages/sesionUsiaro/perfil/Perfil";
import Administrador from "./pages/administrador/Administrador";

import KnowUs from "./pages/KnowUs/KnowUs";
import Footer from "./pages/general/Footer";
import Services from "./pages/Services/Services";
import Menu from "./pages/general/NavMenu";
import InicioSesion from "./pages/general/inicioSesion/InicioSesion";
import Academia from "./pages/sesionUsiaro/academia/Academia.jsx";
import PaginaTerminos from "./pages/general/TerminosCondiciones/PaginaTerminos";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ModalContext } from "./context/ModalContext";
import AbsoluteBackground from "./pages/general/AbsoluteBackground";

function App() {
  const [modal, setModal] = useState(null)

  return (
    <ModalContext.Provider value={{setModal}}>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={InicioSesion} />
          <Route path="/administrador" component={Administrador} />
          <Route path="/artista" component={Perfil} />
          <Route path="/academia" component={Academia} />
          <Route path="/">
            <Menu />
            <Switch>
              <div className="bg-[#090930]">
                <Route path="/" exact component={Inicio} />
                <Route path="/contacto-enviado" component={Contacto} />
                <Route path="/conocenos" component={KnowUs} />
                <Route path="/servicios" component={Services} />
                <Route path="/terminos-condiciones" component={PaginaTerminos} />
              </div>
            </Switch>
            <Contacto />
            <Footer />
          </Route>
        </Switch>
        <ToastContainer />
      </BrowserRouter>
      {
        modal &&
          <AbsoluteBackground modal={modal}/>
      }
    </ModalContext.Provider>
  );
}

export default App;
