import { ERROR, OK } from "../utilidades/Constantes";
import firebase from "./Firebase";
const storageRef = firebase.storage().ref();

export const subirArchivoProgreso = async (ruta, file, onChange) => {
  const ref = storageRef.child(ruta);
  return new Promise((resolve, reject) => {
    ref.put(file).on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onChange(progress)
      },
      (error) => {
        reject(ERROR)
      },
      async () => {
        const url = await ref.getDownloadURL();
        console.log(url);
        resolve(url);
      }
    );
  });
};

export const subirArchivo = (ruta, file) => {
  const ref = storageRef.child(ruta);
  return ref
    .put(file)
    .then(() => {
      return ref.getDownloadURL();
    })
    .catch((error) => ERROR);
};

export const eliminarArchivo = async (ruta) => {
  const ref = storageRef.child(ruta);
  return await ref.delete();
};

export const eliminarArchivosCarpeta = async (ruta) => {
  const mountainsRef = storageRef.child(ruta);
  mountainsRef
    .listAll()
    .then((result) => {
      result.items.forEach((file) => {
        file.delete();
      });
      return OK;
    })
    .catch((error) => {
      return ERROR;
    });
};
